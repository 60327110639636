import { OrderFilter, OrderMarket } from "../../../domestic/orders/types";
import { ViewOrder, GetOrdersParams } from "../../../domestic/orders/types";
import { User } from "../../../auth/types";
import { webApiWithResponse } from "../../../../core/http/httpThunks";
import { getParamsDateString } from "../../../../core/util/functions";

const getOrdersWebApi = (
  user: User,
  page?: number,
  itemsPerPage?: number,
  filter?: OrderFilter,
  defaultOrderStatus?: number,
) => {
  const params: GetOrdersParams = {
    userid: user.userId,
//    cliid: user.clientId,
    secid: filter?.security?.securityId,
    exchid: filter?.exchange?.exchId,
    market: OrderMarket.Foreign,
    side: filter?.side,
    fromrow: page && itemsPerPage ? page * itemsPerPage : 0,
    numofrows: itemsPerPage,
    status: filter?.orderStatus?.ftValue || defaultOrderStatus,
    dateFrom: getParamsDateString(filter?.dateFrom),
    dateTo: getParamsDateString(filter?.dateTo),
  };

  return webApiWithResponse<ViewOrder[]>({
    method: "GET",
    url: "order/getorderlist",
    params,
  });
};

export default getOrdersWebApi;

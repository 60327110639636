import React from "react";
import { Grid, CardContent, CardHeader, Divider, Card, Fab } from "@material-ui/core";
import { SupportTable } from "./SupportTable";
import { usePageStyles } from "../../pageStyles";
import { PageComponent, AccessRights } from "../../../core/navigation/types";
import { useHistory } from "react-router-dom";
import { PageTitle } from "../../components/common/PageTitle";
import { useSupportData } from "../../../data/support/hooks/useSupportData";

const SupportPage: PageComponent = ({ userAccessRights }) => {
	const pageStyles = usePageStyles();

	const [data, loadAll] = useSupportData();

	React.useEffect(() => {
		loadAll();
	}, [loadAll ]);

	const history = useHistory();

	return (
		<Grid container className={pageStyles.root}>
			<Grid item xs={12} className={pageStyles.gridItem}>
				<Card>
					<CardHeader
						title={<PageTitle title="navSupport" />}
						/>
					<Divider />
					<CardContent>
						<SupportTable userAccessRights={userAccessRights} />
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
};


export default SupportPage;

import { ThunkType } from "../../../../core/store/reducers";
import { getCashBalanceApi } from "../api/getCashBalanceData";
import { setData } from "../cashBalListDataSlice";

export const loadCashBalanceData = (): ThunkType => async (dispatch, getStore) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return;
  }

  dispatch(setData());

  const { userId } = store.data.auth.current.user;
  const cashBalList = await dispatch(getCashBalanceApi(userId));

  dispatch(setData(cashBalList || []));
};

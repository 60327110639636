
import { httpWebApi } from "../../../../core/http/httpThunks";
import { LoginResponse } from "../../../models";
import { LocalizationLanguage } from "../../../../core/localization/types";

export const otherSettingsApi = (userid: string, confirmOrder: number) =>
	httpWebApi<LoginResponse>({
		method: "PUT",
		url: "user/setusersettings",
		params: {
			userid,
		},
		data: {
			confirmOrder: confirmOrder,
		},
	}
	)




import React from "react";
import { useDispatch } from "react-redux";
import { MarketListData } from "../types";
import { setSecurity } from "../marketsDataSlice";
import { loadMarketData } from "../thunks/loadMarketData";

export const useMarketSecurityData = () => {
    const dispatch = useDispatch();
	return React.useCallback((security: MarketListData) => {
            dispatch(setSecurity(security.securityId));
            dispatch(loadMarketData());
        },
        [dispatch]
    );
};



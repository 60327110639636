import MainBoard from "./MainBoard";
import { NavigationItem } from "../../../core/navigation/types";
import { mainBoardLocation  } from "./Locations";

export const mainBoardComponents: NavigationItem[] = [
	{
		Component: MainBoard,
		isMenuItem: true,
		path: mainBoardLocation,
		notifications: true,
	},
];

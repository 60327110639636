import React from "react";
import { Grid, Paper, Button } from "@material-ui/core";
import { LocalizationContext } from "../../../core/localization/Localization";
import { MarketFilter } from "../../../data/domestic/mainBoard/types";
import { useAuth } from "../../../data/auth/hooks";
import { DisplayDrawerContext } from "../../../layout/DisplayDrawerContext";
import { SecuritySelect } from "../../components/tables/SecuritySelect";
import { useAddSecurity } from "../../../data/domestic/orders/hooks/useMarketDataAddSecurity";
import { useRemoveSecurity } from "../../../data/domestic/orders/hooks/useMarketDataRemoveSecurity";

interface MainBoardFilterEditorProps {
	filter?: MarketFilter;
	editFilter: (prop: keyof MarketFilter, value: any) => void;
	save: () => void;
	reset?: () => void;
}

export const MainBoardFilterEditor: React.FunctionComponent<MainBoardFilterEditorProps> = ({
	filter,
	editFilter,
	save,
	reset,
}) => {

	const showWithDrawer = React.useContext(DisplayDrawerContext);
	const { getString } = React.useContext(LocalizationContext);
	const [auth] = useAuth();
	const [fetchState, loadAddSecurity] = useAddSecurity();
	const [fetchStateRemove, loadRemoveSecurity] = useRemoveSecurity();

	const saveAdd = React.useCallback(
		async (secid: string) => {
				const result = await loadAddSecurity(secid);
			},
		[loadAddSecurity]
	); 

	const saveRemove = React.useCallback(
		async (secid: string) => {
				const result = await loadRemoveSecurity(secid);
			},
		[loadRemoveSecurity]
	); 

	return (
		<Paper
			style={{
				paddingLeft: "70px",
				paddingRight: "70px",
				paddingTop: "15px",
				paddingBottom: "15px",
			}}
		>
			<Grid container direction="column" spacing={6}>
				<Grid item>
					<SecuritySelect
						label={getString("orderSecurity")}
						predicate={security => security.securityId === filter?.secid}
						onChangeItem={security => {
							editFilter("secid", security?.securityId);
						}}
					/>
				</Grid>

				<Grid item container direction="row-reverse" spacing={2}>
					<Grid item>
						<Button
							variant="contained"
							color="secondary"
							onClick={() => {
								filter && filter.secid !== undefined && saveAdd(filter.secid);
								showWithDrawer(undefined);
							}}
							disabled = {filter === undefined || filter.secid === undefined}
						>
							{getString("marketDataAddSecurity")}
						</Button>
					</Grid>
					<Grid item>
						<Button 
							 variant="contained" 
							 color="secondary" 
							 onClick={ ()=> {
								filter && filter.secid !== undefined && saveRemove(filter.secid);
								showWithDrawer(undefined);
							}}
							disabled = {filter === undefined || filter.secid === undefined}
						>
							{getString("marketRemoveSecurity")}
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</Paper>
	);
};


import React from "react";
import { Grid, CardContent, CardHeader, Divider, Card, Fab } from "@material-ui/core";
import BanksTable from "./CashBalanceListTable";
import { usePageStyles } from "../../pageStyles";
import { PageComponent, AccessRights } from "../../../core/navigation/types";
import { useHistory } from "react-router-dom";
import { PageTitle } from "../../components/common/PageTitle";

const CashBalanceListPage: PageComponent = ({ userAccessRights }) => {
	const pageStyles = usePageStyles();

	const history = useHistory();

	return (
		<Grid container className={pageStyles.root}>
			<Grid item xs={12} className={pageStyles.gridItem}>
				<Card>
					<CardHeader
						title={<PageTitle title="navCashBalance" />}
					/>
					<Divider />
					<CardContent>
						<BanksTable userAccessRights={userAccessRights} />
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
};

export default CashBalanceListPage;

import React, { useState, useContext } from "react";
import { useLocalizationStore } from "../../data/localization/hooks";
import { useAuth, useLastLoginUsername } from "../../data/auth/hooks";
import { LocalizationContext } from "../../core/localization/Localization";
import { LocalizationLanguage } from "../../core/localization/types";
import { TextField, Grid, Button } from "@material-ui/core";
import { ToggleButtonGroup, ToggleButton } from "@material-ui/lab";
import { useLoginStyles } from "./useLoginStyles";
import { LoginError } from "./LoginError";

const handleTBChange = (setter: (value: string) => void) => (event: React.ChangeEvent<HTMLInputElement>) =>
	setter(event.target.value);

const onEnter = (action: () => void) => (e: React.KeyboardEvent<HTMLDivElement>) =>
	e.key === "Enter" && action();

export const LoginForm: React.FunctionComponent = props => {
	const styles = useLoginStyles();
	const { getString } = useContext(LocalizationContext);
	// const lastUsername = useLastLoginUsername();
	const [local, setLocal] = useLocalizationStore();
	const [username, setUsername] = useState<string>("");
	const [password, setPassword] = useState<string>("");
	const [authState, sendLogin] = useAuth();

	const loading = authState.status === "pending";
	const error = authState.status === "failed";
	const login = () => sendLogin(username, password, local, "W");

	return (
		<Grid
			container
			className={styles.formContainer}
			direction="column"
			justify="center"
			alignItems="center"
		>
			<Grid item className={styles.localeSelection}>
				<ToggleButtonGroup value={local} exclusive onChange={(e, v) => setLocal(v)} size="small">
					<ToggleButton value={LocalizationLanguage.EN} color="warning">EN</ToggleButton>
					<ToggleButton value={LocalizationLanguage.SR} color="warning">SR</ToggleButton>
				</ToggleButtonGroup>
			</Grid>
			<Grid item className={styles.formItem}>
				<TextField
					className={styles.textField}
					label={getString("username")}
					value={username}
					onChange={handleTBChange(setUsername)}
					error={error}
					onKeyDown={onEnter(login)}
					disabled={loading}
				/>
			</Grid>
			<Grid item className={styles.formItem}>
				<TextField
					className={styles.textField}
					label={getString("password")}
					type="password"
					value={password}
					onChange={handleTBChange(setPassword)}
					error={error}
					onKeyDown={onEnter(login)}
					disabled={loading}
				/>
			</Grid>
			<Grid item className={styles.formItem}>
				<Button variant="contained" color="primary" disabled={loading} onClick={login}>
					{getString("login")}
				</Button>
			</Grid>
			<LoginError className={styles.alert} auth={authState} />
		</Grid>
	);
};

import { webApiWithResponse } from "../../../../core/http/httpThunks";
import { LoginResponse } from "../../../models";

export const setOrderStatusApi = (userid: string, orderid: string) =>
	webApiWithResponse<LoginResponse>({
		method: "PUT",
		url: "common/setdefsecacc",
		params: {
			userid,
			orderid,
		},
	});


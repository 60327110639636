import React from "react";
import { Table, TableDataProps } from "../../components/common/Table";
import { useMoneyAccountData } from "../../../data/settings/moneyaccounts/hooks/useMoneyAccountData";
import { MoneyAccounts } from "../../../data/models";
import { useMoneyAccountColumns } from "./useMoneyAccountColumns";
import { AccessRights } from "../../../core/navigation/types";
import { useMoneyAccountActions  } from "./useMoneyAccountActions";
import { useMoneyAccountFilter } from "../../../data/settings/moneyaccounts/hooks/useMoneyAccountFilter";

export interface MoneyAccTableProps {
  userAccessRights: AccessRights;
}

type MoneyAccTablePropsType = MoneyAccTableProps & TableDataProps<MoneyAccounts>;

export const MoneyAccountTable: React.FunctionComponent<MoneyAccTablePropsType> = ({
  userAccessRights,
  ...tableProps
}) => {
  const [filter] = useMoneyAccountFilter();

  const [data, loadAll] = useMoneyAccountData(filter);

  const columns = useMoneyAccountColumns();
  const actions = useMoneyAccountActions();

  return (
    <Table<MoneyAccounts>
      {...tableProps}
      getData={loadAll}
      immutableData={data}
      title=""
      columns={columns}
      actions={actions}
      options={{
        toolbar: false,
        pageSize: 10,
        actionsColumnIndex: -1,
      }}
    />
  );
};

export default MoneyAccountTable;

import { ThunkType } from "../../../../core/store/reducers";
import getMarketDataApi from "../api/getMarketData";
import { PageDataStatus, PageData } from "../../../models";
import { FMarketListData , FMarketFilter } from "../../../foreign/mainBoard/types";

export const getMarketDataThunk = (
	pageNumber?: number,
	itemsPerPage?: number,
	filter?: FMarketFilter,
	defaultStatus?: number
): ThunkType<Promise<PageData<FMarketListData> | undefined>> => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return undefined;
	}

	const user = store.data.auth.current.user;
	const marketDataResponse = await dispatch(getMarketDataApi(user.userId, pageNumber, itemsPerPage, filter, defaultStatus));

	if (!marketDataResponse || marketDataResponse.resCode !== "OK") {
		return undefined;
	}

	return {
		currentPageData: marketDataResponse.obj,
		totalCount: Number(marketDataResponse.resDesc) || 0,
		status: PageDataStatus.DataCurrent,
	};
};

import React from "react"
import AccountingIcon from "@material-ui/icons/AssignmentOutlined";
import CliringIcon from "@material-ui/icons/AccountBalanceOutlined";
import BusinessIcon from "@material-ui/icons/BusinessOutlined";
import ReportIcon from "@material-ui/icons/AssessmentOutlined";
import SurveillanceIcon from "@material-ui/icons/DvrOutlined";
import DashboardIcon from "@material-ui/icons/DashboardOutlined";

export type IconDictionary = {
    [k: string]: React.FunctionComponent<any>
}

export const iconDictionary: IconDictionary = {
    "00000001": BusinessIcon,
    "00000002": DashboardIcon,
    "00000003": CliringIcon,
    "00000004": SurveillanceIcon,
    "00000005": ReportIcon,
    "00000006": AccountingIcon,
}
import { webApiWithResponse } from "../../../../core/http/httpThunks";
import { EditOrder } from "../types";

export const createOrderApi = (userId: string, order: EditOrder) =>
  webApiWithResponse<string>({
    method: "PUT",
    url: "order/neworder",
    params: {
      userId,
    },
    data: order
  });

export default createOrderApi;


import React from "react";
import { Grid, CardContent, CardHeader, Divider, Card, Fab } from "@material-ui/core";
import { MoneyAccountTable } from "./MoneyAccountTable";
import { usePageStyles } from "../../pageStyles";
import { PageComponent, AccessRights } from "../../../core/navigation/types";
import { useHistory } from "react-router-dom";
import { PageTitle } from "../../components/common/PageTitle";
import { MoneyAccountFilterButton }  from "./MoneyAccountFilter"
import { useMoneyAccountFilter } from "../../../data/settings/moneyaccounts/hooks/useMoneyAccountFilter";
import { useMoneyAccountData } from "../../../data/settings/moneyaccounts/hooks/useMoneyAccountData";

const MoneyAccountPage: PageComponent = ({ userAccessRights }) => {
	const pageStyles = usePageStyles();

	const [filter] = useMoneyAccountFilter();

	const [data, loadAll] = useMoneyAccountData(filter);

	React.useEffect(() => {
		loadAll();
	}, [loadAll ]);

	const history = useHistory();

	return (
		<Grid container className={pageStyles.root}>
			<Grid item xs={12} className={pageStyles.gridItem}>
				<Card>
					<CardHeader
						title={<PageTitle title="navMoneyAccounts" />}
						action={<MoneyAccountActions />}
						/>
					<Divider />
					<CardContent>
						<MoneyAccountTable userAccessRights={userAccessRights} />
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
};

export const MoneyAccountActions: React.FunctionComponent = () => (
	<Grid container>
	  <Grid item>
		<MoneyAccountFilterButton />
	  </Grid>
	</Grid>
  );

export default MoneyAccountPage;

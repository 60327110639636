import React from "react";
import { useSecurityAccounts } from "../../../data/initialData/hooks/useSecurityAccounts";
import { useSecurityAccountsForeign } from "../../../data/initialData/hooks/useSecurityAccountsForeign";
import { SecurityAccounts } from "../../../data/models";
import { AutocompleteSelect, AutocompleteSelectType,} from "./AutocompleteSelect";
import { SecurityAccFilter } from "../../../data/domestic/orders/types";

type RegionSelectPropsType = Omit<AutocompleteSelectType<SecurityAccounts>,"data" | "getLabel">
& {
	filter?: SecurityAccFilter
};

export const SecurityAccountsSelect: React.FunctionComponent<RegionSelectPropsType> = (
  {
    filter,
    ...props
  } 
 
) => {
  const [statuses, renderStatus] =  useSecurityAccounts(filter) ;

  return (
    <AutocompleteSelect<SecurityAccounts>
      data={statuses}
      getLabel={renderStatus}
      {...(props as any)}
    />
  );
};


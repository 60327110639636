import PortfolioReport from "./PortfolioReport";
import { NavigationItem } from "../../../core/navigation/types";
import { portfolioFReportLocation } from "./Locations";

export const portfolioFReportComponents: NavigationItem[] = [
	{
		Component: PortfolioReport,
		isMenuItem: true,
		path: portfolioFReportLocation,
	},
];

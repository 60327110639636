import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoginResponse } from "../models";
import { successfulLogin } from "./authDataSlice";

interface IAuthUXState {
	users: {
		[username: string]: string | undefined;
	};
	lastLoginUsername?: string;
	colorBdd?: string;
}

const initialState: IAuthUXState = {
	users: {},
};

const name = "auth";

const reportsSlice = createSlice({
	name,
	initialState,
	reducers: {
		deleteUserToken: (state, action: PayloadAction<string>) => ({
			...state,
			users: {
				...state.users,
				[action.payload]: undefined
			},
			lastLoginUsername: undefined,
			colorBdd: undefined
		})
	},
	extraReducers: {
		[successfulLogin.type]: (state, action: PayloadAction<LoginResponse>) => {
			const loginUser = action.payload.uData;
			const newusersDictionary = !loginUser.token
				? state.users
				: {
						...state.users,
						[loginUser.userName.toLowerCase()]: loginUser.token,
				  };

			return {
				...state,
				users: newusersDictionary,
				lastLoginUsername: loginUser.userName,
				colorBdd: loginUser.hdrColor
			};
		},
	},
});

export const { deleteUserToken } = reportsSlice.actions;

export default reportsSlice.reducer;

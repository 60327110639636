import React from "react";
import { Switch, Route } from "react-router-dom";
import {
  useNavigationItems,
  useLinkMenuItems,
} from "./hooks/useNavigationItems";
//import MainBoard from "../trading/mainBoard/MainBoard"
import { useAuth } from "../../data/auth/hooks";

export const PageRouterSwitch = () => {
  const linkMenuItems = useLinkMenuItems();
  const editComponents = useNavigationItems(false);
  const [auth] = useAuth();
  
  if (auth.status !== "successful") {
		return null;
	}
 
  return (
    <Switch>
      {editComponents.map((Item) => (
        <Route key={Item.path} path={Item.path} component={Item.Component} />
      ))}
      {linkMenuItems.map((Link) => (
        <Route key={Link.key} path={Link.path}>
          <Link.Component userAccessRights={Link.accessRights} />
        </Route>
      ))}

    </Switch>
  );
};

import { User } from "../../../auth/types";
import { httpWebApi } from "../../../../core/http/httpThunks";
import { getParamsDateString } from "../../../../core/util/functions";
import { TradeFilter } from "../../../domestic/trades/types";

export const getTradeSumListReport = (user: User, filter: TradeFilter) => {
	return httpWebApi<Blob>({
		method: "GET",
		responseType: "blob",
		timeout: 60 * 4 * 1000,
		url: "reporting/getsumtradebookreport",
		params: {
			userid: user.userId,
			domfor: "F",
			secid: filter?.security?.securityId,
			datefrom: getParamsDateString(filter?.datefrom),
			dateto: getParamsDateString(filter?.dateto),
		},
	});
};

export default getTradeSumListReport;

import { useCopiedState } from "../../../../core/util/hooks";
import { MoneyAccountFilter } from "../types";
import { setFilter, removeFilter, initialState } from "../moneyAccountsUXSlice";
import { invalidatemoneyAccount } from "../moneyAccountsDataSlice";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import React from "react";
import { loadMoneyAccounts } from "../thunks/loadMoneyAccounts";

export const useMoneyAccountFilter = () => {

	const [storeFilter] = useCurrentMoneyAccountFilter();
	const [filter, editFilter, changeState, setCurrentFilter] = useCopiedState<MoneyAccountFilter>(storeFilter, true);

	const dispatch = useDispatch();

	const dispatchSetFilter = React.useCallback(() => {
		dispatch(setFilter(filter!));
		dispatch(loadMoneyAccounts(filter!));
//		dispatch(invalidateSecAccount());
	}, [dispatch,setFilter, loadMoneyAccounts, filter]);

	const dispatchResetFilter = React.useCallback(() => {
		setCurrentFilter(initialState.filter)
		dispatch(loadMoneyAccounts(filter));
	}, [setCurrentFilter, filter]);

	return [filter, editFilter, dispatchSetFilter, changeState, dispatchResetFilter] as [
		typeof filter,
		typeof editFilter,
		typeof dispatchSetFilter,
		typeof changeState,
		typeof dispatchResetFilter
	];
};

export const useCurrentMoneyAccountFilter = () => {
	const filter = useSelector<StoreType, MoneyAccountFilter>(store => store.ux.moneyAccounts.filter);
	const dispatch = useDispatch();
	const dispatchRemoveFilter = React.useCallback(
		(type: keyof MoneyAccountFilter) => {
			dispatch(removeFilter(type));
			dispatch(loadMoneyAccounts());
			//			dispatch(invalidateSecAccount());
		},
		[dispatch,loadMoneyAccounts]
	);

	return [filter, dispatchRemoveFilter] as [typeof filter, typeof dispatchRemoveFilter];
};

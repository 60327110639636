import { webApi } from "../../../../core/http/httpThunks";
import { Order } from "../types";

export const cancelOrderApi = (userId: string, ordid: string) =>
  webApi<Order>({
    method: "PUT",
    url: "order/cancelorder",
    params: {
      userId,
      ordid,
    },
  });

export default cancelOrderApi;


import React, { useContext, useMemo } from "react";
import ViewIcon from "@material-ui/icons/InputOutlined";
import { MoneyAccounts } from "../../../data/models";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Action } from "material-table";
import { useSetDefMoneyAcc } from "../../../data/settings/moneyaccounts/hooks/useSetDefMoneyAcc";

export const useMoneyAccountActions = () => {
  const { getString } = useContext(LocalizationContext);
  
  const onShowBank = useSetDefMoneyAcc();
 

  return useMemo(() => {
    const result: Action<MoneyAccounts>[] = [
      {
        icon: () => <ViewIcon />,
        tooltip: getString("domForChange"),
        onClick: (event, rowData) => onShowBank(rowData as MoneyAccounts),
      },
    ];

    return result;
  }, [onShowBank,  getString]);
};


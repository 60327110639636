import { makeStyles, Theme, createStyles, Grid } from "@material-ui/core";

export const useNavDrawerStyles = makeStyles((theme: Theme) => {
	const { permanentBreakpoint, fullWidth } = theme.navDrawer;

	return createStyles({
		drawer: {
			[permanentBreakpoint]: {
				// width: hiddenWidth,
				// "&:hover": {
				// 	width: fullWidth,
				// 	transition: theme.transitions.create("width", {
				// 		easing: theme.transitions.easing.sharp,
				// 		duration: theme.transitions.duration.enteringScreen,
				// 	}),
				// },
				// "&:not(:hover)": {
				// 	transition: theme.transitions.create("width", {
				// 		easing: theme.transitions.easing.sharp,
				// 		duration: theme.transitions.duration.leavingScreen,
				// 	}),
				// },
				width: fullWidth,

				flexShrink: 0,
				overflowX: "hidden",
				// "& + main": {
				// 	width: `calc(100% - ${hiddenWidth}px)`,
				// 	transition: theme.transitions.create("width", {
				// 		easing: theme.transitions.easing.sharp,
				// 		duration: theme.transitions.duration.leavingScreen,
				// 	}),
				// },
				// "&:hover + main": {
				// 	width: `calc(100% - ${fullWidth}px)`,
				// 	transition: theme.transitions.create("width", {
				// 		easing: theme.transitions.easing.sharp,
				// 		duration: theme.transitions.duration.enteringScreen,
				// 	}),
				// },
				// "&:not(:hover) .MuiCollapse-container": {
				// 	display: "none",
				// }
				"& + main": {
					width: `calc(100% - ${fullWidth}px)`,
				},
			},
		},
		drawerPaper: {
			width: fullWidth,
			// "&:hover": {
			// 	width: fullWidth,
			// 	transition: theme.transitions.create("width", {
			// 		easing: theme.transitions.easing.sharp,
			// 		duration: theme.transitions.duration.enteringScreen,
			// 	}),
			// },
			// "&:not(:hover)": {
			// 	transition: theme.transitions.create("width", {
			// 		easing: theme.transitions.easing.sharp,
			// 		duration: theme.transitions.duration.leavingScreen,
			// 	}),
			// },
			// [permanentBreakpoint]: {
			// 	width: hiddenWidth,
			// },
			overflowX: "hidden",
		},
		menuItem: {
			whiteSpace: "nowrap",
			paddingLeft: theme.spacing(2),
		},
		nestedMenuItem: {
			whiteSpace: "nowrap",
			paddingLeft: theme.spacing(4),
		},
		nestedMenuItem2: {
			whiteSpace: "nowrap",
			paddingLeft: theme.spacing(6),
		},
		logo: {
			marginLeft: `auto`,
			width: "90%",
		},


	});
});

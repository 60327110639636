export const wait = (ms: number) => new Promise(res => setTimeout(res, ms));

export const getDateFromSR = (dateString?: string) => {
	if (!dateString) {
		return undefined;
	}

	const dateParts = dateString.split(" ")[0].split(".");

	if (dateParts.length < 3) {
		return undefined;
	}
	const day = parseInt(dateParts[0]);
	const month = parseInt(dateParts[1]) - 1;
	const year = parseInt(dateParts[2]);

	return new Date(year, month, day);
};

export const exhaustiveCheck = (param: never): never => {
	throw new Error(`should not reach here (${param})`);
};

export function normalize<T>(employee?: T): T {
	return Object.keys(employee || {}).reduce((normalized, field) => {
		const value: any = employee ? employee[field as keyof T] : undefined;
		return {
			...normalized,
			[field]: value === "" || value === null ? undefined : value,
		};
	}, {} as T);
}

export const getEditLocation = (
	basePath: string,
	part?: string,
	id?: string,
	subItemId?: string,
	action?: string
) => {

	let location = `${basePath}/${action || "edit"}`;

	if (part !== undefined) {
		location = `${location}/${part}`;
	}

	if (id !== undefined) {
		location = `${location}/${id}`;
	}

	if (subItemId !== undefined) {
		location = `${location}/edit/${subItemId}`;
	}

	return location;
};

export const getParamsDateString = (dateString?: string): string | undefined => {
	const date = dateString ? new Date(dateString) : undefined;
	return date ? `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}` : undefined;
};

export const isValidDate = (date: any): date is Date => date && date instanceof Date && !isNaN(date.getTime())

export const countDecimals = (value?: number) => {
    if (value && Math.floor(value) !== value)
        return value.toString().split(".")[1].length || 0;
    return 0;
}
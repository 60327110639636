import { NavigationItem } from "../../../core/navigation/types";
import SecAccountPage from "./SecAccountPage";
import { secAccountLocation  } from "./Locations";

export const secAccountComponents: NavigationItem[] = [
	{
		Component: SecAccountPage,
		isMenuItem: true,
		path: secAccountLocation,
	},
];

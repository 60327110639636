import { webApi } from "../../../../core/http/httpThunks";
import { Trades } from "../../../domestic/trades/types";

export const getTradeApi = (userId: string, tranid: string) =>
  webApi<Trades>({
    method: "GET",
    url: "trade/gettran",
    params: {
      userId,
      tranid,
    },
  });

export default getTradeApi;

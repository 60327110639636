import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../core/store/reducers";
import { loadSupport } from "../thunks/loadSupport";
import { EmpContactData } from "../types";

export const useSupportData = () => {

  const allData = useSelector<StoreType, EmpContactData[] | undefined>(
    (s) => s.data.support.all
  ); 

  console.log("hooks 3")

  const dispatch = useDispatch();
  const dispatchLoadSupport = React.useCallback(
    () => dispatch(loadSupport()),
    [dispatch]
  );

  return [allData, dispatchLoadSupport] as [
    typeof allData,
    typeof dispatchLoadSupport
  ];
};


import React from "react";
import FilterIcon from "@material-ui/icons/FilterListOutlined";
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import { IconButton, Grid } from "@material-ui/core";
import { DisplayDrawerContext } from "../../../layout/DisplayDrawerContext";
import { CustomTooltip } from "../../components/common/CustomTooltip";
import { MainBoardFilterEditor } from "./EditMainBoardFilter";
import { useMarketDataFilter, useCurrentMarketDataFilter,} from "../../../data/domestic/mainBoard/hooks/useMarketDataFilter";

export const MainBoardSecurity: React.FunctionComponent = () => {

	const showWithDrawer = React.useContext(DisplayDrawerContext);

	const showFilter = React.useCallback(() => showWithDrawer(<MarketFilterEditor />), [showWithDrawer]);

	const [filter] = useCurrentMarketDataFilter();

	return (
		<Grid container alignItems="center" spacing={2}>
			<Grid item>
				<CustomTooltip titleKeyword="settingsTooltip" >
					<IconButton color="secondary" component="span" onClick={showFilter}>
						<SettingsOutlinedIcon />
					</IconButton>
				</CustomTooltip>
			</Grid>
		</Grid>
	);
};

const MarketFilterEditor: React.FunctionComponent = () => {
	const [filter, editFilter, save, reset] = useMarketDataFilter();
	return (
		<MainBoardFilterEditor
			filter={filter}
			editFilter={editFilter}
			save={save}
		/>
	);
};   

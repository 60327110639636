import { useSelector } from "react-redux";
import { StoreType } from "../../../core/store/reducers";
import { OrderTypes } from "../../models";

const getStatusLabel = (status?: OrderTypes) => (status ? status.ftDescr : "");

export const useOrderTypes = () => {
	const statuses = useSelector<StoreType, OrderTypes[]>(store => store.data.initialData.orderTypes);

	return [statuses, getStatusLabel] as [typeof statuses, typeof getStatusLabel];
};

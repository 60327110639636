import { NavigationItem } from "../../../core/navigation/types";
import CashBalanceListPage from "./CashBalanceListPage";
import { cashBalanceListLocation } from "./Locations";

export const cashBalanceComponents: NavigationItem[] = [
	{
		Component: CashBalanceListPage,
		isMenuItem: true,
		path: cashBalanceListLocation,
	},
/*	{
		Component: EditBankPage,
		isMenuItem: false,
		path: editRouter,
	}, */
];

import React from "react";
import { CardContent, CardHeader, Card, Divider, Typography, Grid } from "@material-ui/core";
import { useGraphData } from "../../../data/foreign/ticker/hooks/useGraphData";
import { useGraphDayData } from "../../../data/foreign/ticker/hooks/useGraphDayData";
import { useGraphPeriod } from "../../../data/foreign/ticker/hooks/useGraphPeriod";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { ChartPeriod } from "../../../data/foreign/ticker/types";
import { CartesianGrid, Line, LineChart, XAxis, Tooltip, YAxis, ResponsiveContainer } from "recharts";
import { LocalizationContext } from "../../../core/localization/Localization";
import { useSelector } from "react-redux";
import { StoreType } from "../../../core/store/reducers";

export interface ProductGraphProps {
	graphId: string;
}

export const ProductGraph: React.FunctionComponent<ProductGraphProps> = ({ graphId }) => {
	const { getString, getDateString, getDateTimeString, getFormatedNumberString } = React.useContext(
		LocalizationContext
	);

	const securitySymbol = useSelector<StoreType, string | undefined>(
		store => store.data.foreign.marketData.secsymbol
	);  	
	const securityName = useSelector<StoreType, string | undefined>(
		store => store.data.foreign.marketData.secname
	);  	

	const [period, setPeriod] = useGraphPeriod(graphId);
	const [data] = useGraphData(graphId);
	const [daydata] = useGraphDayData(graphId);

	return (
		<Card>
			<CardHeader
				title={
					<Grid container spacing={2} alignItems="baseline" alignContent="space-between" justify="space-between">
						<Grid item xs={6} >
							<ToggleButtonGroup
								value={period}
								exclusive
								onChange={(e, v) => setPeriod(v)}
								size="small"
							>
								<ToggleButton size="small" value={ChartPeriod.Day}>Day</ToggleButton>
								<ToggleButton size="small" value={ChartPeriod.Month}>1M</ToggleButton>
								<ToggleButton size="small" value={ChartPeriod.ThreeMonths}>3M</ToggleButton>
								<ToggleButton size="small" value={ChartPeriod.SixMonths}>6M</ToggleButton>
								<ToggleButton size="small" value={ChartPeriod.FromYearBeggining}>YTD</ToggleButton>
								<ToggleButton size="small" value={ChartPeriod.Year}>1Y</ToggleButton>
								<ToggleButton size="small" value={ChartPeriod.TwoYears}>2Y</ToggleButton>
								<ToggleButton size="small" value={ChartPeriod.FiveYears}>5Y</ToggleButton>
							</ToggleButtonGroup>
						</Grid>
						<Grid item xs={6}>
							<Typography>
								{securitySymbol+' - '+securityName}
							</Typography>

						</Grid>
					</Grid>
				}
			>
				</CardHeader>
			<Divider />
			{period===ChartPeriod.Day && <CardContent>
				<ResponsiveContainer width="100%" height={400}>
					<LineChart data={daydata} margin={{ top: 5, right: 20, left: 0, bottom: 5 }}>
						<XAxis dataKey="minute" tickFormatter={date => date} />
						<YAxis yAxisId={0} domain={["auto", "auto"]} />
						<Tooltip
							formatter={value => [
								getFormatedNumberString(value as any, true),
								getString("orderPrice"),
							]}
//							labelFormatter={(date: any) => getDateTimeString(date)}
							labelFormatter={(date: any) => date}
						/>
						<CartesianGrid stroke="#f5f5f5" />
						<Line type="monotone" dataKey="price" stroke="#772E25" yAxisId={0} dot={false} />
					</LineChart>
				</ResponsiveContainer> 
			</CardContent> }  
			{period!==ChartPeriod.Day && <CardContent>
				<ResponsiveContainer width="100%" height={400}>
					   <LineChart data={data} margin={{ top: 5, right: 20, left: 0, bottom: 5 }}>
						<XAxis dataKey="tradeDate" tickFormatter={date => getDateString(date, "DD.MM.")} />
						<YAxis yAxisId={0} domain={["auto", "auto"]} />
						<Tooltip
							formatter={value => [
								getFormatedNumberString(value as any, true),
								getString("orderPrice"),
							]}
//							labelFormatter={(date: any) => getDateTimeString(date)}
							labelFormatter={(date: any) => getDateString(date)}
						/>
						<CartesianGrid stroke="#f5f5f5" />
						<Line type="monotone" dataKey="price" stroke="#772E25" yAxisId={0} dot={false} />
					</LineChart>
				</ResponsiveContainer> 
			</CardContent> }
		</Card>
	);
};

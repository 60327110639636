import { User } from "../../../auth/types";
import { webApiWithResponse } from "../../../../core/http/httpThunks";
import { getParamsDateString } from "../../../../core/util/functions";
import { FMarketListData, FMarketFilter } from "../types";


export const getMarketDataApi = (
  userId: string, 
  page?: number,
  itemsPerPage?: number,
  filter?: FMarketFilter,
  defaultOrderStatus?: number,
) => {

  return webApiWithResponse<FMarketListData[]>({
    method: "GET",
    url: "trade/getfmarketdata",
    params: {
      userId,
      sort: 0,
    }

  });
};

export default getMarketDataApi;
import { useState, useCallback, useMemo, useEffect, useRef } from "react";
import { normalize } from "./functions";
import React from "react";
import { ValidationState } from "./types";
import deepEqual from "deep-equal";

export const useToggleState = (initialState: boolean = false) => {
	const [state, setState] = useState(initialState);

	const toggleState = useCallback(() => setState(currentState => !currentState), [setState]);

	return [state, toggleState, setState] as [
		boolean,
		() => void,
		React.Dispatch<React.SetStateAction<boolean>>
	];
};

export type BooleanDictionary = {
	[key: string]: boolean;
};

export type BooleanDictionaryTogglers = {
	[key: string]: () => void;
};

export const useToggleStateDictionary = (keys: string[], openKeys?: string[]) => {
	const [dictionary, setDictionary] = useState(() =>
		keys.reduce((dict, key) => {
			dict[key] = openKeys !== undefined && openKeys.some(ok => ok === key);
			return dict;
		}, {} as BooleanDictionary)
	);

	const dictionaryTogglers = useMemo(
		() =>
			keys.reduce((togglers, key) => {
				togglers[key] = createToggleDictionaryState(dictionary, setDictionary, keys, key);
				return togglers;
			}, {} as BooleanDictionaryTogglers),
		[keys, setDictionary, dictionary]
	);

	return [dictionary, dictionaryTogglers] as [BooleanDictionary, BooleanDictionaryTogglers];
};

const createToggleDictionaryState = (
	dictionary: BooleanDictionary,
	setDictionary: (dictionary: BooleanDictionary) => void,
	keys: string[],
	toggleKey: string
) => () => {
	const newDictionary: BooleanDictionary = {};
	keys.forEach(key => {
		newDictionary[key] = toggleKey === key ? !dictionary[key] : false;
	});
	setDictionary(newDictionary);
};

export type AditionalValidation<TItem, TValidationState extends ValidationState<TItem>> = (
	itemState: TItem,
	validationState: TValidationState
) => TValidationState;

export const createUseValidator = <TItem, TValidationState extends ValidationState<TItem> = ValidationState<TItem>>(
	initialValidatorState: TValidationState,
	additionalValidation?: AditionalValidation<TItem, TValidationState>
) => () => {
	const [validationState, setValidationState] = useState(initialValidatorState);

	const validate = useCallback(
		(item?: TItem) => {
			const normalized = normalize(item);
			let newState = Object.keys(validationState).reduce((validated, field) => {
				const validField = normalized[field as keyof TItem] !== undefined;
				return {
					...validated,
					[field]: validField,
				};
			}, {} as TValidationState);

			if (additionalValidation) {
				newState = additionalValidation(normalized, newState);
			}

			const valid = Object.keys(newState).reduce((validated, field) => {
				return validated && (newState[field as keyof TValidationState] as boolean);
			}, true as boolean);

			setValidationState(newState);
			return valid ? normalized : undefined;
		},
		[validationState, setValidationState]
	);

	return [validationState, validate] as [typeof validationState, typeof validate];
};

export default function useDebounce<T>(value: T, delay: number = 500) {
	const [debouncedValue, setDebouncedValue] = useState(value);

	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedValue(value);
		}, delay);

		return () => {
			clearTimeout(handler);
		};
	}, [value, delay]);

	return debouncedValue;
}

export function useDebounceState<T>(
	initState: T | (() => T),
	initDelay: number = 500
): [T, React.Dispatch<React.SetStateAction<T>>] {
	const [state, setState] = useState(initState);
	const timeout = useRef<NodeJS.Timeout>();

	useEffect(() => () => timeout.current && clearTimeout(timeout.current), []);

	const setNextState = useCallback(
		(nextState: React.SetStateAction<T>) => {
			if (timeout.current) clearTimeout(timeout.current);

			timeout.current = setTimeout(() => {
				setState(nextState);
			}, initDelay);
		},
		[initDelay]
	);

	return [state, setNextState];
}

export const useCopiedState = <T>(original?: T, followOriginal?: boolean) => {
	const [copyState, setCopyState] = React.useState(original);
	const [changeCalled, setChangeCalled] = React.useState(false);

	React.useEffect(() => {
		setCopyState(e => ((e || !original) && !followOriginal ? e : original));
	}, [original, setCopyState, followOriginal]);

	const editCopyState = React.useCallback(
		(field: keyof T, value: any) => {
			setCopyState(e => (e ? { ...e, [field]: value } : e));
			setChangeCalled(true);
		},
		[setCopyState]
	);

	const changed = React.useMemo(() => changeCalled && !deepEqual(original, copyState), [original, copyState, changeCalled]);

	return [copyState, editCopyState, changed, setCopyState] as [typeof copyState, typeof editCopyState, boolean, typeof setCopyState];
};

import { ThunkType } from "../../../../core/store/reducers";
import  getMoneyAccountApi  from "../api/getMoneyAccountData";
import { setData } from "../moneyAccountsDataSlice";
import { MoneyAccountFilter } from "../types";

export const loadMoneyAccounts = (filter?:MoneyAccountFilter): ThunkType => async (dispatch, getStore) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return;
  }

  const  userid = store.data.auth.current.user.userId;
  const moneyAcc = await dispatch(getMoneyAccountApi(userid, filter));
  
  dispatch(setData(moneyAcc || []));
};

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PortfolioData } from "./types";
import { PageData, PageDataStatus, FetchStateType } from "../../models";

type PortfolioReportData = {
	currentPage?: PageData<PortfolioData>;
	data?: PortfolioData[];
	downloadReportFetchState: FetchStateType;
	downloadXlsFetchState: FetchStateType;
};

const initialState: PortfolioReportData = {
	downloadReportFetchState: { type: "not-started" },
	downloadXlsFetchState: { type: "not-started" },
};

const invalidate = (state: PortfolioReportData) => {
	if (!state.currentPage) return state;

	return {
		...state,
		currentPage: {
			...state.currentPage,
			status: PageDataStatus.DataInvalidated,
		},
	};
};


const name = "portfolio";

const portfolioSlice = createSlice({
	name,
	initialState,
	reducers: {
		setData: (state, action: PayloadAction<PageData<PortfolioData>>) => ({
			...state,
			currentPage: action.payload,
		}),
		setDownloadReportFetchState: (state, action: PayloadAction<FetchStateType>) => ({
			...state,
			downloadReportFetchState: action.payload,
		}),
		setDownloadXlsFetchState: (state, action: PayloadAction<FetchStateType>) => ({
			...state,
			downloadXlsFetchState: action.payload,
		}),

		invalidatePortfolio: invalidate,
	},
});


export const { setData, setDownloadReportFetchState, setDownloadXlsFetchState, invalidatePortfolio } = portfolioSlice.actions;

export default portfolioSlice.reducer;

import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { Trades } from "../../../domestic/trades/types";
import { loadSumTrade } from "../thunks/loadSumTrade";

export const useDisplaySumTrade = (tradeId?: string) => {
  const storeTrade = useSelector<StoreType, Trades | undefined>(
    (store) => store.data.domestic.trades.displayedTrade
  );

  const displayedTrade =
    storeTrade && storeTrade.tranId === tradeId ? storeTrade : undefined;

  const displatch = useDispatch();

  React.useEffect(() => {
    if (tradeId) {
      displatch(loadSumTrade(tradeId));
    }
  }, [displatch, tradeId]);

  return displayedTrade;
};

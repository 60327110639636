import React from "react";
import { PaginationTable, PaginationDataProps } from "../../components/common/PagginationTable";
import { PortfolioData } from "../../../data/domestic/portfolio/types";
import { usePortfolioColumns } from "./usePortfolioReportColumns";
import { AccessRights } from "../../../core/navigation/types";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

export interface PortfolioReportProps {
	userAccessRights: AccessRights;
	getData: () => void;
}

type PortfolioReportPropsType = PortfolioReportProps & PaginationDataProps<PortfolioData>;

const PortfolioReportTable: React.FunctionComponent<PortfolioReportPropsType> = ({
	userAccessRights,
	getData,
	...tableProps
}) => {

	const columns = usePortfolioColumns();

	const theme = useTheme();
	const screenExtraSmall = useMediaQuery(theme.breakpoints.only('xs'));

	return (
		<PaginationTable<PortfolioData>
			{...tableProps}
			getData={getData}
			title=""
			columns={columns}
			options={{
//				actionsColumnIndex: -1,
				actionsColumnIndex: screenExtraSmall === true ? 0 : -1 ,
				search: false,
				toolbar: false,
				pageSize: 20,
			}}
		/>
	);
};

export default PortfolioReportTable;

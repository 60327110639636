import { EditOrder } from "../../../domestic/orders/types";
import { createUseValidator } from "../../../../core/util/hooks";
import { ValidationState } from "../../../../core/util/types";
import { countDecimals } from "../../../../core/util/functions";

export const orderValidationState: ValidationState<EditOrder> = {
	clientId: true,
	side: true,
	qty: true,
	price: true,
	security: true,
	expiryDate: true,
};

export const useOrderValidator = () => {

	return createUseValidator<EditOrder, ValidationState<EditOrder>>(
		orderValidationState,
		(item, validation) => ({
			...validation,
			qty: validation.qty && !!item.qty && countDecimals(item.qty) === 0,
			expiryDate: validation.expiryDate || item.timeInForce !== "GTD",
	//		price: validation.price && item.price > 0 && item.orderType === "LIMIT",
			price: (validation.price && item.price > 0 && item.orderType === "LIMIT") || item.orderType === "MARKET",
		})
	)();
};



//			// && !!item.price && countDecimals(item.price) <= 2,

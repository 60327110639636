import { MarketData, MarketListData, MarketDepth, MarketTrade } from "./types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FetchStateType, getFetchStateChange } from "../../models";

interface IMarketData {
  marketData?: MarketData[];
  marketListData?: MarketListData[];
  marketDepthData?: MarketDepth[];
  marketTrade?: MarketTrade[];
  displayData?: MarketListData[];
  fetchState: FetchStateType;
  secid: string;
}

const initialState: IMarketData = {
  marketDepthData: [],
  fetchState: { type: "not-started" },
  secid: "",
};

const name = "marketData";

const marketDataSlice = createSlice({
  name,
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<MarketData | undefined>) => ({
      ...state,
      marketListData: action.payload?.marketSecList,
      marketDepthData: action.payload?.depthList,
      marketTrade: action.payload?.tradeList,
      displayData: action.payload?.marketSecList, 
    }),
    setFetchState: (state, action: PayloadAction<FetchStateType>) => ({
      ...state,
      all: action.payload.type === "successful" ? undefined : state.marketListData,
      displayData:
        action.payload.type === "successful" ? undefined : state.displayData,
      fetchState: getFetchStateChange(state.fetchState, action.payload),
    }),
    setSecurity: (state, action: PayloadAction<string>) => ({
      ...state,
      secid: action.payload,
      marketDepthData: undefined,
      marketTrade: undefined,
      displayData: undefined, 
    })
  },
});


export const {
  setData,
  setFetchState,
  setSecurity,
} = marketDataSlice.actions;

export default marketDataSlice.reducer;

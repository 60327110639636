import { webApi } from "../../../core/http/httpThunks";
import { SecuritySimpleList } from "../types";

export const getSecurityForSelect = (userId: string, df: string) =>
	webApi<SecuritySimpleList[]>({
		method: "GET",
		url: "common/getsecsimplelist",
		params: {
			userId,
			df,
		},
	});

export default getSecurityForSelect;

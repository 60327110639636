import { User } from "../../../auth/types";
import { httpWebApi } from "../../../../core/http/httpThunks";
import { getParamsDateString } from "../../../../core/util/functions";
import { OrderFilter, OrderMarket } from "../../../domestic/orders/types";

export const getOrderListReport = (user: User, filter: OrderFilter) => {
	return httpWebApi<Blob>({
		method: "GET",
		responseType: "blob",
		timeout: 60 * 4 * 1000,
		url: "reporting/getorderbookreport",
		params: {
			userid: user.userId,
			secid: filter?.security?.securityId,
			market: OrderMarket.Foreign,
			side: filter?.side,
			status: filter?.orderStatus?.ftValue ,
			dateFrom: getParamsDateString(filter?.dateFrom),
			dateTo: getParamsDateString(filter?.dateTo),
			exchid: filter?.exchange?.exchId,
		},
	});
};

export default getOrderListReport;

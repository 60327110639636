import { ViewOrder } from "../../../data/domestic/orders/types";
import React from "react";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Column } from "material-table";
import { DisplayBoolNumber } from "../../components/common/DisplayBoolNumber";

export const useOrderBookColumnsBroker = () => {
	const { getString, getDateString, getFormatedNumberString } = React.useContext(LocalizationContext);


	return React.useMemo(
		() => [
			{
				title: getString("orderSecDep"),
				field: "secDepCode",
				sorting: false,
				width: "80px",
				cellStyle: {
					maxWidth: "80px",
					minWidth: "80px",
					width: "80px",
				},
				headerStyle: {
					maxWidth: "80px",
					minWidth: "80px",
					width: "80px",
				},
			},
			{
				title: getString("orderClmOrdNo"),
				field: "orderNo",
				sorting: false,
				width: "100px",
				cellStyle: {
					maxWidth: "100px",
					minWidth: "100px",
					width: "100px",
				},
				headerStyle: {
					maxWidth: "100px",
					minWidth: "100px",
					width: "100px",
				},
			},
			{
				title: getString("orderTimeReceived"),
				render: (order) => getDateString(order.orderTime, "DD.MM.YYYY HH.mm.ss"),
				sorting: false,
				width: "160px",
				cellStyle: {
					maxWidth: "160px",
					minWidth: "160px",
					width: "160px",
					whiteSpace: "nowrap",
					textAlign: "center",
					paddingRight: "10px",
				},
				headerStyle: {
					maxWidth: "160px",
					minWidth: "160px",
					width: "160px",
					textAlign: "center",
					paddingRight: "10px",
				},
			},
			{
				title: getString("orderSide"),
				field: "side",
				sorting: false,
				width: "80px",
				cellStyle: {
					maxWidth: "80px",
					minWidth: "80px",
					width: "80px",
				},
				headerStyle: {
					maxWidth: "80px",
					minWidth: "80px",
					width: "80px",
				},
			},
			{
				title: getString("orderClientIdNo"),
				field: "clientIdNo",
				sorting: false,
				width: "120px",
				cellStyle: {
					maxWidth: "120px",
					minWidth: "120px",
					width: "120px",
				},
				headerStyle: {
					maxWidth: "120px",
					minWidth: "120px",
					width: "120px",
				},
			},
			{
				title: getString("orderClientName"),
				field: "clientName",
				sorting: false,
				width: "160px",
				cellStyle: {
					maxWidth: "160px",
					minWidth: "160px",
					width: "160px",
				},
				headerStyle: {
					maxWidth: "160px",
					minWidth: "160px",
					width: "160px",
				},
			},
			{
				title: getString("orderSymbol"),
				field: "security.symbol",
				sorting: false,
				width: "80px",
				cellStyle: {
					maxWidth: "80px",
					minWidth: "80px",
					width: "80px",
				},
				headerStyle: {
					maxWidth: "80px",
					minWidth: "80px",
					width: "80px",
				},
			},
			{
				title: getString("orderQty"),
				render: order => getFormatedNumberString(order.qty),
				sorting: false,
				width: "100px",
				cellStyle: {
					maxWidth: "100px",
					minWidth: "100px",
					width: "100px",
					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
				},
				headerStyle: {
					maxWidth: "100px",
					minWidth: "100px",
					width: "100px",
					textAlign: "end",
					paddingRight: "10px",
				},
			},
			{
				title: getString("orderPrice"),
				render: order => getFormatedNumberString(order.price, true),
				sorting: false,
				width: "100px",
				cellStyle: {
					maxWidth: "100px",
					minWidth: "100px",
					width: "100px",
					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
				},
				headerStyle: {
					maxWidth: "100px",
					minWidth: "100px",
					width: "100px",
					textAlign: "end",
					paddingRight: "10px",
				},
			},
			{
				title: getString("orderOrdType"),
				field: "orderType",
				sorting: false,
				width: "80px",
				cellStyle: {
					maxWidth: "80px",
					minWidth: "80px",
					width: "80px",
				},
				headerStyle: {
					maxWidth: "80px",
					minWidth: "80px",
					width: "80px",
				},
			},
			{
				title: getString("orderOrdTimeType"),
				field: "timeInForce",
				sorting: false,
				width: "80px",
				cellStyle: {
					maxWidth: "80px",
					minWidth: "80px",
					width: "80px",
				},
				headerStyle: {
					maxWidth: "80px",
					minWidth: "80px",
					width: "80px",
				},
			},
			{
				title: getString("orderExpiryDate"),
				render: (order) => getDateString(order.expiryDate, "DD.MM.YYYY"),
				sorting: false,
				width: "100px",
				cellStyle: {
					maxWidth: "100px",
					minWidth: "100px",
					width: "100px",
					whiteSpace: "nowrap",
					textAlign: "center",
					paddingRight: "10px",
				},
				headerStyle: {
					maxWidth: "100px",
					minWidth: "100px",
					width: "100px",
					textAlign: "center",
					paddingRight: "10px",
				},
			},
			{
				title: getString("orderOrigOrderNo"),
				field: "origOrderNo",
				sorting: false,
				width: "100px",
				cellStyle: {
					maxWidth: "100px",
					minWidth: "100px",
					width: "100px",
				},
				headerStyle: {
					maxWidth: "100px",
					minWidth: "100px",
					width: "100px",
				},
			},
			{
				title: getString("orderStatusDescr"),
				field: "ordStatusDescr",
				sorting: false,
			},
			{
				title: getString("orderFillStatusDescr"),
				field: "fillStatusDescr",
				sorting: false,
			},
		],
		[getString, getFormatedNumberString]
	) as Column<ViewOrder>[];
};

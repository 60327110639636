import { combineReducers, AnyAction } from "@reduxjs/toolkit";
import orderBook from "./orders/orderBookDataSlice";
import trades from "./trades/tradesDataSlice";
import marketData from "./mainBoard/marketsDataSlice";
import portfolio  from "./portfolio/portfolioDataSlice"

import { resetLoginState } from "../auth/authDataSlice";

const dataDomesticReducer = combineReducers({
	orderBook,
	trades,
	marketData,
	portfolio
});

export default (state: any, action: AnyAction) => {
	if (action.type === resetLoginState.type) {
		return dataDomesticReducer(undefined, action);
	} else {
		return dataDomesticReducer(state, action);
	}
};

import { TradeList, GetTradesParams, TradeFilter } from "../../../domestic/trades/types";
import { User } from "../../../auth/types";
import { webApiWithResponse } from "../../../../core/http/httpThunks";
import { getParamsDateString } from "../../../../core/util/functions";

const getSumTradesWebApi = (
  user: User,
  page?: number,
  itemsPerPage?: number,
  filter?: TradeFilter,
) => {
  const params: GetTradesParams = {
    userid: user.userId,
//    domfor: "F",
    numofrows: itemsPerPage,
    fromrow: page && itemsPerPage ? page * itemsPerPage : undefined,
    secid: filter?.security?.securityId,
    exchid: filter?.exchange?.exchId,
    currid: filter?.curency?.currId,    datefrom: getParamsDateString(filter?.datefrom),
    dateto: getParamsDateString(filter?.dateto),
  };

  return webApiWithResponse<TradeList[]>({
    method: "GET",
    url: "trade/getsumtradelist",
    params,
  });
};

export default getSumTradesWebApi;

import { combineReducers, AnyAction } from "@reduxjs/toolkit";
import marketData from "./mainBoard/marketsDataSlice";
import ticker from "./ticker/tickerDataSlice";

import { resetLoginState } from "../auth/authDataSlice";

const dataForeignReducer = combineReducers({
	marketData,
	ticker,
});

export default (state: any, action: AnyAction) => {
	if (action.type === resetLoginState.type) {
		return dataForeignReducer(undefined, action);
	} else {
		return dataForeignReducer(state, action);
	}
};

import {  FMarketListData } from "./types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FetchStateType, getFetchStateChange } from "../../models";
import { PageData, PageDataStatus } from "../../models";

type MarketData = {
  currentPage?: PageData<FMarketListData>;
  displayData?: FMarketListData;
  fetchState: FetchStateType;
  secid: string;
  secsymbol: string;
  secname: string;
}

const initialState: MarketData = {
  fetchState: { type: "not-started" },
  secid: "",
  secsymbol: "",
  secname: "",
};

const invalidate = (state: MarketData) => {
	if (!state.currentPage) return state;

	return {
		...state,
		currentPage: {
			...state.currentPage,
			status: PageDataStatus.DataInvalidated,
		},
	};
};

const name = "marketData";

const marketDataSlice = createSlice({
  name,
  initialState,
  reducers: {

		setData: (state, action: PayloadAction<PageData<FMarketListData>>) => ({
			...state,
			currentPage: action.payload,
		}),
		setDisplayed: (state, action: PayloadAction<FMarketListData | undefined>) => ({
			...state,
			displayData: action.payload,
		}),
		invalidateData: invalidate,

		setFetchState: (state, action: PayloadAction<FetchStateType>) => ({
			...state,
			fetchState: action.payload,
		}),
	    setSecurity: (state, action: PayloadAction<string>) => ({
    	  ...state,
	      secid: action.payload,
    	  displayData: undefined, 
	    }),
	    setSecuritySymbol: (state, action: PayloadAction<string>) => ({
			...state,
			secsymbol: action.payload,
			displayData: undefined, 
		  }),
		  setSecurityName: (state, action: PayloadAction<string>) => ({
			...state,
			secname: action.payload,
			displayData: undefined, 
		  })
	  },
});


export const {
  setData,
  setDisplayed,
  invalidateData,
  setFetchState,
  setSecurity,
  setSecuritySymbol,
  setSecurityName,
} = marketDataSlice.actions;

export default marketDataSlice.reducer;

import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { resetOrderIdices } from "../../../domestic/portfolio/portfolioUXSlice";
import { PageIndex } from "../../../models";
import { setPortolioIndexThunk } from "../thunks/loadPortfolioData";

export const usePortfolioIndex = () => {
	const pageIndex = useSelector<StoreType, PageIndex>(s => s.ux.domestic.portfolio.index);

	const dispatch = useDispatch();
	const dispatchSetIndex = React.useCallback((index: PageIndex) => dispatch(setPortolioIndexThunk(index)), [
		dispatch,
	]);
	const dispatchResetIndex = React.useCallback(() => dispatch(resetOrderIdices()), [dispatch]);


	return [pageIndex, dispatchSetIndex, dispatchResetIndex] as [
		typeof pageIndex,
		typeof dispatchSetIndex,
		typeof dispatchResetIndex
	];
};

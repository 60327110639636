import { ThunkType } from "../../../../core/store/reducers";
import { setData, invalidateData } from "../../../foreign/mainBoard/marketsDataSlice";
import { setIndex } from "../../../foreign/mainBoard/marketUXSlice";
import { PageIndex } from "../../../models";
import { getMarketDataThunk } from "./getMarketDataThunk";

export const loadMarketAllData = (): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	const { pageNumber, itemsPerPage } = store.ux.foreign.marketData.index;
	const { filter } = store.ux.foreign.marketData;


	const pagedMarketData = await dispatch(getMarketDataThunk(pageNumber, itemsPerPage, filter));

	if (pagedMarketData) {
		dispatch(setData(pagedMarketData));
//		dispatch(invalidateData());
	}
};

export const setMarketDataIndexThunk = (index: PageIndex): ThunkType => dispatch => {
	dispatch(setIndex(index));
	dispatch(invalidateData());
};

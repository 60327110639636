import React from "react";
import { ConnextionManager } from "./ConnectionManager";

export const NotificationContext = React.createContext<ConnextionManager>({} as any);

export interface NotificationContextProviderProps {
	hubUrl: string;
	token: string;
	connect: boolean;
}

export const NotificationContextProvider: React.FunctionComponent<NotificationContextProviderProps> = ({
	hubUrl,
	token,
	children,
	connect,
}) => {
	const [connectionManager, setConnectionManager] = React.useState<ConnextionManager | undefined>();
	React.useEffect(() => {
		(async () => {
			const manager = new ConnextionManager(hubUrl, token);
			if (connect) {
				await manager.start();
			}
			setConnectionManager(manager);
		})();
	}, [hubUrl, token, connect]);

	return connectionManager ? (
		<NotificationContext.Provider value={connectionManager}>{children}</NotificationContext.Provider>
	) : null;
};

import React from "react";
import { useSelector } from "react-redux";
import { StoreType } from "../../../core/store/reducers";
import { SecurityAccounts } from "../../models";

const getStatusLabel = (status?: SecurityAccounts) => (status ? status.accNo : "");

export const useSecurityAccountsDomDefault = () => {
	const statuses = useSelector<StoreType, SecurityAccounts[]>(store => store.data.initialData.securityAccounts);

	return [statuses, getStatusLabel] as [typeof statuses, typeof getStatusLabel];
};

export const useSecurityAccountDomDefault = (accUse?: string) => {
	const [secaccs] = useSecurityAccountsDomDefault();

	const secacc = React.useMemo(() => secaccs.find(c => c.accUse !== "F" && c.defDom===1 ), [secaccs, accUse]);
	const secaccLabel = React.useMemo(() => getStatusLabel(secacc), [secacc]);

	return [secacc, secaccLabel] as [typeof secacc, typeof secaccLabel];
};



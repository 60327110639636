import React from "react";
import { Grid, CardContent, CardHeader, Divider, Card, Fab } from "@material-ui/core";
import MarketDataTable from "./MarketDataTable";
import MarketDepthDataTable from "./MarketDepthDataTable";
import MarketTradeDataTable from "./MarketTradeDataTable";
import { usePageStyles } from "../../pageStyles";
import { PageComponent } from "../../../core/navigation/types";
import { PageTitle } from "../../components/common/PageTitle";
import { MainBoardSecurity }  from "./MainBoardSecurity"
import { useSelector } from "react-redux";
import { StoreType } from "../../../core/store/reducers";
import { MarketTrade, MarketDepth } from "../../../data/domestic/mainBoard/types";
import { useNotificationHandler } from "../../../core/notifications/hooks";
import { useMarketData } from "../../../data/domestic/mainBoard/hooks/useMarketData";

const MainBoard: PageComponent = ({ userAccessRights }) => {

	const pageStyles = usePageStyles();

	 const security = useSelector<StoreType, string | undefined>(
		store => store.data.domestic.marketData.secid
	);  	

	const marketTrade = useSelector<StoreType, MarketTrade[] | undefined>(
		(s) => s.data.domestic.marketData.marketTrade
	  );

    const marketDepth = useSelector<StoreType, MarketDepth[] | undefined>(
		(s) => s.data.domestic.marketData.marketDepthData
	  );
	
	const hasDataMT = (marketTrade !== undefined && marketTrade !== null && marketTrade.length > 0) ;
	const hasDataMD = (marketDepth !== undefined && marketDepth !== null && marketDepth.length > 0) ;

	const [,,,,,invalidateData] = useMarketData();

	useNotificationHandler<string>(
		"MainBoardChanged",
		e => {
			console.log("MainBoardChanged", e);
			invalidateData();
		},
		[]
	);  

	return (
		<Grid container className={pageStyles.root}>
			<Grid item xs={12} className={pageStyles.gridItem}>
				<Card>
					<CardHeader 
							title={<PageTitle title="navMainTable" />}
							action={<MainBoardActions />}					/>
					<Divider />
					<CardContent>
 						<MarketDataTable userAccessRights={userAccessRights} /> 
					</CardContent>  
					<Grid container item direction="row" justify="flex-start" alignItems="center" spacing={2}>
  						<Grid item xs={9}>
							<CardContent >
 								{ security && hasDataMD && <MarketDepthDataTable userAccessRights={userAccessRights} />  } 
							</CardContent> 
						</Grid>
						<Grid item xs={3}>
							<CardContent >
								{ security && hasDataMT && <MarketTradeDataTable userAccessRights={userAccessRights} />  } 
							</CardContent> 
						</Grid>
					</Grid> 
				</Card>
			</Grid>
		</Grid>
	);
};

export const MainBoardActions: React.FunctionComponent = () => (
	<Grid container  spacing={4} >
	  <Grid item>
		<MainBoardSecurity />
	  </Grid>
	</Grid>
  );

export default MainBoard;



import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { FetchStateType } from "../../../models";
import { loadAddSecurity } from "../thunks/loadAddSecurity";
import { setNewOrderFetchState } from "../orderBookDataSlice";
import { loadMarketData } from "../../mainBoard/thunks/loadMarketData";

export const useAddSecurity = () => {
	const fetchState = useSelector<StoreType, FetchStateType>(
		store => store.data.domestic.orderBook.newOrderFetchState
	);

	const dispatch = useDispatch();
	const dispatchAddSecurity: any = React.useCallback(
		(secid: string) => {
			dispatch(loadAddSecurity(secid))
            dispatch(loadMarketData());
		},
		[dispatch ]
	);

	React.useEffect(() => {
		dispatch(setNewOrderFetchState({ type: "not-started" }))
	}, [dispatch])


	return [fetchState, dispatchAddSecurity] as [typeof fetchState, typeof dispatchAddSecurity]
};


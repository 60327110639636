import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../core/store/reducers";
import { SecuritySimpleList } from "../types";
import { loadSecurityForSelect } from "../thunks/loadSecurityForSelect";

export const useSecuritySelectData = (df: string) => {
	const selectSecurities = useSelector<StoreType, SecuritySimpleList[] | undefined | "loading">(
		store => store.data.initialData.securityForSelect
    );

    const displatch = useDispatch();
	React.useEffect(() => {
		if (!selectSecurities) {
			displatch(loadSecurityForSelect(df));
		}
    }, [displatch, selectSecurities]);

	return selectSecurities !== "loading" ? selectSecurities : undefined;
};

import React from "react";
import FilterIcon from "@material-ui/icons/FilterListOutlined";
import { IconButton, Grid } from "@material-ui/core";
import { DisplayDrawerContext } from "../../../layout/DisplayDrawerContext";
import { useOrderBookFilter, useCurrentOrderBookFilter,} from "../../../data/domestic/orders/hooks/useOrderBookFilter";
import { OrderFilterEditor, OrderFilterEditorType, OrderFilterChips } from "./EditOrderFilter";
import { CustomTooltip } from "../../components/common/CustomTooltip";

export const OrderBookFilterButton: React.FunctionComponent = () => {
	const showWithDrawer = React.useContext(DisplayDrawerContext);
	const showFilter = React.useCallback(() => showWithDrawer(<OrderBookFilterEditor />), [showWithDrawer]);

	const [filter, removeFilter] = useCurrentOrderBookFilter();
	return (
		<Grid container alignItems="center" spacing={2}>
			<Grid item>
				<OrderFilterChips filter={filter} removeFilter={removeFilter} />
			</Grid>
			<Grid item>
				<CustomTooltip titleKeyword="filterTooltip" >
					<IconButton color="secondary" component="span" onClick={showFilter}>
						<FilterIcon />
					</IconButton>
				</CustomTooltip>
			</Grid>
		</Grid>
	);
};

const OrderBookFilterEditor: React.FunctionComponent = () => {
	const [filter, editFilter, save, , reset] = useOrderBookFilter();
	return (
		<OrderFilterEditor
			filter={filter}
			editFilter={editFilter}
			save={save}
			type={OrderFilterEditorType.Extended}
			reset={reset}
		/>
	);
};

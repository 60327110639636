import React from "react";
import { useSelector } from "react-redux";
import { StoreType } from "../../../core/store/reducers";
import { MoneyAccounts } from "../../models";

const getStatusLabel = (status?: MoneyAccounts) => (status ? status.accNo : "");

export const useMoneyAccountsForDefault = () => {

	const statuses = useSelector<StoreType, MoneyAccounts[]>(store => store.data.initialData.moneyAccounts);

	return [statuses, getStatusLabel] as [typeof statuses, typeof getStatusLabel];
};

export const useMoneyAccountForDefault = (accUse?: string, currId?: string) => {

	const [monaccs] = useMoneyAccountsForDefault();

	const monacc = React.useMemo(() => monaccs.find(c => (c.accUse !== 'D' && c.accSide !== 'S'  && c.defFor === 1 && c.currId!=='00000001') ), [monaccs]);
	
	const monaccbayeur = React.useMemo(() => monaccs.find(c => (c.accUse !== 'D' && c.accSide !== 'S'  && c.defFor === 1 && c.currId==='00000002') ), [monaccs]);
	const monaccselleur = React.useMemo(() => monaccs.find(c => (c.accUse !== 'D' && c.accSide !== 'B'  && c.defFor === 1 && c.currId==='00000002') ), [monaccs]);
	const monaccbayusd = React.useMemo(() => monaccs.find(c => (c.accUse !== 'D' && c.accSide !== 'S'  && c.defFor === 1 && c.currId==='00000009') ), [monaccs]);
	const monaccsellusd = React.useMemo(() => monaccs.find(c => (c.accUse !== 'D' && c.accSide !== 'B'  && c.defFor === 1 && c.currId==='00000009') ), [monaccs]);

	const monaccLabel = React.useMemo(() => getStatusLabel(monacc), [monacc]);

	return [monacc, monaccLabel, monaccbayeur, monaccselleur, monaccbayusd, monaccsellusd] as 
	[typeof monacc, typeof monaccLabel, typeof monaccbayeur, typeof monaccselleur, typeof monaccbayusd, typeof monaccsellusd];
};

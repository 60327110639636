import React from "react";
import { useSelector } from "react-redux";
import { StoreType } from "../../../core/store/reducers";
import { SecurityAccounts } from "../../models";
import { SecurityAccFilter } from "../../../data/domestic/orders/types";

const getStatusLabel = (status?: SecurityAccounts) => (status ? status.accNo : "");

export const useSecurityAccounts = (filter?: SecurityAccFilter) => {

	const statuses = useSelector<StoreType, SecurityAccounts[]>(store => store.data.initialData.securityAccounts);

	const [secaccs, getStatusLabel ] = useSecurityAccount(statuses,filter );

	return [secaccs, getStatusLabel] as [typeof secaccs, typeof getStatusLabel];
};

export const useSecurityAccount = (secaccs:SecurityAccounts[], filter?:SecurityAccFilter ) => {

	const secacc = React.useMemo(() => secaccs.filter(c => filter &&  c.accUse.includes(filter.accUse)), [secaccs, filter]);

	const secaccLabel = (secacc?: SecurityAccounts) => (secacc ? secacc.accNo : "");

	return [secacc, secaccLabel] as [typeof secacc, typeof secaccLabel];
};



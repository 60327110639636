import { webApi } from "../../../../core/http/httpThunks";
import { MoneyAccounts, OrderSide  } from "../../../models";
import { User } from "../../../auth/types";
import { MoneyAccountFilter }  from "../types"

const getMoneyAccountApi = (
  user: string,
  filter?: MoneyAccountFilter,
) =>
{
 
return webApi<MoneyAccounts[]>({
    method: "GET",
    url: "common/getmoneyaccounts",
    params:{
      userid: user,
      domfor: filter?.domfor,
      currid: filter?.curency.currId,
      buysell: filter?.buysell == OrderSide.Buy ? "B" : "S" ,
    }
    });
};

export default getMoneyAccountApi;





import * as signalR from "@microsoft/signalr";

const reconnectTimeout = 10 * 1000;

export class ConnextionManager {
	private readonly hubUrl: string;
	private readonly token: string;
	private connection?: signalR.HubConnection;

	constructor(hubUrl: string, token: string) {
		this.hubUrl = hubUrl;
		this.token = token;
		this.createConnection();
	}
	
	private createConnection = () => {
		this.connection = new signalR.HubConnectionBuilder()
			.withUrl(this.hubUrl, { accessTokenFactory: () => this.token })
			.configureLogging(signalR.LogLevel.Information)
            .build();

        this.connection.onclose(async error => {
            console.log("SignalR closed", error)
            await this.start()
        })
	}

	public start = async () => {
		try {
			await this.connection?.start();
			console.log("SignalR connected");
		} catch (err) {
			console.log("SignalR connectio error", err);
			setTimeout(this.start, reconnectTimeout);
		}
    };
    
    public registerHandler = <T>(type: string, handler: (e: T) => void) => {
        console.log("Subscribing", type)
        this.connection?.on(type, handler);
        return () => this.connection?.off(type);
	}

	public stop = async () => {
		await this.connection?.stop();
	}

	public reconnect = async () => {
		await this.stop();
		this.createConnection();
		await this.start();
	}

}

import { LocalizationLanguage } from "../../../../core/localization/types";
import { ThunkType } from "../../../../core/store/reducers";
import { changePassApi } from "../api/changePass";
import { setChangePassFetchState, setError  } from "../settingsDataSlice";
import { useAuth  } from "../../../../data/auth/hooks";

export const changePassThunk = (
	password: string,
	language: string
): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return;
	}

	const  userid = store.data.auth.current.user.userId

	dispatch(setChangePassFetchState({ type: "started" }));
		
	const result =  await dispatch(changePassApi(userid, password, language ));

	console.log(result)

	dispatch(setChangePassFetchState(
		result !== undefined && result.resCode === "OK" 
		? { type: "successful"}
		: { type: "error", errorCode: result && result.resCode, errorDesc: result && result.resDesc }
		));

	dispatch(setError(
			result !== undefined && result.resDesc !== undefined
			? result.resDesc
			: ""
			));
	

		/*	if (result && result.resCode === "OK") {
		dispatch(setChangePassFetchState({ type: "successful", result: result.resDesc }));
	} else 
	{
		dispatch(setChangePassFetchState({ type: "error", errorCode: result?.resCode }));
	} */

};

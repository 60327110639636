import React from "react";
import FilterIcon from "@material-ui/icons/FilterListOutlined";
import { IconButton, Grid } from "@material-ui/core";
import { DisplayDrawerContext } from "../../../layout/DisplayDrawerContext";
import { useMoneyAccountFilter, useCurrentMoneyAccountFilter,} from "../../../data/settings/moneyaccounts/hooks/useMoneyAccountFilter";
import { MoneyAccountFilterEditor, MoneyAccountFilterChips } from "./EditMoneyAccountFilter";
import { CustomTooltip } from "../../components/common/CustomTooltip";

export const MoneyAccountFilterButton: React.FunctionComponent = () => {
	const showWithDrawer = React.useContext(DisplayDrawerContext);
	const showFilter = React.useCallback(() => showWithDrawer(<MoneyAccountsFilterEditor />), [showWithDrawer]);

	const [filter, removeFilter] = useCurrentMoneyAccountFilter();

	return (
		<Grid container alignItems="center" spacing={2}>
			<Grid item>
				<MoneyAccountFilterChips filter={filter} removeFilter={removeFilter} />
			</Grid>
			<Grid item>
				<CustomTooltip titleKeyword="filterTooltip" >
					<IconButton color="secondary" component="span" onClick={showFilter}>
						<FilterIcon />
					</IconButton>
				</CustomTooltip>
			</Grid>
		</Grid>
	);
};

const MoneyAccountsFilterEditor: React.FunctionComponent = () => {
	const [filter, editFilter, save, , reset] = useMoneyAccountFilter();
	return (
		<MoneyAccountFilterEditor
			filter={filter}
			editFilter={editFilter}
			save={save}
			reset={reset}
		/>
	);
};

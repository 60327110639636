import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { loadSumTrades } from "../thunks/loadSumTrades";
import { TradeList } from "../../../domestic/trades/types";
import { PageData } from "../../../models";
import { invalidateTrades } from "../../../domestic/trades/tradesDataSlice";

export const useTradesSumData = () => {
	const pageData = useSelector<StoreType, PageData<TradeList> | undefined>(
		s => s.data.domestic.trades.currentPage
	);

	const dispatch = useDispatch();
	const dispatchLoadTradesPage = React.useCallback(() => dispatch(loadSumTrades()), [dispatch]);

	const dispatchInvalidate = React.useCallback(() => dispatch(invalidateTrades()), [dispatch]);

	return [pageData, dispatchLoadTradesPage, dispatchInvalidate] as [
		typeof pageData,
		typeof dispatchLoadTradesPage,
		typeof dispatchInvalidate
	];
};

import { LocalizationLanguage } from "./types";

export const srTranslation = {
	language: LocalizationLanguage.SR as LocalizationLanguage,
	decimalSeparator: ",",
	momentLocale: "sr",
	mainTitle: "WebProTrader",
	tableNextPage: "Sledeća strana",
	tablePreviousPage: "Predhodna strana",
	tableFirsPage: "Prva strana",
	tableLastPage: "Poslednja strana",
	tableDisplayedRows: "{from}-{to} od {count}",
	tabledDisplayedPerPage: "redova",
	orderActView: "Pogledaj nalog",
	orderActReply: "Odgovori na nalog",
	orderWaitingProducts: "Molimo Vas sačekajte...",
	orderSideBuy: "KUPI",
	orderSideSell: "PRODAJ",
	listingOrdersTitle: "Nalozi",
	userOrdersTitle: "Moji nalozi",
	archiveOrdersTitle: "Arhiva",
	search: "Pretraga",
	companyClmName: "Naziv",
	companyClmHeadquorters: "Sedište",
	companyClmMemberFrom: "Član od",
	companyClmProvision: "Provizija",
	reporClmtName: "Naziv",
	reporTitle: "Izveštaji",
	reporDownloadTtl: "Skini",
	username: "Korisničko ime",
	password: "Lozinka",
	login: "Prijava",
	logout: "Odjava",
	orderChooseProduct: "Izaberite proizvod",
	noDataText: "Nema podataka",

	oldpassword: "Stara lozinka",
	newpassword: "Nova lozinka",
	newpasswordverify: "Nova lozinka - potvrdi",
	changePassword: "Promeni",
	
	orderClmOrdNo: "Broj",
	orderDateReceived: "Datum naloga",
	orderTimeReceived: "Vreme naloga",
	orderOrdType: "Tip",
	orderOrdTimeType: "Trajanje",
	orderRefOrd: "Ref nalog",
	orderClientIdNo: "MB klijenta",
	orderClientName: "Naziv klijenta",
	orderSymbol: "Simbol",
	orderYear: "Rod",
	orderTradingPlace: "Mesto isporuke",
	orderFranco: "Pravila transporta",
	orderQty: "Količina",
	orderAllOrNothing: "Sve ili ništa",
	orderPrice: "Cena",
	orderPriceEur: "Cena (EUR)",
	orderPriceWithoutVat: "Cena bez PDV-a",
	orderPriceWithVat: "Cena (PDV)",
	orderPayType: "Način plaćanja",
	orderPayDays: "Dani plaćanja",
	orderFreeStorage: "Besplatno skladištenje",
	orderFreeStorageByDelivery: "Do isporuke",
	orderFreeStorageByDate: "Do datuma:",
	orderCheckPoint: "Kontrola kvaliteta",
	orderCheckPays: "Plaća kontrolnu kuću",
	orderExpiryDate: "Datum isteka",
	orderStatus: "Status",
	orderQuality: "Kvalitet",
	orderRecieveMethod: "Način prijema",
	orderEditTitle: "Ažuriraj nalog",
	orderCreateTitle: "Kreiraj nalog",
	orderGoodsReadyFrom: "Roba spremna od",
	orderGoodsReadyTo: "Roba spremna do",
	orderAttrName: "Naziv",
	orderAttrValue: "Vrednost",
	orderAttrTitle: "Parametri kvaliteta",
	orderAttrCreateTitle: "Kreiraj atribut",
	orderAttrEditAttribute: "Ažuriraj atribut",
	orderReceiveMethod: "Nalog primljen",
	orderbrokName: "Broker",
	orderUserName: "Korisnik",
	orderCliPrsName: "Klijent-osoba",
	orderUnit: "Jedinica",
	orderQtyLeft: "Preostala količina",
	orderQtyFill: "Realizovana količina",
	orderQtyVisible: "Vidljiva količina",
	orderSecurityAcc: "Račun hartija",
	orderMoneyAcc: "Račun novca",
	orderPriceVat: "Pdv",
	orderMinQty: "Minimalna količina",
	orderComment: "Napomena",
	orderCommission: "Provizija",
	ordergoodsRedyFrom: "Proizvod spreman od",
	ordergoodsRedyTo: "Proizvod spreman do",
	orderStatusDescr: "Status",
	orderFillStatusDescr: "Realizacija",
	orderCancelReplTime: "Vreme opoziva/modifikacije",
	orderPayData: "Datum plaćanja",
	orderSide: "Strana",
	orderCurrCode: "Valuta",
	orderexportReady: "Spremno za izvoz",
	orderOrigOrderNo: "Originalni nalog",
	orderIceberg: "Iceberg",
	orderSecurity: "Hartija",
	orderPreMarket: "PreMarket",
	orderRejection: "Razlog odbijanja",

	numberOfDays: "Broj dana",
	navListing: "Listing",
	navMyOrders: "Moji nalozi",
	navArchive: "Arhiva",
	navCompanies: "Kompanije",
	navMembers: "Članovi",
	navProducts: "Proizvodi",
	navBrokers: "Brokeri",
	navUsers: "Korisnici",
	navRoles: "Uloge",
	navCodebook: "Šifarnik",
	navCountries: "Države",
	navTopRegion: "Region",
	navRegion: "Okrug",
	navSubRegion: "Podregion",
	navCities: "Gradovi",
	navCurrencies: "Valute",
	navWarehouses: "Skladišta",
	navTrading: "Trgovanje",
	navMainTable: "Glavna tabla",
	navOrders: "Nalozi",
	navTransactions: "Zaključnice",
	navMarketDepth: "Dubina tržišta",
	navCliring: "Kliring",
	navDeposits: "Depoziti",
	navMarginCalculations: "Obračun marže",
	navSurveillance: "Nadzor",
	navAlarmSetup: "Postavljanje alarma",
	navProductReport: "Izveštaji o proizvodima",
	navMemberReport: "Izveštaji o članovima",
	navReports: "Izvestaji",
	navDailyReports: "Dnevni izvestaji",
	navWeeklyReports: "Nedeljni izvestaji",
	navMonthlyReports: "Mesečni izvestaji",
	navQuaterlyReports: "Kvartalni izvestaji",
	navAnnualReports: "Godišnji izvestaji",
	navSECReports: "SEC izvestaji",
	navMembersReports: "Izvestaji o članovima",
	navOrdersReports: "Nalozi i zaključnice",
	navAccounting: "Knjiženje",
	navTaxes: "Porez i provizija",
	navInvoice: "Kreiranje faktura",
	navAccountingExport: "Eksport za knjiženje",
	navMembersDeposit: "Depoziti članova",
	empFirstName: "Ime",
	empLastName: "Prezime",
	empIdNumber: "JMBG",
	empOfficePhone: "Broj poslovnog telefon",
	empEmail: "Email",
	empStatus: "Status",
	empMidName: "Srednje ime",
	empGender: "Rod",
	empAddress: "Adresa",
	empZipCode: "Poštanski broj",
	empCity: "Grad",
	empPhone: "Broj telefona",
	empDateOfBirth: "Datum rođenja",
	empPosition: "Pozicija",
	empBranch: "Grana",
	empFax: "Faks",
	empShowTooltip: "Prikaži brokera",
	genderMale: "Muški",
	genderFemale: "Ženski",
	statusActive: "Aktivan",
	statusInactive: "Neaktivan",
	empEdit: "Ažuriraj",
	empDelete: "Obriši",
	empEditTitle: "Izmena",
	empCreateTitle: "Kreiranje brokera",
	empName: "Zaposleni",
	empTel: "Telefon",
	empMob: "Mobilni",

	cliName: "Skraćen naziv",
	cliIdNo: "Matični broj",
	cliTaxCode: "PIB broj",
	cliType: "Tip",
	cliForeign: "Strani",
	cliCity: "Grad",
	cliPhone: "Telefon",
	cliBroker: "Broker",
	cliActive: "Aktivan",
	cliStatus: "Status",
	clilFullName: "Naziv",
	cliAddress: "Adresa",
	cliMailCity: "Grad za poštu",
	cliMailAddress: "Adresa za poštu",
	cliMobile: "Mobilni telefon",
	cliFax: "Faks",
	cliEmail: "Email",
	cliHasContract: "Ima ugovor",
	cliActiveFrom: "Aktivan od",
	cliActiveTo: "Aktivan do",
	cliCommission: "Provizija",
	cliCurrentTurnover: "Promet",
	cliComment: "Komentar",
	cilEditTitle: "Ažuriraj člana",
	cilCreateTitle: "Kreiraj člana",
	cilTitle: "Članovi",
	tableConfirmationDelete: "Da li ste sigurni?",
	tableDelete: "Obriši",
	tableAdd: "Dodaj",
	tableEdit: "Ažuriraj",
	tableCancel: "Otkaži",
	tableSave: "Saćuvaj",
	roleCode: "Šifra",
	roleName: "Naziv SR",
	roleNameEn: "Naziv EN",
	tableShow: "Prikaži",
	roleEdit: "Ažuriraj ulogu",
	roleCreate: "Kreiraj ulogu",
	roleNameColumn: "Naziv",
	roleIsEmployee: "Zaposlen",
	userUserName: "Ime",
	userLoginUser: "Korisničko ime",
	userLoginPass: "Lozinka",
	userChangePass: "Promena lozinke",
	userRoleId: "Uloga",
	userIsEmployee: "Zaposlen",
	userEmpId: "Zaposleni",
	userClientId: "Klijent",
	userCliPrsId: "Osoba klijenta",
	userSubscribedUntil: "Pristup do",
	userSubscrUnlimited: "Neograničen pristup",
	userIsActive: "Aktivan",
	userEditTitle: "Ažuriraj korisnika",
	userCreateTitle: "Kreiraj korisnika",
	tableBasciInfo: "Osnovni podaci",
	clientPersonTitle: "Lica",
	clientPersonName: "Ime",
	clientPersonIDNumber: "JMBG",
	clientPersonStatus: "Status",
	clientPersonEmpty: "Nema lica",
	clientBrokerTitle: "Brokeri",
	clientBrokerName: "Ime",
	clientBrokerIDNumber: "JMBG",
	clientBrokerStatus: "Status",
	clientBrokerEmpty: "Nema brokera",
	contractTitle: "Ugovori",
	contractNumber: "Broj",
	contractDate: "Datum",
	contractStatus: "Status",
	contractEmpty: "Nema ugovora",
	contractLincedContrNo: "Povezani ugovori",
	contractTypeDescription: "Vrsta",
	contractDescription: "Opis",
	contractPhoneOrder: "Broj telefona",
	contractWebUser: "Web korisnik",
	contractWebUserBlocked: "Web korisnik blokiran",
	contractTitleEdit: "Ažuriraj ugovor",
	contractTitleCreate: "Kreiraj ugovor",
	accountTitle: "Novčani računi",
	accountTitleEdit: "Ažuriraj novčani račun",
	accountTitleCreate: "Kreiraj novčani račun",
	accountNumber: "Broj računa",
	accountBank: "Banka",
	accountDefault: "Podrazumevani račun",
	accountClientBankCode: "Šifra banke klijenta",
	accountCurrency: "Valuta",
	accountForBuySell: "Kupovina/Prodaja",
	accountStatus: "Status",
	accountSideBuy: "Kupovna",
	accountSideSell: "Prodajna",
	accountSideBoth: "Obe",
	accountEmpty: "Nema novčanih računa",
	invalidDateMessage: "Datum nije validan",
	maxDateMessage: "Datum je isuviše veliki",
	minDateMessage: "Datum je isuviše mali",
	clientPersonEditTitle: "Ažuriraj osobu",
	clientPersonCreateTitle: "Kreiraj osobu",
	clientBrokerEditTitle: "Ažuriraj brokera",
	clientBrokerCreateTitle: "Kreiraj brokera",
	leavePageTitle: "Napuštate stranicu?",
	leavePageMessage: "Ako napustite ovu stranicu sve promene će biti izgubljene.",
	leavePageConfirm: "Napusti stranicu",
	leavePageCancel: "Ostani",
	calendarOK: "OK",
	calendarCancel: "Otkaži",
	calendarClear: "Obriši",


	commission: "Provizija",
	requiredMessage: "Polje je obavezno",
	messageWeakPassword: "Lozinka nije dovoljno jaka",

	currenciesTitle: "Valute",
	currenciesCreateTitle: "Kreiraj valutu",
	currenciesEditTitle: "Ažuriraj valutu",
	currenciesName: "Naziv",
	currenciesCode: "Oznaka",
	currenciesNumericCode: "Šifra",
	regName: "Region",
	regNameDistrict: "Okrug",
	parentRegName: "Region",
	countryReg: "Država",
	regEdit: "Ažuriraj region",
	regEditDistrict: "Ažuriraj okrug",
	regCreate: "Kreiraj region",
	regCreateDistrict: "Kreiraj okrug",
	curRateDate: "Datum kursa",
	curRateBuy: "Kupovni kurs",
	curRateSell: "Prodajni kurs",
	curRateMid: "Srednji kurs",
	curRateCurrency: "Valuta",
	curRateTitle: "Kursna lista",
	curRateEditTitle: "Ažuriraj kurs",
	curRateCreateTitle: "Kreiraj kurs",
	countryEdit: "Ažuriraj državu",
	countryCreate: "Kreiraj državu",
	countryName: "Naziv",
	countryNameEN: "Naziv (EN)",
	cityEdit: "Ažuriraj grad",
	cityCreate: "Kreiraj grad",
	cityName: "Naziv",
	cityZipCode: "Poštanski broj",
	cityRegion: "Okrug",
	citySubRegion: "Podregion",
	cityIsPort: "Luka",

	commissionTitle: "Provizije",
	commissionCreateTitle: "Kreiraj proviziju",
	commissionEditTitle: "Ažuriraj proviziju",
	commissionCode: "Šifra",
	commissionName: "Naziv",
	commissionNameEN: "Naziv (EN)",
	commissionType: "Tip",
	commissionStandard: "Standardna",
	commDetailsTitle: "Detalji provizije",
	commDetailsCreateTitle: "Kreiraj detalj provizije",
	commDetailsEditTitle: "Ažuriraj detalj provizije",
	commDetailsDate: "Datum od",
	commDetailsProductGroup: "Grupa proizvoda",
	commDetailsProduct: "Proizvod",
	commDetailsCurrency: "Valuta",
	commDetailsAmtCurrency: "Valuta iznosa",
	commDetailsAmtTypePercent: "Procenat",
	commDetailsAmtTypeAmount: "Iznos",
	commDetailsBuyAmtType: "Kupovni tip iznosa",
	commDetailsBuyPerc: "Kupovni procenat",
	commDetailsBuyMin: "Kupovna minimalna količina",
	commDetailsBuyMax: "Kupovna maksimalna količina",
	commDetailsSellAmtType: "Prodajni tip iznosa",
	commDetailsSellPerc: "Prodajni procenat",
	commDetailsSellMin: "Prodajni minimalna količina",
	commDetailsSellMax: "Prodajni maksimalna količina",
	productCode: "Šifra",
	clientPersonRole: "Uloga",
	clientPersonDateCertified: "Datum sertifikata",
	clientPersonPhone: "Telefon",
	clientPersonEmail: "Mail",

	tradeTitle: "Zaključnice",
	tradeSumTitle: "Zaključnice zbirne",
	tradeNo: "Broj",
	tradeTime: "Datum prometa",
	tradeSettl: "Datum saldiranja",
	tradeSecurity: "Hartija",
	tradeQty: "Količina",
	tradeDescr: "Transakcija",
	tradeCurrCode: "Valuta",
	tradePrice: "Cena",
	tradeOrder: "Nalog",
	tradeBrokCommPerc: "Brokerska provizija procenat",
	tradebrokCommAmt:"Brokerska provizija",
	tradeExchCommPerc:"Berza provizija procenat",
	tradeExchCommAmt:"Berza provizija",
	tradeRegCommPerc:"Registar provizija procenat",
	tradeRegCommAmt:"Registar provizija",
	tradeBankCommPerc:"Banka provizija procenat",
	tradeBankCommAmt:"Banka provizija",
	tradeVatAmt:"Pdv",
	tradeTotComm:"Ukupno provizija",
	tradeExchange: "Berza",
	tradeIsin:"Isin",
	tradeComm:"Provizija",
	tradeAmtFinal: "Konačan iznos",
	tradeRate: "Kurs",

	listingOrdersBookTitle: "Knjiga naloga",
	orderCancel: "Povuci nalog",
	orderCancelQuestion: "Povuci nalog?",
	orderCancelQuestionYes: "Da",
	orderCancelQuestionNo: "Ne",
	orderModify: "Modifikuj nalog",
	orderVerify: "Verifikuj nalog",
	tradeVatPerc: "Pdv %",
	tradePriceWithVat: "Cena sa pdv",
	tradeAmt: "Iznos",
	tradeAmtWithVat: "Iznos sa pdv",
	tradeTransCostSell: "Trosak transporta prodavca",
	tradeStorCostSell: "Trosak skladistenja prodavca",
	tradeTransCostBuy: "Trosak transporta kupca",
	tradeStorCostBuy: "Trosak skladistenja kupca",
	tradeCommPercSell: "Provizija prodavca %",
	tradeCommAmtSell: "Provizija prodavca ",
	tradeCommPercBuy: "Provizija kupca %", 
	tradeCommAmtBuy: "Provizija kupca", 
	orderFilterConfirm: "Filtriraj",
	orderFilterCancel: "Otkaži",
	orderFilterFrom: "Nalog od",
	orderFilterTo: "Nalog do",
	tradeFilterFrom: "Zaključnica od",
	tradeFilterTo: "Zaključnica do",
	tradeViewAction: "Prikaži zaključnicu",
	tradeCancelAction: "Poništi zaključnicu",
	orderTradeQuestion: "Poništi zaključnicu?",
	orderSecDep: "Depozitar",
	orderStatusChange: "Promena statusa",

	filterFrom: "od",
	filterTo: "do",
	paymentTitle: "Plaćanja",
	paymentDataTime: "Datum",
	paymentClientCode: "Matbr klijenta",
	paymentClientName: "Naziv klijenta",
	paymentPayType: "Vrsta promene",
	paymentClmOrdNo: "Broj naloga",
	paymentTranNo: "Broj transakcije",
	paymentAmount: "Iznos",
	paymentAmountIn: "Ulaz",
	paymentAmountOut: "Izlaz",
	paymentCurrCode: "Valuta",
	paymentStatus: "Status",
	datefrom: "Od datuma",
	dateto: "Do datuma",
	paymentEdit: "Ažuriraj plaćanje",
	paymentCreate: "Kreiraj plaćanje",
	paymentSideIn: "Ulaz",
	paymentSideOut: "Izlaz",
	paymentSide: "Ulaz/Izlaz",
	paymentSideNonDedicated: "Bez namene",
	paymentSideDedicated: "Sa namenom",
	paymentSideDedication: "Namena",
	paymentBankGuarantee: "Bankarska garancija",

	paymentClientOrders: "Nalozi klijenta",
	paymentClientTransactions: "Transakcije klijenta",

	guarGuaranteeTitle: "Garancije",
	guarEdit: "Ažuriraj garanciju",
	guarCreate: "Kreiraj garanciju",

	orderDeliveryLocation: "Mesto isporuke",
	filterReset: "Resetuj",
	orderMarket: "Market",
	orderMarketSpot: "Spot",
	orderMarketForward: "Forward",
	orderLocCountry: "Država",
	orderLocRegion: "Region",
	orderLocDistrict: "Oblast",
	orderLocCity: "Grad",
	orderLocWhs: "Skladište",
	tradeFulfillmentDate: "Datum izvršenja",
	navOrdersReport: "Knjiga naloga",

	stockFeeTitle: "Skladišne tarife",
	stockFeeEdit: "Ažuriraj skladišnu tarifu",
	stockFeeCreate: "Kreiraj skladišnu tarifu",
	stockFeeStartDate: "Datum početka",
	stockFeeStockFee: "Provizija",
	stockFeeStatus: "Status",

	transFeeTitle: "Transport cene",
	transFeeEdit: "Ažuriraj cenu transporta",
	transFeeCreate: "Kreiraj cenu transporta",
	transFeeStartDate: "Datum početka",
	transFeePice: "Cena",
	transFeeDistance: "Razdaljina",
	transFeeStatus: "Status",

	navTradesReport: "Knjiga zaključnica",

	dailyTradeReportTitle: "Dnevni izveštaj",
	dailyTradeReportProduct: "Proizvod",
	dailyTradeReportTurnoverRSD: "Promet RSD",
	dailyTradeReportTurnoverEUR: "Promet EUR",
	dailyTradeReportCountTransaction: "Broj transakcija",
	dailyTradeReportReportDate: "Datum izveštaja",

	monthlyTradeReportReportTitle: "KHOV Mesečni izveštaj",
	monthlyTradeReportReportMonth: "Mesec",
	monthlyTradeReportReportYear: "Godina",
	monthlyTradeReportReportDate: "Datum",
	monthlyTradeReportReportQty: "Količina",
	monthlyTradeReportReportWeightedRSD: "Ponderisana cena RSD",
	monthlyTradeReportReportWeightedEUR: "Ponderisana cena EUR",

	filterTooltip: "Filter",
	exportPDFTooltip: "Eksportuj PDF",
	exportXLSXTooltip: "Eksportuj Excel",
	printTooltip: "Štampaj",
	settingsTooltip: "Podešavanje",
	
	marketDataAddSecurity: "Dodaj na tablu",
	marketRemoveSecurity: "Skloni sa table",

	orderFundCheckButton: "U redu",
	orderFundCheckButtonCancel: "Odustani",
	orderFundCheckTitle: "Provere sredstava",

	weeklyNumofWeeks: "Broj nedelja",
	monthlyNumofmonths: "Broj meseci",

	exmemTitle: "Članovi berze",
	exmemMemberName: "Naziv Člana",
	exmemMemberId: "Matični broj",
	exmemTaxCode: "Pib",
	exmemAdress: "Adresa",
	exmemPhone: "Telefon",
	exmemManager: "Direktor",
	exmemTrader: "Broker",
	exmemContactPerson: "Kontakt osoba",

	memcontTitle: "Ugovori članova berze",
	memcontMember: "Član",
	memcontContractDate: "Datum ugovora",
	memcontExpireDate: "Datum isteka ugovora",
	memcontStatus: "Status",

	exchDateFrom: "Datum od",
	exchDateTo: "Datum do",
	exchTradeReview: "Pregled trgovanja",
	exchCommissionReview: "Pregled provizija",
	exchTradeByProduct: "Pregled trgovanja po proizvodu",
	exchBusinessActivities: "Poslovanje u periodu",
	exchTurnoverByClient: "Promet po klijentima",
	exchTurnoverByBrokers: "Promet po brokerima",
	exchWeightedPrice: "Ponderisane cene",
	exchDate: "Datum",
	exchTradeNumber: "Broj transakcije",
	exchTradeDate: "Datum transakcije",
	exchProduct: "Proizvod",
	exchPrice: "Cena",
	exchQuantity: "Količina",
	exchAmount: "Iznos",
	exchClient: "Klijent",
	exchTransactionDate: "Datum transakcije",
	exchTransactionNumber: "Broj transakcije",
	exchOrderNumber: "Broj naloga",
	exchSide: "Strana",
	exchCommission: "Provizija",
	exchTurnoverValue: "Promet",
	exchBuyersCommission: "Provizija kupca",
	exchSellersCommission: "Provizija prodavca",
	exchBuyerBroker: "Broker kupca",
	exchSellerBroker: "Broker prodavca",
	exchCommissionValue: "Iznos Provizije",
	exchTurnoverParticipation: "Učešće u prometu",
	exchCommissionParticipation: "Učešće u proviziji",
	exchBuyer: "Kupac",
	exchSeller: "Prodavac",
	exchTurnoverRsd: "Promet Rsd",
	exchTurnoverEur: "Promet EUR",
	exchWeightedPriceRsd: "Ponderisana cena Rsd",
	exchWeightedPriceEur: "Ponderisana cena Eur",

	annualNumofyears: "Broj godina",

	pleaseWait: "Molimo sačekajte...",


	iInvoiceTooltip: "Kreiraj fakture",

	invoicingMessage: "Fakturisanje uspešno završeno.",
	invoicingMessageTitle: "Fakturisanje",
	invoicingMessageConfim: "OK",

	accountingOrderTooltip: "Kreiraj nalog",
	accountingOrderTitle: "Nalog za knjiženje",
	accountingOrderMessage: "Formiranje naloga završeno.",
	accountingOrderMessageTitle: "Formiranje naloga",
	accountingOrderConfim: "OK",

	marketDataSymbol: "Simbol",
	marketDataIsin: "Isin",
	marketDataPrice: "Cena",
	marketDataBestBid: "Najbolja ponuda",
	marketDataBestBidQty: "Najbolja ponuda kolicina",
	marketDataBestAsk: "Najbolja traznja",
	marketDataBestAskQty: "Najbolja tražnja količina",
	marketDataOpenPrice: "Cena na otvaranju",
	marketDataHighPrice: "Najviša cena",
	marketDataLowPrice: "Najniža cena",
	marketDataClosePrice: "Cena na zatvaranju",
	marketDataVolume: "Količina",
	marketDataSumBid: "Ukupno ponuda",
	marketDataSumAsk: "Ukupna Tražnja",
	marketDataTrend: "Trend",
	marketDataChange: "Promena",
	marketDataTradingPhase: "Faza",
	marketDataTradeTime: "Vreme",
	marketDataDepth: "Dubina",
	marketPreviousClose: "Prethodna cena",
	marketDataChangePerc: "Promena u %",
	marketWeeks52High: "Najviša za godinu",
	marketWeeks52Low: "Najniža za godinu",
	marketDataName: "Naziv",
	marketDataGraph: "Grafik",
	marketIEX :"Podaci preuzeti od IEX Cloud",
	marketTime:"Vreme",
	marketSource:"Izvor",
	marketview: "Pogledaj detalje",

	tradingDatadatefrom: "Od datuma",
	tradingDatadateto: "Do datuma",
	tradingDataEdit: "Ažuriraj referentnu cenu",


	noFutureDate: "Datum ne sme biti veći od današnjeg.",

	orderQtyMatched: "Uparena količina",
	alarmTime: "Vreme alarma",
	alarmCrossName: "Cross alarm",
	alarmProductName: "Alarm za proizvod",
	alarmClientName: "Alarm za člana",

	tradingDataMessage: "Obračun cena završen.",
	tradingDataMessageTitle: "Obračun cena",
	tradingDataConfim: "OK",
	tradingDataReportDate: "Datum obračuna cena",

	marginsDataMessage: "Obračun margina završen.",
	marginsDataMessageTitle: "Obračun margina",
	marginsDataConfim: "OK",
	marginsDataReportDate: "Datum obračuna margine",


	attrCompTypeLessThan: "<=",
	attrCompTypeEqual: "=",
	attrCompTypeMoreThan: ">=",
	attrCompType: "Komparator",
	productAttributeHasStandard: "Ima standardnu vrednost",
	productAttributeStandardValue: "Standardna vrednost",

	orderParameters: "Parametri naloga",
	tradeParameters: "Parametri transakcije",
	orderDepositeAmount: "Potreban iznos depozita",
	orderDepositeAmountPayed: "Plaćen iznos depozita",
	paymentAmtBal: "Ukupna sredstava",
	paymentAmtBalNonAlloc: "Nealocirana sredstava",

	attributeSRPS: "SRPS kvalitet",

	paymentAmtOrdersBal: "Stanje na nalozima",
	paymentAmtTradesBal: "Stanje na transakicjama",

	deliveryTradeQty: "Ugovorena količina",
	deliveryLeftQty: "Preostalo za isporuku",

	clientCardTitle: "Kartica klijenta",

	alarmProductClientName: "Alram za klijent i proizvod",
	alarmBuyOrderNumber: "Broj kupovnog naloga",
	alarmSellOrderNumber: "Broj prodajnog naloga",
	allowSystemNotifications: "Ukljuci sistemske notifikacije",

	navPortfolio: "Portfelj",

	portfSymbol: "Simbol",
	portfExchCode: "Berza",
	portfCurrCode: "Valuta",
	portfQty: "Količina",
	portfPchPrice: "Nabavna cena",
	portfPchAmt: "Nabavna vrednost",
	portfMktPrice: "Tržišna cena",
	portfMktAmt: "Tržišna vrednost",
	portfDiffPerc: "Razlika %",
	portfDiffAmt: "Razlika",
	portfIsin:"Isin",
	portfSecName:"Naziv",
	portfDepositor: "Depozitar",
	portfSecAccType: "Tip rač.",
	portfSecAccount: "Račun",
	portfOnExchYes: "Na berzi",
	portfOnExchNo: "Nije na berzi",

	errorTitle: "Greška",
	errorConfirm: "OK",
	errorMessageChangePass: "Slaba lozinka",
	errorSuccessTitle: "Promena lozinke",
	errorSuccess: "Uspešno promenjena lozinka",

	settingsPasswordChangeMenu: "Promena lozinke",

	navCashBalance: "Stanje računa",

	cashCurrCode: "Valuta",
	cashAccUse: "Namena",
	cashAccNo: "Broj računa",
	cashBank: "Banka", 
	cashCurrentAmt: "Potencijalno",
	cashSellNetAmt: "Neting Prodaja",
	cashBuyNetAmt: "Neting Kupovina",
	cashSettledAmt: "Saldirano stanje",
	cashBankAmt: "Stanje banka",
	cashSellOrdAmt: "Aktivni nal. prodaje",
	cashBuyOrdAmt: "Aktivni nal. kupovine",
	cashAvailAmt: "Raspoloživo",
	cashImportTime: "Vreme importa",


	domForSideDomestic: "Domaći",
	domforSideForeign: "Strani",
	domForMarket: "Tržište",
	domForDefDom: "Domaći/def ",
	domForDefFor: "Strani/def ",
	domForCurrCode: "Valuta",
	domForAccSide: "Strana",
	domForChange: "Promeni",

	navSecuritiyAccounts: "Računi hartija",
	navMoneyAccounts:"Računi novca",

	navSupport: "Pomoć",

	orderChecked: "Upozorenje: Cena i vrednost realizacije naloga može značajno odstupiti od trenutne tržišne vrednosti.",

	otherSettingsMenu: "Ostala podešavanja",
	confirmOrderSetting: "Dodatna potvrda naloga",

	currCodeSettl: "Valuta saldiranja",
    amountSettl: "Iznos saldiranja",
    settlState: "Status saldiranja"


//	errorProductAttributeDelete: "Atribut proizvoda koji je bio u nekom nalogu ne može da se briše.",

};

import { ThunkType } from "../../../../core/store/reducers";
import { getOrderListReport } from "../api/getOrderListReport";
import fileDownload from "js-file-download";
import { setPrintOrderFetchState } from "../orderBookDataSlice";

export const downloadOrdersReport = (): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return undefined;
	}

	const filter = store.ux.domestic.orderBook.filter;
	const user = store.data.auth.current.user;
	dispatch(setPrintOrderFetchState({ type: "started" }));
	const ordersBookReport = await dispatch(getOrderListReport(user, filter));
	console.log(ordersBookReport)
	if (ordersBookReport) {
		fileDownload(new Blob([ordersBookReport as any], { type: "application/pdf" }), "OrderBook.pdf");
	}
	dispatch(setPrintOrderFetchState({ type: "not-started" }));
};

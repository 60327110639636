import { NavigationItem } from "../../../core/navigation/types";
import { otherSettingsLocation } from "./Locations";
import { OtherSettingsForm } from "./OtherSettingsForm";

export const otherSettingsComponents: NavigationItem[] = [
	{
		Component: OtherSettingsForm,
		isMenuItem: true,
		path: otherSettingsLocation,
		notifications: true,
	},

];


import React from "react";
import { useSecurityFSelectData } from "../../../data/initialData/hooks/useSecurityFSelectData";
import { SecuritySimpleList, SecuritySimpleFilter } from "../../../data/initialData/types";
import { AutocompleteSelectType, AutocompleteSelect } from "../common/AutocompleteSelect";
import {  OrderMarket } from "../../../data/domestic/orders/types";

export type SecuritySelectPropsType = Omit<AutocompleteSelectType<SecuritySimpleList>,"data" | "getLabel">& {
	filter?: SecuritySimpleFilter;};


export const SecuritySelect: React.FunctionComponent<SecuritySelectPropsType> = ({
	filter,
	...props
})  => {

	const securities = useSecurityFSelectData(OrderMarket.Foreign, filter);

	return (
		<AutocompleteSelect<SecuritySimpleList>
			{...props as AutocompleteSelectType<SecuritySimpleList>}
			data={securities}
			getLabel={renderSecurityLabel}
		/>
	);
};

const renderSecurityLabel = (securities: SecuritySimpleList | null) =>
securities ? `${securities.secData} ` : "";


import { ThunkType } from "../../../../core/store/reducers";
import { setdefmoneyaccApi } from "../api/setdefmoneyaccData";
import { setData } from "../moneyAccountsDataSlice";
import { DomForSide, OrderSide } from "../../../models";

export const loadSetDefMoneyacc = ((): ThunkType => async (dispatch, getStore) => {

  const store = getStore();
  if (store.data.auth.current.status !== "successful") {
    return;
  }
  const  userid = store.data.auth.current.user.userId;

  const { filter } = store.ux.moneyAccounts;

  const secid=store.data.moneyAccounts.accountid

  const domfor = filter.domfor ===DomForSide.Domestic ? "D" : "F" ;
  const curid = filter.curency.currId
  const buysell = filter.buysell ===OrderSide.Buy ? "B" : "S" ;


console.log(domfor)
console.log(curid)
console.log(buysell)
console.log(secid)

//  dispatch(setData());

  const marketData = await dispatch(setdefmoneyaccApi(userid, domfor,curid, buysell,secid));
  
  dispatch(setData());
});



import { ThunkType } from "../../../../core/store/reducers";
import { createOrderApi } from "../api/createOrder";
import { updateOrderApi } from "../api/updateOrder";
// import { modifyOrderApi } from "../api/modifyOrder";
// import { oppositeOrderApi } from "../api/oppositeOrder";
import { setNewOrderFetchState, invalidateOrders } from "../orderBookDataSlice";
import { EditOrder, OrdersEditAction, OrderUpsertResult } from "../types";

export const upsertOrder = (
	action: OrdersEditAction,
	order: EditOrder
): ThunkType<Promise<OrderUpsertResult>> => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return {
			status: "ERROR",
			message: "USER NOT LOGED IN!",
		};
	}

	dispatch(setNewOrderFetchState({ type: "started" }));

	var { user } = store.data.auth.current;

//	const result = await dispatch(createOrderApi(user.userId, order))

//	: action === OrdersEditAction.New ? await dispatch(createOrderApi(order));
//			action === OrdersEditAction.Modify ? await dispatch(updateOrderApi(order, user.userId))
//			: await dispatch(oppositeOrderApi(order));
// 		action === OrdersEditAction.Verify ? await dispatch(modifyOrderApi(order, user.userId))


const result =
		action === OrdersEditAction.Modify ? await dispatch(updateOrderApi(user.userId, order.orderId, order.qty , order.price))
			:  await dispatch(createOrderApi(user.userId, order));



	if (result && result.resCode === "OK") {
		dispatch(setNewOrderFetchState({ type: "successful", result: result.resDesc }));
		dispatch(invalidateOrders());
		return {
			status: "CREATED",
			newId: result.resDesc,
		};
	} else 
	{
		dispatch(setNewOrderFetchState({ type: "error", errorCode: result?.resCode }));
		return {
			status: "ERROR",
			message: result?.resCode,
		};
	}

/*	if (result && result.resCode === "OK") {
		dispatch(setNewOrderFetchState({ type: "successful", result: result.resDesc }));
		dispatch(invalidateOrders());
		return {
			status: "CREATED",
			newId: result.resDesc,
		};
	} else if (result && result.resCode === "NOFUNDS") {
		dispatch(setNewOrderFetchState({ type: "not-started" }));
		return {
			status: "NOFUNDS",
			message: result.resDesc,
		};
	} else {
		dispatch(setNewOrderFetchState({ type: "error", errorCode: result?.resCode }));
		return {
			status: "ERROR",
			message: result?.resCode,
		};
	}  */

};

import React, { useContext, useMemo } from "react";
import ViewIcon from "@material-ui/icons/InputOutlined";
import { SecurityAccounts } from "../../../data/models";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Action } from "material-table";
import { useSetDefSeccAcc } from "../../../data/settings/securityaccounts/hooks/useSetDefSecAcc";
import { useSecAccountFilter, useCurrentSecAccountFilter,} from "../../../data/settings/securityaccounts/hooks/useSecAccountFilter";

export const useSecAccountActions = () => {
  const { getString } = useContext(LocalizationContext);
  
  const onShowBank = useSetDefSeccAcc();
 

  return useMemo(() => {
    const result: Action<SecurityAccounts>[] = [
      {
        icon: () => <ViewIcon />,
        tooltip: getString("domForChange"),
        onClick: (event, rowData) => onShowBank(rowData as SecurityAccounts),
      },
    ];

    return result;
  }, [onShowBank,  getString]);
};


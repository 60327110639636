import React from "react";
import { useTifList } from "../../../data/initialData/hooks/useTifList";
import { TifList } from "../../../data/models";
import { AutocompleteSelect, AutocompleteSelectType,} from "./AutocompleteSelect";

type RegionSelectPropsType = Omit<
  AutocompleteSelectType<TifList>,
  "data" | "getLabel"
>;

export const TifListSelect: React.FunctionComponent<RegionSelectPropsType> = (
  props
) => {
  const [statuses, renderStatus] = useTifList();

  return (
    <AutocompleteSelect<TifList>
      data={statuses}
      getLabel={renderStatus}
      {...(props as any)}
    />
  );
};

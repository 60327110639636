import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { ChartPeriod, TradePricesChartData, DayPriceData } from "../types";
import { loadGraphDayData } from "../thunks/loadGraphDayData";

export const useGraphDayData = (graphId: string) => {
	const dispatch = useDispatch();
	const period = ChartPeriod.Day;
	const prodId = useSelector<StoreType, string | undefined>(s => s.data.foreign.marketData.secid);
	const data = useSelector<StoreType, DayPriceData[] | undefined>(
		s => s.data.foreign.ticker.graphDayData[graphId || ""]
	);


	const dateGraph =  new Date().toISOString();

	React.useEffect(() => {
		if (prodId) {
			dispatch(loadGraphDayData(graphId, prodId, dateGraph));
		}
	}, [graphId, period, prodId, dispatch]);

	console.log(data)

	return [data];
};

import React, { useContext, useMemo } from "react";
import ViewIcon from "@material-ui/icons/InputOutlined";
import { MarketListData } from "../../../data/domestic/mainBoard/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { MaterialTableProps } from "material-table";
import { useMarketSecurityData } from "../../../data/domestic/mainBoard/hooks/useMarketSecurityData";

export function useMarketSecurityActions() {

  const { getString } = useContext(LocalizationContext);
  const onGetSecurity = useMarketSecurityData();

  return useMemo(() => {
    const result: MaterialTableProps<MarketListData>["actions"] = [
      {
        icon: () => <ViewIcon />,
        tooltip: getString("marketDataDepth"),
        onClick: (event, rowData) => onGetSecurity(rowData as MarketListData),
       },
    ];
    return result;
  }, [onGetSecurity, getString ]);
}


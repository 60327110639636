import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PageIndex  } from "../../models";
import { successfulLogin } from "../../auth/authDataSlice";
import {  PortfolioFilter } from "./types";

type PortfolioState = {
	index: PageIndex;
	filter: PortfolioFilter;
};

export const initialState: PortfolioState = {
	index: {
		itemsPerPage: 20,
		pageNumber: 0,
	},
	filter: {},
};

const name = "portfolio";

const portfolioSlice = createSlice({
	name,
	initialState,
	reducers: {
		setPortfolioIndex: (state, action: PayloadAction<PageIndex>) => ({
			...state,
			index: action.payload,
		}),
		resetOrderIdices: state => ({
			...state,
			index: {
				itemsPerPage: state.index.itemsPerPage,
				pageNumber: initialState.index.pageNumber,
			},
		}),
		setFilter: (state, action: PayloadAction<PortfolioFilter>) => ({
			...state,
			filter: action.payload,
		}),
		removeFilter: (state, action: PayloadAction<keyof PortfolioFilter>) => ({
			...state,
			filter: {
				...state.filter,
				[action.payload]: undefined,
			},
		}),
		resetFilter: state => ({
			...state,
			filter: {
				...initialState.filter,
			},
		}),
	},
	extraReducers: builder => {
		builder.addCase(successfulLogin, state => ({
			...state,
			index: {
				itemsPerPage: state.index.itemsPerPage,
				pageNumber: initialState.index.pageNumber,
			},
		}));
	},
});

export const {  setPortfolioIndex, resetOrderIdices, setFilter, removeFilter, resetFilter } = portfolioSlice.actions;

export default portfolioSlice.reducer;

import { useSelector } from "react-redux";
import { StoreType } from "../../../core/store/reducers";
import { Exchanges } from "../../models";

const getStatusLabel = (status?: Exchanges) => (status ? `${status.exch} (${status.exchName})`  : "");

export const useExchanges = () => {
	const statuses = useSelector<StoreType, Exchanges[]>(store => store.data.initialData.exchanges);

	return [statuses, getStatusLabel] as [typeof statuses, typeof getStatusLabel];
};

import { ThunkType } from "../../../../core/store/reducers";
import { getPortfolioReport } from "../api/getPortfolioReport";
import fileDownload from "js-file-download";
import { setDownloadReportFetchState } from "../../../domestic/portfolio/portfolioDataSlice";

export const downloadPortfolioReport = (): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return undefined;
	}

	const filter = store.ux.domestic.portfolio.filter;
	const user = store.data.auth.current.user;
	dispatch(setDownloadReportFetchState({ type: "started" }));
	const portfolioReport = await dispatch(getPortfolioReport(user, filter));
	if (portfolioReport) {
		fileDownload(new Blob([portfolioReport as any], { type: "application/pdf" }), "Portfolio.pdf");
	}
	dispatch(setDownloadReportFetchState({ type: "not-started" }));
};

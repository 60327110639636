import React from "react";
import { TableDataProps } from "../../components/common/Table";
import { useMarketData } from "../../../data/foreign/mainBoard/hooks/useMarketData";
import { FMarketListData } from "../../../data/foreign/mainBoard/types";
import { useMarketDataColumns } from "./useMarketDataColumns";
import { AccessRights } from "../../../core/navigation/types";
import { useMarketSecurityActions } from "./useMarketSecurityActions";
import { useSelector } from "react-redux";
import { StoreType } from "../../../core/store/reducers";
import { PaginationTable } from "../../components/common/PagginationTable";
import { useMarketDataIndex } from "../../../data/foreign/mainBoard/hooks/useMarketDataIndex";

export interface MarketDataTableProps {
  userAccessRights: AccessRights;
}

type MarketDataTablePropsType = MarketDataTableProps & TableDataProps<FMarketListData>;

export const MarketDataTable: React.FunctionComponent<MarketDataTablePropsType> = ({
  userAccessRights,
  ...tableProps
}) => {

  const security = useSelector<StoreType, string | undefined>(
		store => store.data.foreign.marketData.secid
	);   

  const [data, loadAll, all] = useMarketData();
  const [index, setIndex] = useMarketDataIndex()

  const columns = useMarketDataColumns(security);
  const actions = useMarketSecurityActions();


  return (
    <PaginationTable<FMarketListData>
      {...tableProps}
      getData={loadAll}
//      pageData={all}
      pageData={data}
      onPageIndexChange={setIndex}
      pageIndex={index}
      title=""
      columns={columns}
			actions={actions}
      options={{
      toolbar: false,
      rowStyle: rowData => {
        if(rowData.securityId === security) {
          return {background: '#DAF7A6'};
        }
        return {};
        } 
      }}
    />
  );
};

export default MarketDataTable;

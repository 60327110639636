import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { FetchStateType } from "../../../models";
import { loadAddSecurity } from "../../mainBoard/thunks/loadAddSecurity";
import { setFetchState } from "../marketsDataSlice";
import { loadMarketAllData } from "../../mainBoard/thunks/loadMarketAllData";

export const useAddSecurity = () => {
	const fetchState = useSelector<StoreType, FetchStateType>(
		store => store.data.foreign.marketData.fetchState
	);

	const dispatch = useDispatch();
	const dispatchAddSecurity: any = React.useCallback(
		(secid: string) => {
			dispatch(loadAddSecurity(secid))
            dispatch(loadMarketAllData());
		},
		[dispatch ]
	);

	React.useEffect(() => {
		dispatch(setFetchState({ type: "not-started" }))
	}, [dispatch])


	return [fetchState, dispatchAddSecurity] as [typeof fetchState, typeof dispatchAddSecurity]
};


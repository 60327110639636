import React from "react";
import { useSelector } from "react-redux";
import { StoreType } from "../../../core/store/reducers";
import { MoneyAccounts } from "../../models";

const getStatusLabel = (status?: MoneyAccounts) => (status ? status.accNo : "");

export const useMoneyAccountsDomDefault = () => {

	const statuses = useSelector<StoreType, MoneyAccounts[]>(store => store.data.initialData.moneyAccounts.filter(f=>f.accUse!=='F'));

	return [statuses, getStatusLabel] as [typeof statuses, typeof getStatusLabel];
};

export const useMoneyAccountDomDefault = (accUse?: string, currId?: string) => {

	const [monaccs] = useMoneyAccountsDomDefault();

	const monacc = React.useMemo(() => monaccs.find(c => (c.accUse!=='F' && c.accSide !== 'S'  && c.defDom === 1 ) ), [monaccs]);
	
	const monaccLabel = React.useMemo(() => getStatusLabel(monacc), [monacc]);

	return [monacc, monaccLabel] as [typeof monacc, typeof monaccLabel];
};

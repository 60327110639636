import { LocalizationLanguage } from "../../core/localization/types";
import { ThunkType } from "../../core/store/reducers";
import { firstLoginWebApi } from "./api/firstLogin";
import { loginWebApi } from "./api/login";
import { successfulLogin, failedLogin, setPendingLoginState, setToken } from "./authDataSlice";
import { deleteUserToken } from "./authUXSlice";
import { ErrorCodes } from "../../core/http/types";
import { LoginResponse } from "../models";

// const fakeLogin: LoginResponse = {
// 	uData: {
// 		userId: "test",
// 		userIsEmployee: 1,
// 		userName: "dimitrije",
// 		token:
// 			"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJUb2tlbklkIjoiOWE4M2FmOTItMWFiYi00NjIyLWJmYTgtZjkzNmU4ZGIyN2JmIiwiVXNlcklkIjoiMDAwMjYwMTgiLCJVc2VyVHlwZSI6IkVNUCIsIlVzZXJJZE5vIjoiMjYwNTk3NTgwMDAzNCIsIm5iZiI6MTYwMDU5OTE5NCwiZXhwIjoxNjk1MjA3MTk0LCJpYXQiOjE2MDA1OTkxOTQsImlzcyI6Imh0dHA6Ly93d3cuaW5mb3Byby5jby5ycyIsImF1ZCI6Imh0dHA6Ly93d3cuaW5mb3Byby5jby5ycyJ9.irYni3vFlNT0g6gnUrneU8d9MLxNT6oiJMlI72oF1A0",
// 		employeeId: "",
// 		employeeCode: "",
// 		employeeName: "",
// 		employeeIdNo: "",
// 		clientPersonId: "",
// 		clientPersonIdNo: "",
// 		clientPersonName: "",
// 		clientId: "",
// 		clientIdNo: "",
// 		clientName: "",
// 		contractId: "",
// 		companyId: "",
// 		companyIdNo: "",
// 		companyRegNo: "",
// 		companyTaxNo: "",
// 		companyName: "",
// 		needCertificate: false,
// 		hasNoMarketData: false,
// 		ordersAllowed: true,
// 		language: LocalizationLanguage.SR,
// 		roleCode: "",
// 	},
// 	attributes: [],
// 	productsGroups: [],
// 	products: [],
// 	cities: [],
// 	clientTypes: [],
// 	banks: [],
// 	currencies: [],
// 	units: [],
// 	vatRates: [],
// 	commissions: [],
// 	regions: [],
// 	subRegions: [],
// 	topRegions: [],
// 	countries: [],
// 	commissionTypes: [],
// 	personRoles: [],
// 	userMenu: [],
// 	orderReceiveMethods: [],
// 	checkPointPayers: [],
// 	checkPoints: [],
// 	francoList: [],
// 	orderStatuses: [],
// 	tradeStatuses: [],
// 	payTypes: [],
// 	checkDoneByList: [],
// 	payTypesG: [],
// 	attributesValues: [],
// 	orderNotes: [],
// 	invTypes: [],
// };
export const loginUser = (
	username: string,
	password: string,
	language: LocalizationLanguage,
	origin: string
): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status === "successful") {
		return;
	}

	const token = store.ux.auth.users[username.toLowerCase()];

	dispatch(setPendingLoginState());

	const loginResponse = !token
		? await dispatch(firstLoginWebApi(username, password, language, origin))
		: await dispatch(loginWebApi(username, password, language, origin, token));

	if (!loginResponse) {
		dispatch(
			failedLogin({
				code: ErrorCodes.HTTPLoginError,
				message: "Greška u komunikaciji sa serverom.",
			})
		);
	} else if (loginResponse.resCode !== "OK") {
		if (loginResponse.resCode === ErrorCodes.AuthWrongToken) {
			dispatch(deleteUserToken(username))
		}

		dispatch(
			failedLogin({
				code: loginResponse.resCode,
				message: loginResponse.resDesc,
			})
		);
	} else {
		dispatch(setToken(loginResponse.obj.uData.token || token));
		dispatch(successfulLogin(loginResponse.obj));
	}
};

import { useCopiedState } from "../../../../core/util/hooks";
import { SecAccountFilter } from "../types";
import { setFilter, removeFilter, initialState } from "../securityAccountsUXSlice";
import { invalidateSecAccount } from "../securityAccountsDataSlice";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import React from "react";
import { loadSecAccounts } from "../thunks/loadSecAccounts";

export const useSecAccountFilter = () => {

	const [storeFilter] = useCurrentSecAccountFilter();
	const [filter, editFilter, changeState, setCurrentFilter] = useCopiedState<SecAccountFilter>(storeFilter, true);

	const dispatch = useDispatch();

	const dispatchSetFilter = React.useCallback(() => {
		dispatch(setFilter(filter!));
		dispatch(loadSecAccounts(filter!));
//		dispatch(invalidateSecAccount());
	}, [dispatch,setFilter, loadSecAccounts, filter]);

	const dispatchResetFilter = React.useCallback(() => {
		setCurrentFilter(initialState.filter)
		dispatch(loadSecAccounts(filter));
	}, [setCurrentFilter, filter]);

	return [filter, editFilter, dispatchSetFilter, changeState, dispatchResetFilter] as [
		typeof filter,
		typeof editFilter,
		typeof dispatchSetFilter,
		typeof changeState,
		typeof dispatchResetFilter
	];
};

export const useCurrentSecAccountFilter = () => {
	const filter = useSelector<StoreType, SecAccountFilter>(store => store.ux.secAccounts.filter);
	const dispatch = useDispatch();
	const dispatchRemoveFilter = React.useCallback(
		(type: keyof SecAccountFilter) => {
			dispatch(removeFilter(type));
			dispatch(loadSecAccounts());
			//			dispatch(invalidateSecAccount());
		},
		[dispatch,loadSecAccounts]
	);

	return [filter, dispatchRemoveFilter] as [typeof filter, typeof dispatchRemoveFilter];
};

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FetchStateType, SecurityAccounts } from "../../models";

interface ISecAccounts  {
	all?: SecurityAccounts[];
	changeSecAccountFetchState: FetchStateType;
	errorDesc: string;
	secid: string;
};

const initialState: ISecAccounts = {
	changeSecAccountFetchState: { type: "not-started" },
	errorDesc: "",
	secid: "",
};

const invalidate = (state: ISecAccounts) => {
	return {...state}
};

const name = "secAccounts";

const secAccountsSlice = createSlice({
	name,
	initialState,
	reducers: {
   		setData: (state, action: PayloadAction<SecurityAccounts[] | undefined>) => ({
			...state,
			all: action.payload,
		  }),
	  setChangeSecAccountFetchState: (state, action: PayloadAction<FetchStateType>) => ({
			...state,
			changeSecAccountFetchState: action.payload,
		}),
		setError: (state, action: PayloadAction<string>) => ({
			...state,
			errorDesc: action.payload,
		}),
		setSecurityAcc: (state, action: PayloadAction<string>) => ({
			...state,
			secid: action.payload,
		  }),
		invalidateSecAccount: invalidate,
	},
});

export const { setData,setChangeSecAccountFetchState, setError, setSecurityAcc, invalidateSecAccount } = secAccountsSlice.actions;

export default secAccountsSlice.reducer;

import { ThunkType } from "../../../../core/store/reducers";
import { setTrades } from "../../../domestic/trades/tradesDataSlice";
import { setTradesIndex } from "../../../domestic/trades/tradesUXSlice";
import { PageIndex } from "../../../models";
import { getTradesSumThunk } from "./getTradesSumThunk";

export const loadSumTrades = (): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	const { pageNumber, itemsPerPage } = store.ux.domestic.trades.index;
	const { filter } = store.ux.domestic.trades;

	const tradesData = await dispatch(getTradesSumThunk(pageNumber, itemsPerPage, filter));

	if (tradesData) {
		dispatch(setTrades(tradesData));
	}
};

export const setTradesIndexThunk = (index: PageIndex): ThunkType => dispatch => {
	dispatch(setTradesIndex(index));
	dispatch(loadSumTrades());
};

import React from "react";
import { Chip, Grid } from "@material-ui/core";
import { SecAccountFilter } from "../../../data/settings/securityaccounts/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { useDomForSideOptionsTranslator } from "../../../data/settings/securityaccounts/hooks/useDomForSideOptions";

export const SecAccountFilterChipsComponent: React.FunctionComponent<SecAccountFilterChipsProps> = ({
	filter,
	removeFilter,
}) => {
	const { getDateString, getString } = React.useContext(LocalizationContext);
	const sideTranslator = useDomForSideOptionsTranslator();
	return (
		<Grid container spacing={1}>
			{filter.domfor && (
				<Grid item >
					<Chip
						label={sideTranslator(filter.domfor)}
						color="secondary"
						variant="outlined"
//						onDelete={() => removeFilter("domfor")}
					/>
				</Grid>
			)}
		</Grid>
	);
};

interface SecAccountFilterChipsProps {
	filter: SecAccountFilter;
	removeFilter: (type: keyof SecAccountFilter) => void;
}

import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import Collapse from "@material-ui/core/Collapse";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { SnackbarContent } from "@material-ui/core";
import { useSnackbarMessage } from "../../data/snackbar/hooks/useSnackbarMessage";

const useStyles = makeStyles(theme => ({
	root: {
		[theme.breakpoints.up("sm")]: {
			minWidth: "344px !important",
		},
		width: "100%",
	},
	warning: {
		backgroundColor: theme.palette.warning.light,
	},
	error: {
		backgroundColor: theme.palette.error.main,
	},
	typography: {
		fontWeight: "bold",
	},
	actionRoot: {
		padding: "8px 8px 8px 16px",
		justifyContent: "space-between",
	},
	icons: {
		marginLeft: "auto",
	},
	expand: {
		padding: "8px 8px",
		transform: "rotate(0deg)",
		transition: theme.transitions.create("transform", {
			duration: theme.transitions.duration.shortest,
		}),
	},
	expandOpen: {
		transform: "rotate(180deg)",
	},
	collapse: {
		padding: 16,
	},
	checkIcon: {
		fontSize: 20,
		color: "#b3b3b3",
		paddingRight: 4,
	},
	button: {
		padding: 0,
		textTransform: "none",
	},
	background: {
		padding: 0,
	},
	message: {
		width: "100%",
		padding: 0,
	},
}));

export const CollapsableMessage = React.forwardRef((props: any, ref) => {
	const classes = useStyles();
	const { closeSnackbar } = useSnackbar();
	const [expanded, setExpanded] = useState(false);
	const [snackbarMessage, dismiss, setShoenId] = useSnackbarMessage(props.message);

	React.useEffect(() => {
		setShoenId(props.id);
		if (Notification.permission === "granted" && snackbarMessage) {
			new Notification(snackbarMessage.title, {
				icon: process.env.PUBLIC_URL + "favicon.ico",
				body: snackbarMessage.message.join("\r\n")
			});
		}
	}, []);

	const handleExpandClick = () => {
		setExpanded(!expanded);
	};

	const handleDismiss = () => {
		dismiss();
		closeSnackbar(props.id);
	};

	const expandClassName = expanded ? `${classes.expand} ${classes.expandOpen}` : classes.expand;
	const cardClassName = React.useMemo(() => snackbarMessage?.type === "error" ? classes.error : classes.warning, [])
	return (
		<SnackbarContent
			ref={ref}
			classes={{
				message: classes.message,
				root: classes.background,
			}}
			message={
				<Card className={cardClassName}>
					<CardActions classes={{ root: classes.actionRoot }}>
						<Typography variant="subtitle2" className={classes.typography}>
							{snackbarMessage?.title}
						</Typography>
						<div className={classes.icons}>
							<IconButton
								aria-label="Show more"
								className={expandClassName}
								onClick={handleExpandClick}
							>
								<ExpandMoreIcon />
							</IconButton>
							<IconButton className={classes.expand} onClick={handleDismiss}>
								<CloseIcon />
							</IconButton>
						</div>
					</CardActions>
					<Collapse in={expanded} timeout="auto" unmountOnExit>
						<Paper className={classes.collapse}>
							{snackbarMessage?.message.map((line, i) => (
								<Typography key={i} gutterBottom>{line}</Typography>
							))}
						</Paper>
					</Collapse>
				</Card>
			}
		/>
	);
});

import React from "react";
import { TradeList } from "../../../data/domestic/trades/types";
import {
  PaginationTable,
  PaginationDataProps,
} from "../../components/common/PagginationTable";
import { useTradeActions } from "./useTradeActions";
import { useTradeColumns } from "./useTradeColumns";
import { AccessRights } from "../../../core/navigation/types";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

export interface TradeTableProps {
  userAccessRights: AccessRights;
  getData: () => void;
}

type TradeTablePropsType = TradeTableProps & PaginationDataProps<TradeList>;

const TradesTable: React.FunctionComponent<TradeTablePropsType> = ({
  userAccessRights,
  getData,
  ...tableProps
}) => {
  const actions = useTradeActions();
  const columns = useTradeColumns();

  const theme = useTheme();
  const screenExtraSmall = useMediaQuery(theme.breakpoints.only('xs'));

  return (
    <PaginationTable<TradeList>
      {...tableProps}
      getData={getData}
      //      immutableData={data}
      title=""
      columns={columns}
      actions={actions}
      options={{
//        actionsColumnIndex: -1,
				actionsColumnIndex: screenExtraSmall === true ? 0 : -1 ,
        search: false,
        toolbar: false,
      }}
    />
  );
};

export default TradesTable;

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { changePassThunk } from "../thunks/changePassThunks";
import { StoreType } from "../../../../core/store/reducers";
import { FetchStateType } from "../../../models";
import { setChangePassFetchState } from "../settingsDataSlice";

export const useChangePassHooks = (password: string, newpassword: string) => {

	const fetchState = useSelector<StoreType, FetchStateType>(
		store => store.data.settings.changePassFetchState
	);

	const errorState = useSelector<StoreType, string>(
		store => store.data.settings.errorDesc
	);

	console.log("Hooks")

	const dispatch = useDispatch();

	const dispatcChangePassword: any = React.useCallback(
		() => {
            dispatch(changePassThunk( password, newpassword));
		},
		[dispatch,password,newpassword ]
	);

	React.useEffect(() => {
		dispatch(setChangePassFetchState({ type: "not-started" }))
	}, [dispatch])

	const resetState = React.useCallback(() => dispatch(setChangePassFetchState({ type: "not-started" })), [
		dispatch,
	]);


	console.log(fetchState)

	return [fetchState, dispatcChangePassword, resetState, errorState ] as [typeof fetchState, typeof dispatcChangePassword, typeof resetState, typeof errorState]

};


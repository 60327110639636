import { getEditLocation } from "../../../core/util/functions";
import { OrdersEditAction } from "../../../data/domestic/orders/types";

export const orderBookLocation = "/domestic/orders"

export enum OrdersEditParts {
	BasicInfo = "basicinfo",
	Attributes = "attributes",
}

/*   export const getOrderEditLocation = (
	action: OrdersEditAction = OrdersEditAction.New,
	part: OrdersEditParts = OrdersEditParts.BasicInfo,
    orderId?: string
) => getEditLocation(orderBookLocation, part, action, orderId );

export const baseEdit = getOrderEditLocation(":action" as any, ":editPart" as any, ":orderId?");

// export const baseEdit = getOrderEditLocation(":editPart" as any);

*/

   export const getOrderEditLocation = (
	action: OrdersEditAction = OrdersEditAction.New,
    part: OrdersEditParts = OrdersEditParts.BasicInfo,
    orderId?: string
) => getEditLocation(orderBookLocation, part, orderId, undefined, action );

export const baseEdit = getOrderEditLocation(":action" as any, ":editPart" as any, ":orderId?");





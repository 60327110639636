import React, { useContext, useMemo } from "react";
import ViewIcon from "@material-ui/icons/InputOutlined";
import CancelIcon from "@material-ui/icons/CancelOutlined";
import { TradeList } from "../../../data/domestic/trades/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { MaterialTableProps } from "material-table";
import { useViewTrade } from "./DisplayTrade";
import { useAuth } from "../../../data/auth/hooks";

export function useTradeActions() {
	const { getString } = useContext(LocalizationContext);
	const onShowTrade = useViewTrade();
	const [auth] = useAuth();

	return useMemo<MaterialTableProps<TradeList>["actions"]>(
		() => [
			{
				icon: () => <ViewIcon />,
				tooltip: getString("tradeViewAction"),
				onClick: (event, rowData) => onShowTrade(rowData as TradeList),
			},
		],
		[onShowTrade, getString, auth]
	);
}

import { webApiWithResponse } from "../../../../core/http/httpThunks";
import { LoginResponse } from "../../../models";
import { LocalizationLanguage } from "../../../../core/localization/types";

export const changePassApi = (username: string, password: string, lang: string) =>
	webApiWithResponse<LoginResponse>({
		method: "POST",
		url: "user/changeuserpass",
		data: {
			username,
			password,
			lang,
		},
	});

import PortfolioReport from "./PortfolioReport";
import { NavigationItem } from "../../../core/navigation/types";
import { portfolioReportLocation } from "./Locations";

export const portfolioReportComponents: NavigationItem[] = [
	{
		Component: PortfolioReport,
		isMenuItem: true,
		path: portfolioReportLocation,
	},
];

import { webApiWithResponse } from "../../../../core/http/httpThunks";
import { PortfolioData, GetPortfolioParams } from "../types";
import { User } from "../../../auth/types";
import { PortfolioFilter }  from "../types"

const getPortfolioDataApi = (
  user: User,
  page?: number,
  itemsPerPage?: number,
  filter?: PortfolioFilter,
) =>
{
  const params: GetPortfolioParams = {
    userid: user.userId,
    domfor: "D",
    secid: filter?.security?.securityId,
    exchid: filter?.exchange?.exchId,
    currid: filter?.curency?.currId,
    onExch: filter?.onExch,
    fromrow: page && itemsPerPage ? page * itemsPerPage : 0,
    numofrows: itemsPerPage,
  };
 
console.log(params)

return webApiWithResponse<PortfolioData[]>({
    method: "GET",
    url: "trade/getportfoliolist",
    params,
    });
};

export default getPortfolioDataApi;





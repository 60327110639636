import React from "react";
import { DisplayDrawerContext } from "./DisplayDrawerContext";
import { Drawer } from "@material-ui/core";
import { Theme, makeStyles, createStyles } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/CloseOutlined";

export const useDisplayDrawerStyles = makeStyles((theme: Theme) => {
	return createStyles({
		loginDrawerPaper: {
            width: "100vw",
            [theme.breakpoints.only("sm")]: {
                width: "60vw",
            },
            [theme.breakpoints.up("sm")]: {
                width: "50vw",
            },
            [theme.breakpoints.up("lg")]: {
                width: "30vw",
            },
			paddingTop: "40px",
			paddingLeft: "10px",
			paddingRight: "10px",
			paddingBottom: "10px",
			overflowX: "hidden",
        },

		drawerPaper: {
            width: "100vw",
            [theme.breakpoints.only("sm")]: {
                width: "60vw",
            },
            [theme.breakpoints.up("sm")]: {
                width: "50vw",
            },
            [theme.breakpoints.up("lg")]: {
                width: "30vw",
            },
			paddingTop: "40px",
			paddingLeft: "10px",
			paddingRight: "10px",
			paddingBottom: "10px",
			overflowX: "hidden",
			// display: "flex",
			// flexDirection: "column",
			// justifyContent: "center",
		},
		header: {
			display: "flex",
			justifyContent: "flex-end",
			//padding: "10px",
			position: "fixed",
			top: "5px",
			right: "10px",
		},
		closeButton: {
			padding: "5px",
		},
		closeIcon: {
			height: "1.2rem",
			width: "1.2rem",
		}
	});
});

export const DisplayDrawer: React.FunctionComponent = ({ children }) => {
	const [displayContent, setDisplayContext] = React.useState<React.ReactNode | undefined>();
	const styles = useDisplayDrawerStyles();
	const close = React.useCallback(() => setDisplayContext(undefined), [setDisplayContext]);

	return (
		<DisplayDrawerContext.Provider value={setDisplayContext}>
			{children}
			<Drawer
				variant="temporary"
				anchor="right"
				open={displayContent !== undefined}
				onClose={close}
				classes={{
					paper: styles.drawerPaper,
				}}
			>
				<div className={styles.header}>
					<IconButton onClick={close} className={styles.closeButton}>
						<CloseIcon className={styles.closeIcon}/>
					</IconButton>
				</div>
				{displayContent}
			</Drawer>
		</DisplayDrawerContext.Provider>
	);
};

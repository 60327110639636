import { webApi } from "../../../../core/http/httpThunks";
import { MarketData } from "../types";

export const getMarketDataApi = (userId: string, secid?: string) =>
  webApi<MarketData>({
    method: "GET",
    url: "trade/getmarketdata",
    params: {
      userId,
//      refresh: "N",
      sort: 0,
      secid,
    },
  });

export default getMarketDataApi;

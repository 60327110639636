import React from "react";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import { useSelector } from "react-redux";
import { StoreType } from "../core/store/reducers";

declare module "@material-ui/core/styles/createMuiTheme" {
	interface Theme {
		navDrawer: {
			fullWidth: number;
			hiddenWidth: number;
			permanentBreakpoint: string;
		};
	}
	interface ThemeOptions {
		navDrawer?: {
			fullWidth: number;
		};
	}
}

export const ThemeComponent: React.FunctionComponent = (props) => {
	const colorBdd = useSelector<StoreType, string | undefined>(
		(store) => store.ux.auth.colorBdd
	);


	const theme = createMuiTheme({
		palette: {
			primary: {
				main: colorBdd ? colorBdd : "#133D8D",    //ilirika 
//				main: "#133D8D",    //ilirika
				///			main: "#0091d0",
				//			main: "#00897B",
			},
			secondary: {
				main: "#f58345",
				//			main: "#772E25",
			},
			warning: {
				main: "#ff0000",
				//			main: "#772E25",
			},
		},
		navDrawer: {
			fullWidth: 240,
		},
	});

	theme.navDrawer.permanentBreakpoint = theme.breakpoints.up("md");
	theme.navDrawer.hiddenWidth = theme.spacing(7);

	return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
};

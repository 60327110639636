import { webApiWithResponse } from "../../../../core/http/httpThunks";

export const addSecurityApi = (userId: string, secid: string) =>
  webApiWithResponse<string>({
    method: "PUT",
    url: "trade/addsecurityforMD",
    params: {
      userId,
      secid,
    },
  });

export default addSecurityApi;


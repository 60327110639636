import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {  MoneyAccountFilter } from "./types";
import { DomForSide, OrderSide } from "../../models";

type MoneyAccountState = {
	filter: MoneyAccountFilter;
};

export const initialState: MoneyAccountState = {
	filter: {
		domfor: DomForSide.Domestic ,
		curency: {currId: "00000001", currCode: "RSD" } ,
		buysell: OrderSide.Buy,
	},
};

const name = "moneyAccounts";

const moneyAccountsSlice = createSlice({
	name,
	initialState,
	reducers: {
		setFilter: (state, action: PayloadAction<MoneyAccountFilter>) => ({
			...state,
			filter: action.payload,
		}),
		removeFilter: (state, action: PayloadAction<keyof MoneyAccountFilter>) => ({
			...state,
			filter: {
				...state.filter,
				[action.payload]: undefined,
			},
		}),
		resetFilter: state => ({
			...state,
			filter: {
				...initialState.filter,
			},
		}),
	},
});

export const {  setFilter, removeFilter, resetFilter } = moneyAccountsSlice.actions;

export default moneyAccountsSlice.reducer;

import { TradeFilter } from "../types";
import { User } from "../../../auth/types";
import { httpWebApi } from "../../../../core/http/httpThunks";
import { getParamsDateString } from "../../../../core/util/functions";

export const getTradeListExcel = (
  user: User,
  filter: TradeFilter
) => {
  return httpWebApi<Blob>({
    method: "GET",
    responseType: "blob",
    timeout: 60 * 4 * 1000,
    url: "reporting/gettranbookxls",
    params: {
			userid: user.userId,
			domfor: "D",
			secid: filter?.security?.securityId,
			datefrom: getParamsDateString(filter?.datefrom),
			dateto: getParamsDateString(filter?.dateto),
		},
  });
};

export default getTradeListExcel;

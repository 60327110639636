import React from "react";
import { Chip, Grid } from "@material-ui/core";
import { OrderFilter } from "../../../data/domestic/orders/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { useOrderSideOptionsTranslator } from "../../../data/domestic/orders/hooks/useOrderSideOptions";

export const OrderFilterChipsComponent: React.FunctionComponent<OrderFilterChipsProps> = ({
	filter,
	removeFilter,
}) => {
	const { getDateString, getString } = React.useContext(LocalizationContext);
	const sideTranslator = useOrderSideOptionsTranslator();
	return (
		<Grid container spacing={1}>
			{filter.side && (
				<Grid item >
					<Chip
						label={sideTranslator(filter.side)}
						color="secondary"
						variant="outlined"
						onDelete={() => removeFilter("side")}
					/>
				</Grid>
			)}
			{filter.dateFrom && (
				<Grid item >
					<Chip
						label={`${getString("filterFrom")}: ${getDateString(new Date(filter.dateFrom))}`}
						color="secondary"
						variant="outlined"
						onDelete={() => removeFilter("dateFrom")}
					/>
				</Grid>
			)}
			{filter.dateTo && (
				<Grid item >
					<Chip
						label={`${getString("filterTo")}: ${getDateString(new Date(filter.dateTo))}`}
						color="secondary"
						variant="outlined"
						onDelete={() => removeFilter("dateTo")}
					/>
				</Grid>
			)}
			{filter.orderStatus && filter.orderStatus.ftDescr !== "" && (
				<Grid item >
					<Chip
						label={filter.orderStatus.ftDescr}
						color="secondary"
						variant="outlined"
						onDelete={() => removeFilter("orderStatus")}
					/>
				</Grid>
			)}
			{filter.security && (
				<Grid item >
					<Chip
						label={filter.security.secData}
						color="secondary"
						variant="outlined"
						onDelete={() => removeFilter("security")}
					/>
				</Grid>
			)}

		</Grid>
	);
};

interface OrderFilterChipsProps {
	filter: OrderFilter;
	removeFilter: (type: keyof OrderFilter) => void;
}

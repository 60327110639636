import { ThunkType } from "../../../../core/store/reducers";
import { printTrade } from "../api/printTrades";
import fileDownload from "js-file-download";
import { setPrintTradeFetchState } from "../tradesDataSlice";
import { Trades } from "../types";

export const printTradesThunk = (trade?: Trades): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful" || !trade || !trade.tranId) {
		return undefined;
	}
	const lan = store.ux.localization.language
//	const filename = `TradingConfirmation${trade.side.trim().toUpperCase()}_${lan.trim().toUpperCase()}_${trade.clTranId}.pdf`
	const filename = `TradingConfirmation${lan.trim().toUpperCase()}_${trade.clTradeId}.pdf`
	const user = store.data.auth.current.user;
	dispatch(setPrintTradeFetchState({ type: "started" }));
	const tradesReport = await dispatch(printTrade(user, trade.tranId));
	if (tradesReport) {
		fileDownload(new Blob([tradesReport as any], { type: "application/pdf" }), filename);
	}
	dispatch(setPrintTradeFetchState({ type: "not-started" }));
};

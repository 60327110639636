import React from "react";
import { Chip, Grid } from "@material-ui/core";
import { PortfolioFilter } from "../../../data/domestic/portfolio/types";
import { useOnExchangeOptionsTranslator } from "../../../data/initialData/hooks/useOnExchangeOption";
import { OnExchange } from "../../../data/models";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

export const PortfolioFilterChipsComponent: React.FunctionComponent<PortfolioFilterChipsProps> = ({
	filter,
	removeFilter,
}) => {

	const onExchangeTranslator = useOnExchangeOptionsTranslator();

	const theme = useTheme();
	const screenExtraSmall = useMediaQuery(theme.breakpoints.only('xs'));

	return (
		<Grid container spacing={1}>
			{filter.security && !screenExtraSmall && (
				<Grid item>
					<Chip
						label={filter.security.secData}
						color="secondary"
						variant="outlined"
						onDelete={() => removeFilter("security")}
					/>
				</Grid>
			)}
			{filter.curency  && !screenExtraSmall && (
				<Grid item>
					<Chip
						label={filter.curency.currCode}
						color="secondary"
						variant="outlined"
						onDelete={() => removeFilter("curency")}
					/>
				</Grid>
			)}
      		{filter.onExch !== undefined  && (filter.onExch===OnExchange.yes || filter.onExch===OnExchange.no) && !screenExtraSmall && (
        		<Grid item>
          			<Chip
            			label={onExchangeTranslator(filter.onExch)}
            			color="secondary"
            			variant="outlined"
            			onDelete={() => removeFilter("onExch")}
          			/>
        		</Grid>
      		)}

		</Grid>
	);
};

interface PortfolioFilterChipsProps {
	filter: PortfolioFilter;
	removeFilter: (type: keyof PortfolioFilter) => void;
}

import { OrderSide, Security, Exchanges } from "../../models";
import { SecuritySimpleList } from "../../initialData/types"


export interface ViewOrder {
	orderId: string;
	orderNo: string;
	orderTime: Date;
	clientId: string;
	clientIdNo: string;
	clientName: string;
	security: Security;
	exchCode: string;
	secDepCode: string;
	currCode: string;
	side: string;
	qty: number;
	leavesQty: number;
	orderType: string;
	price: number;
	timeInForce: string;
	expiryDate: Date;
	iceberg: number;
	ordCancRepl: string; // C-Cancel order, R-replace order, othwerwise null
	origOrderNo: string; // broj referentnog naloga za C i R
	ordStatusDescr: string;
	fillStatusDescr: string;
	cancelAllowed: string; 
	replaceAllowed: string; 
}

export interface GetOrdersParams {
	userid: string;
	market?: OrderMarket;
	side?: OrderSide;
	empid?: string;
	cliid?: string;
	secid?: string;
	exchid?: string;
	currid?: string;
	status?: number;
	dateFrom?: string;
	dateTo?: string;
	fromrow?: number;
	numofrows?: number;
}

export interface Order {
	orderId: string;
	orderNo: string;
	market: string;
	orderTime: Date;
	clientId: string;
	clientIdNo: string;
	clientName: string;
	security: Security;
	exchId: string;
	exchCode: string;
	currId: string;
	currCode: string;
	side: OrderSide;
	qty: number;
	leavesQty: number;
	filledQty: number;
	orderType: string;
	price: number;
	timeInForce: string;
	expiryDate: Date;
	iceberg: number;
	visibleQty: number;
	securityAccId: string;
	securityAcc: string;
	moneyAccId: string;
	moneyAcc:string;
	ordCancRepl: string; // C-Cancel order, R-replace order, othwerwise null
	origOrderId: string; // broj referentnog naloga za C i R
	origOrderNo: string; // broj referentnog naloga za C i R
	ordStatus: string;
	ordStatusDescr: string;
	fillStatus: string;
	fillStatusDescr: string;
	cancelAllowed: string; 
	replaceAllowed: string; 
	preMarket: number,
	razlogOdbijanja?: string;
}

 export interface EditOrder {
	orderId: string;
	orderNo?: string;
	market?: string;     //   D/F (domestic/foreign)
	orderTime?: Date;
	clientId?: string;   // Id klijenta
//	security?: Partial<SecuritySimpleList>  // Security;  // object Security popunjava se samo Id
	security?: Security; // Security;  // object Security popunjava se samo Id
	exchId?: string;     // popunjava se samo za stranu berzu ako je korisnik izabrao berzu
	side: OrderSide;   // Buy/Sell
	qty: number;    // količina
	leavesQty?: number;
	filledQty?: number;
	orderType?: string;     //  limit/market
	price: number;      // cena ako je limit
	timeInForce?: string;   // day/gtc/gtd/ioc/fok
	expiryDate?: Date;    
	iceberg?: number;   // 0/1
	visibleQty?: number;   // ako je iceberg=1 onda 
	securityAccId?: string;
	securityAcc?: string; // račun hartija 
	moneyAccId?: string;
	moneyAcc?:string; // račun novca
	ordCancRepl?: string; // C-Cancel order, R-replace order, othwerwise null
	origOrderId?: string; // id originalnog naloga za C i R
	origOrderNo?: string; // broj originalnog naloga za C i R
	ordStatus?: string;
	ordStatusDescr?: string;
	fillStatus?: string;
	fillStatusDescr?: string;
	cancelAllowed?: string; 
	replaceAllowed?: string; 
	symbol?:string;
	currCode?: string;
	preMarket?: number,
}  

  
export enum OrderType {
	New = 0,
	Replace = 1,
	Cancel = 2,
}

export interface OrderFilter {
	dateFrom?: string,
	dateTo?: string,
	side?: OrderSide,
//	security?: Partial<Security>,
	security?: Partial<SecuritySimpleList>,
	orderStatus?: Partial<OrderStatus>,
	market?: OrderMarket;
	exchange?: Exchanges;
}

 export interface OrderStatus {
	ftValue: number;
	ftDescr: string;
  }
  
export enum OrdersEditAction {
	New = "new",
	Replay = "replay",
	Verify = "verify",
	Modify = "modify",
}

export enum OrderMarket {
	Domestic = "D",
	Foreign = "F",
}

export enum FundsCheckStatus {
	NotChecked = "NOTCHECKED",
	Ignore = "IGNORE",
}

export type OrderUpsertResult =
	| { status: "CREATED"; newId: string }
	| { status: "NOFUNDS"; message: string }
	| { status: "ERROR"; message?: string };


export type OrderSideDictionary<T> = {
	[key in OrderSide]: T;
};

export type OrderSideArgs<T> = {
	side: OrderSide;
	value: T;
};

export enum OrderAllOrNothing {
	True = 1,
	False = 0,
}

export enum PreMarket {
	True = 1,
	False = 0,
}

/* export interface OrderSimpleData {
	ordId: string;
	ordData: string;
	depAmt: number;
	depAmtPayed: number;
	price: number;
	qty: number;
	side: string;
	nonAllocAmt: number;
} */

/* export interface GetSimpleOrdersParams {
	userid: string;
	side?: OrderSide;
	cliid: string;
	prodid?: string;
	status?: number;
} */

export interface MoneyAccFilter {
	accSide: string;
	curId: string;
	accNo?: string;
	def?: number;
}

export interface SecurityAccFilter {
	accUse: string;
	accBank?: string;
}


export interface OrderSecurityFilter {
	accUse: string;
}


/*export enum OrderExportReady {
	True = 1,
	False = 0,
} */

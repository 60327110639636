import { ThunkType } from "../../../../core/store/reducers";
import { setdefsecaccApi } from "../api/setdefsecaccData";
import { setData } from "../securityAccountsDataSlice";
import { DomForSide } from "../../../models";

export const loadSetDefSecacc = ((): ThunkType => async (dispatch, getStore) => {

  const store = getStore();
  if (store.data.auth.current.status !== "successful") {
    return;
  }
  const  userid = store.data.auth.current.user.userId;

  const { filter } = store.ux.secAccounts;

  const secid=store.data.secAccounts.secid

  const domfor = filter.domfor ===DomForSide.Domestic ? "D" : "F" ;

//  dispatch(setData());

  const marketData = await dispatch(setdefsecaccApi(userid, domfor, secid));
  
  dispatch(setData());
});

import React from "react";
import { Chip, Grid } from "@material-ui/core";
import { TradeFilter } from "../../../data/domestic/trades/types"
import { LocalizationContext } from "../../../core/localization/Localization";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

export const TradesFilterChips: React.FunctionComponent<TradeFilterChipsProps> = ({
	filter,
	remnoveFilter,
}) => {
	const { getDateString, getString } = React.useContext(LocalizationContext);

	const theme = useTheme();
	const screenExtraSmall = useMediaQuery(theme.breakpoints.only('xs'));

	return (
		<Grid container spacing={1}>
			{filter.datefrom && !screenExtraSmall && (
				<Grid item>
					<Chip
						label={`${getString("filterFrom")}: ${getDateString(new Date(filter.datefrom))}`}
						color="secondary"
						variant="outlined"
						onDelete={() => remnoveFilter("datefrom")}
					/>
				</Grid>
			)}
			{filter.dateto && !screenExtraSmall && (
				<Grid item>
					<Chip
						label={`${getString("filterTo")}: ${getDateString(new Date(filter.dateto))}`}
						color="secondary"
						variant="outlined"
						onDelete={() => remnoveFilter("dateto")}
					/>
				</Grid>
			)}
			{filter.security && !screenExtraSmall && (
				<Grid item>
					<Chip
						label={filter.security.secData}
						color="secondary"
						variant="outlined"
						onDelete={() => remnoveFilter("security")}
					/>
				</Grid>
			)}
			{filter.exchange && !screenExtraSmall && (
				<Grid item>
					<Chip
						label={filter.exchange.exch}
						color="secondary"
						variant="outlined"
						onDelete={() => remnoveFilter("exchange")}
					/>
				</Grid>
			)}
			{filter.curency  && !screenExtraSmall && (
				<Grid item>
					<Chip
						label={filter.curency.currCode}
						color="secondary"
						variant="outlined"
						onDelete={() => remnoveFilter("curency")}
					/>
				</Grid>
			)}
		</Grid>
	);
};

interface TradeFilterChipsProps {
	filter: TradeFilter;
	remnoveFilter: (type: keyof TradeFilter) => void;
}

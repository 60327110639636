import { ThunkType } from "../../../core/store/reducers";
import  getSupportApi  from "../api/getSupportData";
import { setData } from "../supportDataSlice";

export const loadSupport = (): ThunkType => async (dispatch, getStore) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return;
  }

  const  userid = store.data.auth.current.user.userId;
  console.log("load 1")
  const support = await dispatch(getSupportApi(userid));
  console.log("load 2")
  
  dispatch(setData(support || []));
};

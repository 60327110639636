import { ThunkType } from "../../../../core/store/reducers";
import { getOrderListExcel } from "../api/getOrderListExcel";
import fileDownload from "js-file-download";
import { setDownloadXlsFetchState } from "../orderBookDataSlice";

export const downloadOrderBookExcel = (): ThunkType => async (
  dispatch,
  getStore
) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return undefined;
  }

  const filter  = store.ux.domestic.orderBook.filter;
  const user = store.data.auth.current.user;
  dispatch(setDownloadXlsFetchState({ type: "started" }));
  const dailyTrading = await dispatch(getOrderListExcel(user, filter));
  if (dailyTrading) {
    fileDownload(
      new Blob([dailyTrading as any], {
        type: "application/vnd.openxmlformat",
      }),
      "OrderBook.xlsx"
    );
  }
  dispatch(setDownloadXlsFetchState({ type: "not-started" }));
};

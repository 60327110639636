import { ThunkType } from "../../../core/store/reducers";
import getSecurityFForSelectApi from "../api/getSecurityFForSelect";
import { setSecurityFForSelect } from "../initialDataReducer";
import { SecuritySimpleFilter } from "../types";

export const loadSecurityFForSelect = (df: string, filter?: SecuritySimpleFilter): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return;
	}

	const { userId } = store.data.auth.current.user;
	const securities = await dispatch(getSecurityFForSelectApi(userId, df, filter));

	dispatch(setSecurityFForSelect(securities || []));
};

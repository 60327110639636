import { User } from "../../../auth/types";
import { httpWebApi } from "../../../../core/http/httpThunks";

export const printSumTrade = (user: User, tradeid: string) => {
	return httpWebApi<Blob>({
		method: "GET",
		responseType: "blob",
		timeout: 60 * 4 * 1000,
		url: "reporting/getsumtradeconfreport",
		params: {
			userid: user.userId,
			tradeid,
		},
	});
};

export default printSumTrade;

import { ThunkType } from "../../../../core/store/reducers";
import { setData, invalidatePortfolio } from "../../../domestic/portfolio/portfolioDataSlice";
import { setPortfolioIndex } from "../../../domestic/portfolio/portfolioUXSlice";
import { PageIndex } from "../../../models";
import { getPortfolioThunk } from "./getPortfolioThunk";

export const loadPortfolioData = (
): ThunkType => async (dispatch, getStore) => {
  const store = getStore();

console.log("U loadPortfolioData")
	const { pageNumber, itemsPerPage } = store.ux.domestic.portfolio.index;
	const { filter } = store.ux.domestic.portfolio;

	const pagedOrders = await dispatch(getPortfolioThunk(pageNumber, itemsPerPage, filter));

	if (pagedOrders) {
		dispatch(setData(pagedOrders));
	}
};

export const setPortolioIndexThunk = (index: PageIndex): ThunkType => dispatch => {
	dispatch(setPortfolioIndex(index));
	dispatch(invalidatePortfolio());
};

import React from "react";
import { DisplayOrder } from "./DisplayOrder";
import { ViewOrder } from "../../../data/domestic/orders/types";
import { DisplayDrawerContext } from "../../../layout/DisplayDrawerContext";
import { Grid, Typography, Button, Paper } from "@material-ui/core";
import { useCancelOrder } from "../../../data/domestic/orders/hooks/useOrderCancel";
import { LocalizationContext } from "../../../core/localization/Localization";
import { DiplayOrderMod } from "./DisplayOrderMainAttributes";

export interface CancelOrderProps {
	orderId: string;
	cancel: () => void;
}

export const CancelOrder: React.FunctionComponent<CancelOrderProps> = ({ orderId, cancel }) => {
	const [state, cancelOrder] = useCancelOrder();
	const { getString } = React.useContext(LocalizationContext);

	React.useEffect(() => {
		if (state.type === "successful") cancel();
	}, [state, cancel]);

	return (
		<>
			<Grid container direction="column" spacing={3}>
				<Grid item>
					<Paper style={{ padding: "20px" }}>
						<Grid container direction="row" justify="space-around" alignItems="center">
							<Typography variant="h5">{getString("orderCancelQuestion")}</Typography>
							<span>
								<Button onClick={cancel} disabled={state.type !== "not-started"}>
									{getString("orderCancelQuestionNo")}
								</Button>
								<Button
									variant="contained"
									color="primary"
									onClick={() => cancelOrder(orderId)}
									disabled={state.type !== "not-started"}
								>
									{getString("orderCancelQuestionYes")}
								</Button>
							</span>
						</Grid>
					</Paper>
				</Grid>
				<Grid item>
					<DisplayOrder id={orderId} displayMod={DiplayOrderMod.Full} />
				</Grid>
			</Grid>
		</>
	);
};

export const useShowCancelOrder = () => {
	const showWithDrawer = React.useContext(DisplayDrawerContext);
	return React.useCallback(
		(order: ViewOrder) =>
			showWithDrawer(<CancelOrder orderId={order.orderId} cancel={() => showWithDrawer(undefined)} />),
		[showWithDrawer]
	);
};

import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { usePageStyles } from "../../pageStyles";
import { Card, Grid, CardHeader, Divider, CardContent } from "@material-ui/core";
import { EditOrderBasicInfoPage } from "./EditOrderBasicInfoPage";
import { useEditOrderState } from "../../../data/domestic/orders/hooks/useEditOrderState";
import { PageTitle } from "../../components/common/PageTitle";
// import { mainBoardLocation } from "../mainBoard/Locations";
import { OrdersEditAction } from "../../../data/domestic/orders/types";
import { orderBookLocation } from "./Locations";
import { OrderSide } from "../../../data/models";
import { useOrderSideStyles } from "./useOrderRowColor";

export interface EditOrderPageInfo {
	action: OrdersEditAction;
	editPart?: string;
	orderId?: string;
}

export interface EditOrderPageProps extends RouteComponentProps<EditOrderPageInfo> {}

export const EditOrderPage: React.FunctionComponent<EditOrderPageProps> = props => {
	const pageStyles = usePageStyles();
	const { action, orderId } = props.match.params;
	const sideStyle = useOrderSideStyles();

	const [order, editOrder, changed] = useEditOrderState(action, orderId);

// 	const backLocation = action === OrdersEditAction.Replay ? mainBoardLocation : orderBookLocation;  ovo kad se bude davao nalog i iz table
	const backLocation =  orderBookLocation;

	const pageTitle = orderId
		? action === OrdersEditAction.Verify
			? "orderVerify"
			: "orderModify"
		: "orderCreateTitle";

	return (
		<Grid container className={pageStyles.root}>
			<Grid item xs={12} className={pageStyles.gridItem}>
				<Card >
					<CardHeader
						title={
							<PageTitle
								title={pageTitle}
								subtitle={order?.orderNo}
								backLocation={backLocation}
							/>
						}
						className={order && order.side === OrderSide.Sell ? sideStyle.sell : sideStyle.buy}
					/>
					<Divider />
					<CardContent>
						<div style={{ marginTop: "20px" }} className={order && order.side === OrderSide.Sell ? sideStyle.sell : sideStyle.buy}>
							{order && (
								<EditOrderBasicInfoPage
									order={order}
									editOrder={editOrder}
									changed={changed}
									action={action}
								/>
							)}
						</div>
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
};

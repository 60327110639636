import React from "react";
import { useMoneyAccounts, useMoneyAccount } from "../../../data/initialData/hooks/useMoneyAccounts";
import { MoneyAccounts } from "../../../data/models";
import { MoneyAccFilter } from "../../../data/domestic/orders/types";
import { AutocompleteSelect, AutocompleteSelectType,} from "./AutocompleteSelect";

type RegionSelectPropsType = Omit<
  AutocompleteSelectType<MoneyAccounts>,
  "data" | "getLabel"
> & {filter?: MoneyAccFilter};

export const MoneyAccountsSelect: React.FunctionComponent<RegionSelectPropsType> = ({
filter,
...otherProps
}
) => {
  const [statuses, renderStatus] = useMoneyAccounts(filter);

  const lenmoneyacc = statuses.length

  return (
    <AutocompleteSelect<MoneyAccounts>
      data={statuses}
      getLabel={renderStatus}
      {...(otherProps as any)}
    />
  );
};

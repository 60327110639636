import { webApi } from "../../../../core/http/httpThunks";
import { Trades } from "../../../domestic/trades/types";

export const getSumTradeApi = (userId: string, tradeid: string) =>
  webApi<Trades>({
    method: "GET",
    url: "trade/getsumtrade",
    params: {
      userId,
      tradeid,
    },
  });

export default getSumTradeApi;

import React from "react";
import { Table, TableDataProps } from "../../components/common/Table";
import { useSupportData } from "../../../data/support/hooks/useSupportData";
import { EmpContactData } from "../../../data/support/types";
import { useSupportColumns } from "./useSupportColumns";
import { AccessRights } from "../../../core/navigation/types";

export interface SupportTableProps {
  userAccessRights: AccessRights;
}

type SupportTablePropsType = SupportTableProps & TableDataProps<EmpContactData>;

export const SupportTable: React.FunctionComponent<SupportTablePropsType> = ({
  userAccessRights,
  ...tableProps
}) => {

  const [data, loadAll] = useSupportData();

  const columns = useSupportColumns();

  return (
    <Table<EmpContactData>
      {...tableProps}
      getData={loadAll}
      immutableData={data}
      title=""
      columns={columns}
      options={{
        toolbar: false,
        pageSize: 10,
        actionsColumnIndex: -1,
      }}
    />
  );
};

export default SupportTable;

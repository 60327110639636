import React, { useState, useContext } from "react";
import { Grid, CardContent, CardHeader, Card, Divider, TextField, Button, Switch, FormControlLabel } from "@material-ui/core";
import { useLocalizationStore } from "../../../data/localization/hooks";
import { LocalizationContext } from "../../../core/localization/Localization";
import { useLoginStyles } from "./useLoginStyles";
import { useOtherSettingsHooks } from "../../../data/settings/otherSettings/hooks/otherSettingsHooks";
import { CustomDialog } from "../../components/common/CustomDialog";
import { usePageStyles } from "../../pageStyles";
import { PageTitle } from "../../components/common/PageTitle";
import { User } from "../../../data/auth/types";
import { useAuth } from "../../../data/auth/hooks";
import { BoolInput } from "../../components/common/BoolInput";
import { useDispatch, useSelector } from "react-redux";
import { StoreType } from "../../../core/store/reducers";

export const OtherSettingsForm: React.FunctionComponent = props => {
	const styles = useLoginStyles();
	const pageStyles = usePageStyles();
	const { getString } = useContext(LocalizationContext);

	const [auth] = useAuth();



	const confirmOrder = useSelector<StoreType, number>(s => s.data.otherSettings.confirmOrder);

	const [fatchstate, setconfirORder] = useOtherSettingsHooks();

	const [checked, setChecked] = useState(auth.status==="successful"  && auth.user.confirmOrder===1 ? true : false );


	const switchHandler = (event: any) => {
		let confirm = 1;
		checked === true ? confirm = 0 : confirm = 1;
		setChecked(event.target.checked);
		saveConfirmOrder(confirm)
	};


	const saveConfirmOrder = React.useCallback(
		async (confirmOrder) => {
			const result = setconfirORder(confirmOrder);
		},
		[setconfirORder]
	);

	const onEnter = (action: () => void) => (e: React.KeyboardEvent<HTMLDivElement>) =>
		e.key === "Enter" && action();

	return (
		<>
			<Card>
				<CardHeader
					title={<PageTitle title="otherSettingsMenu" />}
				/>
				<Divider />
				<CardContent>
					<Grid container className={pageStyles.root} direction="row" justify="flex-start" alignItems="center" >

						<FormControlLabel
							control={<Switch checked={checked} onChange={switchHandler} />}
							label={getString("confirmOrderSetting")}
						/>


					</Grid>
				</CardContent>
			</Card>

		</>
	);
};

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FetchStateType } from "../../models";

type SettingsData = {
	confirmOrder: number;
	confirmOrderFetchState: FetchStateType;
};

const initialState: SettingsData = {
	confirmOrder: 1,
	confirmOrderFetchState: { type: "not-started" },
};

const name = "otherSettings";

const otherSettingsSlice = createSlice({
	name,
	initialState,
	reducers: {
		setConfirmOrder: (state, action: PayloadAction<number>) => ({
			...state,
			confirmOrder: action.payload
		}),

		setConfirmOrderFatchState: (state, action: PayloadAction<FetchStateType>) => ({
			...state,
			confirmOrderFetchState: action.payload,
		}),

	},
});

export const { setConfirmOrder, setConfirmOrderFatchState} = otherSettingsSlice.actions;

export default otherSettingsSlice.reducer;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PageIndex  } from "../../models";
import { successfulLogin } from "../../auth/authDataSlice";
import { FMarketFilter } from "./types"
type OrderPageState = {
	filter: FMarketFilter;
	index: PageIndex;
};

export const initialState: OrderPageState = {
	index: {
		itemsPerPage: 10,
		pageNumber: 0,
	},	
	filter: { sort: 0 },
};

const name = "marketData";

const marketDataSlice = createSlice({
	name,
	initialState,
	reducers: {
		setFilter: (state, action: PayloadAction<FMarketFilter>) => ({
			...state,
			filter: action.payload,
		}),
		setIndex: (state, action: PayloadAction<PageIndex>) => ({
			...state,
			index: action.payload,
		}),
		resetIdices: state => ({
			...state,
			index: {
				itemsPerPage: state.index.itemsPerPage,
				pageNumber: initialState.index.pageNumber,
			},
		}),

	},
	extraReducers: builder => {
		builder.addCase(successfulLogin, state => ({
			...state,
			index: {
				itemsPerPage: state.index.itemsPerPage,
				pageNumber: initialState.index.pageNumber,
			},
		}));
	},
});

export const { setFilter, setIndex, resetIdices } = marketDataSlice.actions;

export default marketDataSlice.reducer;

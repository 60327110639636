import React, { useMemo, useEffect } from "react";
import { useTransalation } from "./translations";
import moment from "moment";
import { LocalizationKey, LocalizationLanguage } from "./types";
import { ErrorData } from "../http/types";
import numeral from "numeral";

numeral.register("locale", LocalizationLanguage.SR, {
	delimiters: {
		decimal: ",",
		thousands: ".",
	},
	abbreviations: {
		thousand: "k",
		million: "m",
		billion: "b",
		trillion: "t",
	},
	ordinal: function (number) {
		return number === 1 ? "ac" : "ci";
	},
	currency: {
		symbol: "RSD",
	},
});

export interface NumberSeparators {
	decimal: string;
	thousands: string;
}

export interface Localization {
	getString: (key: LocalizationKey) => string;
	getDateString: (date?: Date, format?: string) => string;
	getDateTimeString: (date?: Date) => string;
	getErrorString: (error: ErrorData) => string;
	language: () => LocalizationLanguage;
	getNumberString: (num?: number, format?: string) => string;
	getFormatedNumberString: (num?: number, money?: boolean) => string;
	getFormatedNumberString4: (num?: number, money?: boolean) => string;
	getNumber: (value?: string) => number | undefined;
	getSeparators: () => NumberSeparators;
}

export const LocalizationContext = React.createContext<Localization>({
	getString: key => key,
	getDateString: date => date?.toString() || "",
	getDateTimeString: date => date?.toString() || "",
	getErrorString: error => error.message,
	language: () => LocalizationLanguage.SR,
	getNumberString: (num?: number, format?: string) => (num ? num.toString() : ""),
	getFormatedNumberString: (num?: number) => (num ? num.toString() : ""),
	getFormatedNumberString4: (num?: number) => (num ? num.toString() : ""),
	getNumber: (value?: string) => (value ? parseFloat(value) : undefined),
	getSeparators: () => ({ decimal: "", thousands: "" }),
});

const momentDateString = (date: Date | undefined, format: string | undefined): string => {
	if (!date) return "";
	const mnt = moment(date);
	if (!format) format = "l";
	return mnt.format(format);
};

export const LocalizationContextProvider: React.FunctionComponent = props => {
	const [translations, errorTranslations] = useTransalation();

	useEffect(() => {
		// moment.locale(translations.momentLocale);
		moment.locale("sr");
		numeral.locale(translations.language);
	}, [translations]);

	const translate: Localization = useMemo(
		() => ({
			getString: key => translations[key] || key,
			getDateString: momentDateString,
			getDateTimeString: date => momentDateString(date, "DD.MM.YYYY. HH:mm:ss"),
			getErrorString: error => errorTranslations[error.code] || error.message,
			language: () => translations.language as LocalizationLanguage,
			getNumberString: (num?: number, format?: string) => (num ? numeral(num).format(format) : ""),
			getFormatedNumberString: (num?: number, money?: boolean) => {
				const format = money === true ? "0,0.00" : "0,0";
				return num ? numeral(num).format(format) : "0";
			},
			getFormatedNumberString4: (num?: number, money?: boolean) => {
				const format = money === true ? "0,0.0000" : "0,0";
				return num ? numeral(num).format(format) : "0";
			},
			getNumber: (value?: string) => {
				var numValue = Number(value);
				return isNaN(numValue) || value === "" ? undefined : numValue;
			},
			getSeparators: () => {
				const numeralLocale = numeral.localeData().delimiters;
				return { decimal: numeralLocale.decimal, thousands: numeralLocale.thousands };
			},
		}),
		[translations, errorTranslations]
	);

	return <LocalizationContext.Provider value={translate}>{props.children}</LocalizationContext.Provider>;
};

import { NavigationItem } from "../../../core/navigation/types";
import MoneyAccountPage from "./MoneyAccountPage";
import { moneyAccountLocation  } from "./Locations";

export const moneyAccountComponents: NavigationItem[] = [
	{
		Component: MoneyAccountPage,
		isMenuItem: true,
		path: moneyAccountLocation,
	},
];

import { User } from "./auth/types";
import { AccessRights } from "../core/navigation/types";

export interface CompanyMember {
  id: string;
  name: string;
}

export enum PageDataStatus {
  DataCurrent,
  DataInvalidated,
}
export interface PageData<T> {
  currentPageData: T[];
  totalCount: number;
  status: PageDataStatus;
}

export interface PageIndex {
  pageNumber: number;
  itemsPerPage: number;
}

export interface Report {
  id: string;
  name: string;
}

export type PendingValueState = "invalid" | "valid" | "initial";

export type PendingValue<T> = {
  status: PendingValueState;
  value?: T;
};

export interface UserMenuItem {
  level: number;
  miId: string;
  miName: string;
  parentId: string;
  path: string;
  readOnly: AccessRights;
}

export interface LoginResponse {
  uData: User;
  userMenu: UserMenuItem[];
  orderStatuses: OrderStatus[];
  exchanges: Exchanges[];
  securityAccounts: SecurityAccounts [];
  moneyAccounts: MoneyAccounts [];
  orderTypes: OrderTypes [];
  tifList: TifList [];
  currencies: Currencies[];
  mAccCurr: MAccCur[];
}

export enum Status {
  Active = 1,
  Inactive = 0,
}

export type FetchStateTypeType =
  | "not-started"
  | "started"
  | "successful"
  | "error";

export type FetchStateType<TSucessResult = any> =
  | { type: "not-started" }
  | { type: "started" }
  | { type: "successful"; result?: TSucessResult }
  | { type: "error"; errorCode?: string };

export const createFetchState = (
  type: FetchStateTypeType,
  payload?: any
): FetchStateType => {
  switch (type) {
    case "started":
    case "not-started":
      return {
        type,
      };
    case "successful":
      return {
        type,
        result: payload,
      };
    case "error":
      return {
        type,
        errorCode: payload,
      };
  }
};

export const getFetchStateChange = (
  current: FetchStateType,
  change: FetchStateType
) =>
  current.type !== "started" || change.type !== "not-started"
    ? change
    : current;


export enum OrderSide {
  Sell = "SELL",
  Buy = "BUY",
}

export enum OnExchange {
  yes = "Y",
  no = "N",
}

export interface OrderStatus {
  ftValue: number;
  ftDescr: string;
}

export interface Security {
  securityId: string;
  symbol: string;
  isin: string;
  descr: string;
  type: string;
  priceType: string;
  tradingMethod: string;
  exchId: string;
  exchCode: string;
  currId: string;
  currCode: string;
}

export interface SecurityEdit {
  securityId: string;
}

export interface Exchanges {
  exchId: string;
  exch: string;
}

export interface Currencies {
  currId: string;
  currCode: string;
  currName: string;
}

export interface SecurityAccounts {
  accId: string;
  accNo: string;
  accBank: string;
  accUse: string;
  defDom: number;
  defFor: number;
}

export interface MoneyAccounts {
  accId: string;
  accNo: string;
  accBank: string;
  currId: string;
  accSide: string;
  accUse: string;
  defDom: number;
  defFor: number;
}

export interface MAccCur {
  currId: string;
  currCode: string;
}

export interface OrderTypes {
  ftValue: string;
  ftDescr: string;
}

export interface TifList {
  ftValue: string;
  ftDescr: string;
}

export interface Exchanges {
	exchId: string;
  exch: string;
  exchName: string;
}

export enum DomForSide {
  Domestic = "D",
  Foreign = "F",
}

import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { loadMarketData } from "../thunks/loadMarketData";
import { loadMarketAllData } from "../thunks/loadMarketAllData";
import { MarketListData, MarketDepth, MarketTrade } from "../types";
import { PageData, PageDataStatus, PageIndex } from "../../../models";

export const useMarketData = () => {

  const data = useSelector<StoreType, MarketListData[] | undefined>(
    (s) => s.data.domestic.marketData.displayData
  );
  const allData = useSelector<StoreType, MarketListData[] | undefined>(
    (s) => s.data.domestic.marketData.marketListData
  );
  const index = useSelector<StoreType, PageIndex>(
    (s) => s.ux.domestic.marketData.index
  );
  const pagedAllData = usePagedData(index, allData)

  const marketDepthData = useSelector<StoreType, MarketDepth[] | undefined>(
    (s) => s.data.domestic.marketData.marketDepthData
  );

  const marketTrade = useSelector<StoreType, MarketTrade[] | undefined>(
    (s) => s.data.domestic.marketData.marketTrade
  );

  const dispatch = useDispatch();
  const dispatchLoadMarketData = React.useCallback(
    () => dispatch(loadMarketData()),
    [dispatch ]
  );

  const dispatchInvalidateMD = React.useCallback(
    () => dispatch(loadMarketAllData()),
    [dispatch]
  );
  
  return [data, dispatchLoadMarketData, pagedAllData, marketDepthData, marketTrade,dispatchInvalidateMD] as [
    typeof data,
    typeof dispatchLoadMarketData,
    typeof pagedAllData,
    typeof marketDepthData,
    typeof marketTrade,
    typeof dispatchInvalidateMD
  ];
};

export const usePagedData = <T>(index: PageIndex, data?: T[]) => 
  React.useMemo(() => {
    const startIndex = index.itemsPerPage * index.pageNumber
    const endIndex = startIndex + index.itemsPerPage
    return data && {
      currentPageData: data.slice(startIndex, endIndex),
      status: PageDataStatus.DataCurrent,
      totalCount: data.length
    } as PageData<T>
  }, [index, data])


import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { loadMoneyAccounts } from "../thunks/loadMoneyAccounts";
import { MoneyAccounts } from "../../../models";
import { MoneyAccountFilter } from "../../moneyaccounts/types";

export const useMoneyAccountData = (filter?:MoneyAccountFilter) => {

  const allData = useSelector<StoreType, MoneyAccounts[] | undefined>(
    (s) => s.data.moneyAccounts.all
  ); 

  const dispatch = useDispatch();
  const dispatchLoadMoneyAcc = React.useCallback(
    () => dispatch(loadMoneyAccounts(filter)),
    [dispatch,filter]
  );

  return [allData, dispatchLoadMoneyAcc] as [
    typeof allData,
    typeof dispatchLoadMoneyAcc
  ];
};


import React, { useContext, useMemo } from "react";
import ViewIcon from "@material-ui/icons/InputOutlined";
import CancelIcon from '@material-ui/icons/CancelOutlined';
import MofifyIcoon from '@material-ui/icons/EditOutlined';
import { ViewOrder } from "../../../data/domestic/orders/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { MaterialTableProps } from "material-table";
import { useViewOrder } from "../orders/DisplayOrder";
import { DiplayOrderMod } from "../orders/DisplayOrderMainAttributes";
import { useShowCancelOrder } from "../orders/CancelOrder";
import { OrdersEditAction } from "../../../data/domestic/orders/types";
import { useHistory } from "react-router-dom";
import { getOrderEditLocation, OrdersEditParts } from "./Locations";

export function useOrderBookActions() {
  const { getString } = useContext(LocalizationContext);
  const onShowOBOrder = useViewOrder(DiplayOrderMod.Full, true);
  const onCancelOrder = useShowCancelOrder();
  const onModifyOrder = useEditOrder(OrdersEditAction.Modify);

  return useMemo(() => {
    const result: MaterialTableProps<ViewOrder>["actions"] = [
      {
        icon: () => <ViewIcon />,
        tooltip: getString("orderActView"),
        onClick: (event, rowData) => onShowOBOrder(rowData as ViewOrder),
      },
      row => ({
        icon: () => <CancelIcon />,
        tooltip: getString("orderCancel"),
        onClick: (event, rowData) => onCancelOrder(rowData as ViewOrder),
        hidden: row.cancelAllowed === "N",
      }),
      row => ({
        icon: () => <MofifyIcoon />,
        tooltip: getString("orderModify"),
        onClick: (event, rowData) => onModifyOrder(rowData as ViewOrder),
        hidden: row.replaceAllowed === "N",
      }),
    ];
    return result;
  }, [onShowOBOrder,onCancelOrder,onModifyOrder, getString ]);
}

export const useEditOrder = (action: OrdersEditAction) => {
  const history = useHistory();
	return React.useCallback(
		(order: ViewOrder) =>
			history.push(
				getOrderEditLocation(action, OrdersEditParts.BasicInfo, order.orderId)
			),
		[history, action]
	);
};


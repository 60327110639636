import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "./thunks";
import { AuthorizationState } from "./types";
import { StoreType } from "../../core/store/reducers";
import { useCallback } from "react";
import { resetLoginState, removeToken } from "./authDataSlice";
import { deleteUserToken } from "./authUXSlice";

export const useAuth = () => {
	const dispatch = useDispatch();
	const authStatus = useSelector<StoreType, AuthorizationState>(store => store.data.auth.current);
	const user = useSelector<StoreType, string | undefined>(store => store.ux.auth.lastLoginUsername);


	const loginCallback = useCallback(
		(username, password, language, origin) => dispatch(loginUser(username, password, language, origin)),
		[dispatch]
	);

	const logoutCallback = useCallback(() => {
		dispatch(resetLoginState())
		dispatch(removeToken())
		user && dispatch(deleteUserToken(user))
	}, [dispatch]);

	return [authStatus, loginCallback, logoutCallback] as [
		typeof authStatus,
		typeof loginCallback,
		typeof logoutCallback
	];
};

export const useLastLoginUsername = () =>
	useSelector<StoreType, string>(store => store.ux.auth.lastLoginUsername || "");

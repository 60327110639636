import React from "react";
import { Grid, Paper, Button } from "@material-ui/core";
import { DomForSideSelect } from "./DomForSelect";
import { LocalizationContext } from "../../../core/localization/Localization";
import { SecAccountFilter } from "../../../data/settings/securityaccounts/types";
import { useAuth } from "../../../data/auth/hooks";
import { SecAccountFilterChipsComponent } from "./SecAccountFilterChips";
import { DisplayDrawerContext } from "../../../layout/DisplayDrawerContext";


interface SecAccountFilterEditorProps {
	filter?: SecAccountFilter;
	editFilter: (prop: keyof SecAccountFilter, value: any) => void;
	save: () => void;
	reset?: () => void;
}

export const SecAccountFilterEditor: React.FunctionComponent<SecAccountFilterEditorProps> = ({
	filter,
	editFilter,
	save,
	reset,
}) => {
	const showWithDrawer = React.useContext(DisplayDrawerContext);
	const { getString } = React.useContext(LocalizationContext);
	const [auth] = useAuth();

	return (
		<Paper
			style={{
				paddingLeft: "70px",
				paddingRight: "70px",
				paddingTop: "15px",
				paddingBottom: "15px",
			}}
		>
			<Grid container direction="column" spacing={6}>
				<Grid item>
						<DomForSideSelect
							handleChange={e => editFilter("domfor", e.target.value)}
							side={filter?.domfor}
							nullable
						/>
				</Grid>

				<Grid item container direction="row-reverse" spacing={2}>
					<Grid item>
						<Button
							variant="contained"
							color="secondary"
							onClick={() => {
								save();
								showWithDrawer(undefined);
							}}
						>
							{getString("orderFilterConfirm")}
						</Button>
					</Grid>
					<Grid item>
						<Button variant="text" onClick={reset}>
							{getString("filterReset")}
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</Paper>
	);
};

export const SecAccountFilterChips = SecAccountFilterChipsComponent;

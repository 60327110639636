import React from "react";
import { useMAccCur } from "../../../data/initialData/hooks/useMAccCur";
import { MAccCur } from "../../../data/models";
import { AutocompleteSelect, AutocompleteSelectType,} from "./AutocompleteSelect";

type MAccCurrencySelectPropsType = Omit<
  AutocompleteSelectType<MAccCur>,
  "data" | "getLabel"
>;

export const MAccCurrencySelect: React.FunctionComponent<MAccCurrencySelectPropsType> = (
  props
) => {
  const [statuses, renderStatus] = useMAccCur();

console.log(statuses)

  return (
    <AutocompleteSelect<MAccCur>
      data={statuses}
      getLabel={renderStatus}
      {...(props as any)}
    />
  );
};

import { useCopiedState } from "../../../../core/util/hooks";
import { MarketFilter } from "../types";
import { setFilter, initialState } from "../marketUXSlice";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import React from "react";

export const useMarketDataFilter = () => {
	const [storeFilter] = useCurrentMarketDataFilter();
	const [filter, editFilter, changeState, setCurrentFilter] = useCopiedState<MarketFilter>(storeFilter, true);

	const dispatch = useDispatch();
	const dispatchSetFilter = React.useCallback(() => {
		dispatch(setFilter(filter!));
	}, [dispatch, filter]);

	const dispatchResetFilter = React.useCallback(() => {
		setCurrentFilter(initialState.filter)
	}, [setCurrentFilter]);

	return [filter, editFilter, dispatchSetFilter, changeState, dispatchResetFilter] as [
		typeof filter,
		typeof editFilter,
		typeof dispatchSetFilter,
		typeof changeState,
		typeof dispatchResetFilter
	];
};

export const useCurrentMarketDataFilter = () => {
	const filter = useSelector<StoreType, MarketFilter>(store => store.ux.domestic.marketData.filter);

	return [filter ] as [typeof filter];
};

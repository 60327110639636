import React from "react";
import { useOrderTypes } from "../../../data/initialData/hooks/useOrderTypes";
import { OrderTypes } from "../../../data/models";
import { AutocompleteSelect, AutocompleteSelectType,} from "./AutocompleteSelect";

type RegionSelectPropsType = Omit<
  AutocompleteSelectType<OrderTypes>,
  "data" | "getLabel"
>;

export const OrderTypesSelect: React.FunctionComponent<RegionSelectPropsType> = (
  props
) => {
  const [statuses, renderStatus] = useOrderTypes();

  return (
    <AutocompleteSelect<OrderTypes>
      data={statuses}
      getLabel={renderStatus}
      {...(props as any)}
    />
  );
};

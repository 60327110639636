import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FetchStateType, MoneyAccounts } from "../../models";

interface IMoneyAccounts  {
	all?: MoneyAccounts[];
	changeMoneyAccountFetchState: FetchStateType;
	errorDesc: string;
	accountid: string;
};

const initialState: IMoneyAccounts = {
	changeMoneyAccountFetchState: { type: "not-started" },
	errorDesc: "",
	accountid: "",
};

const invalidate = (state: IMoneyAccounts) => {
	return {...state}
};

const name = "moneyAccounts";

const moneyAccountsSlice = createSlice({
	name,
	initialState,
	reducers: {
   		setData: (state, action: PayloadAction<MoneyAccounts[] | undefined>) => ({
			...state,
			all: action.payload,
		  }),
	  	setChangeMoneyAccountFetchState: (state, action: PayloadAction<FetchStateType>) => ({
			...state,
			changeMoneyAccountFetchState: action.payload,
		}),
		setError: (state, action: PayloadAction<string>) => ({
			...state,
			errorDesc: action.payload,
		}),
		setMoneyAcc: (state, action: PayloadAction<string>) => ({
			...state,
			accountid: action.payload,
		  }),
		invalidatemoneyAccount: invalidate,
	},
});

export const { setData,setChangeMoneyAccountFetchState, setError, setMoneyAcc, invalidatemoneyAccount } = moneyAccountsSlice.actions;

export default moneyAccountsSlice.reducer;

import { ThunkType } from "../../../../core/store/reducers";
import { setDisplayed } from "../marketsDataSlice";
import getmarketdatabyidApi from "../api/getMarketDataById";
import { FMarketListData } from "../types";

export const loadMarketData = (ordId: string): ThunkType => async dispatch => {
	dispatch(setDisplayed(undefined));

	const mborder = await dispatch(getMarketData(ordId));

	dispatch(setDisplayed(mborder));
};

export const getMarketData = (
	secid: string
  ): ThunkType<Promise<FMarketListData | undefined>> => async (dispatch, getStore) => {
	const store = getStore();
  
	if (store.data.auth.current.status !== "successful") {
	  return undefined;
	}
  
	const { userId } = store.data.auth.current.user;
	return await dispatch(getmarketdatabyidApi(userId, secid));
  };





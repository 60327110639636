import TradesSumPage from "./TradesSumPage";
import { NavigationItem } from "../../../core/navigation/types";
import { tradesSumFLocation } from "./Locations";

export const tradesSumFComponents: NavigationItem[] = [
  {
    Component: TradesSumPage,
    isMenuItem: true,
    path: tradesSumFLocation,
    notifications: true,
  },
];

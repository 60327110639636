import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { FetchStateType } from "../../../models";
import { upsertOrder } from "../thunks/upsertOrder";
import { setNewOrderFetchState } from "../orderBookDataSlice";
import { EditOrder, OrdersEditAction, OrderUpsertResult } from "../types";

export const useOrderUpsert = (action: OrdersEditAction) => {
	const fetchState = useSelector<StoreType, FetchStateType>(
		store => store.data.domestic.orderBook.newOrderFetchState
	);

	const dispatch = useDispatch();
	const dispatchUpsertOrder: any = React.useCallback(
		(order: EditOrder) => dispatch(upsertOrder(action, order)),
		[dispatch, action]
	);

	React.useEffect(() => {
		dispatch(setNewOrderFetchState({ type: "not-started" }))
	}, [dispatch])

	return [fetchState, dispatchUpsertOrder] as [typeof fetchState, (order: EditOrder) => Promise<OrderUpsertResult>]
};


import { ThunkType } from "../../../../core/store/reducers";
import { addSecurityApi } from "../api/addSecurity";
import { setFetchState, invalidateData } from "../marketsDataSlice";

export const loadAddSecurity = ( secid: string) : ThunkType => async (dispatch, getStore) => {
	const store = getStore();


	if (store.data.auth.current.status !== "successful") {
		return undefined;
	}

	dispatch(setFetchState({ type: "started" }));

	var { user } = store.data.auth.current;

	const result = await dispatch(addSecurityApi(user.userId, secid ))

	if (result && result.resCode === "OK") {
		dispatch(setFetchState({ type: "successful", result: result.resDesc }));
		dispatch(invalidateData());
	} else 
	{
		dispatch(setFetchState({ type: "error", errorCode: result?.resCode }));
	}

}

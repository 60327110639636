import React, { useContext } from "react";
import { AppBar, IconButton, Toolbar, Typography } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { useMainBarStyles } from "./MainBarStyles";
import { LocalizationContext } from "../core/localization/Localization";
import { UserAvatar } from "./UserAvatar";
import { appConfig } from "../core/config/config";
import { useAuth } from "../data/auth/hooks";

interface MainBarProps {
	toggleNavMenu: () => void;
}

export const MainBar: React.FunctionComponent<MainBarProps> = props => {
	const styles = useMainBarStyles();
	const { getString } = useContext(LocalizationContext);

	const [auth] = useAuth();

	if (auth.status !== "successful") {
		return null;
	}

	let bddlogo1 = auth.user.hasLogo === 1 || auth.user.hasLogo === 3 ? require("'../../../public/"+auth.user.companyId+"_logo1.jpg") : 0

	return (
		<AppBar position="sticky" className={auth.user.hdrColor === '' ? styles.appBar : styles.appBarBdd} >
			<Toolbar className={styles.appBaraContent}>
				<span className={styles.titleArea}>
					<IconButton
						edge="start"
						color="inherit"
						aria-label="menu"
						className={styles.menuButton}
						onClick={props.toggleNavMenu}
					>
						<MenuIcon />
					</IconButton>
					<Typography variant="h6">{`${getString("mainTitle")} ${appConfig.general.titleSufix
						}`}</Typography>
				</span>
				<span >
					{(auth.user.hasLogo === 1 || auth.user.hasLogo === 3) && bddlogo1 !==0 && (<img className={styles.logo} src={bddlogo1} />)}
				</span>
				<UserAvatar />
			</Toolbar>
		</AppBar>
	);
};

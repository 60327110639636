import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CashBalanceData } from "./types";
import { FetchStateType, getFetchStateChange } from "../../models";

interface CashBalListReportData {
  all?: CashBalanceData[];
  displayData?: CashBalanceData[];
	downloadReportFetchState: FetchStateType;
	downloadXlsFetchState: FetchStateType;
};

const initialState: CashBalListReportData = {
	downloadReportFetchState: { type: "not-started" },
	downloadXlsFetchState: { type: "not-started" },
};

const name = "cashBalList";

const cashBalListSlice = createSlice({
	name,
	initialState,
	reducers: {
		setData: (state, action: PayloadAction<CashBalanceData[] | undefined>) => ({
			...state,
      all: action.payload,
		}),
		setDownloadReportFetchState: (state, action: PayloadAction<FetchStateType>) => ({
			...state,
			downloadReportFetchState: action.payload,
		}),
		setDownloadXlsFetchState: (state, action: PayloadAction<FetchStateType>) => ({
			...state,
			downloadXlsFetchState: action.payload,
		}),

	},
});

export const { setData, setDownloadReportFetchState, setDownloadXlsFetchState  } = cashBalListSlice.actions;

export default cashBalListSlice.reducer;



import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { FetchStateType } from "../../../models";
import { loadRemoveSecurity } from "../thunks/loadRemoveSecurity";
import { setNewOrderFetchState } from "../orderBookDataSlice";
import { loadMarketData } from "../../mainBoard/thunks/loadMarketData";

export const useRemoveSecurity = () => {
	const fetchState = useSelector<StoreType, FetchStateType>(
		store => store.data.domestic.orderBook.newOrderFetchState
	);

	const dispatch = useDispatch();
	const dispatchRemoveSecurity: any = React.useCallback(
		(secid: string) => {
			dispatch(loadRemoveSecurity(secid))
            dispatch(loadMarketData());
		},
		[dispatch ]
	);

	React.useEffect(() => {
		dispatch(setNewOrderFetchState({ type: "not-started" }))
	}, [dispatch])


	return [fetchState, dispatchRemoveSecurity] as [typeof fetchState, typeof dispatchRemoveSecurity]
};


import { webApi } from "../../../../core/http/httpThunks";
import { FMarketListData } from "../types";

export const getmarketdatabyidApi = (userid: string, secid: string) =>
  webApi<FMarketListData>({
    method: "GET",
    url: "trade/getfmarketdatabyid",
    params: {
      userid,
      secid,
    },
  });

export default getmarketdatabyidApi;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TradePricesChartData, ChartPeriod, DayPriceData } from "./types";

type TickerData = {
	graphData: {
		[k: string]: TradePricesChartData[] | undefined;
	};
	graphDayData: {
		[k: string]: DayPriceData[] | undefined;
	};
	graphPeriod: {
		[k: string]: ChartPeriod;
	};

};

export type TickerArg<T> = {
	graphId: string;
	data: T;
};


const initialState: TickerData = {
	graphData: {},
	graphDayData: {},
	graphPeriod: {},
};

const name = "ticker";

const tickerSlice = createSlice({
	name,
	initialState,
	reducers: {
		setGraphData: (state, action: PayloadAction<TickerArg<TradePricesChartData[] | undefined>>) => ({
			...state,
			graphData: {
				...state.graphData,
				[action.payload.graphId]: action.payload.data,
			},
		}),
		setGraphDayData: (state, action: PayloadAction<TickerArg<DayPriceData[] | undefined>>) => ({
			...state,
			graphDayData: {
				...state.graphDayData,
				[action.payload.graphId]: action.payload.data,
			},
		}),
		setGraphPeriod: (state, action: PayloadAction<TickerArg<ChartPeriod>>) => ({
			...state,
			graphPeriod: {
				...state.graphPeriod,
				[action.payload.graphId]: action.payload.data,
			},
		}),
	},
});

export const { setGraphData, setGraphDayData, setGraphPeriod  } = tickerSlice.actions;

export default tickerSlice.reducer;

import { TradePricesChartData, ChartPeriod } from "../types";
import { User } from "../../../auth/types";
import { webApi } from "../../../../core/http/httpThunks";

export const getChartData = (user: User, secid: string, period: ChartPeriod) => {
	return webApi<TradePricesChartData[]>({
			method: "GET",
		url: "trade/getfchartdata",
		params: {
			userid: user.userId,
			secid,
			period,
		},
	});
};

import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../core/store/reducers";
import { SecuritySimpleList, SecuritySimpleFilter } from "../types";
import { loadSecurityFForSelect } from "../thunks/loadSecurityFForSelect";

export const useSecurityFSelectData = (df: string, filter?: SecuritySimpleFilter) => {
	const selectSecurities = useSelector<StoreType, SecuritySimpleList[] | undefined | "loading">(
		store => store.data.initialData.securityFForSelect
    );

    const displatch = useDispatch();
	React.useEffect(() => {
//		if (!selectSecurities) {
				displatch(loadSecurityFForSelect(df,filter));
//		}
    }, [displatch, df, filter]);
//  }, [displatch, selectSecurities]);

	return selectSecurities !== "loading" ? selectSecurities : undefined;
};



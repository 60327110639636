import { useContext, useMemo } from "react";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Column } from "material-table";
import { EmpContactData } from "../../../data/support/types";


export function useSupportColumns() {
  const { getString } = useContext(LocalizationContext);
  return useMemo(
    () => [
      {
        title: getString("empName"),
        field: "empName",
				sorting: false,
				width: "400px",
				cellStyle: {
					maxWidth: "400px",
				},
				headerStyle: {
					maxWidth: "400px",
				},
      },
      {
        title: getString("empTel"),
        field: "empTel",
				sorting: false,
      },
      {
        title: getString("empMob"),
        field: "empMob",
				sorting: false,
      },
    ],
    [getString]
  ) as Column<EmpContactData>[];
}

import { Theme, makeStyles, createStyles } from "@material-ui/core";

export const useUserAvatarStyles = makeStyles((theme: Theme) => {
	const { permanentBreakpoint } = theme.navDrawer
	return createStyles({
		root: {
			alignItems: "baseline",
			display: "flex",
		},
		avatar: {},
		name: {
			display: "none",
			[permanentBreakpoint]: {
				display: "inline"
			}
		},
	});
});

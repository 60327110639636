import { NavigationItem } from "../../../core/navigation/types";
import SupportPage from "./SupportPage";
import { supportLocation  } from "./Locations";

export const contactsComponents: NavigationItem[] = [
	{
		Component: SupportPage,
		isMenuItem: true,
		path: supportLocation,
	},
];

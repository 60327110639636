import React, { useMemo } from "react";
import { MenuItemType, SubmenuMenuItemType, LinkMenuItemType } from "./types";
import { ListItem, ListItemIcon, ListItemText, Collapse, List } from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { useMenuItemStyles } from "./menuItemsStyles";
import { useToggleStateDictionary } from "../util/hooks";
import { Link } from "react-router-dom";
import { useVisiableNavigationItems } from "./hooks";

export interface SubmenuMenuItemProps {
	submenu: SubmenuMenuItemType;
	open: boolean;
	toggleOpen: () => void;
	level: number;
}

export const SubmenuMenuItem: React.FunctionComponent<SubmenuMenuItemProps> = ({
	submenu,
	open,
	toggleOpen,
	level,
}) => {
	const styles = useMenuItemStyles(level);
	const Icon = submenu.icon;

	return (
		<>
			<ListItem className={styles.menuItem} button onClick={toggleOpen}>
				{Icon && (
					<ListItemIcon>
						<Icon />
					</ListItemIcon>
				)}
				<ListItemText primary={submenu.title} />
				{open ? <ExpandLess /> : <ExpandMore />}
			</ListItem>
			<Collapse in={open} timeout="auto" unmountOnExit>
				<MenuList level={level + 1} menuItems={submenu.items} />
			</Collapse>
		</>
	);
};

export interface PageMenuItemProps {
	page: LinkMenuItemType;
	level: number;
}

export const LinkMenuItem: React.FunctionComponent<PageMenuItemProps> = ({ page, level }) => {
	const styles = useMenuItemStyles(level);
	const Icon = page.icon;

	return (
		<ListItem className={styles.menuItem} button component={Link} to={page.path}>
			{Icon && (
				<ListItemIcon>
					<Icon />
				</ListItemIcon>
			)}
			<ListItemText primary={page.title} />
		</ListItem>
	);
};

export interface MenuListProps {
	level?: number;
	menuItems: MenuItemType[];
}

export const MenuList: React.FunctionComponent<MenuListProps> = ({ level, menuItems }) => {
	const currentLevel = level || 0;
	const visiableItems = useVisiableNavigationItems(menuItems);
	const submenuItemKeys = useMemo(() => visiableItems.filter(i => i.type === "Menu").map(i => i.key), [
		visiableItems,
	]);

	const [openDictionary, toggleOpenDictionary] = useToggleStateDictionary(submenuItemKeys);

	return (
		<List disablePadding={level !== 0}>
			{visiableItems.map(item =>
				item.type === "Link" ? (
					<LinkMenuItem key={item.key} level={currentLevel} page={item} />
				) : (
					<SubmenuMenuItem
						key={item.key}
						level={currentLevel}
						submenu={item}
						open={openDictionary[item.key]}
						toggleOpen={toggleOpenDictionary[item.key]}
					/>
				)
			)}
		</List>
	);
};

import OrderBook from "./OrderBook";
import { NavigationItem } from "../../../core/navigation/types";
import { orderBookFLocation, baseEdit} from "./Locations";
import { EditOrderPage } from "../orders/EditOrderPage"

export const orderBookFComponents: NavigationItem[] = [
	{
		Component: OrderBook,
		isMenuItem: true,
		path: orderBookFLocation,
		notifications: true,
	},

	{
		Component: EditOrderPage,
		isMenuItem: false,
		path: baseEdit,
	},
];


import { ThunkType } from "../../../../core/store/reducers";
import { getTradeListExcel } from "../api/getTradeListExcel";
import fileDownload from "js-file-download";
import { setDownloadXlsFetchState } from "../tradesDataSlice";

export const downloadTradesBookExcel = (): ThunkType => async (
  dispatch,
  getStore
) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return undefined;
  }

  const filter  = store.ux.domestic.trades.filter;
  const user = store.data.auth.current.user;
  dispatch(setDownloadXlsFetchState({ type: "started" }));
  const dailyTrading = await dispatch(getTradeListExcel(user, filter));
  if (dailyTrading) {
    fileDownload(
      new Blob([dailyTrading as any], {
        type: "application/vnd.openxmlformat",
      }),
      "TradeBook.xlsx"
    );
  }
  dispatch(setDownloadXlsFetchState({ type: "not-started" }));
};


import React from "react";
import { useSelector } from "react-redux";
import { StoreType } from "../../../core/store/reducers";
import { MoneyAccounts } from "../../models";
import { MoneyAccFilter } from "../../../data/domestic/orders/types";

const getStatusLabel = (status?: MoneyAccounts) => (status ? status.accNo : "");

export const useMoneyAccounts = (filter?: MoneyAccFilter) => {
	
	const statuses = useSelector<StoreType, MoneyAccounts[]>(store => store.data.initialData.moneyAccounts.filter(f=>f.accUse!=='F'));

  	const [monaccs, getAccLabel ] = useMoneyAccount(statuses,filter );

	return [monaccs, getAccLabel] as [typeof statuses, typeof getStatusLabel];
};

export const useMoneyAccount = (monaccs: MoneyAccounts[], filter?: MoneyAccFilter) => {

	const monacc = React.useMemo(() => monaccs.filter(c => filter &&  c.currId === filter.curId && (c.accSide === filter.accSide || c.accSide==="U") ), [monaccs, filter]);

	const getStatusLabel = (monacc?: MoneyAccounts) => (monacc ? monacc.accNo : "");

	return [monacc, getStatusLabel ] as [typeof monacc, typeof getStatusLabel];
};

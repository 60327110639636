import { ThunkType } from "../../../core/store/reducers";
import getSecurityFroSelectApi from "../api/getSecurityForSelect";
import { setSecurityForSelect } from "../initialDataReducer";

export const loadSecurityForSelect = (df: string): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return;
	}

	const { userId } = store.data.auth.current.user;
	const securities = await dispatch(getSecurityFroSelectApi(userId, df));
	console.log(df)


	dispatch(setSecurityForSelect(securities || []));
};

import { ThunkType } from "../../../../core/store/reducers";
import getSecAccountApi from "../api/getSecAccountData";
import { setData } from "../securityAccountsDataSlice";
import { SecAccountFilter } from "../../../../data/settings/securityaccounts/types";

export const loadSecAccounts = (filter?:SecAccountFilter): ThunkType => async (dispatch, getStore) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return;
  }

  const  userid = store.data.auth.current.user.userId;
  const secAcc = await dispatch(getSecAccountApi(userid, filter));
  
  dispatch(setData(secAcc || []));
};

import React from "react";
import { Grid, CardContent, CardHeader, Card, Divider, Fab } from "@material-ui/core";
import OrdersTable from "./OrderBookTable";
import AddIcon from "@material-ui/icons/Add";
import { usePageStyles } from "../../pageStyles";
import { PageComponent, AccessRights } from "../../../core/navigation/types";
import { PageTitle } from "../../components/common/PageTitle";
import { useOrderBookData } from "../../../data/domestic/orders/hooks/useOrderBookData";
import { useOrderBookIndex } from "../../../data/domestic/orders/hooks/useOrderBookIndex";
import { useHistory } from "react-router-dom";
import { PageDataStatus } from "../../../data/models";
import { OrderBookFilterButton } from "./OrderBookFilter";
import { useAuth } from "../../../data/auth/hooks";
import { useNotificationHandler } from "../../../core/notifications/hooks";
import { getOrderEditLocation } from "./Locations";
import { OrdersEditAction } from "../../../data/domestic/orders/types";
import { DownloadButton, DownloadExcelButton } from "./DownloadButton";


const OrderBook: PageComponent = ({ userAccessRights }) => {
	const pageStyles = usePageStyles();

	const [pageData, dispatchLoadOrders, invalidateData] = useOrderBookData();
	const [pageIndex, dispatchSetIndex] = useOrderBookIndex();

	const publiurl = "%PUBLIC_URL%/manifest.json"

	React.useEffect(() => {
		if (pageData && pageData.status === PageDataStatus.DataInvalidated) {
			dispatchLoadOrders();
		}
	}, [dispatchLoadOrders, pageData]);

	React.useEffect(() => () => { invalidateData() }, [])

	const history = useHistory();

	const [auth] = useAuth();

	useNotificationHandler<string>(
		"OrderChanged",
		e => {
			console.log("OrderChanged", e);
			invalidateData();
		},
		[]
	);

	if (auth.status !== "successful") {
		return null;
	}

	return (
		<>
			<Grid container className={pageStyles.root}>
				<Grid item xs={12} className={pageStyles.gridItem}>
					<Card>
						<CardHeader
							title={<PageTitle title="listingOrdersBookTitle" />}
							action={<OrderBookActions />}
						/>
						<Divider />
						<CardContent>
							<OrdersTable
								pageIndex={pageIndex}
								pageData={pageData}
								onPageIndexChange={dispatchSetIndex}
								userAccessRights={userAccessRights}
								getData={dispatchLoadOrders}
							/>
						</CardContent>
					</Card>
				</Grid>
			</Grid>
			{/*userAccessRights === AccessRights.All && (auth.user.roleCode === "BROKER" || auth.user.roleCode === "MEMTRADER") && (
			)*/}
			<Fab
					color="secondary"
					aria-label="add"
					className={pageStyles.mainFab}
					onClick={() => history.push(getOrderEditLocation())}
				>
				<AddIcon />
			</Fab>

		</>
	);
};

export const OrderBookActions: React.FunctionComponent = () => (
	<Grid container>
	  <Grid item>
		<DownloadButton />
	  </Grid>
	  <Grid item>
		<DownloadExcelButton />
	  </Grid>
	  <Grid item>
		<OrderBookFilterButton />
	  </Grid>
	</Grid>
  );
  
export default OrderBook;

import React from "react";
import { OrderSide } from "../../../models";
import { LocalizationContext } from "../../../../core/localization/Localization";
import { exhaustiveCheck } from "../../../../core/util/functions";

export const useOrderSideOptionsTranslator = () => {
	const { getString } = React.useContext(LocalizationContext);
	return React.useCallback(
		(side: OrderSide) => {
			switch (side) {
				case OrderSide.Buy:
					return getString("orderSideBuy");
				case OrderSide.Sell:
					return getString("orderSideSell");
				default:
					exhaustiveCheck(side);
			}
		},
		[getString]
	);
};

interface AccountOrderSideOption {
	label: string;
	value: OrderSide;
}

export const useOrderSideOptions = (): AccountOrderSideOption[] => {
	const translator = useOrderSideOptionsTranslator();
	return React.useMemo(
		() => [
			{
				label: translator(OrderSide.Buy) as string,
				value: OrderSide.Buy,
			},
			{
				label: translator(OrderSide.Sell) as string,
				value: OrderSide.Sell,
			},
		],
		[translator]
	);
};

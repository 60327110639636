import { MarketDepth } from "../../../data/domestic/mainBoard/types";
import React from "react";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Column } from "material-table";

export const useMarketDepthColumns = () => {
	const { getString, getFormatedNumberString } = React.useContext(LocalizationContext);

	return React.useMemo(
		() => [
			{
				title: getString("marketDataSymbol"),
				field: "symbol",
				sorting: false,
			},
			{
				title: getString("marketDataBestBid"),
				render: marketdepth => getFormatedNumberString(marketdepth.bid,true) ,
				sorting: false,
				cellStyle: {
					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
					background: "#e1f5fe",
				},
				headerStyle: {
//					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
					background: "#e1f5fe",
				},
			},
			{
				title: getString("marketDataBestBidQty"),
				render: marketdepth => getFormatedNumberString(marketdepth.bidQty) ,
				sorting: false,
				cellStyle: {
					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
					background: "#e1f5fe",
				},
				headerStyle: {
//					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
					background: "#e1f5fe",
				},
			},
			{
				title: getString("marketDataSumBid"),
				render: marketdepth => getFormatedNumberString(marketdepth.cumBidQty) ,
				sorting: false,
				cellStyle: {
					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
					background: "#e1f5fe",
				},
				headerStyle: {
//					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
					background: "#e1f5fe",
				},
			},
			{
				title: getString("marketDataBestAsk"),
				render: marketdepth => getFormatedNumberString(marketdepth.ask,true) ,
				sorting: false,
				cellStyle: {
					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
					background: "#ffebee",
				},
				headerStyle: {
//					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
					background: "#ffebee",
				},
			},
			{
				title: getString("marketDataBestAskQty"),
				render: marketdepth => getFormatedNumberString(marketdepth.askQty) ,
				sorting: false,
				cellStyle: {
					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
					background: "#ffebee",
				},
				headerStyle: {
//					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
					background: "#ffebee",
				},
			},
			{
				title: getString("marketDataSumAsk"),
				render: marketdepth => getFormatedNumberString(marketdepth.cumAskQty) ,
				sorting: false,
				cellStyle: {
					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
					background: "#ffebee",
				},
				headerStyle: {
//					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
					background: "#ffebee",
				},
			},
		],
		[getString,  getFormatedNumberString]
	) as Column<MarketDepth>[];
};

import { ThunkType } from "../../../../core/store/reducers";
import { getPortfolioExcel } from "../api/getPortfolioExcel";
import fileDownload from "js-file-download";
import { setDownloadXlsFetchState } from "../../../domestic/portfolio/portfolioDataSlice";

export const downloadPortfolioExcel = (): ThunkType => async (
  dispatch,
  getStore
) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return undefined;
  }

  const filter  = store.ux.domestic.portfolio.filter;
  const user = store.data.auth.current.user;
  dispatch(setDownloadXlsFetchState({ type: "started" }));
  const portfolioXls = await dispatch(getPortfolioExcel(user, filter));
  if (portfolioXls) {
    fileDownload(
      new Blob([portfolioXls as any], {
        type: "application/vnd.openxmlformat",
      }),
      "Portfolio.xlsx"
    );
  }
  dispatch(setDownloadXlsFetchState({ type: "not-started" }));
};

import React from "react";
import { Grid, CardContent, CardHeader, Divider, Card, Typography, Link } from "@material-ui/core";
import MarketDataTable from "./MarketDataTable";
import { usePageStyles } from "../../pageStyles";
import { PageComponent } from "../../../core/navigation/types";
import { PageTitle } from "../../components/common/PageTitle";
import { MainBoardSecurity }  from "./MainBoardSecurity"
import { useSelector } from "react-redux";
import { StoreType } from "../../../core/store/reducers";
import { useNotificationHandler } from "../../../core/notifications/hooks";
import { useMarketData } from "../../../data/foreign/mainBoard/hooks/useMarketData";
import { ProductGraph } from "./ProductGraph";
import { LocalizationContext } from "../../../core/localization/Localization";
import { PageDataStatus } from "../../../data/models";

const MainBoard: PageComponent = ({ userAccessRights }) => {

	const { getString } = React.useContext(LocalizationContext);

	const pageStyles = usePageStyles();

	 const security = useSelector<StoreType, string | undefined>(
		store => store.data.foreign.marketData.secid
	);  	

	
	const [pageData,dispatchLoadMarketDataPage,invalidateData] = useMarketData();

	React.useEffect(() => {
		if (pageData && pageData.status === PageDataStatus.DataInvalidated) {
				dispatchLoadMarketDataPage();
		}
	}, [dispatchLoadMarketDataPage, pageData]);

	useNotificationHandler<string>(
		"MainBoardFChanged",
		e => {
			console.log("MainBoardFChanged", e);
			invalidateData();
		},
		[]
	);  

	return (
		<Grid container className={pageStyles.root}>
			<Grid item xs={12} className={pageStyles.gridItem}>
				<Card>
					<CardHeader 
							title={<PageTitle title="navMainTable" />}
							action={<MainBoardActions />}					/>
					<Divider />
					<CardContent>
 						<MarketDataTable userAccessRights={userAccessRights} /> 
					</CardContent>  
				</Card>
			</Grid>
			<Grid item  xs={12}>
				{ security && <ProductGraph graphId="0001" /> } 
			</Grid>
			<Grid item  xs={12}>
				<Link href="https://iexcloud.io"> {getString("marketIEX") }</Link>
			</Grid>

		</Grid>
	);
};

export const MainBoardActions: React.FunctionComponent = () => (
	<Grid container  spacing={4} >
	  <Grid item>
		<MainBoardSecurity />
	  </Grid>
	</Grid>
  );

export default MainBoard;



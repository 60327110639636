import { webApi } from "../../../../core/http/httpThunks";
import { Order } from "../../../domestic/orders/types";

export const getOrderApi = (userId: string, ordid: string) =>
  webApi<Order>({
    method: "GET",
    url: "order/getorder",
    params: {
      userId,
      ordid,
    },
  });

export default getOrderApi;

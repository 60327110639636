import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EmpContactData } from "./types";

interface ISupport  {
	all?: EmpContactData[];
};

const initialState: ISupport = {
//	errorDesc: "",
};

const invalidate = (state: ISupport) => {
	return {...state}
};

const name = "support";

const supportSlice = createSlice({
	name,
	initialState,
	reducers: {
   		setData: (state, action: PayloadAction<EmpContactData[] | undefined>) => ({
			...state,
			all: action.payload,
		  }),
		invalidateSupport: invalidate,
	},
});

export const { setData, invalidateSupport } = supportSlice.actions;

export default supportSlice.reducer;

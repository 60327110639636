import { ThunkType } from "../../../../core/store/reducers";
import { setDisplayed } from "../orderBookDataSlice";
import getOrderApi from "../api/getOrder";
import { Order } from "../types";

export const loadBookOrder = (ordId: string): ThunkType => async dispatch => {
	dispatch(setDisplayed(undefined));

	const mborder = await dispatch(getOrder(ordId));

	dispatch(setDisplayed(mborder));
};

export const getOrder = (
	ordId: string
  ): ThunkType<Promise<Order | undefined>> => async (dispatch, getStore) => {
	const store = getStore();
  
	if (store.data.auth.current.status !== "successful") {
	  return undefined;
	}
  
	const { userId } = store.data.auth.current.user;
	return await dispatch(getOrderApi(userId, ordId));
  };

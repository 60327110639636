import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PageData, PageDataStatus, FetchStateType } from "../../models";
import { ViewOrder, Order } from "../orders/types";
//import { invalidateOrders as mainBoardInvalidate } from "../mainBoard/ordersDataSlice";

type OrdersData = {
	currentPage?: PageData<ViewOrder>;
	displayedOBOrder?: Order;
	newOrderFetchState: FetchStateType;
	attributeFetchState: FetchStateType;
	printOrderFetchState: FetchStateType;
	cancelOrderFetchState: FetchStateType;
	downloadXlsFetchState: FetchStateType;
};

const initialState: OrdersData = {
	newOrderFetchState: { type: "not-started" },
	attributeFetchState: { type: "not-started" },
	printOrderFetchState: { type: "not-started" },
	cancelOrderFetchState: { type: "not-started" },
	downloadXlsFetchState: { type: "not-started" },
};

const invalidate = (state: OrdersData) => {
	if (!state.currentPage) return state;

	return {
		...state,
		currentPage: {
			...state.currentPage,
			status: PageDataStatus.DataInvalidated,
		},
	};
};

const name = "orderBook";

const orderBookSlice = createSlice({
	name,
	initialState,
	reducers: {
		setOrders: (state, action: PayloadAction<PageData<ViewOrder>>) => ({
			...state,
			currentPage: action.payload,
		}),
		setDisplayed: (state, action: PayloadAction<Order | undefined>) => ({
			...state,
			displayedOBOrder: action.payload,
		}),
		invalidateOrders: invalidate,
		setNewOrderFetchState: (state, action: PayloadAction<FetchStateType>) => ({
			...state,
			newOrderFetchState: action.payload,
		}),
		setAttributeFetchState: (state, action: PayloadAction<FetchStateType>) => ({
			...state,
			attributeFetchState: action.payload,
		}),
		setPrintOrderFetchState: (state, action: PayloadAction<FetchStateType>) => ({
			...state,
			printOrderFetchState: action.payload,
		}),
		setDownloadXlsFetchState: (state, action: PayloadAction<FetchStateType>) => ({
			...state,
			downloadXlsFetchState: action.payload,
		}),
		setCancelOrderFetchState: (state, action: PayloadAction<FetchStateType>) => ({
			...state,
			cancelOrderFetchState: action.payload,
		}),
	},
});

export const {
	setOrders,
	setDisplayed,
	invalidateOrders,
	setNewOrderFetchState,
	setAttributeFetchState,
	setPrintOrderFetchState,
	setCancelOrderFetchState,
	setDownloadXlsFetchState,
} = orderBookSlice.actions;

export default orderBookSlice.reducer;

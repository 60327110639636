import React from "react";
import { ContentLayout } from "../layout/ContentLayout";
import { LoginPage } from "./login/LoginPage";
import { PageRouterSwitch } from "./navigation/PageRouterSwitch";
import { useAuth } from "../data/auth/hooks";
import { ThemeComponent } from "../layout/Theme";
import { SnackbarMessages } from "./snackbar/SnackbarMessages";
import { NotificationContextProvider } from "../core/notifications/NotificationContext";
import { appConfig } from "../core/config/config";
import { canRecieveNotifications } from "../core/notifications/hooks";
import { IdleTimerContainer } from "../layout/UserAvatar";


export const ContentRoot: React.FunctionComponent = props => {
	const [auth] = useAuth();
	return (
		<ThemeComponent>
			<SnackbarMessages>
				{auth.status === "successful" ? (
					<ContentLayout>
						<NotificationContextProvider
							hubUrl={appConfig.notifications.uri}
							token={auth.user.token!}
							//							connect={canRecieveNotifications(auth.user)}
							//							connect={false}
							connect={true}
						>
							<IdleTimerContainer></IdleTimerContainer>
							<PageRouterSwitch />
						</NotificationContextProvider>
					</ContentLayout>
				) : (
					<LoginPage />
				)}
			</SnackbarMessages>
		</ThemeComponent>
	);
};

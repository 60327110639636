import { ThunkType } from "../../../../core/store/reducers";
import getPortfolioDataApi from "../api/getPortfolioData";
import { PageDataStatus, PageData } from "../../../models";
import { PortfolioData, PortfolioFilter } from "../types";

export const getPortfolioThunk = (
	pageNumber?: number,
	itemsPerPage?: number,
	filter?: PortfolioFilter,
): ThunkType<Promise<PageData<PortfolioData> | undefined>> => async (dispatch, getStore) => {
	const store = getStore();

	console.log("U getoirtfolioThunk")

	if (store.data.auth.current.status !== "successful") {
		return undefined;
	}

	const user = store.data.auth.current.user;
	const portfolioResponse = await dispatch(getPortfolioDataApi(user, pageNumber, itemsPerPage, filter ));

	if (!portfolioResponse || portfolioResponse.resCode !== "OK") {
		return undefined;
	}

	return {
		currentPageData: portfolioResponse.obj,
		totalCount: Number(portfolioResponse.resDesc) || 0,
		status: PageDataStatus.DataCurrent,
	};
};

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { successfulLogin } from "../auth/authDataSlice";
import { UserMenuItem, OrderStatus, Exchanges, SecurityAccounts, MoneyAccounts, OrderTypes, TifList, Currencies,MAccCur,} from "../models";
import { SecuritySimpleList }  from "./types";


interface IInitialDataState {
	userMenu: UserMenuItem[];
	orderStatuses: OrderStatus[];
	exchanges: Exchanges[];
	securityForSelect?: SecuritySimpleList[] | "loading";
	securityFForSelect?: SecuritySimpleList[] | "loading";
	securityAccounts: SecurityAccounts [];
	moneyAccounts:MoneyAccounts [];
	orderTypes: OrderTypes [];
	tifList: TifList [];
	currencies: Currencies[];
	mAccCurr: MAccCur[];
  }

const initialState: IInitialDataState = {
	userMenu: [],
	orderStatuses: [],
	exchanges: [],
	securityAccounts: [],
	moneyAccounts: [],
	orderTypes: [],
	tifList: [],
	currencies: [],
	mAccCurr: [],
  };

const name = "initialData";

const initialDataSlice = createSlice({
	name,
	initialState,
	reducers: {
		setSecurityForSelect: (state, action: PayloadAction<SecuritySimpleList[]>) => ({
			...state,
			securityForSelect: action.payload,
		}),
		setSecurityFForSelect: (state, action: PayloadAction<SecuritySimpleList[]>) => ({
			...state,
			securityFForSelect: action.payload,
		}),
	},
	extraReducers: builder => {
		builder.addCase(successfulLogin, (state, action) => ({
			...state,
			userMenu: action.payload.userMenu,
			orderStatuses: action.payload.orderStatuses,
			exchanges: action.payload.exchanges,
			securityAccounts: action.payload.securityAccounts ,
			moneyAccounts: action.payload.moneyAccounts,
			orderTypes: action.payload.orderTypes ,
			tifList: action.payload.tifList,
			currencies: action.payload.currencies,
			mAccCurr: action.payload.mAccCurr,
		}));
	},
});

export const {
	setSecurityForSelect,
	setSecurityFForSelect,
} = initialDataSlice.actions;

export default initialDataSlice.reducer;

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { ChartPeriod } from "../types";
import { setGraphPeriod } from "../tickerDataSlice";

export const useGraphPeriod = (graphId: string) => {
	const dispatch = useDispatch();
	const period = useSelector<StoreType, ChartPeriod>(s => s.data.foreign.ticker.graphPeriod[graphId || ""]);

	const dispatchSetPeriod = React.useCallback(
		(newPeriod: ChartPeriod) => {
			if (graphId) {
				dispatch(
					setGraphPeriod({
						graphId,
						data: newPeriod,
					})
				);
			}
		},
		[dispatch, graphId]
	);

	return [period || ChartPeriod.Month, dispatchSetPeriod] as [typeof period, typeof dispatchSetPeriod];
};

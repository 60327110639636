import { MarketListData } from "../../../data/domestic/mainBoard/types";
import React from "react";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Column } from "material-table";
import { StoreType } from "../../../core/store/reducers";
import { useSelector } from "react-redux";
import MarketDataTable from "./MarketDataTable";

export const useMarketDataColumns = (symbol?: string) => {
	const { getString, getFormatedNumberString } = React.useContext(LocalizationContext);

/*	const security = useSelector<StoreType, string | undefined>(
		store => store.data.domestic.marketData.secid
	);    */

	console.log(symbol)

	return React.useMemo(
		() => [
			{
				title: getString("marketDataSymbol"),
				field: "symbol",
				sorting: false,
			},
			{
				title: getString("marketDataIsin"),
				field: "isin",
				sorting: false,
			},
			{
				title: getString("marketDataPrice"),
				render: marketdata => getFormatedNumberString(marketdata.price,true) ,
				sorting: false,
				cellStyle: {
					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
				},
				headerStyle: {
//					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
				},
			},
			{
				title: getString("marketDataVolume"),
				render: marketdata => getFormatedNumberString(marketdata.volume) ,
				sorting: false,
				cellStyle: {
					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
				},
				headerStyle: {
//					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
				},
			},
			{
				title: getString("marketDataSumBid"),
				render: marketdata => getFormatedNumberString(marketdata.sumBid) ,
				sorting: false,
				cellStyle: {
					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
//					background: e => symbol===e.sy ? "#DAF7A6": "#e1f5fe", 
				},
				headerStyle: {
//					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
//					background: "#e1f5fe",
				},
			},
			{
				title: getString("marketDataBestBidQty"),
				render: marketdata => getFormatedNumberString(marketdata.bestBidQty) ,
				sorting: false,
				cellStyle: {
					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
//					background: symbol ? "#DAF7A6": "#e1f5fe", 
				},
				headerStyle: {
//					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
//					background: "#e1f5fe",
				},
			},
			{
				title: getString("marketDataBestBid"),
				render: marketdata => getFormatedNumberString(marketdata.bestBid,true) ,
				sorting: false,
				cellStyle: {
					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
//					background: symbol ? "#DAF7A6": "#e1f5fe", 
				},
				headerStyle: {
//					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
//					background: "#e1f5fe",
				},
			},
			{
				title: getString("marketDataBestAsk"),
				render: marketdata => getFormatedNumberString(marketdata.bestAsk,true) ,
				sorting: false,
				cellStyle: {
					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
//					background: "#ffebee",
				},
				headerStyle: {
//					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
//					background: "#ffebee",
				},
			},
			{
				title: getString("marketDataBestAskQty"),
				render: marketdata => getFormatedNumberString(marketdata.bestAskQty) ,
				sorting: false,
				cellStyle: {
					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
//					background: "#ffebee",
				},
				headerStyle: {
//					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
//					background: "#ffebee",
				},
			},
			{
				title: getString("marketDataSumAsk"),
				render: marketdata => getFormatedNumberString(marketdata.sumAsk) ,
				sorting: false,
				cellStyle: {
					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
//					background: "#ffebee",
				},
				headerStyle: {
//					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
//					background: "#ffebee",
				},
			},
			{
				title: getString("marketDataOpenPrice"),
				render: marketdata => getFormatedNumberString(marketdata.open,true) ,
				sorting: false,
				cellStyle: {
					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
				},
				headerStyle: {
//					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
				},
			},
			{
				title: getString("marketDataHighPrice"),
				render: marketdata => getFormatedNumberString(marketdata.high,true) ,
				sorting: false,
				cellStyle: {
					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
				},
				headerStyle: {
//					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
				},
			},
			{
				title: getString("marketDataLowPrice"),
				render: marketdata => getFormatedNumberString(marketdata.low,true) ,
				sorting: false,
				cellStyle: {
					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
				},
				headerStyle: {
//					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
				},
			},
			{
				title: getString("marketDataClosePrice"),
				render: marketdata => getFormatedNumberString(marketdata.close,true) ,
				sorting: false,
				cellStyle: {
					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
				},
				headerStyle: {
//					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
				},
			},
			{
				title: getString("marketDataTrend"),
				render: marketdata => getFormatedNumberString(marketdata.trend) ,
				sorting: false,
				cellStyle: {
					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
				},
				headerStyle: {
//					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
				},
			},
			{
				title: getString("marketDataChange"),
				render: marketdata => getFormatedNumberString(marketdata.change) ,
				sorting: false,
				cellStyle: {
					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
				},
				headerStyle: {
//					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
				},
			},
			{
				title: getString("marketDataTradingPhase"),
				field: "tradingPhase",
				sorting: false,
			},
		],
		[getString,  getFormatedNumberString]
	) as Column<MarketListData>[];
};

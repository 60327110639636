import { FMarketListData } from "../../../data/foreign/mainBoard/types";
import React from "react";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Column } from "material-table";
import { StoreType } from "../../../core/store/reducers";
import { useSelector } from "react-redux";
import MarketDataTable from "./MarketDataTable";

export const useMarketDataColumns = (symbol?: string) => {
	const { getString, getFormatedNumberString, getDateString } = React.useContext(LocalizationContext);

/*	const security = useSelector<StoreType, string | undefined>(
		store => store.data.foreign.marketData.secid
	);    */

	const now = new Date();


	return React.useMemo(
		() => [
			{
				title: getString("tradeExchange"),
				field: "exchange",
				sorting: false,
				width: "100px",
			},
			{
				title: getString("marketDataSymbol"),
				field: "symbol",
				sorting: false,
				width: "120px",
			},
			{/*
				title: getString("marketDataIsin"),
				field: "isin",
				sorting: false,
			*/},
			{
				title: getString("marketDataPrice"),
				render: marketdata => getFormatedNumberString(marketdata.price,true) ,
				sorting: false,
				cellStyle: {
					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
				},
				headerStyle: {
//					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
				},
			},
			{
				title: getString("marketDataVolume"),
				render: marketdata => getFormatedNumberString(marketdata.volume) ,
				sorting: false,
				cellStyle: {
					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
				},
				headerStyle: {
//					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
				},
			},
			{
				title: getString("marketDataBestBid"),
				render: marketdata => getFormatedNumberString(marketdata.bestBid,true) ,
				sorting: false,
				cellStyle: {
					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
//					background: symbol ? "#DAF7A6": "#e1f5fe", 
				},
				headerStyle: {
//					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
//					background: "#e1f5fe",
				},
			},
			{
				title: getString("marketDataBestAsk"),
				render: marketdata => getFormatedNumberString(marketdata.bestAsk,true) ,
				sorting: false,
				cellStyle: {
					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
//					background: "#ffebee",
				},
				headerStyle: {
//					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
//					background: "#ffebee",
				},
			},  
			{
				title: getString("marketDataOpenPrice"),
				render: marketdata => getFormatedNumberString(marketdata.open,true) ,
				sorting: false,
				cellStyle: {
					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
				},
				headerStyle: {
//					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
				},
			},
			{
				title: getString("marketDataHighPrice"),
				render: marketdata => getFormatedNumberString(marketdata.high,true) ,
				sorting: false,
				cellStyle: {
					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
				},
				headerStyle: {
//					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
				},
			},
			{
				title: getString("marketDataLowPrice"),
				render: marketdata => getFormatedNumberString(marketdata.low,true) ,
				sorting: false,
				cellStyle: {
					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
				},
				headerStyle: {
//					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
				},
			},
			{
				title: getString("marketDataClosePrice"),
				render: marketdata => getFormatedNumberString(marketdata.close,true) ,
				sorting: false,
				cellStyle: {
					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
				},
				headerStyle: {
//					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
				},
			},
			{
				title: getString("marketDataChange"),
				render: marketdata => getFormatedNumberString(marketdata.change,true) ,
				sorting: false,
				cellStyle: {
					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
				},
				headerStyle: {
//					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
				},
			},
			{
				title: getString("marketTime"),
//				render: (order) => getDateString(now , "HH.mm.ss"),
				field: "latestTime",
				sorting: false,
				width: "200px",
			},
			{
				title: getString("marketSource"),
//				render: (order) => getDateString(now , "HH.mm.ss"),
				field: "latestSource",
				sorting: false,
				width: "200px",
			},
		],
		[getString,  getFormatedNumberString]
	) as Column<FMarketListData>[];
};

import { MarketTrade } from "../../../data/domestic/mainBoard/types";
import React from "react";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Column } from "material-table";

export const useMarketTradeColumns = () => {
	const { getString, getFormatedNumberString, getDateString } = React.useContext(LocalizationContext);

	return React.useMemo(
		() => [
			{
				title: getString("marketDataTradeTime"),
				field: "time",
				sorting: false,
				width: "100px",
				cellStyle: {
					maxWidth: "100px",
					minWidth: "100px",
					width: "100px",
				},
				headerStyle: {
					maxWidth: "100px",
					minWidth: "100px",
					width: "100px",
				},
			},
			{
				title: getString("marketDataVolume"),
				render: markettrade => getFormatedNumberString(markettrade.qty,true) ,
				sorting: false,
				cellStyle: {
					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
				},
				headerStyle: {
//					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
				},
			},
			{
				title: getString("marketDataPrice"),
				render: markettrade => getFormatedNumberString(markettrade.price) ,
				sorting: false,
				cellStyle: {
					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
				},
				headerStyle: {
//					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
				},
			},
		],
		[getString,  getFormatedNumberString]
	) as Column<MarketTrade>[];
};

import React from "react";
import { useDisplayMarketData } from "../../../data/foreign/mainBoard/hooks/useDisplayMarketData";
import { DisplayDrawerContext } from "../../../layout/DisplayDrawerContext";
import { FMarketListData } from "../../../data/foreign/mainBoard/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { DisplayMarketDataMainAttributes, DiplayMarketDataMod } from "./DisplayMarketDataMainAttributes";
import { ExpansionPanel, ExpansionPanelSummary, Typography, Grid, IconButton } from "@material-ui/core";
import { useOrderPrinter } from "../../../data/foreign/orders/hooks/useOrderPrinter";
import { CustomTooltip } from "../../components/common/CustomTooltip";
import DownloadIcon from "@material-ui/icons/CloudDownloadOutlined";

export interface DisplayMDProps {
	id: string;
	displayMod: DiplayMarketDataMod;
	enablePrint?: boolean;
}

export const DisplayMarketData: React.FunctionComponent<DisplayMDProps> = ({
	id,
	displayMod,
	enablePrint,
}) => {

console.log(id)
console.log(displayMod)

	const marketdata = useDisplayMarketData(id);
	const { getString } = React.useContext(LocalizationContext);
//	const [print, fetchState] = useOrderPrinter(marketdata);

console.log(marketdata)

	return !marketdata ? (
		<>{"loading placeholder"}</>
	) : (
		<Grid container justify="center" direction="column">
			<ExpansionPanel expanded>
				<ExpansionPanelSummary>
					<Grid container justify="space-between">
						<Grid item>
							<Typography>{getString("tableBasciInfo")}</Typography>
						</Grid>
						<Grid item>
					{/* fetchState.type !== "not-started"  ? getString("pleaseWait") : null */}
				</Grid>
					</Grid>
				</ExpansionPanelSummary>
				<DisplayMarketDataMainAttributes marketdata={marketdata} mod={displayMod} />
			</ExpansionPanel>
		</Grid>
	);
};

export const useViewMarketData = (displayMod: DiplayMarketDataMod, enablePrint?: boolean) => {
	const showWithDrawer = React.useContext(DisplayDrawerContext);
	return React.useCallback(
		(marketdata: FMarketListData) => showWithDrawer(<DisplayMarketData id={marketdata.securityId} displayMod={displayMod} enablePrint={enablePrint} />),
		[showWithDrawer, displayMod]
	);
};


import { useDispatch, useSelector } from "react-redux";
import { downloadPortfolioReport } from "../thunks/downloadReport";
import React from "react";
import { StoreType } from "../../../../core/store/reducers";
import { FetchStateType } from "../../../models";

export const usePortfolioReportDownloader = () => {
	const fetchState = useSelector<StoreType, FetchStateType>(
		s => s.data.domestic.portfolio.downloadReportFetchState
	);
	const dispatch = useDispatch();
	const download = React.useCallback(() => dispatch(downloadPortfolioReport()), [dispatch]);

	return [download, fetchState] as [typeof download, typeof fetchState]
};

import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { loadCashBalanceData } from "../thunks/loadCashBalanceData";
import { CashBalanceData } from "../types";

export const useCashBalanceData = () => {
  const allData = useSelector<StoreType, CashBalanceData[] | undefined>(
    (s) => s.data.reports.cashBalList.all
  );

  const dispatch = useDispatch();
  const dispatchLoadCashBalanceAll = React.useCallback(
    () => dispatch(loadCashBalanceData()),
    [dispatch]
  );

  return [allData, dispatchLoadCashBalanceAll] as [
    typeof allData,
    typeof dispatchLoadCashBalanceAll
  ];
};


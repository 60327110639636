import { ThunkType } from "../../../../core/store/reducers";
import getSumTradeApi from "../api/getSumTrade";
import { setDisplayed } from "../../../domestic/trades/tradesDataSlice";
import { Trades } from "../../../domestic/trades/types";

export const loadSumTrade = (tradeId: string): ThunkType => async (
  dispatch,
  getStore
) => {
  dispatch(setDisplayed());

  const trade = await dispatch(getTrade(tradeId));

  dispatch(setDisplayed(trade));
};

export const getTrade = (
  tradeId: string
): ThunkType<Promise<Trades | undefined>> => async (dispatch, getStore) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return undefined;
  }

  const { userId } = store.data.auth.current.user;
  return await dispatch(getSumTradeApi(userId, tradeId));
};

import { webApi } from "../../../core/http/httpThunks";
import { SecuritySimpleList, SecuritySimpleFilter } from "../types";

export const getSecurityFForSelect = (userId: string, df: string, filter?: SecuritySimpleFilter) =>
	webApi<SecuritySimpleList[]>({
		method: "GET",
		url: "common/getsecsimplelist",
		params: {
			userId,
			df,
			exchid: filter?.exchId,
			forMD: filter?.forMD,
		},
	});

export default getSecurityFForSelect;

import React from "react";
import { Table, TableDataProps } from "../../components/common/Table";
import { useMarketData } from "../../../data/domestic/mainBoard/hooks/useMarketData";
import { MarketDepth } from "../../../data/domestic/mainBoard/types";
import { useMarketDepthColumns } from "./useMarketDepthColumns";
import { AccessRights } from "../../../core/navigation/types";

export interface MarketDataTableProps {
  userAccessRights: AccessRights;
}

type MarketDataTablePropsType = MarketDataTableProps & TableDataProps<MarketDepth>;

export const MarketDepthDataTable: React.FunctionComponent<MarketDataTablePropsType> = ({
  userAccessRights,
  ...tableProps
}) => {
  const [data, loadAll, all, marketDepthData] = useMarketData();

  const columns = useMarketDepthColumns();

  return (
    <Table<MarketDepth>
      {...tableProps}
      getData={loadAll}
      immutableData={marketDepthData}
      title=""
      columns={columns}
      options={{
        toolbar: false,
        pageSize: 10,
        actionsColumnIndex: -1,
      }}
    />
  );
};

export default MarketDepthDataTable;

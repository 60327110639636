import React from "react";
import FilterIcon from "@material-ui/icons/FilterListOutlined";
import { IconButton, Grid } from "@material-ui/core";
import { DisplayDrawerContext } from "../../../layout/DisplayDrawerContext";
import { usePortfolioFilter, useCurrentPortfolioFilter,} from "../../../data/domestic/portfolio/hooks/usePortfolioFilter";
import { PortfolioFilterEditor, PortfolioFilterChips } from "./EditPortfolioFilter";
import { CustomTooltip } from "../../components/common/CustomTooltip";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

export const PortfolioFilterButton: React.FunctionComponent = () => {
	const showWithDrawer = React.useContext(DisplayDrawerContext);
	const showFilter = React.useCallback(() => showWithDrawer(<PortfolioDataFilterEditor />), [showWithDrawer]);

	const theme = useTheme();
	const screenExtraSmall = useMediaQuery(theme.breakpoints.only('xs'));

	const [filter, removeFilter] = useCurrentPortfolioFilter();
	
	return (
		<Grid container alignItems="center" spacing={2}>
			<Grid item>
				<PortfolioFilterChips filter={filter} removeFilter={removeFilter} />
			</Grid>
			<Grid item>
				<CustomTooltip titleKeyword="filterTooltip" >
					<IconButton color={!screenExtraSmall || Object.keys(filter).length===0 ? "secondary" : "primary" } component="span" onClick={showFilter}>
						<FilterIcon />
					</IconButton>
				</CustomTooltip>
			</Grid>
		</Grid>
	);
};


const PortfolioDataFilterEditor: React.FunctionComponent = () => {
	const [filter, editFilter, save, , reset] = usePortfolioFilter();
	return (
		<PortfolioFilterEditor
			filter={filter}
			editFilter={editFilter}
			save={save}
			reset={reset}
		/>
	);
};

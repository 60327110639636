import { domesticComponents } from "../domestic/domesticNavigationItems";
import { foreignComponents } from "../foreign/foreignNavigationItems";
import { settingsComponents } from "../settings/settingsNavigationItems";
import { reportsComponents } from "../reports/reportsNavigationItems";
import { supportComponents } from "../support/supportNavigationItems"

export const appComponents = [
    ...domesticComponents,
    ...foreignComponents,
    ...settingsComponents,
    ...reportsComponents,
    ...supportComponents,
]
import { TradePricesChartData, ChartPeriod } from "../types";
import { User } from "../../../auth/types";
import { webApi } from "../../../../core/http/httpThunks";

export const getfDayChartData = (user: User, secid: string, date: string) => {
	return webApi<TradePricesChartData[]>({
		method: "GET",
		url: "trade/getfdaychartdata",
		params: {
			userid: user.userId,
			secid,
			date,
		},
	});
};

import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { loadPortfolioData } from "../thunks/loadPortfolioData";
import { PortfolioData } from "../../../domestic/portfolio/types";
import { PageData } from "../../../models";
import { invalidatePortfolio } from "../../../domestic/portfolio/portfolioDataSlice";

export const usePortfolioData = () => {
  const pageData = useSelector<StoreType, PageData<PortfolioData> | undefined>(
    (s) => s.data.domestic.portfolio.currentPage
  );

  const dispatch = useDispatch();
  const dispatchLoadMarketData = React.useCallback(
    () => dispatch(loadPortfolioData()),
    [dispatch ]
  );

  const dispatchInvalidatePortfolio = React.useCallback(
    () => dispatch(invalidatePortfolio()),
    [dispatch]
  );

  return [pageData, dispatchLoadMarketData, dispatchInvalidatePortfolio] as [
    typeof pageData,
    typeof dispatchLoadMarketData,
    typeof dispatchInvalidatePortfolio,
  ];
};

import React from "react";
import { DomForSide } from "../../../models";
import { LocalizationContext } from "../../../../core/localization/Localization";
import { exhaustiveCheck } from "../../../../core/util/functions";

export const useDomForSideOptionsTranslator = () => {
	const { getString } = React.useContext(LocalizationContext);
	return React.useCallback(
		(side: DomForSide) => {
			switch (side) {
				case DomForSide.Domestic:
					return getString("domForSideDomestic");
				case DomForSide.Foreign:
					return getString("domforSideForeign");
				default:
					exhaustiveCheck(side);
			}
		},
		[getString]
	);
};

interface AccountDomForSideOption {
	label: string;
	value: DomForSide;
}

export const useDomForSideOptions = (): AccountDomForSideOption[] => {
	const translator = useDomForSideOptionsTranslator();
	return React.useMemo(
		() => [
			{
				label: translator(DomForSide.Domestic) as string,
				value: DomForSide.Domestic,
			},
			{
				label: translator(DomForSide.Foreign) as string,
				value: DomForSide.Foreign,
			},
		],
		[translator]
	);
};

import React, { useContext, useMemo } from "react";
import ViewIcon from "@material-ui/icons/InputOutlined";
import CancelIcon from '@material-ui/icons/CancelOutlined';
import MofifyIcoon from '@material-ui/icons/EditOutlined';
import { ViewOrder, OrdersEditAction } from "../../../data/domestic/orders/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { MaterialTableProps } from "material-table";
import { useViewOrder } from "./DisplayOrder";
import { DiplayOrderMod } from "./DisplayOrderMainAttributes";
import { useShowCancelOrder } from "./CancelOrder";
import { useHistory } from "react-router-dom";
import { getOrderBookFEditLocation, OrdersEditParts } from "./Locations";
import { useSetOrderStatus }  from "../../../data/foreign/orders/hooks/useSetOrderStatus";

export function useOrderBookActionsBroker() {
  const { getString } = useContext(LocalizationContext);
  const onShowOBOrder = useViewOrder(DiplayOrderMod.Full, true);
  const onCancelOrder = useShowCancelOrder();
  const onModifyOrder = useEditOrder(OrdersEditAction.Modify);
  const onCheckBOrder = useSetOrderStatus();

  return useMemo(() => {
    const result: MaterialTableProps<ViewOrder>["actions"] = [
      {
        icon: () => <ViewIcon />,
        tooltip: getString("orderActView"),
        onClick: (event, rowData) => onShowOBOrder(rowData as ViewOrder),
      },
      {
        icon: () => <MofifyIcoon />,
        tooltip: getString("orderStatusChange"),
        onClick: (event, rowData) => onCheckBOrder(rowData as ViewOrder),
      },
    ];
    return result;
  }, [onShowOBOrder,onCancelOrder,onModifyOrder, getString ]);
}

export const useEditOrder = (action: OrdersEditAction) => {
  const history = useHistory();
	return React.useCallback(
		(order: ViewOrder) =>
			history.push(
				getOrderBookFEditLocation(action, OrdersEditParts.BasicInfo, order.orderId)
			),
		[history, action]
	);
};


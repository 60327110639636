import React from "react";
import { TextField, TextFieldProps } from "@material-ui/core";
import { Autocomplete, Skeleton } from "@material-ui/lab";
import { LocalizationContext } from "../../../core/localization/Localization";
//import  { createFilterOptions } from '@material-ui/lab/Autocomplete';

interface AutocompleteSelectProps<T> {
	selected?: T | null;
	onChangeItem: (newValue: T | null) => void;
	predicate?: (value: T) => boolean;
	dataFilter?: (value: T) => boolean;
	data?: T[];
	getLabel: (data: T) => string;
	errorMessage?: string;
//	filterOptions?: T | null ;
}

export type AutocompleteSelectType<T> = AutocompleteSelectProps<T> & TextFieldProps;

export function AutocompleteSelect<T>({
	selected,
	onChangeItem,
	predicate,
	data,
	getLabel,
	error,
	errorMessage,
	disabled,
	dataFilter,
	...otherProps
}: AutocompleteSelectType<T>) {

	
	const { getString } = React.useContext(LocalizationContext);

	const handleCityChange = React.useCallback(
		(event: any, newValue: T | null) => {
			if (onChangeItem) {
				onChangeItem(newValue);
			}
		},
		[onChangeItem]
	);


/*	const OPTIONS_LIMIT = 10;
	const defaultFilterOptions = createFilterOptions();

	const filterOptions = (options, state) => {
  		return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
	};   */


	const helperText = React.useMemo(
		() => (error ? errorMessage || getString("requiredMessage") : undefined),
		[getString, error, errorMessage]
	);

	const filtredData = React.useMemo(() => {
		return (dataFilter && data && data.filter(dataFilter)) || data;
	}, [dataFilter, data]);

	const selectedItem = React.useMemo(() => {
		return selected || (predicate && filtredData && filtredData.find(predicate)) || null;
	}, [selected, predicate, filtredData]);


	if (!filtredData) {
		return <Skeleton variant="text" width={"100%"} />;
	}


//	const ELEMENT_TO_SHOW = 10;

	return (
		<Autocomplete<T>
			options={filtredData}
//			options={filtredData.filter((el, i) => {  if (i < ELEMENT_TO_SHOW) return el })}
			getOptionLabel={getLabel}
			value={selectedItem}
			onChange={handleCityChange}
			disabled={disabled}
//			filterOptions={filterOptions}
			renderInput={params => (
				<TextField {...params} error={error} helperText={helperText} {...otherProps} />
			)}
		/>
	);
}

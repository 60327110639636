import React from "react";
import { useDispatch } from "react-redux";
import { SecurityAccounts } from "../../../models";
import { setSecurityAcc } from "../securityAccountsDataSlice";
import { loadSetDefSecacc } from "../thunks/loadSetDefSecacc";

export const useSetDefSeccAcc = () => {
    
    const dispatch = useDispatch();
	return React.useCallback((defAccount: SecurityAccounts) => {
        console.log(defAccount.accNo)

        dispatch(setSecurityAcc(defAccount.accId));
        dispatch(loadSetDefSecacc());
        },
        [dispatch]
    );
};



import { ThunkType } from "../../../../core/store/reducers";
import { otherSettingsApi } from "../api/otherSettingsApi";
import { setConfirmOrderFatchState  } from "../otherSettingsDataSlice";

export const otherSettingsThunk = (
	confirmOrder: number,
): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return;
	}

	dispatch(setConfirmOrderFatchState({ type: "started" }));

	const userid = store.data.auth.current.user.userId
	
	const result = await dispatch(otherSettingsApi(userid, confirmOrder));

	dispatch(setConfirmOrderFatchState(
		result !== undefined && result.resCode === "OK"
			? { type: "successful" }
			: { type: "error", errorCode: result && result.resCode, errorDesc: result && result.resDesc }
	));



};

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PageIndex  } from "../../models";
import { successfulLogin } from "../../auth/authDataSlice";
import {  OrderFilter, MoneyAccFilter } from "./types";
import moment from "moment";

type OrderPageState = {
	index: PageIndex;
	filter: OrderFilter;
	filterMoney: MoneyAccFilter;
};

export const initialState: OrderPageState = {
	index: {
		itemsPerPage: 20,
		pageNumber: 0,
	},
	filter: {
		dateFrom: moment().add(-180, "day").toString()
	},
	filterMoney: {
		accSide: "B",
		curId: "00000001",
	},
};

const name = "orderBook";

const orderBookSlice = createSlice({
	name,
	initialState,
	reducers: {
		setOrdersIndex: (state, action: PayloadAction<PageIndex>) => ({
			...state,
			index: action.payload,
		}),
		resetOrderIdices: state => ({
			...state,
			index: {
				itemsPerPage: state.index.itemsPerPage,
				pageNumber: initialState.index.pageNumber,
			},
		}),
		setFilter: (state, action: PayloadAction<OrderFilter>) => ({
			...state,
			filter: action.payload,
		}),
		setFilterMoney: (state, action: PayloadAction<MoneyAccFilter>) => ({
			...state,
			filterMoney: action.payload,
		}), 
		removeFilter: (state, action: PayloadAction<keyof OrderFilter>) => ({
			...state,
			filter: {
				...state.filter,
				[action.payload]: undefined,
			},
		}),
		resetFilter: state => ({
			...state,
			filter: {
				...initialState.filter,
			},
		}),
	},
	extraReducers: builder => {
		builder.addCase(successfulLogin, state => ({
			...state,
			index: {
				itemsPerPage: state.index.itemsPerPage,
				pageNumber: initialState.index.pageNumber,
			},
		}));
	},
});

export const { setOrdersIndex, resetOrderIdices, setFilter, setFilterMoney, removeFilter, resetFilter } = orderBookSlice.actions;

export default orderBookSlice.reducer;

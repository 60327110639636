import React from "react";
import { LocalizationContext } from "../../../core/localization/Localization";
import { useOnExchangeOptions } from "../../../data/initialData/hooks/useOnExchangeOption";
import { TextField, MenuItem } from "@material-ui/core";
import { OnExchange } from "../../../data/models";

interface OnExchangesSelectProps {
  onExchange?: OnExchange;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  nullable?: boolean;
}

export const OnExchangeSelect: React.FunctionComponent<OnExchangesSelectProps> = ({
  onExchange,
  handleChange,
  disabled,
  nullable,
}) => {
  const { getString } = React.useContext(LocalizationContext);
  const options = useOnExchangeOptions();
  const enchanchedOptions = React.useMemo(() => {
    return !nullable
      ? options
      : [
          {
            label: "--",
            value: "",
          },
          ...options,
        ];
  }, [nullable, options]);

  return (
    <TextField
      name="nondedicated"
      value={onExchange?.toUpperCase() || ""}
      onChange={handleChange}
      fullWidth
      select
      label={getString("portfOnExchYes")}
      disabled={disabled}
    >
      {enchanchedOptions.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

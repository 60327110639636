import React from "react";
import { Table, TableDataProps } from "../../components/common/Table";
import { useMarketData } from "../../../data/domestic/mainBoard/hooks/useMarketData";
import { MarketTrade } from "../../../data/domestic/mainBoard/types";
import { useMarketTradeColumns } from "./useMarketTradeColumns";
import { AccessRights } from "../../../core/navigation/types";

export interface MarketTradeTableProps {
  userAccessRights: AccessRights;
}

type MarketTradeTablePropsType = MarketTradeTableProps & TableDataProps<MarketTrade>;

export const MarketTradeDataTable: React.FunctionComponent<MarketTradeTablePropsType> = ({
  userAccessRights,
  ...tableProps
}) => {
  const [data, loadAll, all, marketDepthData, marketTradeData] = useMarketData();

  const columns = useMarketTradeColumns();

  return (
    <Table<MarketTrade>
      {...tableProps}
      getData={loadAll}
      immutableData={marketTradeData}
      title=""
      columns={columns}
      options={{
        toolbar: false,
        pageSize: 10,
        actionsColumnIndex: -1,
      }}
    /> 
  );
};

export default MarketTradeDataTable;

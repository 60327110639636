import React, { useState, useContext } from "react";
import { Grid, CardContent, CardHeader, Card, Divider, TextField, Button } from "@material-ui/core";
import { useLocalizationStore } from "../../../data/localization/hooks";
import { LocalizationContext } from "../../../core/localization/Localization";
import { useLoginStyles } from "./useLoginStyles";
import { useChangePassHooks } from "../../../data/settings/passwordchange/hooks/changePassHooks";
import { CustomDialog } from "../../components/common/CustomDialog";
import { usePageStyles } from "../../pageStyles";
import { PageTitle } from "../../components/common/PageTitle";

export const PasswordChangeForm: React.FunctionComponent = props => {
	const styles = useLoginStyles();
	const pageStyles = usePageStyles();

	const { getString } = useContext(LocalizationContext);
	const [password, setPassword] = useState<string>("");
	const [newpassword, setNewPassword] = useState<string>("");
	const [newpassword1, setNewPassword1] = useState<string>("");
	const [fatchstate, setNewPass, reset, errorDesc ] = useChangePassHooks(password, newpassword);

	const handleTBChange = (setter: (value: string) => void) => (event: React.ChangeEvent<HTMLInputElement>) =>
	setter(event.target.value);

	const handleTBChange1 = (setter: (value: string) => void) => (event: React.ChangeEvent<HTMLInputElement>) =>
	setter(event.target.value);

	const savePassword = React.useCallback(
		async () => {
				const result = setNewPass(password, newpassword);

				console.log(result)

//				const result = await setNewPass(password, newpassword);
/*				if (result && result.resCode !== "OK") {
					const messageError = result. 
				} else 
				{
					const messageError = "";
				}    */
			 
			},
		[setNewPass]
	); 

	const onEnter = (action: () => void) => (e: React.KeyboardEvent<HTMLDivElement>) =>
	e.key === "Enter" && action();

//	const messageError = (fatchstate.type === "error" && fatchstate.errorCode ===  tate.errorDesc) ? fatchstate.result : ""

	return (
		<>
		<Card>
				<CardHeader
							title={<PageTitle title="settingsPasswordChangeMenu" />}
						/>
						<Divider />
						<CardContent>
		<Grid container className={pageStyles.root} direction="column" justify="center" alignItems="center" >


			<Grid item xs={12} sm={6} md={3} className={styles.formItem}>
				<TextField
					className={styles.textField}
					label={getString("oldpassword")}
					type="password"
					value={password}
					onKeyDown={onEnter(savePassword)}
					onChange={handleTBChange(setPassword)}
				/>
			</Grid>
			<Grid item xs={12} sm={6} md={3} className={styles.formItem}>
				<TextField
					className={styles.textField}
					label={getString("newpassword")}
					type="password"
					value={newpassword}
					onKeyDown={onEnter(savePassword)}
					onChange={handleTBChange(setNewPassword)}
				/>
			</Grid>
			<Grid item xs={12} sm={6} md={3} className={styles.formItem}>
				<TextField
					className={styles.textField}
					label={getString("newpasswordverify")}
					type="password"
					value={newpassword1}
					onKeyDown={onEnter(savePassword)}
					onChange={handleTBChange1(setNewPassword1)}
				/>
			</Grid>
			<Grid item className={styles.formItem}>
				<Button variant="contained" color="primary" disabled={newpassword!==newpassword1} onClick={savePassword}  >
					{getString("changePassword")}
				</Button>
			</Grid>
      
		</Grid>
		<CustomDialog 
		 	open={fatchstate.type === "error"}
			title={getString("errorTitle")}
			message={ errorDesc }
			closeLabel={getString("errorConfirm")}
			onClose={reset}
		/>
		<CustomDialog 
		 	open={fatchstate.type === "successful"}
			title={getString("errorSuccessTitle")}
			message={ getString("errorSuccess") }
			closeLabel={getString("errorConfirm")}
			onClose={reset}
		/>
								</CardContent>
					</Card>

		</>
	);
};

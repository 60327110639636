import { webApi } from "../../../../core/http/httpThunks";
import { CashBalanceData } from "../types";

export const getCashBalanceApi = (userId: string) =>
	webApi<CashBalanceData[]>({
		method: "GET",
		url: "trade/getcashballist",
		params: {
			userId,
		},
	});

export default getCashBalanceApi;



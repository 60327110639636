import React from "react";
import { Table, TableDataProps } from "../../components/common/Table";
import { useCashBalanceData } from "../../../data/reports/cashBalList/hooks/useCashBalanceData";
import { CashBalanceData } from "../../../data/reports/cashBalList/types";
import { useCashBalanceColumns } from "./useCashBalanceColumns";
import { AccessRights } from "../../../core/navigation/types";

export interface CashBalanceTableProps {
  userAccessRights: AccessRights;
}

type CashBalanceTablePropsType = CashBalanceTableProps & TableDataProps<CashBalanceData>;

export const CashBalanceListTable: React.FunctionComponent<CashBalanceTablePropsType> = ({
  userAccessRights,
  ...tableProps
}) => {
  const [data, loadAll] = useCashBalanceData();

  const columns = useCashBalanceColumns();
//  const actions = useBankActions();
//  const onDeleteBank = useDeleteBankAction();

  return (
    <Table<CashBalanceData>
      {...tableProps}
      getData={loadAll}
      immutableData={data}
      title=""
      columns={columns}
  //    actions={actions}
      options={{
        toolbar: false,
        pageSize: 10,
        actionsColumnIndex: -1,
      }}
    />
  );
};

export default CashBalanceListTable;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {  SecAccountFilter } from "./types";
import { DomForSide } from "../../models";

type SecAccountState = {
	filter: SecAccountFilter;
};

export const initialState: SecAccountState = {
	filter: {domfor: DomForSide.Domestic},
};

const name = "secAccounts";

const secAccountsSlice = createSlice({
	name,
	initialState,
	reducers: {
		setFilter: (state, action: PayloadAction<SecAccountFilter>) => ({
			...state,
			filter: action.payload,
		}),
		removeFilter: (state, action: PayloadAction<keyof SecAccountFilter>) => ({
			...state,
			filter: {
				...state.filter,
				[action.payload]: undefined,
			},
		}),
		resetFilter: state => ({
			...state,
			filter: {
				...initialState.filter,
			},
		}),
	},
});

export const {  setFilter, removeFilter, resetFilter } = secAccountsSlice.actions;

export default secAccountsSlice.reducer;

import { webApi } from "../../../core/http/httpThunks";
import { EmpContactData } from "../types";
import { User } from "../../auth/types";

const getSupportApi = (
  user: string,
) =>
{
 
return webApi<EmpContactData[]>({
    method: "GET",
    url: "common/getempcontacts",
    params:{
      userid: user,
    }
    });
};

export default getSupportApi;





import { PortfolioData } from "../../../data/domestic/portfolio/types";
import React from "react";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Column } from "material-table";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

export const usePortfolioColumns = () => {
	const { getString, getDateTimeString, getFormatedNumberString } = React.useContext(LocalizationContext);

	const theme = useTheme();
	const screenExtraSmall = useMediaQuery(theme.breakpoints.only('xs'));

	return React.useMemo(
		() => [
/*			{
				title: getString("portfDepositor"),
				field: "depositor",
				sorting: false,
				width: "100px",
				cellStyle: {
					maxWidth: "100px",
				},
				headerStyle: {
					maxWidth: "100px",
				},
			},
			{
				title: getString("portfSecAccType"),
				field: "secAccType",
				sorting: false,
				width: "100px",
				cellStyle: {
					maxWidth: "100px",
				},
				headerStyle: {
					maxWidth: "100px",
				},
			},  */
			{
				title: getString("portfSymbol"),
				field: "symbol",
				sorting: false,
				width: "100px",
				cellStyle: {
					maxWidth: "100px",
				},
				headerStyle: {
					maxWidth: "100px",
				},
			},
			{
				title: getString("portfSecName"),
				field: "secName",
				sorting: false,
				width: "300px",
				cellStyle: {
					maxWidth: "300px",
				},
				headerStyle: {
					maxWidth: "300px",
				},
			},
			{
				title: getString("portfIsin"),
				field: "isin",
				sorting: false,
				width: "100px",
				cellStyle: {
					maxWidth: "100px",
				},
				headerStyle: {
					maxWidth: "100px",
				},
				hidden: screenExtraSmall,
			},
			{
				title: getString("portfQty"),
				render: portfolio => getFormatedNumberString(portfolio.qty),
				sorting: false,
				cellStyle: {
					minWidth: "130px",
					whiteSpace: "nowrap",
					textAlign: "end",
				},
				headerStyle: {
					minWidth: "130px",
					textAlign: "end",
				},
			},
			{
				title: getString("portfPchPrice"),
				render: portfolio => getFormatedNumberString(portfolio.pchPrice, true),
				sorting: false,
				cellStyle: {
					whiteSpace: "nowrap",
					textAlign: "end",
				},
				headerStyle: {
					whiteSpace: "nowrap",
					textAlign: "end",
				},
				hidden: screenExtraSmall,
			},
			{
				title: getString("portfPchAmt"),
				render: portfolio => getFormatedNumberString(portfolio.pchAmt, true),
				sorting: false,
				cellStyle: {
					whiteSpace: "nowrap",
					textAlign: "end",
				},
				headerStyle: {
					whiteSpace: "nowrap",
					textAlign: "end",
				},
				hidden: screenExtraSmall,
			},
			{
				title: getString("portfMktPrice"),
				render: portfolio => getFormatedNumberString(portfolio.mktPrice, true),
				sorting: false,
				cellStyle: {
					whiteSpace: "nowrap",
					textAlign: "end",
				},
				headerStyle: {
					whiteSpace: "nowrap",
					textAlign: "end",
				},
				hidden: screenExtraSmall,
			},
			{
				title: getString("portfMktAmt"),
				render: portfolio => getFormatedNumberString(portfolio.mktAmt, true),
				sorting: false,
				cellStyle: {
					whiteSpace: "nowrap",
					textAlign: "end",
				},
				headerStyle: {
					whiteSpace: "nowrap",
					textAlign: "end",
				},
				hidden: screenExtraSmall,
			},
			{
				title: getString("portfDiffPerc"),
				render: portfolio => getFormatedNumberString(portfolio.diffPerc, true),
				sorting: false,
				cellStyle: {
					whiteSpace: "nowrap",
					textAlign: "end",
				},
				headerStyle: {
					whiteSpace: "nowrap",
					textAlign: "end",
				},
				hidden: screenExtraSmall,
			},
			{
				title: getString("portfDiffAmt"),
				render: portfolio => getFormatedNumberString(portfolio.diffAmt, true),
				sorting: false,
				cellStyle: {
					whiteSpace: "nowrap",
					textAlign: "end",
				},
				headerStyle: {
					whiteSpace: "nowrap",
					textAlign: "end",
				},
				hidden: screenExtraSmall,
			},
		],
		[getString, getDateTimeString, screenExtraSmall ]
	) as Column<PortfolioData>[];
};

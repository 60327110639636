import { LocalizationLanguage } from "./types";

export const enTranslations = {
	language: LocalizationLanguage.EN as LocalizationLanguage,
	decimalSeparator: ".",
	momentLocale: "en",
	mainTitle: "WebProTraderr",
	tableNextPage: "Next page",
	tablePreviousPage: "Previous page",
	tableFirsPage: "First page",
	tableLastPage: "Last page",
	tableDisplayedRows: "{from}-{to} from {count}",
	tabledDisplayedPerPage: "rows",
	orderActView: "Show order",
	orderActReply: "Reply to order",
	orderWaitingProducts: "Please wait...",
	orderSideBuy: "BUY",
	orderSideSell: "SELL",
	listingOrdersTitle: "Orders",
	userOrdersTitle: "My orders",
	archiveOrdersTitle: "Archive",
	search: "Search",
	companyClmName: "Name",
	companyClmHeadquorters: "Headquaters",
	companyClmMemberFrom: "Member from",
	companyClmProvision: "Commission ",
	reporClmtName: "Name",
	reporTitle: "Reports",
	reporDownloadTtl: "Download",
	username: "Username",
	password: "Password",
	login: "Login",
	logout: "Logout",
	orderChooseProduct: "Choose product",
	noDataText: "No data",

	oldpassword: "Old Password",
	newpassword: "New Password",
	changePassword: "Change",
	newpasswordverify: "New Password- verify",

	orderClmOrdNo: "Number",
	orderDateReceived: "Date Recieved",
	orderTimeReceived: "Time Recieved",
	orderOrdType: "Type",
	orderOrdTimeType: "Valid time",
	orderRefOrd: "Ref order",
	orderClientIdNo: "Client number",
	orderClientName: "Client name",
	orderSymbol: "Symbol",
	orderYear: "Year",
	orderTradingPlace: "Trading place",
	orderFranco: "Franco",
	orderQty: "Quantity",
	orderAllOrNothing: "All or nothing",
	orderPrice: "Price",
	orderPriceEur: "Price (EUR)",
	orderPriceWithoutVat: "Price without VAT",
	orderPriceWithVat: "Price (VAT)",
	orderPayType: "Pay type",
	orderPayDays: "Pay days",
	orderFreeStorage: "Free storage",
	orderFreeStorageByDelivery: "By delivery",
	orderFreeStorageByDate: "By date:",
	orderCheckPoint: "Check point",
	orderCheckPays: "Pays check point",
	orderExpiryDate: "Expiry date",
	orderStatus: "Status",
	orderQuality: "Quality",
	orderRecieveMethod: "Recieve Method",
	orderEditTitle: "Edit order",
	orderCreateTitle: "Create order",
	orderGoodsReadyFrom: "Goods ready from",
	orderGoodsReadyTo: "Goods ready to",
	orderAttrName: "Name",
	orderAttrValue: "Value",
	orderAttrTitle: "Quality paraneters",
	orderAttrCreateTitle: "Crete attribute",
	orderAttrEditAttribute: "Edit attribute",
	orderReceiveMethod: "Order Receive Method",
	orderbrokName: "Broker Name",
	orderUserName: "User Name",
	orderCliPrsName: "Client Person Name",
	orderUnit: "Unit",
	orderQtyLeft: "Quantity Left",
	orderQtyFill: "Filled Qty",
	orderQtyVisible: "Visible Qty",
	orderSecurityAcc: "Security Account",
	orderMoneyAcc: "Money Account",
	orderPriceVat: "Vat",
	orderMinQty: "Minimum Quantity",
	orderComment: "Comment",
	orderCommission: "Commission",
	ordergoodsRedyFrom: "Goods Ready From",
	ordergoodsRedyTo: "Goods Ready To",
	orderStatusDescr: "Status",
	orderFillStatusDescr: "Fill Status",
	orderCancelReplTime: "Cancel Replacement Time",
	orderPayData: "Pay Data",
	orderSide: "Side",
	orderCurrCode: "Cuurency",
	orderexportReady: "Export Ready",
	orderOrigOrderNo: "Original Order",
	orderIceberg: "Iceberg",
	orderSecurity: "Security",
	orderPreMarket: "PreMarket",
	orderRejection: "Order rejection",

	numberOfDays: "Number of days",
	navListing: "Listing",
	navMyOrders: "My orders",
	navArchive: "Archive",
	navCompanies: "Companies",
	navMembers: "Members",
	navProducts: "Products",
	navBrokers: "Brokers",
	navUsers: "Users",
	navRoles: "Roles",
	navCodebook: "Codebook",
	navCountries: "Countries",
	navTopRegion: "Region",
	navRegion: "District",
	navSubRegion: "SubRegion",
	navCities: "Cities",
	navCurrencies: "Currencies",
	navWarehouses: "Warehouses",
	navTrading: "Trading",
	navMainTable: "Main table",
	navOrders: "Orders",
	navTransactions: "Transactions",
	navMarketDepth: "Market Depth",
	navCliring: "Cliring",
	navDeposits: "Deposits",
	navMarginCalculations: "Margin calculations",
	navSurveillance: "Surveillance",
	navAlarmSetup: "Alarm setup",
	navProductReport: "Product report",
	navMemberReport: "Member report",
	navReports: "Reports",
	navDailyReports: "Daily reports",
	navWeeklyReports: "Weekly reports",
	navMonthlyReports: "Monthly reports",
	navQuaterlyReports: "Quaterly reports",
	navAnnualReports: "Annual reports",
	navSECReports: "SEC reports",
	navMembersReports: "Members reports",
	navOrdersReports: "Orders and trades",
	navAccounting: "Accounting",
	navTaxes: "Taxes and commissions",
	navInvoice: "Invoice generation",
	navAccountingExport: "Export for accounting",
	navMembersDeposit: "Members deposit",
	empFirstName: "First name",
	empLastName: "Last name",
	empIdNumber: "ID number",
	empOfficePhone: "Office phone",
	empEmail: "Email",
	empStatus: "Status",
	empMidName: "Middle name",
	empGender: "Gender",
	empAddress: "Address",
	empZipCode: "ZipCode",
	empCity: "City",
	empPhone: "Phone",
	empDateOfBirth: "Date of Birth",
	empPosition: "Position",
	empBranch: "Branch",
	empFax: "Fax",
	empShowTooltip: "Show",
	genderMale: "Male",
	genderFemale: "Female",
	statusActive: "Active",
	statusInactive: "Inactive",
	empEdit: "Edit",
	empDelete: "Delete",
	empEditTitle: "Edit broker",
	empCreateTitle: "Create broker",
	empName: "Employee",
	empTel: "Phone",
	empMob: "Mobile",


	cliName: "Short name", //	naziv klijenta
	cliIdNo: "ID number", //	maticni broj klijenta
	cliTaxCode: "Tax code", //	PIB broj
	cliType: "Type",
	cliForeign: "Foreign",
	cliCity: "City",
	cliPhone: "Phone",
	cliBroker: "Broker",
	cliActive: "Active",
	cliStatus: "Status",
	clilFullName: "Name",
	cliAddress: "Adress",
	cliMailCity: "Mail city",
	cliMailAddress: "Mail address",
	cliMobile: "Moblie phone",
	cliFax: "Fax",
	cliEmail: "Email",
	cliHasContract: "Has contract",
	cliActiveFrom: "Active from",
	cliActiveTo: "Acrive to",
	cliCommission: "Commission",
	cliCurrentTurnover: "Turnover",
	cliComment: "Comment",
	cilEditTitle: "Edit client",
	cilCreateTitle: "Create client",
	cilTitle: "Clients",
	tableConfirmationDelete: "Are you sure?",
	tableDelete: "Delete",
	tableAdd: "Add",
	tableEdit: "Edit",
	tableCancel: "Cancel",
	tableSave: "Save",
	roleCode: "Code",
	roleName: "Name SR",
	roleNameEn: "Name EN",
	tableShow: "Show",
	roleEdit: "Edit role",
	roleCreate: "Create role",
	roleNameColumn: "Name",
	roleIsEmployee: "Employee",
	userUserName: "User name",
	userLoginUser: "Login user",
	userLoginPass: "Password",
	userChangePass: "Change password",
	userRoleId: "Role",
	userIsEmployee: "Is employee",
	userEmpId: "Employee",
	userClientId: "Client",
	userCliPrsId: "Client person",
	userSubscribedUntil: "Subscribed until",
	userSubscrUnlimited: "Unlimited Subscription",
	userIsActive: "Active",
	userEditTitle: "Edit user",
	userCreateTitle: "Create user",
	tableBasciInfo: "Basic info",
	clientPersonTitle: "Persons",
	clientPersonName: "Name",
	clientPersonIDNumber: "ID Number",
	clientPersonStatus: "Status",
	clientPersonEmpty: "There are no persons",
	clientBrokerTitle: "Brokers",
	clientBrokerName: "Name",
	clientBrokerIDNumber: "ID Number",
	clientBrokerStatus: "Status",
	clientBrokerEmpty: "There are no brokers",
	contractTitle: "Contracts",
	contractNumber: "Number",
	contractDate: "Date",
	contractStatus: "Status",
	contractEmpty: "There are no contracts",
	contractLincedContrNo: "Linked contract",
	contractTypeDescription: "Type",
	contractDescription: "Description",
	contractPhoneOrder: "Phone number",
	contractWebUser: "Web user",
	contractWebUserBlocked: "Web user blocked",
	contractTitleEdit: "Edit Contract",
	contractTitleCreate: "Create Contract",
	accountTitle: "Accounts",
	accountTitleEdit: "Edit Accounts",
	accountTitleCreate: "Create Accounts",
	accountNumber: "Account number",
	accountBank: "Bank",
	accountDefault: "Default account",
	accountClientBankCode: "Client bank code",
	accountCurrency: "Currency",
	accountForBuySell: "Buy/Sell",
	accountStatus: "Status",
	accountSideBuy: "Buy",
	accountSideSell: "Sell",
	accountSideBoth: "Both",
	accountEmpty: "There are no accounts",
	invalidDateMessage: "Invalid Date Format",
	maxDateMessage: "Date should not be after maximal date",
	minDateMessage: "Date should not be before minimal date",
	clientPersonEditTitle: "Edit Person",
	clientPersonCreateTitle: "Create Person",
	clientBrokerEditTitle: "Edit Broker",
	clientBrokerCreateTitle: "Create Broker",
	leavePageTitle: "Leave this page?",
	leavePageMessage: "If you leave this page all changes will be discarded.",
	leavePageConfirm: "Leave page",
	leavePageCancel: "Stay",
	calendarOK: "OK",
	calendarCancel: "Cancel",
	calendarClear: "Clear",

	commission: "Commission",
	requiredMessage: "Field is required",
	messageWeakPassword: "Password isn't strong enough",

	currenciesTitle: "Currencies",
	currenciesCreateTitle: "Create Currency",
	currenciesEditTitle: "Edit Currency",
	currenciesName: "Name",
	currenciesCode: "Designation",
	currenciesNumericCode: "Code",
	regName: "Region",
	regNameDistrict: "District",
	parentRegName: "Region",
	countryReg: "Country",
	regEdit: "Edit Region",
	regEditDistrict: "Edit District",
	regCreate: "Create Region",
	regCreateDistrict: "Create District",
	curRateDate: "Currency rate date",
	curRateBuy: "Buy rate",
	curRateSell: "Sell rate",
	curRateMid: "Mid rate",
	curRateCurrency: "Currency",
	curRateTitle: "Currency Rates",
	curRateEditTitle: "Edit Currency Rates",
	curRateCreateTitle: "Create Currency Rates",
	countryEdit: "Edit Country",
	countryCreate: "Create Country",
	countryName: "Name",
	countryNameEN: "Name (EN)",
	cityEdit: "Edit City",
	cityCreate: "Create City",
	cityName: "Name",
	cityZipCode: "Zip Code",
	cityRegion: "District",
	citySubRegion: "Subregion",
	cityIsPort: "Port",

	commissionTitle: "Commissions",
	commissionCreateTitle: "Create Commission",
	commissionEditTitle: "Edit Commission",
	commissionCode: "Code",
	commissionName: "Name",
	commissionNameEN: "Name (EN)",
	commissionType: "Type",
	commissionStandard: "Standard",
	commDetailsTitle: "Commission Details",
	commDetailsCreateTitle: "Create Commission Details",
	commDetailsEditTitle: "Edit Commission Details",
	commDetailsDate: "Date from",
	commDetailsProductGroup: "Product Group",
	commDetailsProduct: "Product",
	commDetailsCurrency: "Currency",
	commDetailsAmtCurrency: "Amount Curency",
	commDetailsAmtTypePercent: "Percent",
	commDetailsAmtTypeAmount: "Amount",
	commDetailsBuyAmtType: "Buy Amount Type",
	commDetailsBuyPerc: "Buy Percent",
	commDetailsBuyMin: "Buy Min Amount",
	commDetailsBuyMax: "Buy Max Amount",
	commDetailsSellAmtType: "Sell Amount Type",
	commDetailsSellPerc: "Sell Percent",
	commDetailsSellMin: "Sell Min Amount",
	commDetailsSellMax: "Sell Max Amount",
	productCode: "Code",
	clientPersonRole: "Role",
	clientPersonDateCertified: "Date certified",
	clientPersonPhone: "Phone",
	clientPersonEmail: "Email",

	tradeTitle: "Trades",
	tradeSumTitle: "Agregate Trades",
	tradeNo: "Trade Number",
	tradeTime: "Trade Time",
	tradeSettl: "Settlement Date",
	tradeSecurity: "Security",
	tradeQty: "Quantity",
	tradeDescr: "Transaction",
	tradeCurrCode: "Currency",
	tradePrice: "Price",
	tradeOrder: "Order",
	tradeBrokCommPerc: "Broker Commission Perc.",
	tradebrokCommAmt:"Broker Commission",
	tradeExchCommPerc:"Exchange Commission Perc.",
	tradeExchCommAmt:"Exchange Commission",
	tradeRegCommPerc:"Register Commission Perc.",
	tradeRegCommAmt:"Register Commission",
	tradeBankCommPerc:"Bank Commission Perc.",
	tradeBankCommAmt:"Bank Commission",
	tradeVatAmt:"Vat",
	tradeTotComm:"Commission Total",
	tradeExchange: "Exchange",
	tradeIsin:"Isin",
	tradeComm:"Commission",
	tradeAmtFinal: "Final Amount",
	tradeRate: "Rate",

	listingOrdersBookTitle: "Order Book",
	orderCancel: "Cancel order",
	orderCancelQuestion: "Cancel order?",
	orderCancelQuestionYes: "Yes",
	orderCancelQuestionNo: "No",
	orderModify: "Modify order",
	orderVerify: "Verify order",
	tradeVatPerc: "Vat %",
	tradePriceWithVat: "Price with Vat",
	tradeAmt: "Amount",
	tradeAmtWithVat: "Amount with Vat",
	tradeTransCostSell: "Transport Costs Seller",
	tradeStorCostSell: "Storage Costs Seller",
	tradeTransCostBuy: "Transport Costs Buyer",
	tradeStorCostBuy: "Storage Costs Buyer",
	tradeCommPercSell: "Commission Seller %",
	tradeCommAmtSell: "Commission Seller ",
	tradeCommPercBuy: "Commission Buyer %", 
	tradeCommAmtBuy: "Commission Buyer",
	orderFilterConfirm: "Filter",
	orderFilterCancel: "Cancel",
	orderFilterFrom: "Order from",
	orderFilterTo: "Order to",
	tradeFilterFrom: "Trade from",
	tradeFilterTo: "Trade to",
	tradeViewAction: "View trade",
	tradeCancelAction: "Cancel trade",
	orderTradeQuestion: "Cancel trade?",
	orderSecDep: "Custodian",
	orderStatusChange: "Status Change",

	filterFrom: "from",
	filterTo: "to",
	paymentTitle: "Payment",
	paymentDataTime: "Date",
	paymentClientCode: "Client Code",
	paymentClientName: "Client Name",
	paymentPayType: "Pay Type",
	paymentClmOrdNo: "Order No.",
	paymentTranNo: "Transaction No.",
	paymentAmount: "Amount",
	paymentAmountIn: "Amount In",
	paymentAmountOut: "Amount Out",
	paymentCurrCode: "Currency",
	paymentStatus: "Status",
	datefrom: "From date",
	dateto: "To date",
	paymentEdit: "Edit Payment",
	paymentCreate: "Create Payment",
	paymentSideIn: "In",
	paymentSideOut: "Out",
	paymentSide: "In/Out",
	paymentSideNonDedicated: "Non Dedicated",
	paymentSideDedicated: "Dedicated",
	paymentSideDedication: "Dedication",
	paymentBankGuarantee: "Bank Guarantee",


	paymentClientOrders: "Client orders",
	paymentClientTransactions: "Client transactions",
	guarGuaranteeTitle: "Guarantees",
	guarEdit: "Edit Guarantee",
	guarCreate: "Create Guarantee",

	orderDeliveryLocation: "Delivery Location",
	filterReset: "Clear",
	orderMarket: "Market",
	orderMarketSpot: "Spot",
	orderMarketForward: "Forward",
	orderLocCountry: "Country",
	orderLocRegion: "Region",
	orderLocDistrict: "District",
	orderLocCity: "City",
	orderLocWhs: "Warehouse",
	tradeFulfillmentDate: "Fulfillment Date",
	navOrdersReport: "Order book",

	stockFeeTitle: "Stock Fees",
	stockFeeEdit: "Edit Stock Fee",
	stockFeeCreate: "Create Stock Fee",
	stockFeeStartDate: "Start Date",
	stockFeeStockFee: "Stock Fee",
	stockFeeStatus: "Status",

	transFeeTitle: "Transport Fees",
	transFeeEdit: "Edit Transport Fee",
	transFeeCreate: "Create Transport Fee",
	transFeeStartDate: "Start Date",
	transFeePice: "Price",
	transFeeDistance: "Distance",
	transFeeStatus: "Status",

	navTradesReport: "Trade Book",

	dailyTradeReportTitle: "Daily Trades",
	dailyTradeReportProduct: "Product",
	dailyTradeReportTurnoverRSD: "Turnover RSD",
	dailyTradeReportTurnoverEUR: "Turnover EUR",
	dailyTradeReportCountTransaction: "Transaction Count",
	dailyTradeReportReportDate: "Report Date",

	monthlyTradeReportReportTitle: "SEC Monthly Trades Report",
	monthlyTradeReportReportMonth: "Month",
	monthlyTradeReportReportYear: "Year",
	monthlyTradeReportReportDate: "Date",
	monthlyTradeReportReportQty: "Quantity",
	monthlyTradeReportReportWeightedRSD: "Weighted Price RSD",
	monthlyTradeReportReportWeightedEUR: "Weighted Price EUR",

	filterTooltip: "Filter",
	exportPDFTooltip: "Download PDF",
	exportXLSXTooltip: "Download Excel",
	printTooltip: "Print",
	settingsTooltip: "Settings",

	marketDataAddSecurity: "Add Security",
	marketRemoveSecurity: "Remove Securita",


	orderFundCheckButton: "OK",
	orderFundCheckButtonCancel: "Cancel",
	orderFundCheckTitle: "Fund Check",

	weeklyNumofWeeks: "Number of Weeks",
	monthlyNumofmonths: "Number of Months",


	exmemTitle: "Exchange Members",
	exmemMemberName: "Member Name",
	exmemMemberId: "Member Id",
	exmemTaxCode: "Tax Code",
	exmemAdress: "Address",
	exmemPhone: "Phone",
	exmemManager: "Manager",
	exmemTrader: "Trader",
	exmemContactPerson: "Contact Person",

	memcontTitle: "Member Contracts",
	memcontMember: "Member",
	memcontContractDate: "Contract Date",
	memcontExpireDate: "Expire Date",
	memcontStatus: "Status",

	exchDateFrom: "Date From",
	exchDateTo: "Date To",
	exchTradeReview: "Trade Review",
	exchCommissionReview: "Commission Review",
	exchTradeByProduct: "Trade By Product",
	exchBusinessActivities: "Business Activities",
	exchTurnoverByClient: "Turnover By Client",
	exchTurnoverByBrokers: "Turnover By Brokers",
	exchWeightedPrice: "Weighted Prices",
	exchDate: "Date",
	exchTradeNumber: "Trade Number",
	exchTradeDate: "Trade Date",
	exchProduct: "Product",
	exchPrice: "Price",
	exchQuantity: "Quantity",
	exchAmount: "Amount",
	exchClient: "Client",
	exchTransactionDate: "Transaction Date",
	exchTransactionNumber: "Transaction Number",
	exchOrderNumber: "Order Number",
	exchSide: "Side",
	exchCommission: "Commission",
	exchTurnoverValue: "Turnover Value",
	exchBuyersCommission: "Buyers Commission",
	exchSellersCommission: "Sellers Commission",
	exchBuyerBroker: "Buyers Broker",
	exchSellerBroker: "Sellers Broker",
	exchCommissionValue: "Commission Value",
	exchTurnoverParticipation: "Turnover Participation",
	exchCommissionParticipation: "Commission Participation",
	exchBuyer: "Buyer",
	exchSeller: "Seller",
	exchTurnoverRsd: "Turnover RSD",
	exchTurnoverEur: "Turnover EUR",
	exchWeightedPriceRsd: "Weighted Price RSD",
	exchWeightedPriceEur: "Weighted Price EUR",

	annualNumofyears: "Number of year",

	pleaseWait: "Please wait...",


	iInvoiceTooltip: "Create Invoice",

	invoicingMessage: "Invoicing successfully finished",
	invoicingMessageTitle: "Invoicing",
	invoicingMessageConfim: "OK",

	accountingOrderTooltip: "Creating Order",
	accountingOrderTitle: "Accounting Order",
	accountingOrderMessage: "Order Creating finished",
	accountingOrderMessageTitle: "Creating Order",
	accountingOrderConfim: "OK",


	marketDataSymbol: "Symbol",
	marketDataIsin: "Isin",
	marketDataPrice: "Price",
	marketDataBestBid: "Best Bid",
	marketDataBestBidQty: "Best Bid Quantity",
	marketDataBestAsk: "Best Ask",
	marketDataBestAskQty: "Best Ask Quatnity",
	marketDataOpenPrice: "Open Price",
	marketDataHighPrice: "High Price",
	marketDataLowPrice: "Low Price",
	marketDataClosePrice: "Close Price",
	marketDataVolume: "Volume",
	marketDataSumBid: "Total Bid",
	marketDataSumAsk: "Total Ask",
	marketDataTrend: "Trend",
	marketDataChange: "Change",
	marketDataTradingPhase: "Phase",
	marketDataTradeTime: "Time",
	marketDataDepth: "Depth",
	marketPreviousClose: "Previous Close",
	marketDataChangePerc: "Change %",
	marketWeeks52High: "Week 52 Hihg",
	marketWeeks52Low: "Week 52 Low",
	marketDataName: "Name",
	marketDataGraph: "Graph",
	marketIEX :"Data provided by IEX Cloud",
	marketTime:"Time",
	marketSource:"Source",
	marketview: "Show Details",

	tradingDatadatefrom: "From Date",
	tradingDatadateto: "To Date",
	tradingDataEdit: "Edit Referent Price",


	noFutureDate: "Future dates not allowed.",

	orderQtyMatched: "Matched quantity",
	alarmTime: "Alarm time",
	alarmCrossName: "Cross alarm",
	alarmProductName: "Product alarm",
	alarmClientName: "Client alarm",

	tradingDataMessage: "Price Calculation Over",
	tradingDataMessageTitle: "Referent Price Calculation",
	tradingDataConfim: "OK",
	tradingDataReportDate: "Calculation Date",

	marginsDataMessage: "Margins Data Calculation Over",
	marginsDataMessageTitle: "Margins Data Calculation",
	marginsDataConfim: "OK",
	marginsDataReportDate: "Calculation Date",

	attrCompTypeLessThan: "<=",
	attrCompTypeEqual: "=",
	attrCompTypeMoreThan: ">=",
	attrCompType: "Comparator",
	productAttributeHasStandard: "Has Standard Value",
	productAttributeStandardValue: "Standard Value",

	orderParameters: "Order parameters",
	tradeParameters: "Trade parameters",
	orderDepositeAmount: "Needed deposite amount",
	orderDepositeAmountPayed: "Payed deposite amount",
	paymentAmtBal: "Ammount ballance",
	paymentAmtBalNonAlloc: "Not allocated ballance",

	attributeSRPS: "SRPS quality",

	paymentAmtOrdersBal: "Orders state",
	paymentAmtTradesBal: "Transactions state",

	deliveryTradeQty: "Agreed quantity",
	deliveryLeftQty: "Left for delivery",
	
	clientCardTitle: "Client Card",

	alarmProductClientName: "Alram for client and product",
	alarmBuyOrderNumber: "Buy order number",
	alarmSellOrderNumber: "Sell order number",
	allowSystemNotifications: "Turn on system notifications",

	navPortfolio: "Portfolio",

	portfSymbol: "Symbol",
	portfExchCode: "Exchange",
	portfCurrCode: "Currency",
	portfQty: "Quantity",
	portfPchPrice: "Purchase Price",
	portfPchAmt: "Purchase Value",
	portfMktPrice: "Market price",
	portfMktAmt: "Market Value",
	portfDiffPerc: "Difference %",
	portfDiffAmt: "Difference",
	portfIsin:"Isin",
	portfSecName:"Name",
	portfDepositor: "Depozitor",
	portfSecAccType: "ACC. Type",
	portfSecAccount: "Account",
	portfOnExchYes: "On Exchange",
	portfOnExchNo: "Not on Exchange",

	errorTitle: "Error",
	errorConfirm: "OK",
	errorMessageChangePass: "Weak Password",
	errorSuccessTitle: "Password Change",
	errorSuccess: "Password Change Successful",

	settingsPasswordChangeMenu: "Password Change",

	navCashBalance: "Cash Balance",

	cashCurrCode: "Currecy",
	cashAccUse: "Buy/Sell",
	cashAccNo: "Number",
	cashBank: "Bank", 
	cashCurrentAmt: "Current",
	cashSellNetAmt: "Net Buy",
	cashBuyNetAmt: "Net Sell",
	cashSettledAmt: "Settled",
	cashBankAmt: "Bank amt.",
	cashSellOrdAmt: "Active Sell orders",
	cashBuyOrdAmt: "Active Buy orders",
	cashAvailAmt: "Available",
	cashImportTime: "Import Time",

	domForSideDomestic: "Domestic",
	domforSideForeign: "Foreign",
	domForMarket: "Market",
	domForDefDom: "Domestic/def ",
	domForDefFor: "Foreign/def ",
	domForCurrCode: "Currency",
	domForAccSide: "Side",
	domForChange: "Change",

	navSecuritiyAccounts: "Security Accounts",
	navMoneyAccounts:"Money Accounts",

	navSupport: "Support",

	orderChecked: "Warning: Realized price and value of the order may significantly deviate from current market price.",

	otherSettingsMenu: "Other Settings",
	confirmOrderSetting: "Extra order verification",

	currCodeSettl: "Settlement Currency",
    amountSettl: "Settlement Amount",
    settlState: "Settlement State"


//	errorProductAttributeDelete: "Atribut proizvoda koji je bio u nekom nalogu ne može da se briše.",


};

import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage/session";
import snackbar from "./snackbar/snackbarDataSlice";
import auth from "./auth/authDataSlice";
import initialData from "./initialData/initialDataReducer";
import domestic from "./domestic/domesticDataReducer";
import reports from "./reports/reportsDataRecucer";
import secAccounts from "./settings/securityaccounts/securityAccountsDataSlice";
import moneyAccounts from "./settings/moneyaccounts/moneyAccountsDataSlice";
import settings from "./settings/passwordchange/settingsDataSlice";
import otherSettings from "./settings/otherSettings/otherSettingsDataSlice";
import foreign from "./foreign/foreignDataReducer";
import support from "./support/supportDataSlice";

const dataPersistConfig = {
  key: "auth",
  storage: storage,
  whitelist: ["auth", "initialData"],
};

const dataComReducer = combineReducers({
  snackbar,
  auth,
  initialData,
  domestic,
  settings,
  secAccounts,
  moneyAccounts,
  reports,
  foreign,
  otherSettings,
  support,
});

export default persistReducer(dataPersistConfig, dataComReducer);


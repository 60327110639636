import React from "react";
import { ViewOrder } from "../../../data/domestic/orders/types";
import { PaginationTable, PaginationDataProps } from "../../components/common/PagginationTable";
import { useOrderBookActions } from "./useOrderBookActions";
import { useOrderBookColumns } from "./useOrderBookColumns";
import { AccessRights } from "../../../core/navigation/types";
import { useOrderRowColor } from "../orders/useOrderRowColor";
import { useAuth } from "../../../data/auth/hooks";

export interface OrderBookTableProps {
	userAccessRights: AccessRights;
	getData: () => void;
}

type OrderBookTablePropsType = OrderBookTableProps & PaginationDataProps<ViewOrder>;

const OrdersBookTable: React.FunctionComponent<OrderBookTablePropsType> = ({
	userAccessRights,
	getData,
	...tableProps
}) => {

  const [auth] = useAuth();

  const actions = useOrderBookActions();
  const columns = useOrderBookColumns();
  const rowStyle = useOrderRowColor()

	return (
		<PaginationTable<ViewOrder>
			{...tableProps}
			getData={getData}
			title=""
			columns={columns}
			actions={actions}
			options={{
				actionsColumnIndex: -1,
				search: false,
				toolbar: false,
				actionsCellStyle: {
					width: "100px",
					maxWidth: "100px",
				},
        rowStyle
      }}
		/>
	);
};

export default OrdersBookTable;

import { TradeList } from "../../../data/domestic/trades/types";
import React from "react";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Column } from "material-table";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

export const useTradeColumns = () => {
	const { getString, getDateTimeString,getDateString,  getFormatedNumberString } = React.useContext(LocalizationContext);

	const theme = useTheme();
	const screenExtraSmall = useMediaQuery(theme.breakpoints.only('xs'));

	return React.useMemo(
		() => [
			{
				title: getString("tradeNo"),
				field: "clTranId",
				sorting: false,
				width: "200px",
				cellStyle: {
					maxWidth: "200px",
				},
				headerStyle: {
					maxWidth: "200px",
				},
				hidden: screenExtraSmall,
			},
			{
				title: getString("tradeTime"),
				render: trade => getDateTimeString(trade.tranTime),
				sorting: false,
				width: "180px",
				cellStyle: {
					maxWidth: "180px",
					minWidth: "180px",
					width: "180px",
				},
				headerStyle: {
					maxWidth: "180px",
					minWidth: "180px",
					width: "180px",
				},
				hidden: screenExtraSmall,
			},
			{
				title: getString("tradeTime"),
				render: trade => getDateString(trade.tranTime),
				sorting: false,
				width: "120px",
				cellStyle: {
					maxWidth: "120px",
					minWidth: "120px",
					width: "120px",
				},
				headerStyle: {
					maxWidth: "120px",
					minWidth: "120px",
					width: "120px",
				},
				hidden: !screenExtraSmall,
			},
			{
				title: getString("tradeSecurity"),
				field: "symbol",
				sorting: false,
			},
			{
				title: getString("tradeIsin"),
				field: "isin",
				sorting: false,
				hidden: screenExtraSmall,			},
			{
				title: getString("tradeDescr"),
				field: "tranDesc",
				sorting: false,
				hidden: screenExtraSmall,			},
			{
				title: getString("tradeQty"),
				render: trade => getFormatedNumberString(trade.qty) ,
				sorting: false,
				cellStyle: {
					minWidth: "130px",
					whiteSpace: "nowrap",
					textAlign: "end",
				},
				headerStyle: {
					minWidth: "130px",
					textAlign: "end",
				},
			},
			{
				title: getString("orderPrice"),
				render: trade => getFormatedNumberString(trade.price, true),
				sorting: false,
				cellStyle: {
					whiteSpace: "nowrap",
					textAlign: "end",
				},
				headerStyle: {
					whiteSpace: "nowrap",
					textAlign: "end",
				},
				hidden: screenExtraSmall,			},
			{
				title: getString("tradeCurrCode"),
				field: "curr",
				sorting: false,
				hidden: screenExtraSmall,			},
			{
				title: getString("tradeAmt"),
				render: trade => getFormatedNumberString(trade.tradeAmt, true) ,
				sorting: false,
				cellStyle: {
					minWidth: "130px",
					whiteSpace: "nowrap",
					textAlign: "end",
				},
				headerStyle: {
					minWidth: "130px",
					textAlign: "end",
				},
				hidden: screenExtraSmall,			},
			{
				title: getString("tradeComm"),
				render: trade => getFormatedNumberString(trade.feesAmt, true) ,
				sorting: false,
				cellStyle: {
					minWidth: "130px",
					whiteSpace: "nowrap",
					textAlign: "end",
				},
				headerStyle: {
					minWidth: "130px",
					textAlign: "end",
				},
				hidden: screenExtraSmall,
			},
			{
				title: getString("tradeOrder"),
				field: "ordNo",
				sorting: false,
				hidden: screenExtraSmall,			},
		],
		[getString, getDateTimeString, getFormatedNumberString,screenExtraSmall]
	) as Column<TradeList>[];
};

import TradesPage from "./TradesPage";
import { NavigationItem } from "../../../core/navigation/types";
import { tradesFLocation } from "./Locations";

export const tradesFComponents: NavigationItem[] = [
  {
    Component: TradesPage,
    isMenuItem: true,
    path: tradesFLocation,
    notifications: true,
  },
];

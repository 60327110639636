import { combineReducers, AnyAction } from "@reduxjs/toolkit";
import cashBalList from "./cashBalList/cashBalListDataSlice";
import { resetLoginState } from "../auth/authDataSlice";

const dataReportsReducer = combineReducers({
	cashBalList,
});

export default (state: any, action: AnyAction) => {
	if (action.type === resetLoginState.type) {
		return dataReportsReducer(undefined, action);
	} else {
		return dataReportsReducer(state, action);
	}
};

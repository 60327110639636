import { ThunkType } from "../../../../core/store/reducers";
import getOrders from "../api/getOrders";
import { PageDataStatus, PageData } from "../../../models";
import { ViewOrder, OrderFilter } from "../../../domestic/orders/types";

export const getOrdersThunk = (
	pageNumber?: number,
	itemsPerPage?: number,
	filter?: OrderFilter,
	defaultStatus?: number
): ThunkType<Promise<PageData<ViewOrder> | undefined>> => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return undefined;
	}

	const user = store.data.auth.current.user;
	const ordersResponse = await dispatch(getOrders(user, pageNumber, itemsPerPage, filter, defaultStatus));

	if (!ordersResponse || ordersResponse.resCode !== "OK") {
		return undefined;
	}

	return {
		currentPageData: ordersResponse.obj,
		totalCount: Number(ordersResponse.resDesc) || 0,
		status: PageDataStatus.DataCurrent,
	};
};

import { useDispatch, useSelector } from "react-redux";
import { printTradesSumThunk } from "../thunks/printTradesSumThunk";
import React from "react";
import { StoreType } from "../../../../core/store/reducers";
import { FetchStateType } from "../../../models";
import { Trades } from "../../../domestic/trades/types";

export const useTradesSumPrinter = (trade?: Trades) => {
	const fetchState = useSelector<StoreType, FetchStateType>(
		s => s.data.domestic.trades.printTradeFetchState
	);
	const dispatch = useDispatch();
	const download = React.useCallback(() => {
			dispatch(printTradesSumThunk(trade));
	}, [dispatch, trade]);

	return [download, fetchState] as [typeof download, typeof fetchState];
};

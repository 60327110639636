import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { loadSecAccounts } from "../thunks/loadSecAccounts";
import { SecurityAccounts } from "../../../models";
import { SecAccountFilter } from "../../../../data/settings/securityaccounts/types";

export const useSecAccountData = (filter?:SecAccountFilter) => {

  const allData = useSelector<StoreType, SecurityAccounts[] | undefined>(
    (s) => s.data.secAccounts.all
  ); 

  const dispatch = useDispatch();
  const dispatchLoadSecAcc = React.useCallback(
    () => dispatch(loadSecAccounts(filter)),
    [dispatch,filter]
  );

  return [allData, dispatchLoadSecAcc] as [
    typeof allData,
    typeof dispatchLoadSecAcc
  ];
};


import { Theme, makeStyles, createStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import { StoreType } from "../core/store/reducers";

export const useMainBarStyles  = makeStyles((theme: Theme) => {
	const { permanentBreakpoint, fullWidth } = theme.navDrawer;

	const colorBdd = useSelector<StoreType, string | undefined>(
		(store) => store.ux.auth.colorBdd
	);
	
	return createStyles({
		appBar: {
			// [permanentBreakpoint]: {
			// 	width: `calc(100% - ${hiddenWidth}px)`,
			// 	marginLeft: hiddenWidth,
			// },
			[permanentBreakpoint]: {
				width: `calc(100% - ${fullWidth}px)`,
				marginLeft: fullWidth,
			},
			background: colorBdd,
		},
		appBarBdd: {
			// [permanentBreakpoint]: {
			// 	width: `calc(100% - ${hiddenWidth}px)`,
			// 	marginLeft: hiddenWidth,
			// },
			[permanentBreakpoint]: {
				width: `calc(100% - ${fullWidth}px)`,
				marginLeft: fullWidth,
			},
			background: colorBdd,
//			background:  "#133D8D",
		},
		appBaraContent: {
//			alignItems: "baseline",
			alignItems: "center",
			justifyContent: "space-between",
		},
		titleArea: {
			display: "flex",
//			justifyContent: "center",
			justifyContent: "space-between",
//			alignItems: "baseline",
			alignItems: "center",
		},
		logo: {
			width: 200,
		  },
		menuButton: {
			[permanentBreakpoint]: {
				display: "none",
			},
			marginRight: theme.spacing(2),
		},
	});
});

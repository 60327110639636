import { webApiWithResponse } from "../../../../core/http/httpThunks";
import { LoginResponse } from "../../../models";
import { DomForSide } from "../../../models";

export const setdefmoneyaccApi = (userid: string, domfor: string, curid: string, buysell: string, monaccid: string) =>
	webApiWithResponse<LoginResponse>({
		method: "PUT",
		url: "common/setdefmonacc",
		params: {
			userid,
			domfor,
			monaccid,
			curid,
			buysell,
		},
	});


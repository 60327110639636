import React from "react";
import { Chip, Grid } from "@material-ui/core";
import { OrderFilter } from "../../../data/domestic/orders/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { useOrderSideOptionsTranslator } from "../../../data/foreign/orders/hooks/useOrderSideOptions";
import useMediaQuery from '@material-ui/core/useMediaQuery';

export const OrderFilterChipsComponent: React.FunctionComponent<OrderFilterChipsProps> = ({
	filter,
	removeFilter,
}) => {
	const { getDateString, getString } = React.useContext(LocalizationContext);
	const sideTranslator = useOrderSideOptionsTranslator();

	const matches = useMediaQuery('(min-width:600px)');

	return (
		<Grid container spacing={1}>
			{filter.side && matches && (
				<Grid item>
					<Chip
						label={sideTranslator(filter.side)}
						color="secondary"
						variant="outlined"
						onDelete={() => removeFilter("side")}
					/>
				</Grid>
			)}
			{filter.dateFrom && matches &&  (
				<Grid item>
					<Chip
						label={`${getString("filterFrom")}: ${getDateString(new Date(filter.dateFrom))}`}
						color="secondary"
						variant="outlined"
						onDelete={() => removeFilter("dateFrom")}
					/>
				</Grid>
			)}
			{filter.dateTo && matches &&  (
				<Grid item>
					<Chip
						label={`${getString("filterTo")}: ${getDateString(new Date(filter.dateTo))}`}
						color="secondary"
						variant="outlined"
						onDelete={() => removeFilter("dateTo")}
					/>
				</Grid>
			)}
			{filter.orderStatus &&  matches && filter.orderStatus.ftDescr !== "" && (
				<Grid item>
					<Chip
						label={filter.orderStatus.ftDescr}
						color="secondary"
						variant="outlined"
						onDelete={() => removeFilter("orderStatus")}
					/>
				</Grid>
			)}
			{filter.exchange &&  matches && (
				<Grid item>
					<Chip
						label={filter.exchange.exch}
						color="secondary"
						variant="outlined"
						onDelete={() => removeFilter("exchange")}
					/>
				</Grid>
			)}
			{filter.security &&  matches && (
				<Grid item>
					<Chip
						label={filter.security.secData}
						color="secondary"
						variant="outlined"
						onDelete={() => removeFilter("security")}
					/>
				</Grid>
			)}

		</Grid>
	);
};

interface OrderFilterChipsProps {
	filter: OrderFilter;
	removeFilter: (type: keyof OrderFilter) => void;
}

import React, { ReactNode } from "react";
import { useOrderValidator } from "../../../data/foreign/orders/hooks/useOrderValidator";
import { useOrderUpsert } from "../../../data/foreign/orders/hooks/useOrderUpsert";
import { EditOrder, OrdersEditAction, MoneyAccFilter, OrderSecurityFilter } from "../../../data/domestic/orders/types";
import { useHistory } from "react-router-dom";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Grid, TextField, InputAdornment, Typography } from "@material-ui/core";
import { FabSave } from "../../components/common/FabSave";
import { BoolInput } from "../../components/common/BoolInput";
import { LeavePrompt } from "../../components/common/LeavePrompt";
import { useAuth } from "../../../data/auth/hooks";
import { OrderSideSelect } from "./OrderSideSelect";
import { NumberInput } from "../../components/common/NumberInput";
import { DateInput } from "../../components/common/DateInput";
import { orderBookFLocation } from "./Locations";
import { OrderSide } from "../../../data/models";
import { SecuritySelect } from "../../components/tables/SecurityForeignSelect";
import { SecurityAccountsSelect } from "../../components/common/SecurityAccountsSelect";
import { SecurityAccountsSelectForeign } from "../../components/common/SecurityAccountsSelectForeign";
import { MoneyAccountsSelectForeign } from "../../components/common/MoneyAccountsSelectForeign";
import { OrderTypesSelect } from "../../components/common/OrderTypesSelect";
import { TifListSelect } from "../../components/common/TifListSelect";
import { useSecurityAccount } from "../../../data/initialData/hooks/useSecurityAccounts";
import { useSecurityAccountForDefault } from "../../../data/initialData/hooks/useSecurityAccountsForDefault";
import { useMoneyAccount } from "../../../data/initialData/hooks/useMoneyAccounts";
import { useMoneyAccountForDefault } from "../../../data/initialData/hooks/useMoneyAccountsForDefault";
import { Security } from "@material-ui/icons";
import { Status } from "../../../data/models";
import { ExchangeSelect } from "../../components/common/ExchangeSelect";
import { SecuritySimpleFilter } from "../../../data/initialData/types";
import { CustomDialog } from "../../components/common/CustomDialog";
import { useOrderSideStyles } from "./useOrderRowColor";

export interface EditOrderBasicInfoPageProps {
	order: EditOrder;
	editOrder: (field: keyof EditOrder, value: any) => void;
	changed: boolean;
	action: OrdersEditAction;
}

export type FundCheckState =
	| { status: "INIT"; message?: string }
	| { status: "SHOWMESSAGE"; message: string }
	| { status: "RESUBMITED"; message?: string }
	| { status: "CONFIRMED"; message?: string };

export type ActiveCheckState =
	| { status: "INIT"; message?: string }
	| { status: "SHOWMESSAGE"; message?: string }
	| { status: "CANCEL"; message?: string }
	| { status: "CONFIRMED"; message?: string };


export const EditOrderBasicInfoPage: React.FunctionComponent<EditOrderBasicInfoPageProps> = props => {
	const { getString, getFormatedNumberString } = React.useContext(LocalizationContext);

	const { action, order, editOrder, changed } = props;
	const [auth] = useAuth();
	const [validationState, validate] = useOrderValidator();
	const [fetchState, upsert] = useOrderUpsert(action);
	const history = useHistory();
	const sideStyle = useOrderSideStyles();

	//	const [secAccount, renderStatus] = useSecurityAccount("F");    // Računi hartija i novca

	const [activeCheckState, setActiveCheckState] = React.useState<ActiveCheckState>({ status: "INIT" });

	const [secAccountDef, secAccountDefName] = useSecurityAccountForDefault();    // Račun hartija default

	const unsavedChanges = fetchState.type === "not-started" && changed;

	const handleChange = React.useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			editOrder(event.target.name as any, event.target.value);
		},
		[editOrder]
	);

	const [orderSecurityFilter, setOrderSecurityFilter] = React.useState<OrderSecurityFilter | undefined>(
		createOrderSecurityFilter()
	);

	const [monAccountFilter, setMonAccountFilter] = React.useState<MoneyAccFilter | undefined>(
		createOrderMoneyAccountFilter(order)
	);

	const [exchFilter, setexchFilter] = React.useState<SecuritySimpleFilter | undefined>(
		createExchangeFilter(order)
	);


	React.useEffect(() => {
		setOrderSecurityFilter(createOrderSecurityFilter());
	}, [setOrderSecurityFilter]);

	React.useEffect(() => {
		setMonAccountFilter(createOrderMoneyAccountFilter(order));
	}, [setMonAccountFilter, order]);


	const [monAccountDef, monrenderStatusDef, monaccbayeurdef, monaccselleurdef, monaccbayusddef, monaccsellusddef] = useMoneyAccountForDefault();

	const backLocation = orderBookFLocation;

	React.useEffect(() => {
		setexchFilter(createExchangeFilter(order));
	}, [order, setexchFilter]);


	const saveOrder = React.useCallback(
		async (order: EditOrder) => {
			const normalized = validate(order);
			if (normalized) {
				const result = await upsert(normalized);
				if (result.status === "CREATED") {
					editOrder("orderId", result.newId);
				}
			}
		},
		[validate, editOrder, upsert]
	);


	const fabClick = React.useCallback(
		(order: EditOrder) => {
			if (fetchState.type === "successful") {
				history.push(backLocation);
			} else if (fetchState.type !== "started") {
				saveOrder(order);
			}
		},
		[fetchState, history, backLocation, saveOrder]
	);

	if (!order || auth.status !== "successful") {
		return null;
	}

	const filtermd = {
		forMD: 'N',
	}

	const messageelement: ReactNode = (
		<>
			<div className={order && order.side === OrderSide.Sell ? sideStyle.sell : sideStyle.buy}>{`${getString("orderSecurity")} ${order.security?.symbol}`} </div>
			<div className={order && order.side === OrderSide.Sell ? sideStyle.sell : sideStyle.buy}>{`${getString("orderQty")}: ${getFormatedNumberString(order.qty)}`}</div>
			<div className={order && order.side === OrderSide.Sell ? sideStyle.sell : sideStyle.buy}>{`${getString("orderPrice")}: ${order.price === undefined || order.price === 0 ? "Tržišna" : getFormatedNumberString(order.price, true)} ${order.security?.currCode}`}</div>
			<div className={order && order.side === OrderSide.Sell ? sideStyle.sell : sideStyle.buy}>{`${order.price === undefined || order.price === 0 ? "" : getString("orderAttrValue")} ${order.price === undefined || order.price === 0 ? getString("orderChecked") : getFormatedNumberString(order.price * order.qty, true)} ${order.price === undefined || order.price === 0 ? "" : order.security?.currCode}`}</div>
		</>
	)

	return (
		<>
			<Grid container direction="row" spacing={2}>
				<Grid
					item
					container
					direction="column"
					justify="center"
					alignItems="center"
					spacing={2}
				>
					<Grid container item direction="row" justify="flex-start" alignItems="center" spacing={2}>
						<Grid item xs={12} sm={4} md={2}>
							<OrderSideSelect
								handleChange={e => {
									editOrder("side", e.target.value);
									editOrder("securityAccId", secAccountDef?.accId);
									editOrder("securityAcc", secAccountDef?.accNo);
									editOrder("moneyAccId", order.moneyAccId && order.security?.currId !== monAccountDef?.currId ? order.moneyAccId : monAccountDef?.accId);
									editOrder("moneyAcc", order.moneyAcc && order.security?.currId !== monAccountDef?.currId ? order.moneyAcc : monAccountDef?.accNo);
									//									editOrder("securityAccId", order.securityAccId ? order.securityAccId : secAccountDef?.accId);
									//									editOrder("securityAcc", order.securityAcc ? order.securityAcc : secAccountDef?.accNo);
									//									editOrder("moneyAccId", order.moneyAccId && order.security?.currId === monAccountDef?.currId? order.moneyAccId : monAccountDef?.accId);
									//									editOrder("moneyAcc", order.moneyAcc && order.security?.currId === monAccountDef?.currId ? order.moneyAcc : monAccountDef?.accNo);
									createOrderMoneyAccountFilter(order);
								}}
								side={order.side}
								disabled={action === OrdersEditAction.Modify}
							/>
						</Grid>
					</Grid>


					{/* 					<Grid container item direction="row" justify="flex-start" alignItems="center" spacing={2}>
						<Grid item xs={12} sm={6} md={3} >  
							<ExchangeSelect
								label={getString("portfExchCode")}
								predicate={exchange => exchange.exchId === order.exchId}
								onChangeItem={exchange => {
									editOrder("exchId", exchange?.exchId);
									createExchangeFilter(order);
								}}
								disabled={action === OrdersEditAction.Modify}
							/>
						</Grid>
					</Grid>    */}


					<Grid container item direction="row" justify="flex-start" alignItems="center" spacing={2}>
						<Grid item xs={12} sm={9} md={6} >
							<SecuritySelect
								label={getString("orderSecurity")}
								//								filter={exchFilter}
								filter={filtermd}
								predicate={security => security.securityId === order.security?.securityId}
								onChangeItem={security => {
									editOrder("symbol", security?.secData);
									editOrder("security", security);
									editOrder("exchId", security?.exchId);
									editOrder("securityAccId", order.securityAccId ? order.securityAccId : secAccountDef?.accId);
									editOrder("securityAcc", order.securityAcc ? order.securityAcc : secAccountDef?.accNo);
									//									editOrder("moneyAccId", order.moneyAcc && order.security?.currId !== monAccountDef?.currId? order.moneyAccId : monAccountDef?.accId);
									editOrder("moneyAccId", security && security.currId === '00000002' && order.side === OrderSide.Buy ? monaccbayeurdef?.accId :
										security && security.currId === '00000002' && order.side === OrderSide.Sell ? monaccselleurdef?.accId :
											security && security.currId === '00000009' && order.side === OrderSide.Buy ? monaccbayusddef?.accId :
												security && security.currId === '00000009' && order.side === OrderSide.Sell ? monaccsellusddef?.accId : monaccsellusddef?.accId);
									//									editOrder("moneyAcc", order.moneyAcc && order.security?.currId !== monAccountDef?.currId ? order.moneyAcc : monAccountDef?.accNo);
									editOrder("moneyAcc", security && security.currId === '00000002' && order.side === OrderSide.Buy ? monaccbayeurdef?.accNo :
										security && security.currId === '00000002' && order.side === OrderSide.Sell ? monaccselleurdef?.accNo :
											security && security.currId === '00000009' && order.side === OrderSide.Buy ? monaccbayusddef?.accNo :
												security && security.currId === '00000009' && order.side === OrderSide.Sell ? monaccsellusddef?.accNo : monaccsellusddef?.accNo);
									editOrder("preMarket", security && security.currId !== '00000009' ? 0 : order.preMarket);
									createOrderMoneyAccountFilter(order);
								}}
								required={validationState.security !== undefined}
								error={validationState.security !== undefined && !validationState.security}
								disabled={action === OrdersEditAction.Modify}
							/>
						</Grid>
					</Grid>

					<Grid container item direction="row" justify="flex-start" alignItems="center" spacing={2}>
						<Grid item xs={12} sm={6} md={3}>
							<NumberInput<EditOrder>
								property="qty"
								item={order}
								validationState={validationState}
								onChange={handleChange}
								label={getString("orderQty")}
							/>
						</Grid>
					</Grid>

					<Grid container item direction="row" justify="flex-start" alignItems="center" spacing={2}>
						<Grid item xs={12} sm={6} md={3} >
							<SecurityAccountsSelectForeign
								label={getString("orderSecurityAcc")}
								filter={orderSecurityFilter}
								predicate={securityAcc => securityAcc.accId === order.securityAccId}
								onChangeItem={securityAcc => {
									editOrder("securityAccId", securityAcc?.accId);
									editOrder("securityAcc", securityAcc?.accNo);
								}}
								required={validationState.security !== undefined}
								error={validationState.security !== undefined && !validationState.security}
								disabled={action === OrdersEditAction.Modify}
							/>
						</Grid>
					</Grid>

					<Grid container item direction="row" justify="flex-start" alignItems="center" spacing={2}>
						<Grid item xs={12} sm={6} md={3} >
							<MoneyAccountsSelectForeign
								label={getString("orderMoneyAcc")}
								filter={monAccountFilter}
								predicate={monAccount => monAccount.accId === order.moneyAccId}
								onChangeItem={monAccount => {
									editOrder("moneyAccId", monAccount?.accId);
									editOrder("moneyAcc", monAccount?.accNo);
								}}
								required={validationState.security !== undefined}
								error={validationState.security !== undefined && !validationState.security}
								disabled={action === OrdersEditAction.Modify}
							/>
						</Grid>
					</Grid>

					<Grid container item direction="row" justify="flex-start" alignItems="center" spacing={2}>
						<Grid item xs={12} sm={4} md={3}>
							<OrderTypesSelect
								label={getString("orderOrdType")}
								predicate={orderTypes => orderTypes.ftValue === order.orderType}
								onChangeItem={orderTypes => {
									editOrder("orderType", orderTypes?.ftValue);
									editOrder("price", order.orderType === "LIMIT" ? 0 : order.price)
								}}
								disabled={action === OrdersEditAction.Modify}
							/>
						</Grid>
						<Grid item xs={12} sm={4} md={3}>
							<NumberInput<EditOrder>
								property="price"
								item={order}
								validationState={validationState}
								onChange={handleChange}
								label={getString("orderPrice")}
								money
								required={order.orderType === "LIMIT"}
								error={order.orderType === "LIMIT" && !validationState.price}
								//								disabled={order.orderType !== "LIMIT" || action === OrdersEditAction.Modify}   
								disabled={order.orderType !== "LIMIT"}
							/>
						</Grid>
					</Grid>


					<Grid container item direction="row" justify="flex-start" alignItems="center" spacing={2}>
						<Grid item xs={12} sm={4} md={3}>
							<TifListSelect
								label={getString("orderOrdTimeType")}
								predicate={tifList => tifList.ftValue === order.timeInForce}
								onChangeItem={tifList => {
									editOrder("timeInForce", tifList?.ftValue);
									editOrder("expiryDate", tifList?.ftValue !== "GTD" ? null : order.expiryDate)
									editOrder("preMarket", tifList?.ftValue !== "DAY" ? 0 : order.preMarket)
								}}
								disabled={action === OrdersEditAction.Modify}
							/>
						</Grid>
						<Grid item xs={12} sm={4} md={3}>
							<DateInput
								label={getString("orderExpiryDate")}
								value={order.expiryDate || null}
								onChange={date => editOrder("expiryDate", date)}
								required={order.timeInForce === "GTD"}
								error={order.timeInForce === "GTD" && !validationState.expiryDate}
								disabled={order.timeInForce !== "GTD" || action === OrdersEditAction.Modify}
								disablePast
							/>
						</Grid>
					</Grid>

					<Grid container item direction="row" justify="flex-start" alignItems="center" spacing={2}>

						<Grid item xs={12} sm={6}>
							<BoolInput<EditOrder>
								property="preMarket"
								item={order}
								onChange={handleChange}
								falsyValue={0}
								thrutyValue={1}
								label={getString("orderPreMarket")}
								disabled={order.timeInForce !== "DAY" || order.security?.currCode !== 'USD'}
							/>
						</Grid>
					</Grid>


				</Grid>
			</Grid>
			{auth.user.confirmOrder === 0 && (<FabSave fetchState={fetchState} onClick={() => fabClick(order)} />)}
			{auth.user.confirmOrder === 1 && (<FabSave fetchState={fetchState}
				onClick={() => {
					activeCheckState.status === "INIT" || fetchState.type === "not-started" ? setActiveCheckState({ status: "SHOWMESSAGE" }) : fabClick(order);
				}
				}
			/>)}
			<LeavePrompt shouldShow={unsavedChanges} />
			{auth.user.confirmOrder === 1 && (<CustomDialog
				open={activeCheckState.status === "SHOWMESSAGE"}
				title={order.side === OrderSide.Sell ? getString("orderSideSell") : getString("orderSideBuy")}
				message={messageelement}
				closeLabel={getString("orderFundCheckButton")}
				confirmLabel={getString("orderFundCheckButtonCancel")}
				onClose={close => {
					close === false ? setActiveCheckState(s => ({ status: "CONFIRMED", message: s.message })) : setActiveCheckState(s => ({ status: "CANCEL", message: s.message }));
					close === false ? fabClick(order) : setActiveCheckState(s => ({ status: "INIT", message: s.message }));
				}}
				disableBackdropClick
			/>)}
		</>
	);
};

const createOrderSecurityFilter = (): OrderSecurityFilter | undefined => {
	return {
		accUse: "F",
	};
};

const createOrderMoneyAccountFilter = (order?: EditOrder): MoneyAccFilter | undefined => {
	return order && !order.security && order.side === "BUY" ? { accSide: "B", curId: "00000002" } :
		order && !order.security && order.side === "SELL" ? { accSide: "S", curId: "00000002" } :
			order && order.security && order.side === "BUY" ? { accSide: "B", curId: order.security.currId } :
				order && order.security && order.side === "SELL" ? { accSide: "S", curId: order.security.currId } : { accSide: "B", curId: "00000002" }
};



const createExchangeFilter = (order?: EditOrder): SecuritySimpleFilter | undefined => {
	return order && order.exchId ? { exchId: order.exchId, } : undefined;
};

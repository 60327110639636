import { combineReducers } from "@reduxjs/toolkit";
import auth from "./auth/authUXSlice";
import localization from "./localization/localizationUXSlice";
import domestic from "./domestic/domesticUXReducer";
import secAccounts from "./settings/securityaccounts/securityAccountsUXSlice";
import moneyAccounts from "./settings/moneyaccounts/moneyAccountsUXSlice";
import foreign from "./foreign/foreignUXReducer";

export default combineReducers({
  auth,
  localization,
  domestic,
  secAccounts,
  moneyAccounts,
  foreign,
});

import React from "react";
import { useOrderStatuses } from "../../../data/initialData/hooks/useOrderStatuses";
import { OrderStatus } from "../../../data/models";
import { AutocompleteSelect, AutocompleteSelectType,} from "./AutocompleteSelect";

type RegionSelectPropsType = Omit<
  AutocompleteSelectType<OrderStatus>,
  "data" | "getLabel"
>;

export const OrderStatusSelect: React.FunctionComponent<RegionSelectPropsType> = (
  props
) => {
  const [statuses, renderStatus] = useOrderStatuses();

  return (
    <AutocompleteSelect<OrderStatus>
      data={statuses}
      getLabel={renderStatus}
      {...(props as any)}
    />
  );
};


import React from "react";
import { useSelector } from "react-redux";
import { StoreType } from "../../../core/store/reducers";
import { MoneyAccounts } from "../../models";
import { MoneyAccFilter } from "../../domestic/orders/types";

const getStatusLabel = (status?: MoneyAccounts) => (status ? status.accNo : "");

export const useMoneyAccountsForeign = (filter?: MoneyAccFilter) => {

	const statuses = useSelector<StoreType, MoneyAccounts[]>(store => store.data.initialData.moneyAccounts);

  	const [monaccs, getAccLabel ] = useMoneyAccountForeign(statuses,filter );

	return [monaccs, getAccLabel] as [typeof statuses, typeof getStatusLabel];
};

export const useMoneyAccountForeign = (monaccs: MoneyAccounts[], filter?: MoneyAccFilter) => {

//	const monacc = React.useMemo(() => monaccs.filter(c => filter && (c.currId==="00000002" || c.currId==="00000009") && (c.accSide === filter.accSide || c.accSide==="U") ), [monaccs, filter]);
	const monacc = React.useMemo(() => monaccs.filter(c => filter && c.accUse !== 'D' && (c.accSide === filter.accSide || c.accSide==="U") ), [monaccs, filter]);

	const getStatusLabel = (monacc?: MoneyAccounts) => (monacc ? monacc.accNo : "");

	return [monacc, getStatusLabel ] as [typeof monacc, typeof getStatusLabel];
};

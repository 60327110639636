import React from "react";
import { Trades } from "../../../data/domestic/trades/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { useDisplaySingleEmementStyles } from "../../useDisplaySingleEmementStyles";

export interface DisplayTradeMainAttributesProps {
	trade: Trades;
}

export const DisplayTradeMainAttributes: React.FunctionComponent<DisplayTradeMainAttributesProps> = ({
	trade,
}) => {
	const style = useDisplaySingleEmementStyles();
	const { getString, getDateTimeString, getDateString, getFormatedNumberString, getFormatedNumberString4 } = React.useContext(LocalizationContext);
	
	return (
		<div className={style.root}>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("tradeNo")}</span>
				<span className={style.value}>{trade.clTradeId}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("tradeDescr")}</span>
				<span className={style.value}>{trade.tranDesc}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("tradeTime")}</span>
				<span className={style.value}>{getDateTimeString(trade.tradeTime)}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("tradeSettl")}</span>
				<span className={style.value}>{getDateString(trade.settlDate, "DD.MM.YYYY")}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("tradeSecurity")}</span>
				<span className={style.value}>{trade.symbol}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("tradeCurrCode")}</span>
				<span className={style.value}>{trade.currency}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("tradeQty")}</span>
				<span className={style.value}> {getFormatedNumberString(trade.qty)}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("tradePrice")}</span>
				<span className={style.value}> {getFormatedNumberString(trade.price, true)}</span>
			</div>

			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("tradeAmt")}</span>
				<span className={style.value}> {getFormatedNumberString(trade.tradeAmt, true)}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("tradeBrokCommPerc")}</span>
				<span className={style.value}> {`${getFormatedNumberString(trade.brokCommPerc, true)} %`}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("tradebrokCommAmt")}</span>
				<span className={style.value}> {getFormatedNumberString(trade.brokCommAmt, true)}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("tradeExchCommPerc")}</span>
				<span className={style.value}> {`${getFormatedNumberString(trade.exchCommPerc, true)} %`}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("tradeExchCommAmt")}</span>
				<span className={style.value}> {getFormatedNumberString(trade.exchCommAmt, true)}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("tradeRegCommPerc")}</span>
				<span className={style.value}> {`${getFormatedNumberString(trade.regCommPerc, true)} %`}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("tradeRegCommAmt")}</span>
				<span className={style.value}> {getFormatedNumberString(trade.regCommAmt, true)}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("tradeBankCommPerc")}</span>
				<span className={style.value}> {`${getFormatedNumberString(trade.bankCommPerc, true)} %`}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("tradeBankCommAmt")}</span>
				<span className={style.value}> {getFormatedNumberString(trade.bankCommAmt, true)}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("tradeVatAmt")}</span>
				<span className={style.value}> {getFormatedNumberString(trade.vatAmt, true)}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("tradeTotComm")}</span>
				<span className={style.value}> {getFormatedNumberString(trade.totComm, true)}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("tradeAmtFinal")}</span>
				<span className={style.value}> {getFormatedNumberString(trade.totAmt, true)}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("tradeRate")}</span>
				<span className={style.value}> {getFormatedNumberString4(trade.rate, true)}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("orderSecurityAcc")}</span>
				<span className={style.value}>{trade.securityAcc}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("orderMoneyAcc")}</span>
				<span className={style.value}>{trade.moneyAcc}</span>
			</div>
		</div>
	);
};

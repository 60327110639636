import { Order, OrdersEditAction } from "../../../domestic/orders/types";
// import { getOppositeOrder } from "../api/getOppositeOrder";
import { getOrderApi } from "../api/getOrder";
import { useAuth } from "../../../auth/hooks";
import { useDispatch } from "react-redux";
import React from "react";

export const useOrderForEdit = (action: OrdersEditAction, ordId?: string) => {
	const [auth] = useAuth();
	const dispatch = useDispatch();
	const [order, setOrder] = React.useState<Order | undefined>();

	React.useEffect(() => {
		if (auth.status !== "successful" || !ordId) {
			return undefined;
		}
		const load = async () => {
			const newOrder = await dispatch(getOrderApi(auth.user.userId, ordId));

//				action === OrdersEditAction.Replay
//					? await dispatch(getOppositeOrder(auth.user.userId, ordId))
//					: await dispatch(getOrderApi(auth.user.userId, ordId));

					setOrder(newOrder as any);
		};
		load();
	}, [dispatch, action, ordId, setOrder, auth]);

	return order;
};

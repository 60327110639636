import { User } from "../../../auth/types";
import { httpWebApi } from "../../../../core/http/httpThunks";
import { PortfolioFilter  } from "../types";

export const getPortfolioReport = (user: User, filter: PortfolioFilter) => {
	return httpWebApi<Blob>({
		method: "GET",
		responseType: "blob",
		timeout: 60 * 4 * 1000,
		url: "reporting/getportfolioreport",
		params: {
			userid: user.userId,
			domfor: "D",
			secid: filter?.security?.securityId,
			exchid: filter?.exchange?.exchId,
			currid: filter?.curency?.currId,
				},
	});
};

export default getPortfolioReport;

import { ThunkType } from "../../../../core/store/reducers";
import getMarketDataApi from "../api/getMarketData";
import { setData } from "../marketsDataSlice";

export const loadMarketData = (): ThunkType => async (dispatch, getStore) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return;
  }

 const secid=store.data.domestic.marketData.secid

  dispatch(setData());

  const { userId } = store.data.auth.current.user;
  const marketData = await dispatch(getMarketDataApi(userId, secid));
  
  dispatch(setData(marketData));
};

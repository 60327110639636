import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { Order } from "../../orders/types";
import { loadBookOrder } from "../thunks/loadOrderBook";

export const useDisplayOBOrder = (ordId?: string) => {
  const storeOB = useSelector<StoreType, Order | undefined>(
    (store) => store.data.domestic.orderBook.displayedOBOrder
  );

  const displayedOrderBookOrder =
  storeOB && storeOB.orderId === ordId ? storeOB : undefined;

  const displatch = useDispatch();

  React.useEffect(() => {
    if (ordId) {
      displatch(loadBookOrder(ordId));
    }
  }, [displatch, ordId]);

  return displayedOrderBookOrder;
};

import React from "react";
import { useDisplayOBOrder } from "../../../data/foreign/orders/hooks/useDisplayOrderBook";
import { DisplayDrawerContext } from "../../../layout/DisplayDrawerContext";
import { ViewOrder } from "../../../data/domestic/orders/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { DisplayOrderMainAttributes, DiplayOrderMod } from "./DisplayOrderMainAttributes";
import { ExpansionPanel, ExpansionPanelSummary, Typography, Grid, IconButton } from "@material-ui/core";
import { useOrderPrinter } from "../../../data/foreign/orders/hooks/useOrderPrinter";
import { CustomTooltip } from "../../components/common/CustomTooltip";
import DownloadIcon from "@material-ui/icons/CloudDownloadOutlined";

export interface DisplayMBOrderProps {
	id?: string;
	displayMod: DiplayOrderMod;
	enablePrint?: boolean;
}

export const DisplayOrder: React.FunctionComponent<DisplayMBOrderProps> = ({
	id,
	displayMod,
	enablePrint,
}) => {
	const order = useDisplayOBOrder(id);
	const { getString } = React.useContext(LocalizationContext);
	const [print, fetchState] = useOrderPrinter(order);

	return !order ? (
		<>{"loading placeholder"}</>
	) : (
		<Grid container justify="center" direction="column">
			<ExpansionPanel expanded>
				<ExpansionPanelSummary>
					<Grid container justify="space-between">
						<Grid item>
							<Typography>{getString("tableBasciInfo")}</Typography>
						</Grid>
						<Grid item>
					<CustomTooltip titleKeyword="exportPDFTooltip">
						<IconButton
							color="secondary"
							component="span"
							onClick={print}
							disabled={fetchState.type !== "not-started"}
						>
							<DownloadIcon />
						</IconButton>
					</CustomTooltip>
					{ fetchState.type !== "not-started"  ? getString("pleaseWait") : null }
				</Grid>
					</Grid>
				</ExpansionPanelSummary>
				<DisplayOrderMainAttributes order={order} mod={displayMod} />
			</ExpansionPanel>
		</Grid>
	);
};

export const useViewOrder = (displayMod: DiplayOrderMod, enablePrint?: boolean) => {
	const showWithDrawer = React.useContext(DisplayDrawerContext);
	return React.useCallback(
		(order: ViewOrder) => showWithDrawer(<DisplayOrder id={order.orderId} displayMod={displayMod} enablePrint={enablePrint} />),
		[showWithDrawer, displayMod]
	);
};


import React from "react";
import { Chip, Grid } from "@material-ui/core";
import { MoneyAccountFilter } from "../../../data/settings/moneyaccounts/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { useDomForSideOptionsTranslator } from "../../../data/settings/securityaccounts/hooks/useDomForSideOptions";

export const MoneyAccountFilterChipsComponent: React.FunctionComponent<MoneyAccountFilterChipsProps> = ({
	filter,
	removeFilter,
}) => {
	const { getDateString, getString } = React.useContext(LocalizationContext);
	const sideTranslator = useDomForSideOptionsTranslator();
	return (
		<Grid container spacing={1}>
			{filter.domfor && (
				<Grid item >
					<Chip
						label={sideTranslator(filter.domfor)}
						color="secondary"
						variant="outlined"
//						onDelete={() => removeFilter("domfor")}
					/>
				</Grid>
			)}
			{filter.curency && (
				<Grid item >
					<Chip
						label={filter.curency.currCode}
						color="secondary"
						variant="outlined"
//						onDelete={() => removeFilter("domfor")}
					/>
				</Grid>
			)}
			{filter.buysell && (
				<Grid item >
					<Chip
						label={filter.buysell}
						color="secondary"
						variant="outlined"
//						onDelete={() => removeFilter("domfor")}
					/>
				</Grid>
			)}
		</Grid>
	);
};

interface MoneyAccountFilterChipsProps {
	filter: MoneyAccountFilter;
	removeFilter: (type: keyof MoneyAccountFilter) => void;
}

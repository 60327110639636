import { PortfolioFilter }  from "../../../domestic/portfolio/types"
import { User } from "../../../auth/types";
import { httpWebApi } from "../../../../core/http/httpThunks";

export const getPortfolioExcel = (
  user: User,
  filter: PortfolioFilter
) => {
  return httpWebApi<Blob>({
    method: "GET",
    responseType: "blob",
    timeout: 60 * 4 * 1000,
    url: "reporting/getportfolioxls",
    params: {
			userid: user.userId,
			domfor: "F",
			secid: filter?.security?.securityId,
      exchid: filter?.exchange?.exchId,
      currid: filter?.curency?.currId,
      },
  });
};

export default getPortfolioExcel;

import { useSelector } from "react-redux";
import { StoreType } from "../../../core/store/reducers";
import { Currencies } from "../../models";

const getStatusLabel = (status?: Currencies) => (status ? status.currCode : "");

export const useCurrencies = () => {
	const statuses = useSelector<StoreType, Currencies[]>(store => store.data.initialData.currencies);

	return [statuses, getStatusLabel] as [typeof statuses, typeof getStatusLabel];
};

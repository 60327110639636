import React from "react";
import { Grid, Paper, Button } from "@material-ui/core";
import { OrderSideSelect } from "./OrderSideSelect";
import { DateInput } from "../../components/common/DateInput";
import { LocalizationContext } from "../../../core/localization/Localization";
import { OrderFilter } from "../../../data/domestic/orders/types";
import { OrderStatusSelect } from "../../components/common/OrderStatusSelect";
import { useAuth } from "../../../data/auth/hooks";
import { isValidDate } from "../../../core/util/functions";
import { OrderFilterChipsComponent } from "./OrderFilterChips";
import { DisplayDrawerContext } from "../../../layout/DisplayDrawerContext";
import { SecuritySelect } from "../../components/tables/SecuritySelect";

export enum OrderFilterEditorType {
	Basic = 0,
	Extended = 1,
	Full = 2,
}

interface OrderFilterEditorProps {
	filter?: OrderFilter;
	editFilter: (prop: keyof OrderFilter, value: any) => void;
	save: () => void;
	type: OrderFilterEditorType;
	reset?: () => void;
}

export const OrderFilterEditor: React.FunctionComponent<OrderFilterEditorProps> = ({
	filter,
	editFilter,
	save,
	type,
	reset,
}) => {
	const showWithDrawer = React.useContext(DisplayDrawerContext);
	const { getString } = React.useContext(LocalizationContext);
	const [auth] = useAuth();

	return (
		<Paper
			style={{
				paddingLeft: "70px",
				paddingRight: "70px",
				paddingTop: "15px",
				paddingBottom: "15px",
			}}
		>
			<Grid container direction="column" spacing={6}>
				{type >= OrderFilterEditorType.Extended && (
					<Grid item>
						<OrderSideSelect
							handleChange={e => editFilter("side", e.target.value)}
							side={filter?.side}
							nullable
						/>
					</Grid>
				)}
				<Grid item>
					<SecuritySelect
						label={getString("orderSecurity")}
						predicate={security => security.securityId === filter?.security?.securityId}
						onChangeItem={security => {
							editFilter("security", security);
						}}
					/>
				</Grid>

				<Grid item container direction="row" spacing={2}>
					<Grid item xs={6}>
						<DateInput
							label={getString("datefrom")}
							value={filter?.dateFrom || null}
							onChange={date => {
								editFilter("dateFrom", isValidDate(date) ? date.toISOString() : undefined);
							}}
						/>
					</Grid>
					<Grid item xs={6}>
						<DateInput
							label={getString("dateto")}
							value={filter?.dateTo || null}
							onChange={date =>
								editFilter("dateTo", isValidDate(date) ? date.toISOString() : undefined)
							}
						/>
					</Grid>
				</Grid>
				<Grid item>
						<OrderStatusSelect
							label={getString("orderStatus")}
							predicate={status => status.ftValue === filter?.orderStatus?.ftValue}
							onChangeItem={status => {
								editFilter("orderStatus", status);
							}}
						/>
					</Grid>


				<Grid item container direction="row-reverse" spacing={2}>
					<Grid item>
						<Button
							variant="contained"
							color="secondary"
							onClick={() => {
								save();
								showWithDrawer(undefined);
							}}
						>
							{getString("orderFilterConfirm")}
						</Button>
					</Grid>
					<Grid item>
						<Button variant="text" onClick={reset}>
							{getString("filterReset")}
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</Paper>
	);
};

export const OrderFilterChips = OrderFilterChipsComponent;

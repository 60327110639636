import React from "react";
import { Grid, CardContent, CardHeader, Card, Divider } from "@material-ui/core";
import PortfolioReportTable from "./PortfolioReportTable";
import { usePageStyles } from "../../pageStyles";
import { PageComponent } from "../../../core/navigation/types";
import { PageTitle } from "../../components/common/PageTitle";
import { PortfolioFilterButton }  from "./PortfolioReportFilter"
import { usePortfolioData } from "../../../data/foreign/portfolio/hooks/usePortfolioData";
import { usePortfolioIndex } from "../../../data/foreign/portfolio/hooks/usePortfolioIndex";
import { PageDataStatus } from "../../../data/models";
import { DownloadButton, DownloadExcelButton } from "./DownloadButton";

const PortfolioReport: PageComponent = ({ userAccessRights }) => {
	const pageStyles = usePageStyles();

	const [pageData, dispatchLoadPortfolio, invalidateData] = usePortfolioData();
	const [pageIndex, dispatchSetIndex] = usePortfolioIndex();

	React.useEffect(() => {
		if (pageData && pageData.status === PageDataStatus.DataInvalidated) {
			dispatchLoadPortfolio();
		}
	}, [dispatchLoadPortfolio, pageData]);

	React.useEffect(() => () => { invalidateData() }, [])	
	
	return (
		<>
			<Grid container className={pageStyles.root}>
				<Grid item xs={12} className={pageStyles.gridItem}>
					<Card>
						<CardHeader
							title={<PageTitle title="navPortfolio" />}
							action={<PortfolioReportActions />}
						/>
						<Divider />
						<CardContent>
							<PortfolioReportTable
								pageIndex={pageIndex}
								pageData={pageData}
								onPageIndexChange={dispatchSetIndex}
								userAccessRights={userAccessRights}
								getData={dispatchLoadPortfolio}
							/>
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		</>
	);
};

export const PortfolioReportActions: React.FunctionComponent = () => (
	<Grid container>
	  <Grid item>
		<DownloadButton />
	  </Grid>
	  <Grid item>
		<DownloadExcelButton />
	  </Grid>
	  <Grid item>
		<PortfolioFilterButton />
	  </Grid>
	</Grid>
  );

export default PortfolioReport;

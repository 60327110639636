import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FetchStateType } from "../../models";

type SettingsData = {
	changePassFetchState: FetchStateType;
	errorDesc: string;
};

const initialState: SettingsData = {
	changePassFetchState: { type: "not-started" },
	errorDesc: "",
};

const name = "settings";

const settingsSlice = createSlice({
	name,
	initialState,
	reducers: {
		setChangePassFetchState: (state, action: PayloadAction<FetchStateType>) => ({
			...state,
			changePassFetchState: action.payload,
		}),
		setError: (state, action: PayloadAction<string>) => ({
			...state,
			errorDesc: action.payload,
		}),

	},
});

export const { setChangePassFetchState, setError } = settingsSlice.actions;

export default settingsSlice.reducer;

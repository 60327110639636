import React from "react";
import { useSecurityAccounts } from "../../../data/initialData/hooks/useSecurityAccounts";
import { useSecurityAccountsForeign } from "../../../data/initialData/hooks/useSecurityAccountsForeign";
import { SecurityAccounts } from "../../../data/models";
import { AutocompleteSelect, AutocompleteSelectType,} from "./AutocompleteSelect";
import { SecurityAccFilter } from "../../../data/domestic/orders/types";
//import  { createFilterOptions } from '@material-ui/lab/Autocomplete';

type RegionSelectPropsType = Omit<AutocompleteSelectType<SecurityAccounts>,"data" | "getLabel">
& {
	filter?: SecurityAccFilter
};

/* const filterOptions = createFilterOptions({
  //  matchFrom: 'start',
  //  stringify: (option: FilmOptionType) => option.title,
    limit: 10,
  });  */
  

export const SecurityAccountsSelectForeign: React.FunctionComponent<RegionSelectPropsType> = (
  {
    filter,
    ...props
  } 
 
) => {
  const [statuses, renderStatus] =  useSecurityAccountsForeign(filter) ;

  return (
    <AutocompleteSelect<SecurityAccounts>
      data={statuses}
      getLabel={renderStatus}
      {...(props as any)}
//      filterOptions={filterOptions}
    />
  );
};


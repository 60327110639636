import React from "react";
import { Order, PreMarket } from "../../../data/domestic/orders/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { useDisplaySingleEmementStyles } from "../../useDisplaySingleEmementStyles";
import { DisplayBoolNumber } from "../../components/common/DisplayBoolNumber";
import { useOrderSideOptionsTranslator } from "../../../data/foreign/orders/hooks/useOrderSideOptions";
import { Status } from "../../../data/models";
import { useAuth } from "../../../data/auth/hooks";

export enum DiplayOrderMod {
	Full,
	PublicData,
}

export interface DisplayOrderMainAttributesProps {
	order: Order;
	mod: DiplayOrderMod;
}

export const DisplayOrderMainAttributes: React.FunctionComponent<DisplayOrderMainAttributesProps> = ({
	order,
	mod,
}) => {
	const style = useDisplaySingleEmementStyles();
	const { getString, getDateString, getDateTimeString, getFormatedNumberString, getFormatedNumberString4 } = React.useContext(
		LocalizationContext
	);

	const sideTranslator = useOrderSideOptionsTranslator();
	const [auth] = useAuth();

	if (!order || auth.status !== "successful") {
		return null;
	}

	return (
		<div className={style.root}>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("orderClmOrdNo")}</span>
				<span className={style.value}>{order.orderNo}</span>
			</div>
			

			{auth.user.roleCode==="BROKER"  && <div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("orderClientIdNo")}</span>
				<span className={style.value}>{order.clientIdNo}</span>
			</div> }
			{auth.user.roleCode==="BROKER"  && <div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("orderClientName")}</span>
				<span className={style.value}>{order.clientName}</span>
			</div> }


			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("orderDateReceived")}</span>
				<span className={style.value}>{getDateString(order.orderTime, "DD.MM.YYYY" )}</span>
			</div>

			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("orderTimeReceived")}</span>
				<span className={style.value}>{getDateString(order.orderTime, "HH.mm.ss")}</span>
			</div>

			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("orderSymbol")}</span>
				<span className={style.value}>{order.security.symbol}</span>
			</div>
			{auth.user.roleCode==="BROKER"  && <div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("tradeIsin")}</span>
				<span className={style.value}>{order.security.isin}</span>
			</div> }
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("tradeExchange")}</span>
				<span className={style.value}>{order.exchCode}</span>
			</div>

			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("orderSide")}</span>
				<span className={style.value}>{order.side}</span>
			</div>  
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("orderQty")}</span>
				<span className={style.value}>{getFormatedNumberString(order.qty )}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("orderOrdType")}</span>
				<span className={style.value}>{order.orderType}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("orderPrice")}</span>
				<span className={style.value}>{getFormatedNumberString(order.price, true)}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("orderOrdTimeType")}</span>
				<span className={style.value}>{order.timeInForce}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("orderExpiryDate")}</span>
				<span className={style.value}>{getDateString(order.expiryDate, "DD.MM.YYYY" )}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("orderSecurityAcc")}</span>
				<span className={style.value}>{order.securityAcc}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("orderMoneyAcc")}</span>
				<span className={style.value}>{order.moneyAcc}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("orderQtyLeft")}</span>
				<span className={style.value}>{getFormatedNumberString(order.leavesQty)}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("orderQtyFill")}</span>
				<span className={style.value}>{getFormatedNumberString(order.filledQty)}</span>
			</div>

			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("orderOrigOrderNo")}</span>
				<span className={style.value}>{order.origOrderNo}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("orderStatusDescr")}</span>
				<span className={style.value}>{order.ordStatusDescr}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("orderFillStatusDescr")}</span>
				<span className={style.value}>{order.fillStatusDescr}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("orderPreMarket")}</span>
				<span className={style.value}>
					<DisplayBoolNumber
						value={order.preMarket}
						thrutyValue={PreMarket.True}
						className={style.icon}
					/>
				</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("orderRejection")}</span>
				<span className={style.value}>{order.razlogOdbijanja}</span>
			</div>
		</div>
	);
};


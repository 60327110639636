import { ThunkType } from "../../../../core/store/reducers";
import { printOrder } from "../../orders/api/printOrder";
import fileDownload from "js-file-download";
import { setPrintOrderFetchState } from "../../../domestic/orders/orderBookDataSlice";
import { Order } from "../../../domestic/orders/types";

export const printOrderThunk = (order?: Order): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful" || !order || !order.orderId) {
		return undefined;
	}
	const lan = store.ux.localization.language
	const filename = `TradingOrder${order.side.trim().toUpperCase()}_${lan.trim().toUpperCase()}_${order.orderNo}.pdf`
	const user = store.data.auth.current.user;
	dispatch(setPrintOrderFetchState({ type: "started" }));
	const ordersReport = await dispatch(printOrder(user, order.orderId));
	if (ordersReport) {
		fileDownload(new Blob([ordersReport as any], { type: "application/pdf" }), filename);
	}
	dispatch(setPrintOrderFetchState({ type: "not-started" }));
};

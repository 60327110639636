import React from "react";
import { Grid, Paper, Button } from "@material-ui/core";
import { LocalizationContext } from "../../../core/localization/Localization";
import { PortfolioFilter } from "../../../data/domestic/portfolio/types";
import { PortfolioFilterChipsComponent } from "./PortfolioFilterChips";
import { DisplayDrawerContext } from "../../../layout/DisplayDrawerContext";
import { ExchangeSelect } from "../../components/common/ExchangeSelect";
import { CurrencySelect } from "../../components/common/CurrencySelect";
import { SecuritySelect } from "../../components/tables/SecurityForeignSelect";
import { OnExchangeSelect } from "../../components/common/OnExchangeSelect";

interface PortfolioFilterEditorProps {
	filter?: PortfolioFilter;
	editFilter: (prop: keyof PortfolioFilter, value: any) => void;
	save: () => void;
	reset?: () => void;
}

export const PortfolioFilterEditor: React.FunctionComponent<PortfolioFilterEditorProps> = ({
	filter,
	editFilter,
	save,
	reset,
}) => {
	const showWithDrawer = React.useContext(DisplayDrawerContext);
	const { getString } = React.useContext(LocalizationContext);

	return (
		<Paper
			style={{
				paddingLeft: "70px",
				paddingRight: "70px",
				paddingTop: "15px",
				paddingBottom: "15px",
			}}
		>
			<Grid container direction="column" spacing={6}>
				<Grid item>
					<SecuritySelect
						label={getString("orderSecurity")}
						predicate={security => security.securityId === filter?.security?.securityId}
						onChangeItem={security => {
							editFilter("security", security);
						}}
					/>
				</Grid>

				<Grid item>
					<ExchangeSelect
						label={getString("portfExchCode")}
						predicate={exchange => exchange.exchId === filter?.exchange?.exchId}
						onChangeItem={exchange => {
							editFilter("exchange", exchange);
						}}
					/>
				</Grid>

				<Grid item>
					<CurrencySelect
						label={getString("portfCurrCode")}
						predicate={currencies => currencies.currId === filter?.curency?.currId}
						onChangeItem={currencies => {
							editFilter("curency", currencies);
						}}
					/>
				</Grid>
				<Grid item xs={6}>
           			 <OnExchangeSelect
			            handleChange={(e) => editFilter("onExch", e.target.value)}
						onExchange={filter?.onExch}
              			nullable
            		/>
    			</Grid>
				<Grid item container direction="row-reverse" spacing={2}>
					<Grid item>
						<Button
							variant="contained"
							color="secondary"
							onClick={() => {
								save();
								showWithDrawer(undefined);
							}}
						>
							{getString("orderFilterConfirm")}
						</Button>
					</Grid>
					<Grid item>
						<Button variant="text" onClick={reset}>
							{getString("filterReset")}
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</Paper>
	);
};

export const PortfolioFilterChips = PortfolioFilterChipsComponent;

import React from "react";
import FilterIcon from "@material-ui/icons/FilterListOutlined";
import { IconButton, Grid } from "@material-ui/core";
import { DisplayDrawerContext } from "../../../layout/DisplayDrawerContext";
import { useSecAccountFilter, useCurrentSecAccountFilter,} from "../../../data/settings/securityaccounts/hooks/useSecAccountFilter";
import { SecAccountFilterEditor, SecAccountFilterChips } from "./EditSecAccountFilter";
import { CustomTooltip } from "../../components/common/CustomTooltip";

export const SecAccountFilterButton: React.FunctionComponent = () => {
	const showWithDrawer = React.useContext(DisplayDrawerContext);
	const showFilter = React.useCallback(() => showWithDrawer(<SecAccountsFilterEditor />), [showWithDrawer]);

	const [filter, removeFilter] = useCurrentSecAccountFilter();

	return (
		<Grid container alignItems="center" spacing={2}>
			<Grid item>
				<SecAccountFilterChips filter={filter} removeFilter={removeFilter} />
			</Grid>
			<Grid item>
				<CustomTooltip titleKeyword="filterTooltip" >
					<IconButton color="secondary" component="span" onClick={showFilter}>
						<FilterIcon />
					</IconButton>
				</CustomTooltip>
			</Grid>
		</Grid>
	);
};

const SecAccountsFilterEditor: React.FunctionComponent = () => {
	const [filter, editFilter, save, , reset] = useSecAccountFilter();
	return (
		<SecAccountFilterEditor
			filter={filter}
			editFilter={editFilter}
			save={save}
			reset={reset}
		/>
	);
};

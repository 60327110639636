import { ThunkType } from "../../../../core/store/reducers";
import { removeSecurityApi } from "../api/removeSecurity";
import { setNewOrderFetchState, invalidateOrders } from "../orderBookDataSlice";

export const loadRemoveSecurity = ( secid: string
): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return undefined;
	}

	dispatch(setNewOrderFetchState({ type: "started" }));

	var { user } = store.data.auth.current;

	const result = await dispatch(removeSecurityApi(user.userId, secid))


	if (result && result.resCode === "OK") {
		dispatch(setNewOrderFetchState({ type: "successful", result: result.resDesc }));
		dispatch(invalidateOrders());
	} else 
	{
		dispatch(setNewOrderFetchState({ type: "error", errorCode: result?.resCode }));
	}

}

// import React from "react";
import React, { ReactNode } from "react";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Button,
} from "@material-ui/core";

interface LeavePromptProps {
	open: boolean;
	title: string;
	message: ReactNode;
//	message: string;
	onClose: (confirmed: boolean) => void;
	confirmLabel?: string;
	closeLabel: string;
	disableBackdropClick?: boolean;
}

export const CustomDialog: React.FunctionComponent<LeavePromptProps> = ({
	open,
	title,
	message,
	onClose,
	closeLabel,
	confirmLabel,
	disableBackdropClick,
}) => {
	const confirm = React.useCallback(() => {
		onClose(true);
	}, [onClose]);

	const close = React.useCallback(() => {
		onClose(false);
	}, [onClose]);

	return (
		<>
			<Dialog open={open} onClose={() => !disableBackdropClick && close()}>
				<DialogTitle>{title}</DialogTitle>
				<DialogContent>
					<DialogContentText>{message}</DialogContentText>
				</DialogContent>
				<DialogActions>
					{confirmLabel && (
						<Button onClick={confirm} color="primary">
							{confirmLabel}
						</Button>
					)}
					<Button
						onClick={close}
						color="primary"
						variant="contained"
						autoFocus
					>
						{closeLabel}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

import React from "react";
import { Drawer, CssBaseline } from "@material-ui/core";
import { LoginForm } from "./LoginForm";
import { useLoginStyles } from "./useLoginStyles";

export const LoginPage: React.FunctionComponent = props => {
	const styles = useLoginStyles();

	return (
		<>
			<CssBaseline />
			<div className={styles.wraper}>
				<Drawer
					anchor="right"
					variant="permanent"
					classes={{
						paper: styles.loginDrawerPaper,
					}}
					PaperProps={{
						elevation: 6,
					}}
				>
					<LoginForm />
				</Drawer>
			</div>
		</>
	);
};

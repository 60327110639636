import React from "react";
import { useCurrencies } from "../../../data/initialData/hooks/useCurrencies";
import { Currencies } from "../../../data/models";
import { AutocompleteSelect, AutocompleteSelectType,} from "./AutocompleteSelect";

type RegionSelectPropsType = Omit<
  AutocompleteSelectType<Currencies>,
  "data" | "getLabel"
>;

export const CurrencySelect: React.FunctionComponent<RegionSelectPropsType> = (
  props
) => {
  const [statuses, renderStatus] = useCurrencies();

  return (
    <AutocompleteSelect<Currencies>
      data={statuses}
      getLabel={renderStatus}
      {...(props as any)}
    />
  );
};

import { useSelector } from "react-redux";
import { StoreType } from "../../../core/store/reducers";
import { TifList } from "../../models";

const getStatusLabel = (status?: TifList) => (status ? status.ftDescr : "");

export const useTifList = () => {
	const statuses = useSelector<StoreType, TifList[]>(store => store.data.initialData.tifList);

	return [statuses, getStatusLabel] as [typeof statuses, typeof getStatusLabel];
};

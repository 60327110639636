import { combineReducers, } from "@reduxjs/toolkit";
import orderBook from "./orders/orderBookUXSlice";
import trades from "./trades/tradesUXSlice";
import marketData from "./mainBoard/marketUXSlice";
import portfolio  from "./portfolio/portfolioUXSlice"

const uxTradingReducer = combineReducers({
  orderBook,
  trades,
  marketData,
  portfolio
});

export default uxTradingReducer
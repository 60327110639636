import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { FMarketListData } from "../../../foreign/mainBoard/types";
import { loadMarketData } from "../thunks/loadMarketData";

export const useDisplayMarketData = (curid: string) => {
  const storeMD = useSelector<StoreType, FMarketListData | undefined>(
    (store) => store.data.foreign.marketData.displayData
  );

  console.log(storeMD)
  console.log(curid)

  const displayedMarketData =
  storeMD && storeMD.securityId === curid ? storeMD : undefined;

  const displatch = useDispatch();

  React.useEffect(() => {
    if (curid) {
      displatch(loadMarketData(curid));
    }
  }, [displatch, curid]);

  return displayedMarketData;
};

import React from "react";
import { useMoneyAccountsForeign } from "../../../data/initialData/hooks/useMoneyAccountsForeign";
import { MoneyAccounts } from "../../../data/models";
import { MoneyAccFilter } from "../../../data/domestic/orders/types";
import { AutocompleteSelect, AutocompleteSelectType,} from "./AutocompleteSelect";

type RegionSelectPropsType = Omit<
  AutocompleteSelectType<MoneyAccounts>,
  "data" | "getLabel"
> & {filter?: MoneyAccFilter};

export const MoneyAccountsSelectForeign: React.FunctionComponent<RegionSelectPropsType> = ({
filter,
...otherProps
}
) => {
  const [statuses, renderStatus] = useMoneyAccountsForeign(filter);

  return (
    <AutocompleteSelect<MoneyAccounts>
      data={statuses}
      getLabel={renderStatus}
      {...(otherProps as any)}
    />
  );
};

import { webApiWithResponse } from "../../../../core/http/httpThunks";
import { LoginResponse } from "../../../models";
import { DomForSide } from "../../../models";

export const setdefsecaccApi = (userid: string, domfor: string, secaccid: string) =>
	webApiWithResponse<LoginResponse>({
		method: "PUT",
		url: "common/setdefsecacc",
		params: {
			userid,
			domfor,
			secaccid,
		},
	});


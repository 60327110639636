import React from "react";
import { Paper, Grid, Button } from "@material-ui/core";
import { DateInput } from "../../components/common/DateInput";
import { LocalizationContext } from "../../../core/localization/Localization";
import { isValidDate } from "../../../core/util/functions";
import { TradeFilter } from "../../../data/domestic/trades/types";
import { DisplayDrawerContext } from "../../../layout/DisplayDrawerContext";
import { ExchangeSelect } from "../../components/common/ExchangeSelect";
import { CurrencySelect } from "../../components/common/CurrencySelect";
import { SecuritySelect } from "../../components/tables/SecurityForeignSelect";

export interface TradesFilterEditorProps {
	filter?: TradeFilter;
	editFilter: (prop: keyof TradeFilter, value: any) => void;
	save: () => void;
	reset: () => void;
}

export const TradesFilterEditor: React.FunctionComponent<TradesFilterEditorProps> = ({
	filter,
	editFilter,
	save,
	reset,
}) => {
	const { getString } = React.useContext(LocalizationContext);
	const showWithDrawer = React.useContext(DisplayDrawerContext);

	return (
		<Paper
			style={{
				paddingLeft: "70px",
				paddingRight: "70px",
				paddingTop: "15px",
				paddingBottom: "15px",
			}}
		>
			<Grid container direction="column" spacing={6}>
				<Grid item container direction="row" spacing={6}>
					<Grid item xs={12}>
						<DateInput
							label={getString("tradeFilterFrom")}
							value={filter?.datefrom || null}
							onChange={date =>
								editFilter("datefrom", isValidDate(date) ? date.toISOString() : undefined)
							}
						/>
					</Grid>
					<Grid item xs={12}>
						<DateInput
							label={getString("tradeFilterTo")}
							value={filter?.dateto || null}
							onChange={date =>
								editFilter("dateto", isValidDate(date) ? date.toISOString() : undefined)
							}
						/>
					</Grid>
				</Grid>
				
					<Grid item>
						<SecuritySelect
						label={getString("orderSecurity")}
						predicate={security => security.securityId === filter?.security?.securityId}
						onChangeItem={security => {
							editFilter("security", security);
						}}
					/>
					</Grid>

					<Grid item>
						<ExchangeSelect
						label={getString("portfExchCode")}
						predicate={exchange => exchange.exchId === filter?.exchange?.exchId}
						onChangeItem={exchange => {
							editFilter("exchange", exchange);
						}}
						/>
					</Grid>

					<Grid item>
					<CurrencySelect
						label={getString("portfCurrCode")}
						predicate={currencies => currencies.currId === filter?.curency?.currId}
						onChangeItem={currencies => {
							editFilter("curency", currencies);
						}}
						/>
					</Grid>

				</Grid>
				<Grid item container direction="row-reverse" spacing={2}>
					<Grid item>
						<Button
							variant="contained"
							color="secondary"
							onClick={() => {
								save();
								showWithDrawer(undefined);
							}}
						>
							{getString("orderFilterConfirm")}
						</Button>
					</Grid>
					<Grid item>
						<Button variant="text" onClick={reset}>
							{getString("filterReset")}
						</Button>
					</Grid>
			</Grid>
		</Paper>
	);
};

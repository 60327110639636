import { useSelector } from "react-redux";
import { StoreType } from "../../../core/store/reducers";
import { MAccCur } from "../../models";

const getStatusLabel = (status?: MAccCur) => (status ? status.currCode : "");

export const useMAccCur = () => {
	const statuses = useSelector<StoreType, MAccCur[]>(store => store.data.initialData.mAccCurr);


console.log(statuses)

	return [statuses, getStatusLabel] as [typeof statuses, typeof getStatusLabel];
};

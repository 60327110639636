import OrderBook from "./OrderBook";
import { NavigationItem } from "../../../core/navigation/types";
import { orderBookLocation, baseEdit } from "./Locations";
import { EditOrderPage } from "../orders/EditOrderPage"

export const orderBookComponents: NavigationItem[] = [
	{
		Component: OrderBook,
		isMenuItem: true,
		path: orderBookLocation,
		notifications: true,
	},

	{
		Component: EditOrderPage,
		isMenuItem: false,
		path: baseEdit,
	},

];




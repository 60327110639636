export interface TradePricesChartData {
    tradeDate: Date,
    price: number,
}

export interface DayPriceData {
    tradeDate: Date,
    price: number,
}

// export enum ChartPeriod { 
//    Month = 30,
//    ThreeMonts = 90,
//    HalfYear = 130,
//    Year = 365,
//    FromYearBeggining = -1,
//    All = -2,
//}

export enum ChartPeriod { 
    Day = 'day',
    Month = '1m',
    ThreeMonths = '3m',
    SixMonths = '6m',
    Year = '1y',
    FromYearBeggining = 'ytd',
    TwoYears = '2y',
    FiveYears = '5y',
}
import { webApiWithResponse } from "../../../../core/http/httpThunks";

export const removeSecurityApi = (userId: string, secid: string) =>
  webApiWithResponse<string>({
    method: "PUT",
    url: "trade/removesecurityforMD",
    params: {
      userId,
      secid,
    },
  });

export default removeSecurityApi;


import React from "react";
import { Grid, CardContent, CardHeader, Card, Divider, Fab } from "@material-ui/core";
import TradesTable from "./TradesSumTable";
import { usePageStyles } from "../../pageStyles";
import { PageComponent } from "../../../core/navigation/types";
import { PageTitle } from "../../components/common/PageTitle";
import { useTradesSumData } from "../../../data/foreign/tradessum/hooks/useTradesSumData";
import { useTradeSumIndex } from "../../../data/foreign/tradessum/hooks/useTradesSumIndex";
import { TradesFilterButton } from "./TradesFilter";
import { PageDataStatus } from "../../../data/models";
import { useNotificationHandler } from "../../../core/notifications/hooks";
import { DownloadButton, DownloadExcelButton } from "./DownloadButton";

const TradesSumPage: PageComponent = ({ userAccessRights }) => {
	const pageStyles = usePageStyles();

	const [pageData, dispatchLoadTradesPage, invalidateData] = useTradesSumData();
	const [pageIndex, dispatchSetIndex] = useTradeSumIndex();

	React.useEffect(() => {
		if (pageData && pageData.status === PageDataStatus.DataInvalidated) {
			dispatchLoadTradesPage();
		}
	}, [dispatchLoadTradesPage, pageData]);

	React.useEffect(() => () => { invalidateData() }, [])	

	useNotificationHandler<string>(
		"SumTradeChange",
		e => {
			console.log("SumTradeChange", e);
			invalidateData();
		},
		[]
	);

	return (
		<>
			<Grid container className={pageStyles.root}>
				<Grid item xs={12} className={pageStyles.gridItem}>
					<Card>
						<CardHeader
							title={<PageTitle title="tradeSumTitle" />}
							action={<TradeBookActions />}
						/>
						<Divider />
						<CardContent>
							<TradesTable
								pageIndex={pageIndex}
								pageData={pageData}
								onPageIndexChange={dispatchSetIndex}
								userAccessRights={userAccessRights}
								getData={dispatchLoadTradesPage}
							/>
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		</>
	);
};

export const TradeBookActions: React.FunctionComponent = () => (
	<Grid container>
	  <Grid item>
		<DownloadButton />
	  </Grid>
	  <Grid item>
		<DownloadExcelButton />
	  </Grid>
	  <Grid item>
		<TradesFilterButton />
	  </Grid>
	</Grid>
  );

export default TradesSumPage;

import React from "react";
import { FMarketListData } from "../../../data/foreign/mainBoard/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { useDisplaySingleEmementStyles } from "../../useDisplaySingleEmementStyles";
import { DisplayBoolNumber } from "../../components/common/DisplayBoolNumber";
import { useOrderSideOptionsTranslator } from "../../../data/foreign/orders/hooks/useOrderSideOptions";
import { Status } from "../../../data/models";
import { useAuth } from "../../../data/auth/hooks";

export enum DiplayMarketDataMod {
	Full,
	PublicData,
}

export interface DisplayMarketDataMainAttributesProps {
	marketdata: FMarketListData;
	mod: DiplayMarketDataMod;
}

export const DisplayMarketDataMainAttributes: React.FunctionComponent<DisplayMarketDataMainAttributesProps> = ({
	marketdata,
	mod,
}) => {
	const style = useDisplaySingleEmementStyles();
	const { getString, getDateString, getDateTimeString, getFormatedNumberString, getFormatedNumberString4 } = React.useContext(
		LocalizationContext
	);

	const sideTranslator = useOrderSideOptionsTranslator();
	const [auth] = useAuth();

	if (!marketdata || auth.status !== "successful") {
		return null;
	}

	return (
		<div className={style.root}>

			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("marketDataSymbol")}</span>
				<span className={style.value}>{marketdata.symbol}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("marketDataIsin")}</span>
				<span className={style.value}>{marketdata.isin}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("tradeExchange")}</span>
				<span className={style.value}>{marketdata.exchange}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("tradeCurrCode")}</span>
				<span className={style.value}>{marketdata.curr}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("marketDataName")}</span>
				<span className={style.value}>{marketdata.name}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("marketDataBestBidQty")}</span>
				<span className={style.value}>{getFormatedNumberString(marketdata.bestBidQty)}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("marketDataBestBid")}</span>
				<span className={style.value}>{getFormatedNumberString(marketdata.bestBid, true)}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("marketDataBestAsk")}</span>
				<span className={style.value}>{getFormatedNumberString(marketdata.bestAsk, true)}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("marketDataBestAskQty")}</span>
				<span className={style.value}>{getFormatedNumberString(marketdata.bestAskQty )}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("marketDataPrice")}</span>
				<span className={style.value}>{getFormatedNumberString(marketdata.price, true)}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("marketDataOpenPrice")}</span>
				<span className={style.value}>{getFormatedNumberString(marketdata.open, true)}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("marketDataHighPrice")}</span>
				<span className={style.value}>{getFormatedNumberString(marketdata.high, true)}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("marketDataLowPrice")}</span>
				<span className={style.value}>{getFormatedNumberString(marketdata.low, true)}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("marketDataClosePrice")}</span>
				<span className={style.value}>{getFormatedNumberString(marketdata.close, true)}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("marketPreviousClose")}</span>
				<span className={style.value}>{getFormatedNumberString(marketdata.previousClose, true)}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("marketDataChange")}</span>
				<span className={style.value}>{getFormatedNumberString(marketdata.change, true)}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("marketDataChangePerc")}</span>
				<span className={style.value}>{getFormatedNumberString(marketdata.changePerc, true)}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("marketWeeks52High")}</span>
				<span className={style.value}>{getFormatedNumberString(marketdata.week52High, true)}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("marketWeeks52Low")}</span>
				<span className={style.value}>{getFormatedNumberString(marketdata.week52Low, true)}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("marketTime")}</span>
				<span className={style.value}>{marketdata.latestTime}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("marketSource")}</span>
				<span className={style.value}>{marketdata.latestSource}</span>
			</div>
		</div>
	);
};


import React from "react";
import { useExchanges } from "../../../data/initialData/hooks/useExchanges";
import { Exchanges } from "../../../data/models";
import { AutocompleteSelect, AutocompleteSelectType,} from "./AutocompleteSelect";

type RegionSelectPropsType = Omit<
  AutocompleteSelectType<Exchanges>,
  "data" | "getLabel"
>;

export const ExchangeSelect: React.FunctionComponent<RegionSelectPropsType> = (
  props
) => {
  const [statuses, renderStatus] = useExchanges();

  return (
    <AutocompleteSelect<Exchanges>
      data={statuses}
      getLabel={renderStatus}
      {...(props as any)}
    />
  );
};

import React, { useContext, useMemo } from "react";
import ViewIcon from "@material-ui/icons/InputOutlined";
import ShowChartIcon from "@material-ui/icons/ShowChart";
import { FMarketListData } from "../../../data/foreign/mainBoard/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { MaterialTableProps } from "material-table";
import { useMarketSecurityData } from "../../../data/foreign/mainBoard/hooks/useMarketSecurityData";
import { useViewMarketData } from "./DisplayMarketData";
import { DiplayMarketDataMod } from "./DisplayMarketDataMainAttributes";


export function useMarketSecurityActions() {

  const { getString } = useContext(LocalizationContext);
  const onGetSecurity = useMarketSecurityData();
  const onShowOBOrder = useViewMarketData(DiplayMarketDataMod.Full, true);

  return useMemo(() => {
    const result: MaterialTableProps<FMarketListData>["actions"] = [
      {
        icon: () => <ShowChartIcon />,
        tooltip: getString("marketDataGraph"),
        onClick: (event, rowData) => onGetSecurity(rowData as FMarketListData),
       },
       {
        icon: () => <ViewIcon />,
        tooltip: getString("marketview"),
        onClick: (event, rowData) => onShowOBOrder(rowData as FMarketListData),
      },
    ];
    return result;
  }, [onGetSecurity, getString ]);
}


import { ThunkType } from "../../../../core/store/reducers";
import { getfDayChartData } from "../api/getfDayChartData";
import { setGraphDayData } from "../tickerDataSlice";
import { ChartPeriod } from "../types";

export const loadGraphDayData = (graphId: string, secid: string, date: string): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful" || !secid) {
		return;
	}

	const { user } = store.data.auth.current;
	const graphData = await dispatch(getfDayChartData(user, secid, date));

	dispatch(
		setGraphDayData({
			graphId: graphId,
			data: graphData || [],
		})
	);
};

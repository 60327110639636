import React from "react";
import { useDispatch } from "react-redux";
import { MoneyAccounts } from "../../../models";
import { setMoneyAcc } from "../moneyAccountsDataSlice";
import { loadSetDefMoneyacc } from "../thunks/loadSetDefMoneyacc";

export const useSetDefMoneyAcc = () => {
    
    const dispatch = useDispatch();
	return React.useCallback((defAccount: MoneyAccounts) => {
        console.log(defAccount.accNo)

        dispatch(setMoneyAcc(defAccount.accId));
        dispatch(loadSetDefMoneyacc());
        },
        [dispatch]
    );
};



import { webApiWithResponse } from "../../../../core/http/httpThunks";
import { EditOrder } from "../types";

export const updateOrderApi = (userid: string, ordid: string, qty: number, price:number, ) =>
  webApiWithResponse<string>({
    method: "PUT",
    url: "order/replaceorder",
    params: {
      userid,
      ordid,
      qty,
      price
   }
  });

export default updateOrderApi;

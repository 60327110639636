import React from "react";
import { ViewOrder } from "../../../data/domestic/orders/types";
import { PaginationTable, PaginationDataProps } from "../../components/common/PagginationTable";
import { useOrderBookActions } from "./useOrderBookActions";
import { useOrderBookActionsBroker } from "./useOrderBookActionsBroker";
import { useOrderBookColumns } from "./useOrderBookColumns";
import { useOrderBookColumnsBroker } from "./useOrderBookColumnsBroker";
import { AccessRights } from "../../../core/navigation/types";
import { useOrderRowColor } from "../orders/useOrderRowColor";
import { useAuth } from "../../../data/auth/hooks";

export interface OrderBookTableProps {
	userAccessRights: AccessRights;
	getData: () => void;
}

type OrderBookTablePropsType = OrderBookTableProps & PaginationDataProps<ViewOrder>;

const OrdersBookTable: React.FunctionComponent<OrderBookTablePropsType> = ({
	userAccessRights,
	getData,
	...tableProps
}) => {

  const [auth] = useAuth();

/*  if (auth.status !== "successful") {
	return null;
	}   */
	
  const actions = useOrderBookActions();
  const actionsBroker = useOrderBookActionsBroker();

  const columns =  useOrderBookColumns() ;
  const columnsBroker =  useOrderBookColumnsBroker();

  const rowStyle = useOrderRowColor()

  
  console.log(auth.status)

	return (
		<PaginationTable<ViewOrder>
			{...tableProps}
			getData={getData}
			title=""
			columns={auth.status === "successful" && auth.user.roleCode==="BROKER" ? columnsBroker : columns}
			actions={auth.status === "successful" && auth.user.roleCode==="BROKER" ? actionsBroker : actions}
			options={{
				actionsColumnIndex: auth.status === "successful" && auth.user.roleCode==="BROKER" ?   0 :  -1,
				search: false,
				toolbar: false,
				actionsCellStyle: {
					width: "100px",
					maxWidth: "100px",
				},
        rowStyle
      }}
		/>
	);
};

export default OrdersBookTable;

import { webApi } from "../../../../core/http/httpThunks";
import { SecurityAccounts  } from "../../../models";
import { User } from "../../../auth/types";
import { SecAccountFilter }  from "../types"

const getSecAccountApi = (
  user: string,
  filter?: SecAccountFilter,
) =>
{
 
return webApi<SecurityAccounts[]>({
    method: "GET",
    url: "common/getsecurityaccounts",
    params:{
      userid: user,
      domfor: filter?.domfor,
    }
    });
};

export default getSecAccountApi;





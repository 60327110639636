import { AxiosRequestConfig, AxiosResponse } from "axios";
import axiosIntance from "./axiosInstance";
import { ApiErrorResponse, APIResponse, HTTPWebApiResponse, WebApiResponse, WebApiWholeResponse } from "./types";
import { pushError } from "../../data/snackbar/snackbarDataSlice";
import { v4 } from "uuid";

const getHTTPErrorMessage = (responseCode?: string) => {
	if (responseCode) {
		return `HTTP error (${responseCode})`;
	} else {
		return `HTTP error`;
	}
};

const dipatchHTTPError = (dispatch: any, message: string, responseCode?: string) =>
	dipatchError(dispatch, message, getHTTPErrorMessage(responseCode));

const dipatchError = (dispatch: any, message: string, title?: string, ) =>
	dispatch(
		pushError({
			id: v4(),
			message: [message],
			title: title || "Error",
		})
	);

export const httpWebApiRaw = <T, TReturn = T>(
	request: AxiosRequestConfig,
	responseMapper: (res: AxiosResponse<APIResponse<T>>) => APIResponse<TReturn>,
	token?: string,
): HTTPWebApiResponse<TReturn> => async (dispatch, getState) => {
	try {
		if (!request.headers) {
			request.headers = {};
		}

		const headerToken = token || getState().data.auth.token;

		if (headerToken) {
			request.headers["Authorization"] = `Bearer ${headerToken}`;
		}

		const response = await axiosIntance.request<APIResponse<T>>(request);
		if (response.status === 200) {
			return responseMapper(response);
		}
	} catch (error) {
		if (error.response) {
			dipatchHTTPError(dispatch, error.data, error.status);
		} else if (error.request) {
			dipatchHTTPError(dispatch, "No response from server!");
		} else {
			dipatchHTTPError(dispatch, error.message);
		}
		return undefined;
	}
};
export const httpWebApi = <T>(
	request: AxiosRequestConfig,
	token?: string) => httpWebApiRaw<T>(request, response => response.data, token)

export const webApi = <T>(request: AxiosRequestConfig, getErrorMessage?: (error:ApiErrorResponse) => string): WebApiResponse<T> => async (dispatch, getState) => {
	const response = await dispatch(webApiWithResponse<T>(request, getErrorMessage));
	return response !== undefined && response.resCode === "OK" ? response.obj : undefined;
};



const globalErrorExceptions = ["NOFUNDS", "E--1", "M-15","M-16","M-17","E-9","M-1","M-2","M-3","M-4","M-9","M-10","M-11","M-12","M-13","M-22" ] 
export const webApiWithResponse = <T>(request: AxiosRequestConfig, getErrorMessage?: (error:ApiErrorResponse) => string, errorExceptions?: string[]): WebApiWholeResponse<T> => async (
	dispatch,
	getState
) => {
	try {
		const response = await dispatch(httpWebApi<T>(request));

		if (response === undefined) {
			return response;
		}

		const exceptionCodes = errorExceptions ? [...globalErrorExceptions, ...errorExceptions] : globalErrorExceptions;

		if (response.resCode !== "OK" && exceptionCodes.indexOf(response.resCode) === -1) {
			const errorMessage = getErrorMessage ? getErrorMessage(response) : response.resDesc
			dipatchError(dispatch, errorMessage);
		}

		return response;
	} catch (error) {
		dipatchError(dispatch, error.toString());
		return undefined;
	}
};

export const httpWebApiFile = (
	request: AxiosRequestConfig,
	token?: string) => httpWebApiRaw<Blob>(request, response => {
		return response.data
	}, token)

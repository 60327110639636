import { EditOrder } from "../types";
import { createUseValidator } from "../../../../core/util/hooks";
import { ValidationState } from "../../../../core/util/types";
import { countDecimals } from "../../../../core/util/functions";

export const orderValidationState: ValidationState<EditOrder> = {
	clientId: true,
	side: true,
	qty: true,
	price: true,
	security: true,
	expiryDate: true,
	securityAcc: true,
	moneyAcc: true,
	visibleQty: true,
};

export const useOrderValidator = () => {
	
	return createUseValidator<EditOrder, ValidationState<EditOrder>>(
		orderValidationState,
		(item, validation) => ({
			...validation,
			qty: validation.qty && !!item.qty && countDecimals(item.qty) === 0,
			expiryDate: validation.expiryDate ||  item.timeInForce !== "GTD",
			price: (validation.price && item.price>0 && item.orderType === "LIMIT") || item.orderType === "MARKET"  ,   
			securityAcc: validation.securityAcc || item.securityAcc !== "" ,
			moneyAcc: validation.moneyAcc || item.moneyAcc !== "" ,
			visibleQty: validation.visibleQty || item.iceberg === 0 ,   
		})
	)();
};




//			// && !!item.price && countDecimals(item.price) <= 2,

import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { FetchStateType } from "../../../models";
import { loadRemoveSecurity } from "../../mainBoard/thunks/loadRemoveSecurity";
import { setFetchState } from "../marketsDataSlice";
import { loadMarketAllData } from "../../mainBoard/thunks/loadMarketAllData";

export const useRemoveSecurity = () => {
	const fetchState = useSelector<StoreType, FetchStateType>(
		store => store.data.foreign.marketData.fetchState
	);

	const dispatch = useDispatch();
	const dispatchRemoveSecurity: any = React.useCallback(
		(secid: string) => {
			dispatch(loadRemoveSecurity(secid))
            dispatch(loadMarketAllData());
		},
		[dispatch ]
	);

	React.useEffect(() => {
		dispatch(setFetchState({ type: "not-started" }))
	}, [dispatch])


	return [fetchState, dispatchRemoveSecurity] as [typeof fetchState, typeof dispatchRemoveSecurity]
};


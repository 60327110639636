import { PasswordChangePage} from "./PasswordChangePage";
import { NavigationItem } from "../../../core/navigation/types";
import { settingsLocation } from "./Locations";
import { PasswordChangeForm } from "./PasswordChangeForm";

export const passwordchangeComponents: NavigationItem[] = [
	{
		Component: PasswordChangeForm,
//		Component: PasswordChangePage,
		isMenuItem: true,
		path: settingsLocation,
		notifications: true,
	},

];


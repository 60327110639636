import React from "react";
import { useContext, useMemo } from "react";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Column } from "material-table";
import { CashBalanceData } from "../../../data/reports/cashBalList/types";

export function useCashBalanceColumns() {
	const { getString, getDateTimeString, getFormatedNumberString } = React.useContext(LocalizationContext);

  return useMemo(
    () => [
      {
        title: getString("cashBank"),
        field: "bank",
      },
      {
        title: getString("cashAccNo"),
        field: "accNo",
      },
      {
        title: getString("cashCurrCode"),
        field: "currCode",
				width: "80px",
				cellStyle: {
					maxWidth: "80px",
				},
				headerStyle: {
					maxWidth: "80px",
				},
      },
      {
        title: getString("cashAccUse"),
        field: "accUse",
      },
      {
				title: getString("cashCurrentAmt"),
				render: cash => getFormatedNumberString(cash.currentAmt, true),
				sorting: false,
				cellStyle: {
					whiteSpace: "nowrap",
					textAlign: "end",
				},
				headerStyle: {
					whiteSpace: "nowrap",
					textAlign: "end",
				},
			},
      {
				title: getString("cashSellNetAmt"),
				render: cash => getFormatedNumberString(cash.sellNetAmt, true),
				sorting: false,
				cellStyle: {
//					whiteSpace: "nowrap",
					textAlign: "end",
          maxWidth: "130px",
				},
				headerStyle: {
//					whiteSpace: "nowrap",
					textAlign: "end",
					maxWidth: "130px",
				},
			},
      {
				title: getString("cashBuyNetAmt"),
				render: cash => getFormatedNumberString(cash.buyNetAmt, true),
				sorting: false,
				cellStyle: {
//					whiteSpace: "nowrap",
					textAlign: "end",
          maxWidth: "130px",
				},
				headerStyle: {
//					whiteSpace: "nowrap",
					textAlign: "end",
					maxWidth: "130px",
				},
			},
      {
				title: getString("cashSettledAmt"),
				render: cash => getFormatedNumberString(cash.settledAmt, true),
				sorting: false,
				cellStyle: {
//					whiteSpace: "nowrap",
					textAlign: "end",
          maxWidth: "130px",
				},
				headerStyle: {
//					whiteSpace: "nowrap",
					textAlign: "end",
					maxWidth: "130px",
				},
			},
      {
				title: getString("cashSellOrdAmt"),
				render: cash => getFormatedNumberString(cash.sellOrdAmt, true),
				sorting: false,
				cellStyle: {
//					whiteSpace: "nowrap",
					textAlign: "end",
          maxWidth: "130px",
				},
				headerStyle: {
//					whiteSpace: "nowrap",
					textAlign: "end",
					maxWidth: "130px",
				},
			},
      {
				title: getString("cashBuyOrdAmt"),
				render: cash => getFormatedNumberString(cash.buyOrdAmt, true),
				sorting: false,
				cellStyle: {
//					whiteSpace: "nowrap",
					textAlign: "end",
          maxWidth: "130px",
				},
				headerStyle: {
//					whiteSpace: "nowrap",
					textAlign: "end",
					maxWidth: "130px",
				},
			},
      {
				title: getString("cashBankAmt"),
				render: cash => getFormatedNumberString(cash.bankAmt, true),
				sorting: false,
				cellStyle: {
//					whiteSpace: "nowrap",
					textAlign: "end",
          maxWidth: "130px",
				},
				headerStyle: {
//					whiteSpace: "nowrap",
					textAlign: "end",
					maxWidth: "130px",
				},
			},
      {
        title: getString("cashImportTime"),
        field: "importTime",
      },
      {
				title: getString("cashAvailAmt"),
				render: cash => getFormatedNumberString(cash.availAmt, true),
				sorting: false,
				cellStyle: {
//					whiteSpace: "nowrap",
					textAlign: "end",
          maxWidth: "130px",
				},
				headerStyle: {
//					whiteSpace: "nowrap",
					textAlign: "end",
					maxWidth: "130px",
				},
			},

    ],
    [getString]
  ) as Column<CashBalanceData>[];
}

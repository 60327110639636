import { useContext, useMemo } from "react";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Column } from "material-table";
import { MoneyAccounts } from "../../../data/models";


export function useMoneyAccountColumns() {
  const { getString } = useContext(LocalizationContext);
  return useMemo(
    () => [
      {
        title: getString("portfSecAccount"),
        field: "accNo",
				sorting: false,
				width: "400px",
				cellStyle: {
					maxWidth: "400px",
				},
				headerStyle: {
					maxWidth: "400px",
				},
      },
      {
        title: getString("domForCurrCode"),
        field: "currCode",
				sorting: false,
      },
      {
        title: getString("domForMarket"),
        field: "accUse",
				sorting: false,
      },
      {
        title: getString("domForAccSide"),
        field: "accSide",
				sorting: false,
      },
      {
        title: getString("domForDefDom"),
        field: "defDom",
				sorting: false,
      },
      {
        title: getString("domForDefFor"),
        field: "defFor",
				sorting: false,
      },
    ],
    [getString]
  ) as Column<MoneyAccounts>[];
}

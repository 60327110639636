import React from "react";
import { EditOrder, OrdersEditAction, OrderMarket } from "../../../domestic/orders/types";
import { useCopiedState } from "../../../../core/util/hooks";
import { OrderSide } from "../../../models";
import { User } from "../../../auth/types";
import { useAuth } from "../../../auth/hooks";
import { useOrderForEdit } from "./useOrderForEdit";
import { Status } from "../../../models";
import { useMoneyAccountForDefault } from "../../../../data/initialData/hooks/useMoneyAccountsForDefault";
import { MoneyAccounts, SecurityAccounts } from "../../../models";
import { useSecurityAccountForDefault } from "../../../../data/initialData/hooks/useSecurityAccountsForDefault";

export const createDefauiltOrder = (user: User, moneyacc?: MoneyAccounts, securityAcc?:SecurityAccounts) : EditOrder => ({
	
//	userId: user.userId,
	clientId: user.clientId,
	timeInForce: "DAY",
	orderType: "LIMIT",
//	exchId: "00000001",
	qty: 0,
	price: 0,
	orderId: "",
	side: OrderSide.Buy,
	iceberg: Status.Inactive,
	market: OrderMarket.Foreign,
	moneyAccId: moneyacc?.accId,
	securityAccId: securityAcc?.accId
});


export const useEditOrder = (action: OrdersEditAction, orderId?: string): EditOrder | undefined => {
	const diplayedOrder = useOrderForEdit(action, orderId);
	const [auth] = useAuth();

	const [monAccountDef, monrenderStatusDef, monaccbayeurdef, monaccselleurdef, monaccbayusddef, monaccsellusddef] = useMoneyAccountForDefault();
	const [secAccountDef, secAccountDefName] = useSecurityAccountForDefault();    // Račun hartija default

	return React.useMemo(() => {
		if ((orderId !== undefined && diplayedOrder === undefined) || auth.status !== "successful") {
			return undefined;
		}

		if (orderId === undefined) {
			const defaultOrder = createDefauiltOrder(auth.user, monaccbayeurdef, secAccountDef);
			return { ...defaultOrder };
		}
		const order = diplayedOrder
			? (({ ...diplayedOrder, userId: auth.user.userId } as unknown) as EditOrder)
			: undefined;

		if (order && auth.user.clientId) {
			order.clientId = auth.user.clientId;
		}

		return order;
	}, [orderId, diplayedOrder, auth]);
};

export const useEditOrderState = (action: OrdersEditAction, orderId?: string) => {
	const order = useEditOrder(action, orderId);
	return useCopiedState(order);
};

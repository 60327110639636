import React from "react";
import { Grid, CardContent, CardHeader, Divider, Card, Fab } from "@material-ui/core";
import { SecAccountTable } from "./SecAccountTable";
import { usePageStyles } from "../../pageStyles";
import { PageComponent, AccessRights } from "../../../core/navigation/types";
import { useHistory } from "react-router-dom";
import { PageTitle } from "../../components/common/PageTitle";
import { SecAccountFilterButton }  from "./SecAccountFilter"
import { useSecAccountData } from "../../../data/settings/securityaccounts/hooks/useSecAccountData";
import { useSecAccountFilter } from "../../../data/settings/securityaccounts/hooks/useSecAccountFilter";

const SecAccountPage: PageComponent = ({ userAccessRights }) => {
	const pageStyles = usePageStyles();

	const [filter] = useSecAccountFilter();

	const [data, loadAll] = useSecAccountData(filter);
  
	React.useEffect(() => {
		loadAll();
	}, [loadAll ]);

	const history = useHistory();

	return (
		<Grid container className={pageStyles.root}>
			<Grid item xs={12} className={pageStyles.gridItem}>
				<Card>
					<CardHeader
						title={<PageTitle title="navSecuritiyAccounts" />}
						action={<SecAccountActions />}
						/>
					<Divider />
					<CardContent>
						<SecAccountTable userAccessRights={userAccessRights} />
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
};

export const SecAccountActions: React.FunctionComponent = () => (
	<Grid container>
	  <Grid item>
		<SecAccountFilterButton />
	  </Grid>
	</Grid>
  );

export default SecAccountPage;

import React, { useContext, useRef } from "react";
import { useAuth } from "../data/auth/hooks";
import { Avatar, IconButton, Typography, Box, Menu, MenuItem } from "@material-ui/core";
import { useUserAvatarStyles } from "./UserAvatarStyle";
import { LocalizationContext } from "../core/localization/Localization";
import { canRecieveNotifications } from "../core/notifications/hooks";
import IdleTimer from "react-idle-timer";

const getInitials = (name: string) => {
	if (!name) {
		return "NN";
	}

	const nameWords = name.split(" ");
	if (nameWords.length === 1) {
		return nameWords[0].substring(0, 1).toUpperCase();
	}

	return nameWords[0].substring(0, 1).toUpperCase() + nameWords[1].substring(0, 1).toUpperCase();
};

export const IdleTimerContainer = () => {
	const [authState, , logaout] = useAuth();
	const idleTimerRef = useRef(null);
	return (
		<div>
			<IdleTimer ref={idleTimerRef as any} timeout={10 * 60 * 1000} onIdle={logaout}></IdleTimer>
		</div>
	)
};


export const UserAvatar: React.FunctionComponent = porps => {
	const styles = useUserAvatarStyles();
	const { getString } = useContext(LocalizationContext);
	const [authState, , logaout] = useAuth();
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

{/*	const logoutTimerIdRef = useRef(0);
	React.useEffect(() => {
		const autoLogout = () => {
			if (document.visibilityState === 'hidden') {
				// set timer to log user out
				const timeOutId = window.setTimeout(logaout, 10 * 1000);
				logoutTimerIdRef.current = timeOutId;
			} else {
				window.clearTimeout(logoutTimerIdRef.current);
			}
		};

		document.addEventListener('visibilitychange', autoLogout);

		return () => {
			document.removeEventListener('visibilitychange', autoLogout);
		};
	}, []);  */}


	const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	console.log(Notification.permission)

	if (authState.status !== "successful") {
		return null;
	}

	const askForPermission = async () => Notification.requestPermission();
	const showSystemNotification = Notification.permission === "default" && canRecieveNotifications(authState.user)

	//	const name = authState.user.employeeName || authState.user.clientPersonName;
	const name = authState.user.clientName;

	return (
		<Box className={styles.root}>
			<Typography className={styles.name}>{name}</Typography>
			<IconButton onClick={handleMenu}>
				<Avatar className={styles.avatar}>{getInitials(name)}</Avatar>
			</IconButton>
			<Menu
				id="menu-appbar"
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				keepMounted
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				open={open}
				onClose={handleClose}
			>
				{showSystemNotification && <MenuItem onClick={askForPermission}>{getString("allowSystemNotifications")}</MenuItem>}
				<MenuItem onClick={logaout}>{getString("logout")}</MenuItem>
			</Menu>
		</Box>
	);
};

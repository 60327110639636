import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { otherSettingsThunk } from "../thunks/otherSettingsThunks";
import { StoreType } from "../../../../core/store/reducers";
import { FetchStateType } from "../../../models";
import { setConfirmOrderFatchState } from "../otherSettingsDataSlice";

export const useOtherSettingsHooks = () => {

	const fetchState = useSelector<StoreType, FetchStateType>(
		store => store.data.otherSettings.confirmOrderFetchState
	);


	const dispatch = useDispatch();

	const dispatchConfirmOrder = React.useCallback(
		(confirmOrder: number) => {
            dispatch(otherSettingsThunk(confirmOrder));
		},
		[dispatch ]
	);

	React.useEffect(() => {
		dispatch(setConfirmOrderFatchState({ type: "not-started" }))
	}, [dispatch])


	return [fetchState, dispatchConfirmOrder ] as [typeof fetchState, typeof dispatchConfirmOrder]

};


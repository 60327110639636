import React from "react";
import { Table, TableDataProps } from "../../components/common/Table";
import { useSecAccountData } from "../../../data/settings/securityaccounts/hooks/useSecAccountData";
import { SecurityAccounts } from "../../../data/models";
import { useSecAccountColumns } from "./useSecAccountColumns";
import { AccessRights } from "../../../core/navigation/types";
import { useSecAccountActions  } from "./useSecAccountActions";
import { useSecAccountFilter } from "../../../data/settings/securityaccounts/hooks/useSecAccountFilter";

export interface SecAccTableProps {
  userAccessRights: AccessRights;
}

type SecAccTablePropsType = SecAccTableProps & TableDataProps<SecurityAccounts>;

export const SecAccountTable: React.FunctionComponent<SecAccTablePropsType> = ({
  userAccessRights,
  ...tableProps
}) => {
  const [filter] = useSecAccountFilter();

  const [data, loadAll] = useSecAccountData(filter);

  const columns = useSecAccountColumns();
  const actions = useSecAccountActions();

  return (
    <Table<SecurityAccounts>
      {...tableProps}
      getData={loadAll}
      immutableData={data}
      title=""
      columns={columns}
      actions={actions}
      options={{
        toolbar: false,
        pageSize: 10,
        actionsColumnIndex: -1,
      }}
    />
  );
};

export default SecAccountTable;

import { httpWebApi } from "../../../core/http/httpThunks";
import { LoginResponse } from "../../models";
import { LocalizationLanguage } from "../../../core/localization/types";

export const loginWebApi = (username: string, password: string, lang: LocalizationLanguage, origin: string, token: string) =>
	httpWebApi<LoginResponse>({
		method: "POST",
		url: "user/checkuserlogin",
		data: {
			username,
			password,
			lang,
			origin,
		},
	}, token);

import React from "react";
import { Grid, Paper, Button } from "@material-ui/core";
import { DomForSideSelect } from "../secaccountsdef/DomForSelect";
import { LocalizationContext } from "../../../core/localization/Localization";
import { MoneyAccountFilter } from "../../../data/settings/moneyaccounts/types";
import { useAuth } from "../../../data/auth/hooks";
import { MoneyAccountFilterChipsComponent } from "./MoneyAccountFilterChips";
import { DisplayDrawerContext } from "../../../layout/DisplayDrawerContext";
import { MAccCurrencySelect } from "../../components/common/MAccCurrencySelect";
import { OrderSideSelect } from "./OrderSideSelect";


interface MoneyAccountFilterEditorProps {
	filter?: MoneyAccountFilter;
	editFilter: (prop: keyof MoneyAccountFilter, value: any) => void;
	save: () => void;
	reset?: () => void;
}

export const MoneyAccountFilterEditor: React.FunctionComponent<MoneyAccountFilterEditorProps> = ({
	filter,
	editFilter,
	save,
	reset,
}) => {
	const showWithDrawer = React.useContext(DisplayDrawerContext);
	const { getString } = React.useContext(LocalizationContext);
	const [auth] = useAuth();

	return (
		<Paper
			style={{
				paddingLeft: "70px",
				paddingRight: "70px",
				paddingTop: "15px",
				paddingBottom: "15px",
			}}
		>
			<Grid container direction="column" spacing={6}>
				<Grid item>
						<DomForSideSelect
							handleChange={e => editFilter("domfor", e.target.value)}
							side={filter?.domfor}
							nullable
						/>
				</Grid>

				<Grid item>
					<MAccCurrencySelect
						label={getString("portfCurrCode")}
						predicate={currencies => currencies.currId === filter?.curency.currId}
						onChangeItem={currencies => {
							editFilter("curency", currencies);
						}}
					/>
				</Grid>

				<Grid item>
						<OrderSideSelect
							handleChange={e => editFilter("buysell", e.target.value)}
							side={filter?.buysell}
						/>
					</Grid>

				<Grid item container direction="row-reverse" spacing={2}>
					<Grid item>
						<Button
							variant="contained"
							color="secondary"
							onClick={() => {
								save();
								showWithDrawer(undefined);
							}}
						>
							{getString("orderFilterConfirm")}
						</Button>
					</Grid>
					<Grid item>
						<Button variant="text" onClick={reset}>
							{getString("filterReset")}
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</Paper>
	);
};

export const MoneyAccountFilterChips = MoneyAccountFilterChipsComponent;

import { ThunkType } from "../../../../core/store/reducers";
import { setOrderStatusApi } from "../api/setOrderStatus";
//import { setData } from "../moneyAccountsDataSlice";
import { DomForSide, OrderSide } from "../../../models";

export const loadSetOrderStatus = ((orderid : string): ThunkType => async (dispatch, getStore) => {

  const store = getStore();
  if (store.data.auth.current.status !== "successful") {
    return;
  }
  const  userid = store.data.auth.current.user.userId;


//  dispatch(setData());

  const marketData = await dispatch(setOrderStatusApi(userid, orderid));
  
//  dispatch(setData());
});



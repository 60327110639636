import { ThunkType } from "../../../../core/store/reducers";
import { getTradeSumListReport } from "../api/getTradeSumListReport";
import fileDownload from "js-file-download";
import { setPrintTradeFetchState } from "../../../domestic/trades/tradesDataSlice";

export const downloadTradesSumReport = (): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return undefined;
	}

	const filter = store.ux.domestic.trades.filter;
	const user = store.data.auth.current.user;
	dispatch(setPrintTradeFetchState({ type: "started" }));
	const tradeBookReport = await dispatch(getTradeSumListReport(user, filter));
	if (tradeBookReport) {
		fileDownload(new Blob([tradeBookReport as any], { type: "application/pdf" }), "TradeBook.pdf");
	}
	dispatch(setPrintTradeFetchState({ type: "not-started" }));
};

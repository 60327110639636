import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { loadMarketAllData } from "../thunks/loadMarketAllData";
import { FMarketListData } from "../../../foreign/mainBoard/types";
import { PageData, PageDataStatus, PageIndex } from "../../../models";
import { invalidateData } from "../../../foreign/mainBoard/marketsDataSlice";

export const useMarketData = () => {
  const pageData = useSelector<StoreType, PageData<FMarketListData> | undefined>(
    (s) => s.data.foreign.marketData.currentPage
  );

  const dispatch = useDispatch();
  const dispatchLoadMarketDataPage = React.useCallback(
    () => dispatch(loadMarketAllData()),
    [dispatch]
  );

  const dispatchInvalidateMarketData = React.useCallback(
    () => dispatch(invalidateData()),
    [dispatch]
  );

  return [pageData, dispatchLoadMarketDataPage, dispatchInvalidateMarketData] as [
    typeof pageData,
    typeof dispatchLoadMarketDataPage,
    typeof dispatchInvalidateMarketData,
  ];
};


export const usePagedData = <T>(index: PageIndex, data?: T[]) => 
  React.useMemo(() => {
    const startIndex = index.itemsPerPage * index.pageNumber
    const endIndex = startIndex + index.itemsPerPage
    return data && {
      currentPageData: data.slice(startIndex, endIndex),
      status: PageDataStatus.DataCurrent,
      totalCount: data.length
    } as PageData<T>
  }, [index, data])


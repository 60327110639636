import React from "react";
import { OnExchange } from "../../models";
import { LocalizationContext } from "../../../core/localization/Localization";
import { exhaustiveCheck } from "../../../core/util/functions";

export const useOnExchangeOptionsTranslator = () => {
  const { getString } = React.useContext(LocalizationContext);
  return React.useCallback(
    (onExch: OnExchange) => {
      switch (onExch) {
        case OnExchange.yes:
          return getString("portfOnExchYes");
        case OnExchange.no:
          return getString("portfOnExchNo");
        default:
          exhaustiveCheck(onExch);
      }
    },
    [getString]
  );
};

interface AccountPaymentSideOption {
  label: string;
  value: OnExchange;
}

export const useOnExchangeOptions = (): AccountPaymentSideOption[] => {
  const translator = useOnExchangeOptionsTranslator();
  return React.useMemo(
    () => [
      {
        label: translator(OnExchange.yes) as string,
        value: OnExchange.yes,
      },
      {
        label: translator(OnExchange.no) as string,
        value: OnExchange.no,
      },
    ],
    [translator]
  );
};

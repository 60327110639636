import React from "react";
import { useSecuritySelectData } from "../../../data/initialData/hooks/useSecuritySelectData";
import { SecuritySimpleList } from "../../../data/initialData/types";
import { AutocompleteSelectType, AutocompleteSelect } from "../common/AutocompleteSelect";
import {  OrderMarket } from "../../../data/domestic/orders/types";

export type SecuritySelectPropsType = Omit<AutocompleteSelectType<SecuritySimpleList>,"data" | "getLabel">

export const SecuritySelect: React.FunctionComponent<SecuritySelectPropsType> = props => {

	const securities = useSecuritySelectData(OrderMarket.Domestic);

	return (
		<AutocompleteSelect<SecuritySimpleList>
			{...props as AutocompleteSelectType<SecuritySimpleList>}
			data={securities}
			getLabel={renderSecurityLabel}
		/>
	);
};

const renderSecurityLabel = (securities: SecuritySimpleList | null) =>
securities ? `${securities.secData} ` : "";


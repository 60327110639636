import React from "react";
import { useDispatch } from "react-redux";
import { SecurityAccounts } from "../../../models";
//import { setSecurityAcc } from "../securityAccountsDataSlice";
import { loadSetOrderStatus } from "../thunks/loadSetOrderStatus";
import { ViewOrder } from "../../../domestic/orders/types";

export const useSetOrderStatus = () => {
    
    const dispatch = useDispatch();
	return React.useCallback((order: ViewOrder) => {
        console.log(order.orderId)

//        dispatch(setSecurityAcc(defAccount.accId));
        dispatch(loadSetOrderStatus(order.orderId));
        },
        [dispatch]
    );
};



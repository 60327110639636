import { ThunkType } from "../../../../core/store/reducers";
import { addSecurityApi } from "../api/addSecurity";
import { setNewOrderFetchState, invalidateOrders } from "../orderBookDataSlice";

export const loadAddSecurity = ( secid: string) : ThunkType => async (dispatch, getStore) => {
	const store = getStore();

console.log("Ovde Sam")

	if (store.data.auth.current.status !== "successful") {
		return undefined;
	}

	dispatch(setNewOrderFetchState({ type: "started" }));

	var { user } = store.data.auth.current;

	const result = await dispatch(addSecurityApi(user.userId, secid ))

	if (result && result.resCode === "OK") {
		dispatch(setNewOrderFetchState({ type: "successful", result: result.resDesc }));
		dispatch(invalidateOrders());
	} else 
	{
		dispatch(setNewOrderFetchState({ type: "error", errorCode: result?.resCode }));
	}

}

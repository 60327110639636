import { getEditLocation } from "../../../core/util/functions";
import { OrdersEditAction } from "../../../data/domestic/orders/types";

export const orderBookFLocation = "/foreign/orders"

export enum OrdersEditParts {
	BasicInfo = "basicinfo",
	Attributes = "attributes",
}

   export const getOrderBookFEditLocation = (
	action: OrdersEditAction = OrdersEditAction.New,
    part: OrdersEditParts = OrdersEditParts.BasicInfo,
    orderId?: string
) => getEditLocation(orderBookFLocation, part, orderId, undefined, action );

export const baseEdit = getOrderBookFEditLocation(":action" as any, ":editPart" as any, ":orderId?");